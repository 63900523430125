import useAnimation from 'assets/components/animation/hooks/Animation';
import EventCalendar from 'assets/components/eventCalendar/EventCalendar';
import ThemeEventCalendarDatepicker from 'assets/components/eventCalendar/themes/Theme.EventCalendar.Datepicker';
import Dropdown from 'assets/components/dropdown/Dropdown';
import ThemeDropdownPopup from 'assets/components/dropdown/themes/Theme.Dropdown.Popup';
import RenderProps from 'assets/components/helpers/RenderProps';
import Icon, { iconStyles } from 'assets/components/icon/Icon';
import { useTheme } from 'assets/hooks/theme/useTheme';
import React from 'react';
import useDateInput from './hooks/DateInput';

export enum DateInputStructure {
  formInput = 'formInput',
  input = 'input',
}
export type Verify = Utils.VerifyExtends<Component.Input.DateInput.Structure, typeof DateInputStructure>;
export default function DateInput<Name>(props: Component.Input.DateInput.Import<Name>) {
  const animation = useAnimation();

  const extend = useTheme(DateInputStructure, props, {
    animation: {
      formInput: {
        trigger: 'focus',
      },
    },
    animateIn: animation.animateIn,
    animateOut: animation.animateOut,
  });
  const { date, selectDate, min, max, isTempDateUpdated, tempDate, updateTempDate } = useDateInput(props);

  return (
    <>
      <div
        {...extend(DateInputStructure.formInput, true)}
        data-label={props.label}
        data-error={props.error || undefined}
        data-read-only={props.readOnly || undefined}
        children={
          <>
            <input
              {...extend(DateInputStructure.input)}
              name="dateInput"
              type="text"
              autoComplete="off"
              value={tempDate}
              placeholder={props.placeholder}
              onChange={(event) => updateTempDate(event.currentTarget.value)}
              onBlur={(event) => {
                if (isTempDateUpdated) {
                  selectDate(event.currentTarget.value);
                }
              }}
            />
            {RenderProps(props, 'icon')}
            {animation.isVisible && (
              <Dropdown
                class={ThemeDropdownPopup}
                horizontalPosition="auto_absolute"
                verticalPosition="auto_absolute"
                constrainment="unconstrained"
                animation={{ animate: animation.isIn }}
                render={
                  <EventCalendar
                    class={ThemeEventCalendarDatepicker}
                    isLocal={props.isLocal}
                    onDateChange={selectDate}
                    highlightedDate={date}
                    minDate={min}
                    maxDate={max}
                    prevIcon={<Icon class={iconStyles.common.left} />}
                    nextIcon={<Icon class={iconStyles.common.right} />}
                    disabled={props.disabled || props.readOnly}
                  />
                }
              />
            )}
          </>
        }
      />
    </>
  );
}
