import { addLog } from 'assets/components/feedback/Feedback';
import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { HttpStatus } from 'config/Api.Config';
import { first, isEmpty, set } from 'lodash';
import claimJobSaveApi from 'models/claims/claimJob/save/Api.ClaimJob.Save';
import { useClaimJobEdit } from '../ClaimEdit.Job.Hooks';
import ClaimEditJobFormDataPoints from './tabs/ClaimEdit.Job.Form.DataPoints';
import ClaimEditJobFormInfo from './tabs/ClaimEdit.Job.Form.Info';

export default function ClaimEditJobForm() {
  const { lang } = getLocales();
  const { userId } = useAuthStore();
  const { claimJob, claim, claimTypes, defaultStatusCode, setAction, reloadClaim, mapClaimJobDataPoints } =
    useClaimJobEdit();
  const onlyClaimType = claimTypes && claimTypes.length === 1;

  return (
    <>
      <ModalForm<Api.Core.ClaimJob.Save.IRequest>
        subcomponents={ThemeModalFormDefault}
        title={lang.edit}
        onSubmit={async (data) => {
          const res = await claimJobSaveApi({ ...data, claimTypeId: data.claimType.id });
          if (res.status === HttpStatus.ok) {
            addLog({ success: lang.saveSuccess });
            setAction('expanded');
          } else {
            addLog({ error: res.message });
          }
          reloadClaim();
        }}
        validation={(data, errors) => {
          if (!isEmpty(data.dataPoints) && data.dataPoints?.some((it) => it.isRequired && !it.value))
            set(errors, 'dataPoints._objectError', lang.required);
        }}
        default={
          claimJob
            ? {
                ...claimJob?.toJSON(),
                dataPoints: claimJob?.dataPoints
                  ? claimJob?.dataPoints
                  : onlyClaimType && mapClaimJobDataPoints(first(claimTypes)?.claimTypeDataPoints),
              }
            : {
                ...claimJob?.toJSON(),
                claimTypeId: onlyClaimType ? first(claimTypes)?.id : null,
                cause: undefined,
                correction: undefined,
                customerComplaint: undefined,
                claimId: claim?.id,
                jobNumber: (claim?.claimJobs?.length || 0) + 1,
                statusCode: defaultStatusCode,
                ownerId: userId,
                dataPoints: onlyClaimType && mapClaimJobDataPoints(first(claimTypes)?.claimTypeDataPoints),
              }
        }
        tabs={[
          { id: 'info', icon: 'fas-info', text: lang.info },
          { id: 'dataPoints', icon: 'fas-table', text: lang.details },
        ]}
        onCancel={() => setAction('expanded')}
        render={({ activeTab }) =>
          activeTab.id == 'info' ? (
            <ClaimEditJobFormInfo />
          ) : activeTab.id == 'dataPoints' ? (
            <ClaimEditJobFormDataPoints />
          ) : null
        }
      />
    </>
  );
}
