import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import useForm from 'assets/components/form/hooks/Form';
import Modal from 'assets/components/page/templates/Modal';
import ThemeModalDefault from 'assets/components/page/templates/themes/Theme.Modal.Default';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { values } from 'lodash';
import contractSaveUnrestrictedApi from 'models/sales/contract/saveUnrestricted/Api.Contract.SaveUnrestricted';
import { SalesContractAction, useSalesContractProvider } from '../Sales.Contract.Hooks';
import DataObjectMap from 'assets/components/dataObjectMap/DataObjectMap';
import ThemeTableSimple from 'assets/components/table/themes/Theme.Table.Simple';
import FormatValue from 'assets/components/formatValue/FormatValue';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import Icon from 'assets/components/icon/Icon';
import Theme from 'assets/themes/Theme.Common';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';

export default function SalesContractAdjustDistributions() {
  const { lang } = getLocales();
  const { isDealer } = useAuthStore();
  const { selected, findApi, listApi, pageRouter } = useSalesContractProvider();

  const saveApi = useApi({
    action: contractSaveUnrestrictedApi,
    callback: () => {
      pageRouter.updateParams({ id: selected.id.toString(), action: SalesContractAction.INFO });
      listApi.execute((body) => body);
      findApi.execute((body) => body);
    },
    wait: true,
  });
  const form = useForm<Partial<Model.IContract>>({
    default: {
      originalPriceCAD: selected?.originalPriceCAD,
      distributorPriceCAD: selected?.distributorPriceCAD,
      salePriceCAD: selected?.salePriceCAD,
      discountPriceCAD: selected?.discountPriceCAD,
      pricingDistributions: selected?.pricingDistributions,
      distributorDistributions: selected?.distributorDistributions,
    },
  });

  function sumAll(distributions?: Utils.PricingDistribution) {
    return values(distributions).reduce((p, c) => p + c.amount, 0) ?? 0;
  }
  const isSameSum =
    sumAll(form.data.pricingDistributions) === sumAll(selected?.pricingDistributions) &&
    sumAll(form.data.distributorDistributions) === sumAll(selected?.distributorDistributions);

  return (
    <Modal
      header={lang.adjustDistributions}
      subcomponents={ThemeModalDefault}
      onClose={() => pageRouter.updateParams({ id: selected.id.toString(), action: SalesContractAction.INFO })}
      render={
        <>
          <p
            style={{
              backgroundColor: Theme.Color.warning,
              color: 'white',
              padding: `${Theme.Size.M} ${Theme.Size.L}`,
              fontWeight: 'bold',
            }}
          >
            Changes made here will just override prices changed bellow and will not create revisions nor do any actions
            like sign documents or similar!
          </p>
          {!isSameSum && (
            <p
              style={{
                backgroundColor: Theme.Color.error,
                color: 'white',
                padding: `${Theme.Size.M} ${Theme.Size.L}`,
                fontWeight: 'bold',
              }}
            >
              Sum of changed prices need to match original sum!
            </p>
          )}
          <FormWrapper>
            <InputWrapper>
              <NumberInput
                name="originalPriceCAD"
                value={form.data.originalPriceCAD}
                label={lang.originalPrice}
                onChange={form.update}
                icon={<Icon class={'fas-dollar-sign'} />}
                min={0}
                step={0.01}
                roundOnBlur
              />
              <NumberInput
                name="distributorPriceCAD"
                value={form.data.distributorPriceCAD}
                label={lang.distributorPrice}
                onChange={form.update}
                icon={<Icon class={'fas-dollar-sign'} />}
                min={0}
                step={0.01}
                roundOnBlur
              />
              <NumberInput
                name="discountPriceCAD"
                value={form.data.discountPriceCAD}
                label={lang.adjustedPrice}
                onChange={form.update}
                icon={<Icon class={'fas-dollar-sign'} />}
                min={0}
                step={0.01}
                roundOnBlur
              />
              <NumberInput
                name="salePriceCAD"
                value={form.data.salePriceCAD}
                label={lang.salePrice}
                onChange={form.update}
                icon={<Icon class={'fas-dollar-sign'} />}
                min={0}
                step={0.01}
                roundOnBlur
              />
            </InputWrapper>
          </FormWrapper>
          <table className={ThemeTableSimple}>
            <thead>
              <tr>
                <th>{lang.pricingDistributions}</th>
                <th>{lang.originalPrice}</th>
                <th>{lang.adjustedPrice}</th>
              </tr>
            </thead>
            <tbody>
              <DataObjectMap
                data={form.data.pricingDistributions}
                render={({ data: pricingInfo, key }) => {
                  const old = selected.pricingDistributions[key];
                  return (
                    <tr key={key}>
                      <td>{key}</td>
                      <td>
                        <FormatValue value={`${(old?.amount ?? 0).toFixed(2)}$`} />
                      </td>
                      <td>
                        <NumberInput
                          name="amount"
                          value={pricingInfo.amount}
                          onChange={({ amount }) =>
                            form.update({
                              pricingDistributions: {
                                ...form.data.pricingDistributions,
                                [key]: {
                                  ...pricingInfo,
                                  amount,
                                },
                              },
                            })
                          }
                          style={{
                            formInput: {
                              minWidth: '157px',
                            },
                            input: {
                              textAlign: 'right',
                            },
                          }}
                          icon={<Icon class={'fas-dollar-sign'} />}
                          min={0}
                          step={0.01}
                          roundOnBlur
                        />
                      </td>
                    </tr>
                  );
                }}
              />
            </tbody>
            {!isDealer && (
              <>
                <thead>
                  <tr>
                    <th>{lang.distributorDistributions}</th>
                    <th>{lang.originalPrice}</th>
                    <th>{lang.adjustedPrice}</th>
                  </tr>
                </thead>
                <tbody>
                  <DataObjectMap
                    data={form.data.distributorDistributions}
                    render={({ data: pricingInfo, key }) => {
                      const old = selected.distributorDistributions[key];
                      return (
                        <tr key={key}>
                          <td>{key}</td>
                          <td>
                            <FormatValue value={`${(old?.amount ?? 0).toFixed(2)}$`} />
                          </td>
                          <td>
                            <NumberInput
                              name="amount"
                              value={pricingInfo.amount}
                              onChange={({ amount }) =>
                                form.update({
                                  distributorDistributions: {
                                    ...form.data.distributorDistributions,
                                    [key]: {
                                      ...pricingInfo,
                                      amount,
                                    },
                                  },
                                })
                              }
                              style={{
                                formInput: {
                                  minWidth: '157px',
                                },
                                input: {
                                  textAlign: 'right',
                                },
                              }}
                              icon={<Icon class={'fas-dollar-sign'} />}
                              min={0}
                              step={0.01}
                              roundOnBlur
                            />
                          </td>
                        </tr>
                      );
                    }}
                  />
                </tbody>
              </>
            )}
          </table>
        </>
      }
      footer={
        <Button
          class={ThemeButtonCircle}
          render={lang.save}
          disabled={!isSameSum}
          onClick={() => saveApi.execute({ ...selected, ...form.data })}
          active={isSameSum}
        />
      }
    />
  );
}
