import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import RenderProps from 'assets/components/helpers/RenderProps';
import Icon from 'assets/components/icon/Icon';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import CustomEventBuilder from 'assets/hooks/events/builder/customEventBuilder';
import { getLocales } from 'assets/locales/Locale';
import ObjectTable from 'assets/templates/objectTable/ObjectTable';
import { useState } from 'react';
import './PricingDistributionTable.scss';

type Props<T> = {
  columnKey: string;
  columns?: string[];
  rows?: T[];
  getPrice: (row: T) => Utils.PricingDistribution;
  updatePrice: (row: T, distribution: Utils.PricingDistribution) => void;
  priceOption?: Utils.RenderProps<{
    row: T;
    distributedValue: Utils.PricingInfo;
    distributionKey: string;
  }>;
  isEditDisabled: boolean;
};
const { dispatch, useEvent } = CustomEventBuilder<{ id: string }>({ eventName: 'PricingDistributionTable' });
export const dispatchPricingDistribution = dispatch;
export const usePricingDistributionEvent = useEvent;
export default function PricingDistributionTable<T>({
  columnKey,
  columns,
  rows,
  getPrice,
  updatePrice,
  priceOption,
  isEditDisabled,
}: Props<T>) {
  const { lang } = getLocales();
  const [isOpen, setIsOpen] = useState(false);

  useEvent(
    {
      callback: (event) => {
        setIsOpen((old) => (columnKey === event.id ? !old : false));
      },
    },
    [columnKey]
  );
  return !(columns && rows) ? null : (
    <>
      <Button
        class={ThemeButtonCircle}
        style={{ button: { display: 'inline-block' } }}
        media="fas-divide"
        active={isOpen}
        onClick={dispatch}
        data={{ data: { id: columnKey } }}
        disabled={!columns.length || !rows.length}
        htmlElementProps={{ title: lang.openPricingDistributionTable }}
      />
      {isOpen && (
        <ObjectTable
          className={'PricingDistributionTable'}
          paths={columns}
          data={rows}
          focusOnLoad
          translateHeader
          render={({ path: col, data: row }) => {
            const distribution = getPrice(row);
            const distributedValue = distribution && distribution[col];

            return (
              <td key={col}>
                <NumberInput
                  name="amount"
                  htmlElementProps={{ title: col.replace(/\./gi, ' | ') }}
                  value={distributedValue?.amount}
                  onChange={({ amount }) =>
                    updatePrice(row, {
                      ...distribution,
                      [col]: { amount, currency: 'CAD' },
                    } as Utils.PricingDistribution)
                  }
                  style={{
                    formInput: {
                      minWidth: '157px',
                    },
                    input: {
                      textAlign: 'right',
                    },
                  }}
                  icon={<Icon class={'fas-dollar-sign'} />}
                  min={0}
                  step={0.01}
                  roundOnBlur
                  iconBefore={RenderProps({ priceOption }, 'priceOption', {
                    row,
                    distributedValue,
                    distributionKey: col,
                  })}
                  disabled={!isEditDisabled}
                />
              </td>
            );
          }}
        />
      )}
    </>
  );
}
