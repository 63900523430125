import DataMap from 'assets/components/dataMap/DataMap';
import FormatValue from 'assets/components/formatValue/FormatValue';
import Icon from 'assets/components/icon/Icon';
import TextBox from 'assets/components/textBox/TextBox';
import { getLocales } from 'assets/locales/Locale';
import { isValidValue } from 'assets/utils/parsersAndValidation/Validators';

export default function ValueDisplay(props: Template.ValueDisplay.Import) {
  const { lang } = getLocales();

  return Array.isArray(props.value) ? (
    props.value.length > 0 ? (
      <DataMap
        data={props.value}
        render={({ data, index }) => (
          <TextBox
            key={index}
            text={<FormatValue objKey={props.objKey} value={data || lang.empty} />}
            {...props.subcomponents?.listValue}
          />
        )}
      />
    ) : (
      <TextBox text={lang.empty} {...props.subcomponents?.empty} />
    )
  ) : typeof props.value === 'boolean' ? (
    <Icon class={props.value ? 'fas-check' : 'fas-times'} />
  ) : isValidValue(props.value) ? (
    <TextBox text={<FormatValue objKey={props.objKey} value={props.value} />} {...props.subcomponents?.validValue} />
  ) : (
    <TextBox text={lang.empty} {...props.subcomponents?.empty} />
  );
}
