import Box from 'assets/components/box/Box';
import Button from 'assets/components/button/Button';
import LinkButton from 'assets/components/button/LinkButton';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import FormatValue from 'assets/components/formatValue/FormatValue';
import Icon from 'assets/components/icon/Icon';
import ThemeTagRounded from 'assets/components/tag/themes/Theme.Tag.Rounded';
import TextBox, { textBoxStyles } from 'assets/components/textBox/TextBox';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { defaultDateFormat, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import dayjs from 'dayjs';
import { SalesContractAction } from 'module/sales/views/contract/Sales.Contract.Hooks';
import { salesContractIndexRoute } from 'module/sales/views/contract/Sales.Contract.Index';
import { useClaimEdit } from '../../ClaimEdit.Hooks';
import ClaimEditClientInfoForm from './ClaimEdit.ClientInfo.Form';
import ClaimEditContactInfoForm from './ClaimEdit.ContactInfo.Form';
import ClaimEditContactInfo from './others/ClaimEdit.ContactInfo';

export const paragraphStyles: React.CSSProperties = {
  display: 'grid',
  flexWrap: 'wrap',
  gap: `${Theme.Size.M} ${Theme.Size.L}`,
  gridTemplateColumns: 'repeat(auto-fill, minmax(10em, 1fr))',
};
export default function ClaimEditInfo() {
  const { lang } = getLocales();
  const {
    claim,
    contract,
    selectClaim,
    selectedClaim,
    reloadClaim,
    canEditClientInfo,
    isClaimCanceled,
    isEditable,
    claimEditAction,
    setClaimEditAction,
  } = useClaimEdit();

  return !claim ? null : (
    <div style={{ marginLeft: Theme.Size.XL, marginRight: Theme.Size.XL }}>
      <h2>Client info</h2>
      <div style={paragraphStyles}>
        <TextBox
          class={textBoxStyles.block.flexBlock}
          title="First name:"
          subtitle={contract?.client?.firstName ?? 'N/A'}
        />
        <TextBox
          class={textBoxStyles.block.flexBlock}
          title="Last name:"
          subtitle={contract?.client?.lastName ?? 'N/A'}
        />
        <TextBox class={textBoxStyles.block.flexBlock} title="Phone:" subtitle={claim.contactPhone ?? 'N/A'} />
        <TextBox class={textBoxStyles.block.flexBlock} title="Email address:" subtitle={claim.contactEmail ?? 'N/A'} />
        <TextBox
          class={textBoxStyles.block.flexBlock}
          title="Street address:"
          subtitle={claim.streetAddress ?? 'N/A'}
        />
        <TextBox
          class={textBoxStyles.block.flexBlock}
          title="Muncipality:"
          subtitle={contract?.client?.muncipality ?? 'N/A'}
        />
        <TextBox class={textBoxStyles.block.flexBlock} title="Postal code:" subtitle={claim.postalCode ?? 'N/A'} />
        <TextBox class={textBoxStyles.block.flexBlock} title="Province:" subtitle={claim.provinceCode ?? 'N/A'} />
        <TextBox class={textBoxStyles.block.flexBlock} title="Country:" subtitle={claim.countryCode ?? 'N/A'} />
        <TextBox
          class={textBoxStyles.block.flexBlock}
          title="Tax status (exempt):"
          subtitle={contract?.client?.taxExempt ? <Icon class="fas-check" /> : <Icon class="fas-times" />}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: Theme.Size.S, marginTop: Theme.Size.L }}>
        <Button
          class={ThemeButtonCircle}
          disabled={!canEditClientInfo || isClaimCanceled}
          data={claim}
          onClick={(data) => {
            setClaimEditAction('editClientInfo');
            selectClaim(data);
          }}
          media={<Icon class="fas-edit" />}
          style={{ display: 'inline-block' }}
        >
          Edit client info
        </Button>
        {canEditClientInfo && claim.id === selectedClaim?.id && (
          <ClaimEditClientInfoForm onSave={reloadClaim} onCancel={() => selectClaim(undefined)} data={claim} />
        )}
      </div>
      <br />
      <hr />
      <ClaimEditContactInfo claim={claim} />
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: Theme.Size.S, marginTop: Theme.Size.L }}>
        <Button
          class={ThemeButtonCircle}
          disabled={!isEditable || isClaimCanceled}
          data={claim}
          onClick={(data) => {
            selectClaim(data);
            setClaimEditAction('editContactInfo');
          }}
          media={<Icon class="fas-edit" />}
          style={{ display: 'inline-block' }}
        >
          Edit contact info
        </Button>
        {claimEditAction === 'editContactInfo' && isEditable && claim.id === selectedClaim?.id && (
          <ClaimEditContactInfoForm onSave={reloadClaim} onCancel={() => selectClaim(undefined)} data={claim} />
        )}
      </div>
      <br />

      <hr />
      <h2>Vehicle info</h2>
      <div style={paragraphStyles}>
        <TextBox class={textBoxStyles.block.flexBlock} title="Year:" subtitle={contract?.vehicle?.year} />
        <TextBox class={textBoxStyles.block.flexBlock} title="Make:" subtitle={contract?.vehicle?.makeCode} />
        <TextBox class={textBoxStyles.block.flexBlock} title="Model:" subtitle={contract?.vehicle?.model} />
        <TextBox class={textBoxStyles.block.flexBlock} title="Trim level:" subtitle={contract?.vehicle?.trimLevel} />
        <TextBox class={textBoxStyles.block.flexBlock} title="VIN:" subtitle={contract?.vehicle?.vin} />
        <TextBox class={textBoxStyles.block.flexBlock} title="Body type:" subtitle={contract?.vehicle?.bodyType} />
        <TextBox class={textBoxStyles.block.flexBlock} title="Engine:" subtitle={contract?.vehicle?.engineInfo} />
        <TextBox class={textBoxStyles.block.flexBlock} title="Engine type:" subtitle={contract?.vehicle?.engineType} />
        <TextBox
          class={textBoxStyles.block.flexBlock}
          title="Purchase price:"
          subtitle={
            contract?.vehicle?.purchasePrice ? (
              <FormatValue value={`$${contract?.vehicle?.purchasePrice?.toFixed(2)}`} />
            ) : (
              'N/A'
            )
          }
        />
        <TextBox
          class={textBoxStyles.block.flexBlock}
          title="Delivery date:"
          subtitle={
            (contract?.vehicle?.deliveryDate &&
              dayjs(contract?.vehicle?.deliveryDate, defaultServerDateTimeFormat).format(defaultDateFormat)) ??
            'N/A'
          }
        />
        <TextBox
          class={textBoxStyles.block.flexBlock}
          title="Original In-service date:"
          subtitle={
            (contract?.vehicle?.inServiceDate &&
              dayjs(contract?.vehicle?.inServiceDate, defaultServerDateTimeFormat).format(defaultDateFormat)) ??
            'N/A'
          }
        />
        <TextBox
          class={textBoxStyles.block.flexBlock}
          title="Purchase state:"
          subtitle={contract?.vehicle?.purchaseState}
        />
      </div>
      <br />
      <hr />
      <h2>Contract info</h2>
      <div style={paragraphStyles}>
        <TextBox
          class={textBoxStyles.block.flexBlock}
          title={`${lang.appNumber}:`}
          subtitle={contract?.appNumber + (contract?.revisionNumber ? `-R${contract?.revisionNumber}` : '')}
        />
        <TextBox
          class={textBoxStyles.block.flexBlock}
          title="Effective date:"
          subtitle={
            (contract?.effectiveDate &&
              dayjs(contract?.effectiveDate, defaultServerDateTimeFormat).format(defaultDateFormat)) ??
            'N/A'
          }
        />
        <TextBox
          class={textBoxStyles.block.flexBlock}
          title="Expiration date:"
          subtitle={
            (contract?.expiryDate &&
              dayjs(contract?.expiryDate, defaultServerDateTimeFormat).format(defaultDateFormat)) ??
            'N/A'
          }
        />
        <TextBox
          class={textBoxStyles.block.flexBlock}
          title="Selling dealership:"
          subtitle={contract?.dealer?.name ?? 'N/A'}
        />
        <TextBox
          class={textBoxStyles.block.flexBlock}
          title="Term in months:"
          subtitle={contract?.termInMonths ?? 'N/A'}
        />
        <TextBox class={textBoxStyles.block.flexBlock} title="Deductible:" subtitle={contract?.deductible ?? 'N/A'} />
        <TextBox
          class={textBoxStyles.block.flexBlock}
          title="Contract status:"
          subtitle={
            <Box
              class={ThemeTagRounded}
              style={{
                backgroundColor: contract?.statusCode === 'PAID_ACTIVE' ? Theme.Color.success : Theme.Color.error,
                fontSize: Theme.FontSize.S,
              }}
            >
              {contract?.statusCode?.replace('_', ' - ') ?? 'N/A'}
            </Box>
          }
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: Theme.Size.S, marginTop: Theme.Size.L }}>
        <LinkButton
          class={ThemeButtonCircle}
          url={contract?.documentationAggregate?.url}
          disabled={!contract?.documentationAggregate?.url}
          media={<Icon class="fas-download" />}
          target="_blank"
        >
          Download contract document {contract?.appNumber}
        </LinkButton>
        <Button
          class={ThemeButtonCircle}
          disabled={!contract?.id}
          onClick={(data) => {
            window.open(
              createPathFromRoute(salesContractIndexRoute, {
                ...data,
              }),
              '_blank'
            );
          }}
          data={{ id: contract?.id?.toString(), action: SalesContractAction.INFO }}
          media={<Icon class="fas-external-link-square-alt" />}
          style={{ display: 'inline-block' }}
        >
          View contract
        </Button>
      </div>
    </div>
  );
}
