import LinkButton from 'assets/components/button/LinkButton';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import DataMap from 'assets/components/dataMap/DataMap';
import { useModalForm } from 'assets/components/form/modalForm/ModalForm';
import Icon from 'assets/components/icon/Icon';
import FileInput from 'assets/components/inputs/file/FileInput';
import ThemeFileInputDropzone from 'assets/components/inputs/file/themes/Theme.FileInput.Dropzone';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { compact, first, uniq } from 'lodash';
import Product from 'models/productManagement/product/Model.Product';
import React, { Fragment, useMemo } from 'react';
import { useDealerProvider } from '../../Dealer.Hooks';
import { DealerForm } from '../Dealer.Edit';

const DealerProductDocumentForm: React.FC<{
  additionalProductIds: number[];
  setAdditionalProductIds: React.Dispatch<React.SetStateAction<number[]>>;
}> = ({ additionalProductIds }) => {
  const { form } = useModalForm<DealerForm>();
  const { selected } = useDealerProvider();
  const { lang } = getLocales();

  const productIds = useMemo(() => {
    const ids = uniq([...compact(selected?.products?.map((p) => p.id)), ...additionalProductIds]);
    return ids.length ? ids : undefined;
  }, [selected?.products, additionalProductIds]);
  const { payload: productsPayload } = useApi(
    {
      action: Product.list,
      wait: !productIds,
      body: {
        productId: productIds,
      },
    },
    [productIds]
  );
  const products = productsPayload?.data;

  return (
    <FormWrapper>
      <DataMap
        data={products}
        render={({ data: product }) =>
          !product.productDocuments.length ? null : (
            <Fragment key={product.id}>
              <h3>{product.name}</h3>
              <DataMap
                data={product.productDocuments}
                render={({ data: productDocument }) => {
                  let currentDocs = form.data.productDealerDocuments ?? [];
                  const foundDocs = currentDocs.filter(
                    (d) => d.productDocumentId === productDocument.id && !d.isDeleted
                  );
                  const dealerProductDocumentMediaFile = currentDocs?.find(
                    (d) => d.id && d.productDocumentId === productDocument.id
                  )?.mediaFile;

                  return (
                    <Fragment key={productDocument.id}>
                      <FileInput
                        name="files"
                        fileLimit={1}
                        value={foundDocs?.map((it) => it.formFile ?? { id: it.id, name: it.mediaFile?.name })}
                        onChange={({ files }) => {
                          currentDocs = currentDocs.map((d) =>
                            d.productDocumentId === productDocument.id ? { ...d, isDeleted: true } : d
                          );
                          if (files.length) {
                            currentDocs.push({
                              productDocumentId: productDocument.id,
                              productDocument,
                              formFile: first(files),
                            });
                          }
                          form.update({ productDealerDocuments: currentDocs });
                        }}
                        class={ThemeFileInputDropzone}
                        label={<b>{productDocument.displayInfo.title}</b>}
                      />
                      {dealerProductDocumentMediaFile && (
                        <LinkButton
                          media={<Icon class="fas-download" />}
                          class={ThemeButtonCircle}
                          style={{ alignSelf: 'flex-end' }}
                          render={`${lang.download} - ${dealerProductDocumentMediaFile?.name}`}
                          url={dealerProductDocumentMediaFile?.url}
                        />
                      )}
                    </Fragment>
                  );
                }}
              />
            </Fragment>
          )
        }
      />
    </FormWrapper>
  );
};
export default DealerProductDocumentForm;
