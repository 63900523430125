import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useTheme } from 'assets/hooks/theme/useTheme';
import RenderProps from 'assets/components/helpers/RenderProps';
import {
  dayOfWeek,
  daysOfWeek,
  monthsInYear,
  date as dateFunc,
  dateDef as dateDefFunc,
  defaultServerDateFormat,
} from 'assets/utils/data/Date';
import DataMap from 'assets/components/dataMap/DataMap';
import { preventDefaultBehavior } from 'assets/utils/dom/Events';
import { getLocales } from 'assets/locales/Locale';

export enum EventCalendarStructure {
  eventCalendar = 'eventCalendar',
  previewDate = 'previewDate',
  previewWeek = 'previewWeek',
  previewMonth = 'previewMonth',
  previewDay = 'previewDay',
  previewYear = 'previewYear',
  calendarDates = 'calendarDates',
  calendarMonthInfo = 'calendarMonthInfo',
  calendarNavButton = 'calendarNavButton',
  calendarNavTitle = 'calendarNavTitle',
  calendarDatesInfo = 'calendarDatesInfo',
  calendarDayOfWeekInfo = 'calendarDayOfWeekInfo',
  calendarDayInfo = 'calendarDayInfo',
}
export type Verify = Utils.VerifyExtends<Component.EventCalendar.Structure, typeof EventCalendarStructure>;
export default function EventCalendarCore(props: Component.EventCalendar.Import) {
  const extend = useTheme(EventCalendarStructure, props);
  const { translate } = getLocales();

  const useDate = props.isLocal ? dateDefFunc : dateFunc;

  const [date, setDate] = useState<dayjs.Dayjs>(() =>
    props.highlightedDate ? useDate(props.highlightedDate, defaultServerDateFormat) : useDate()
  );
  const firstVisibleDate = date.clone().startOf('month').startOf('isoWeek');
  const lastVisibleDate = date.clone().endOf('month').endOf('isoWeek');
  const daysShown = lastVisibleDate.diff(firstVisibleDate, 'days') + 1;

  const minDate = props.minDate ? useDate(props.minDate, defaultServerDateFormat) : null;
  const maxDate = props.maxDate ? useDate(props.maxDate, defaultServerDateFormat) : null;

  useEffect(() => {
    setDate(props.highlightedDate ? useDate(props.highlightedDate, defaultServerDateFormat) : useDate());
  }, [props.highlightedDate, props.isLocal]);

  return (
    <>
      <div
        {...extend(EventCalendarStructure.eventCalendar, true)}
        children={
          <>
            <div
              {...extend(EventCalendarStructure.previewDate)}
              children={
                <>
                  <div
                    {...extend(EventCalendarStructure.previewWeek)}
                    children={translate(daysOfWeek[dayOfWeek(date)].name)}
                  />
                  <div
                    {...extend(EventCalendarStructure.previewMonth)}
                    children={translate(monthsInYear[parseInt(date.format('M')) - 1].name)}
                  />
                  <div {...extend(EventCalendarStructure.previewDay)} children={date.format('DD')} />
                  <div {...extend(EventCalendarStructure.previewYear)} children={date.format('YYYY')} />
                </>
              }
            />
            <div
              {...extend(EventCalendarStructure.calendarDates)}
              children={
                <>
                  <div
                    {...extend(EventCalendarStructure.calendarMonthInfo)}
                    children={
                      <>
                        <div
                          {...extend(EventCalendarStructure.calendarNavButton)}
                          onMouseDown={(e) => preventDefaultBehavior(e, () => setDate(date.clone().add(-1, 'year')))}
                          children={RenderProps(props, 'prevIcon') || <>&lt;</>}
                        />
                        <div
                          {...extend(EventCalendarStructure.calendarNavTitle)}
                          children={<>{date.format('YYYY')}</>}
                        />
                        <div
                          {...extend(EventCalendarStructure.calendarNavButton)}
                          onMouseDown={(e) => preventDefaultBehavior(e, () => setDate(date.clone().add(1, 'year')))}
                          children={RenderProps(props, 'nextIcon') || <>&gt;</>}
                        />
                      </>
                    }
                  />
                  <div
                    {...extend(EventCalendarStructure.calendarMonthInfo)}
                    children={
                      <>
                        <div
                          {...extend(EventCalendarStructure.calendarNavButton)}
                          onMouseDown={(e) => preventDefaultBehavior(e, () => setDate(date.clone().add(-1, 'month')))}
                          children={RenderProps(props, 'prevIcon') || <>&lt;</>}
                        />
                        <div
                          {...extend(EventCalendarStructure.calendarNavTitle)}
                          children={<>{translate(monthsInYear[parseInt(date.format('M')) - 1].name)}</>}
                        />
                        <div
                          {...extend(EventCalendarStructure.calendarNavButton)}
                          onMouseDown={(e) => preventDefaultBehavior(e, () => setDate(date.clone().add(1, 'month')))}
                          children={RenderProps(props, 'nextIcon') || <>&gt;</>}
                        />
                      </>
                    }
                  />
                  <div
                    {...extend(EventCalendarStructure.calendarDatesInfo)}
                    children={
                      <>
                        <DataMap
                          data={daysOfWeek}
                          render={({ data: dayOfWeek }) => (
                            <div
                              {...extend(EventCalendarStructure.calendarDayOfWeekInfo)}
                              key={dayOfWeek.id}
                              children={dayOfWeek.abbreviation}
                            />
                          )}
                        />
                        <DataMap
                          data={Array.from(Array(daysShown).keys())}
                          render={({ data: dayOffset }) => {
                            const d = firstVisibleDate.clone().add(dayOffset, 'days');
                            return (
                              <div
                                {...extend(EventCalendarStructure.calendarDayInfo)}
                                key={d.unix()}
                                data-active={
                                  props.highlightedDate && d.diff(date.clone().startOf('day'), 'days') == 0
                                    ? true
                                    : undefined
                                }
                                data-hidden={d.month() != date.month() ? true : null}
                                data-disabled={
                                  props.disabled || (minDate && minDate.isAfter(d)) || (maxDate && maxDate.isBefore(d))
                                    ? true
                                    : undefined
                                }
                                onClick={
                                  props.onDateChange
                                    ? () =>
                                        props.onDateChange!(d.format(defaultServerDateFormat), defaultServerDateFormat)
                                    : undefined
                                }
                                tabIndex={0}
                                children={d.format('DD')}
                              />
                            );
                          }}
                        />
                      </>
                    }
                  />
                </>
              }
            />
          </>
        }
      />
    </>
  );
}
