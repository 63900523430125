import { addLog } from 'assets/components/feedback/Feedback';
import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { formatFormPhone } from 'assets/utils/forms/formatFormPhone';
import { isEmail, isValidPhoneNumber } from 'assets/utils/parsersAndValidation/Validators';
import { ApiHeaderApplication, HttpStatus } from 'config/Api.Config';
import { set } from 'lodash';
import claimSaveApi from 'models/claims/claim/save/Api.Claim.Save';
import Payee from 'models/claims/payee/Model.Payee';
import PayeeType from 'models/claims/payeeType/Model.PayeeType';
import Dealer from 'models/dealerManagement/dealer/Model.Dealer';

type Props = {
  data?: Model.IClaim;
  onCancel(): void;
  onSave(): void;
};

const CreatedFromApplication = {
  PUBLIC: ApiHeaderApplication.public,
  ASC: ApiHeaderApplication.claims,
} as const;
const CreatedAs = {
  DEALERSHIP: PayeeType.DEALER_ID,
  REPAIR_FACILITY: PayeeType.REPAIR_FACILITY_ID,
  CUSTOMER: PayeeType.CLIENT_ID,
  OTHER: PayeeType.OTHER_ID,
} as const;

export default function ClaimEditContactInfoForm(props: Props) {
  const { lang } = getLocales();
  const dealerListApi = useApi({
    action: Dealer.list,
  });
  const repairFacilityListApi = useApi({
    action: Payee.list,
    body: { payeeTypeId: [PayeeType.REPAIR_FACILITY_ID] },
  });

  return (
    <ModalForm<
      Api.Core.Claim.Save.IRequest & {
        repairFacilityId: number;
      }
    >
      subcomponents={ThemeModalFormDefault}
      default={{
        ...props?.data,
        createdByPayee: {
          ...props?.data?.createdByPayee,
          name: props.data?.createdByPayee?.name,
          email: props.data?.createdByPayee?.email,
          phoneNumber: props.data?.createdByPayee?.phoneNumber,
        },
        createdFromApplicationId: props.data?.createdFromApplicationId,
      }}
      dependencyArray={[props.data]}
      title={lang.edit}
      onCancel={props.onCancel}
      onSubmit={async (data) => {
        const res = await claimSaveApi(data);
        if (res.status === HttpStatus.ok) {
          addLog({ success: lang.saveSuccess });
          props.onSave();
        } else {
          addLog({ error: res.message });
        }
        return props.onCancel();
      }}
      validation={(data, errors) => {
        if (!data?.createdByPayee?.name) set(errors, 'createdByPayee.name', lang.isRequired);
        if (!data?.createdByPayee?.payeeTypeId) set(errors, 'createdByPayee.payeeTypeId', lang.isRequired);
        if (!data?.createdByPayee?.email) set(errors, 'createdByPayee.email', lang.isRequired);
        if (data?.createdByPayee?.email && !isEmail(data?.createdByPayee?.email))
          set(errors, 'createdByPayee.email', lang.invalidFormatRequires.replace('{format}', 'example@example.com'));
        if (data?.createdByPayee?.phoneNumber && !isValidPhoneNumber(data?.createdByPayee?.phoneNumber))
          set(errors, 'createdByPayee.phoneNumber', lang.invalidFormatRequires.replace('{format}', '###-###-####'));
        if (!data?.createdByPayee?.email) set(errors, 'createdByPayee.email', lang.isRequired);
        if (!data?.dealerId && PayeeType.DEALER_ID == data.createdByPayee?.payeeTypeId)
          set(errors, 'dealerId', lang.isRequired);
      }}
      beforeUpdate={(data) => {
        if (data.createdByPayee.payeeTypeId !== PayeeType.DEALER_ID) data.dealerId = null;

        return data;
      }}
      render={({ form }) => (
        <FormWrapper>
          <SelectInput
            name="payeeTypeId"
            label={lang.createdBy}
            data={Object.entries(CreatedAs).map(([key, value]) => ({
              id: value,
              title: key.replace(/_/g, ' '),
            }))}
            value={form.data?.createdByPayee?.payeeTypeId}
            onChange={({ payeeTypeId }) =>
              form.merge({
                createdByPayee: { payeeTypeId },
              })
            }
            error={form.errors?.createdByPayee?.payeeTypeId}
          />
          {PayeeType.REPAIR_FACILITY_ID === form.data?.createdByPayee?.payeeTypeId && (
            <SelectInput
              name="repairFacilityId"
              label={lang.repairFacility}
              data={repairFacilityListApi.payload?.data?.map((it) => it.displayInfo)}
              value={form.data?.repairFacilityId}
              error={form.errors?.repairFacilityId}
              onChange={({ repairFacilityId }) => {
                const payee = repairFacilityListApi.payload?.data?.find((it) => it.id === repairFacilityId);
                form.merge({
                  repairFacilityId,
                  createdByPayee: {
                    name: payee?.name,
                    email: payee?.email,
                    payeeTypeId: payee?.payeeTypeId,
                    phoneNumber: payee?.phoneNumber,
                  },
                });
              }}
            />
          )}
          {PayeeType.DEALER_ID === form.data?.createdByPayee?.payeeTypeId && (
            <SelectInput
              name="dealerId"
              label={lang.dealership}
              data={dealerListApi.payload?.data?.map((it) => it.displayInfo)}
              value={form.data?.dealerId}
              error={form.errors?.dealerId}
              onChange={form.update}
            />
          )}
          <SelectInput
            name="createdFromApplicationId"
            label={lang.createdBy}
            data={Object.entries(CreatedFromApplication).map(([key, value]) => ({
              id: value,
              title: key,
            }))}
            value={form.data?.createdFromApplicationId}
            onChange={form.update}
            error={form.errors?.createdFromApplicationId}
          />
          <TextInput
            name="name"
            label={`${lang.contact} ${lang.name}`}
            value={form.data?.createdByPayee?.name}
            onChange={({ name }) =>
              form.merge({
                createdByPayee: { name },
              })
            }
            error={form.errors?.createdByPayee?.name}
          />
          <TextInput
            name="email"
            label={`${lang.contact} ${lang.email}`}
            value={form.data?.createdByPayee?.email}
            onChange={({ email }) =>
              form.merge({
                createdByPayee: { email },
              })
            }
            error={form.errors?.createdByPayee?.email}
          />
          <TextInput
            name="phone"
            label={`${lang.contact} ${lang.phoneNumber}`}
            value={form.data?.createdByPayee?.phoneNumber}
            onChange={({ phone }) =>
              form.merge({
                createdByPayee: { phoneNumber: formatFormPhone(form.data?.createdByPayee?.phoneNumber, phone) },
              })
            }
            error={form.errors?.createdByPayee?.phoneNumber}
          />
        </FormWrapper>
      )}
    />
  );
}
