import { date, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { combineStrings } from 'assets/utils/data/String';
import Model from 'models/Model';
import Lender from 'models/sales/lender/Model.Lender';
import VehicleModel from '../vehicleModel/Model.VehicleModel';
import vehicleDetailsApi from './details/Api.Vehicle.Details';
import { first, isEmpty } from 'lodash';
import convertMilesToKm from 'assets/utils/units/Util.Units.ConvertSpeedUnit';

export default class Vehicle
  extends Model<Model.IVehicle>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IVehicle>
{
  id: number;
  vin?: string;
  year: number;
  makeCode?: string;
  model?: string;
  bodyType?: string;
  bodyColor?: string;
  vehicleConditionCode?: string;
  posOdometerReading?: number;
  stockNumber?: string;

  engineSize: number;
  purchaseDate: string;
  deliveryDate: string;
  classDate: string;
  purchasePrice: number;
  inServiceDate: string;
  mfgPowertrainWarrantyMonths?: string;
  mfgPowertrainWarrantyKm?: string;
  mfgWholeVehicleWarrantyMonths?: string;
  mfgWholeVehicleWarrantyKm?: string;

  trimLevel?: string;
  engineType?: Model.Enum.EngineType;
  engineInfo?: string;
  driveLine?: string;
  purchaseState?: string;
  purchaseType?: string;
  financedAmount?: number;
  interestRate?: number;
  lenderId?: number;
  lender?: Lender;

  constructor(data: Partial<Model.IVehicle> | Utils.FormData<Model.IVehicle>, language?: Locale.Languages) {
    super({ lender: Lender, vehicleModel: VehicleModel }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title:
        combineStrings(
          ' ',
          this.model,
          this.makeCode,
          this.year && date(this.year, defaultServerDateTimeFormat).format('YYYY')
        ) || this.id.toString(),
      info: this.vin,
    };
  }

  // TODO: Remove vehicle details mappings from the frontend and handle them on the backend.
  static async details(body: Api.Core.Vehicle.Details.IRequest) {
    const { payload, ...rest } = await vehicleDetailsApi(body);
    const data = payload.query_responses?.response_data;
    const commonUsData = data.us_market_data?.common_us_data;
    const usMarketData = first(data.us_market_data?.us_styles);

    const basic_data =
      commonUsData?.basic_data ??
      usMarketData?.basic_data ??
      data.supplemental_data?.common_supplemental_data?.basic_data;

    const warranties = !isEmpty(commonUsData?.warranties)
      ? commonUsData?.warranties
      : !isEmpty(usMarketData?.warranties)
      ? usMarketData?.warranties
      : data.supplemental_data?.common_supplemental_data?.warranties;

    const engines = !isEmpty(commonUsData?.engines)
      ? commonUsData?.engines
      : !isEmpty(usMarketData?.engines)
      ? usMarketData?.engines
      : data.supplemental_data?.common_supplemental_data?.engines;

    function getWarrantyByType(searchName: Api.Core.Vehicle.Details.WarrantyType) {
      return warranties?.find((it) => it?.type === searchName);
    }

    const powertrainWarranty = getWarrantyByType('Drivetrain/Powertrain');
    const wholeVehicleWarranty = getWarrantyByType('Basic');

    return {
      ...rest,
      payload: new Vehicle({
        vin: body?.vin,
        year: basic_data && parseInt(basic_data?.year),
        makeCode: basic_data?.make,
        model: basic_data?.model,
        bodyType: `${basic_data?.doors} Door ${basic_data?.body_type}`,
        trimLevel: basic_data?.trim,
        engineType: first(engines)?.engine_type as Model.Enum.EngineType,
        engineInfo: first(engines)?.name,
        driveLine: basic_data?.drive_type,
        mfgPowertrainWarrantyMonths: powertrainWarranty?.months,
        mfgPowertrainWarrantyKm: convertMilesToKm(powertrainWarranty?.miles)?.toString(),
        mfgWholeVehicleWarrantyMonths: wholeVehicleWarranty?.months,
        mfgWholeVehicleWarrantyKm: convertMilesToKm(wholeVehicleWarranty?.miles)?.toString(),
      }),
    };
  }
}
