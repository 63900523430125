import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import statementEmailTemplateDeleteApi from 'models/core/statementEmailTemplate/delete/Api.StatementEmailTemplate.Delete';
import statementEmailTemplateSaveApi from 'models/core/statementEmailTemplate/save/Api.StatementEmailTemplate.Save';
import StatementEmailTemplate from 'models/core/statementEmailTemplate/Model.StatementEmailTemplate';
import { useMemo } from 'react';
import { statementEmailTemplateIndexRoute } from './StatementEmailTemplate.Index';

export enum StatementEmailTriggers {
  SendEmail = 'ANYSTATUS_SEND_EMAIL',
}

export enum StatementEmailTemplateAction {
  EDIT = 'edit',
  DELETE = 'delete',
}
export type VerifyStatementEmailTemplateAction = Utils.VerifyExtends<
  Module.Sales.StatementEmailTemplate.Actions,
  Utils.ValueOf<typeof StatementEmailTemplateAction>
>;
export default function useStatementEmailTemplate() {
  const { permissions } = useAuthStore();
  const pageRouter = usePageRouter<
    Module.Sales.StatementEmailTemplate.Params,
    Module.Sales.StatementEmailTemplate.Query
  >({
    route: statementEmailTemplateIndexRoute,
  });

  const canView =
    permissions.CREATE_EMAILTEMPLATE || permissions.EDIT_EMAILTEMPLATE || permissions.DELETE_EMAILTEMPLATE;
  const listApi = useApi({
    action: StatementEmailTemplate.list,
    default: { limit: defaultLimit, offset: defaultOffset },
    body: pageRouter.query.list,
    callback: (req) => pageRouter.redirect({}, { ...pageRouter.query, list: req }),
    wait: !canView,
  });

  const deleteApi = useApi({
    action: statementEmailTemplateDeleteApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const saveApi = useApi({
    action: statementEmailTemplateSaveApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  return {
    canView,
    pageRouter,
    listApi,
    saveApi,
    deleteApi,
    selected,
    permissions,
    views: {
      [StatementEmailTemplateAction.DELETE]: !!(
        pageRouter.params.action === StatementEmailTemplateAction.DELETE &&
        selected &&
        permissions.DELETE_EMAILTEMPLATE
      ),
      [StatementEmailTemplateAction.EDIT]: !!(
        pageRouter.params.action === StatementEmailTemplateAction.EDIT &&
        ((permissions.CREATE_EMAILTEMPLATE && !selected) || (permissions.EDIT_EMAILTEMPLATE && selected))
      ),
    },
  };
}

export function useStatementEmailTemplateProvider() {
  return useDataProvider<ReturnType<typeof useStatementEmailTemplate>>();
}
