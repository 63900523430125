import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import ErrorWrapper from 'assets/components/inputs/wrappers/ErrorWrapper';
import { getLocales } from 'assets/locales/Locale';
import BigHr from 'assets/templates/dividers/BigHr';
import Theme from 'assets/themes/Theme.Common';
import { combineStrings } from 'assets/utils/data/String';
import { useClaimEdit } from '../ClaimEdit.Hooks';
import ClaimEditJobAggregateInfo from './claimJobEdit/ClaimEdit.Job.AggregateInfo';
import ClaimEditJobComments from './claimJobEdit/ClaimEdit.Job.Comments';
import ClaimEditJobDelete from './claimJobEdit/ClaimEdit.Job.Delete';
import ClaimEditJobDocuments from './claimJobEdit/ClaimEdit.Job.Documents';
import ClaimEditJobEmailTemplate from './claimJobEdit/ClaimEdit.Job.EmailTemplate';
import ClaimEditJobForm from './claimJobEdit/claimJobForm/ClaimEdit.Job.Form';
import { ClaimJobEditProvider } from './claimJobEdit/ClaimEdit.Job.Hooks';
import ClaimEditJobInfo from './claimJobEdit/ClaimEdit.Job.Info';
import ClaimEditJobSubmitButton from './claimJobEdit/ClaimEdit.Job.SubmitButton';
import ClaimEditJobItems from './claimJobEdit/claimJobEditItem/ClaimEdit.Job.Items';

export default function ClaimEditJobs() {
  const { lang } = getLocales();
  const { claim, product, isClaimAdmin, isClaimOwner } = useClaimEdit();

  return (
    <>
      <div style={{ marginLeft: Theme.Size.XL, marginRight: Theme.Size.XL }}>
        {!claim?.claimJobs?.length && <h2>{lang.jobs}</h2>}
        {!claim?.claimJobs?.length && <ErrorWrapper children={lang.noJobs} />}
      </div>
      {!!claim?.claimJobs?.length && (
        <DataMap
          data={claim?.claimJobs}
          render={({ data: job, isFirst }) => {
            return (
              <ClaimJobEditProvider
                key={job.id}
                claimJobId={job.id}
                render={({
                  form,
                  claimTypeReport,
                  setAction,
                  claimJob,
                  action,
                  permissions,
                  isClaimJobEditable,
                  isClaimJobEditableByStatus,
                }) => {
                  return (
                    claimJob && (
                      <>
                        {form.data.emailTemplate && <ClaimEditJobEmailTemplate />}
                        {!isFirst && <BigHr />}
                        <div
                          id={`${lang.job}-${claimJob.id}`}
                          style={{
                            marginLeft: Theme.Size.XL,
                            marginRight: Theme.Size.XL,
                            justifyContent: isClaimJobEditable && 'space-between',
                          }}
                        >
                          <h2>
                            {combineStrings('-', product?.category?.code, claim?.id?.toString(), `J${job.jobNumber}`)}
                            <br />
                            {claimJob.claimType && (
                              <span style={{ fontSize: Theme.FontSize.L }}>
                                {claimJob.claimType?.displayInfo?.title}
                              </span>
                            )}
                            <br />
                            {!claimTypeReport && <ClaimEditJobAggregateInfo />}
                          </h2>
                          <ClaimEditJobInfo />
                          {isClaimJobEditable && !isClaimJobEditableByStatus && action === 'edit' && (
                            <ClaimEditJobForm />
                          )}
                          {isClaimJobEditable && !isClaimJobEditableByStatus && action === 'delete' && (
                            <ClaimEditJobDelete />
                          )}
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              gap: Theme.Size.S,
                              marginTop: Theme.Size.L,
                            }}
                          >
                            <>
                              {action === 'compressed' ? (
                                <Button
                                  onClick={setAction}
                                  data="expanded"
                                  media={<Icon class="fas-chevron-down" />}
                                  render={lang.viewMore}
                                  class={ThemeButtonCircle}
                                  htmlElementProps={{ title: lang.viewMore }}
                                />
                              ) : (
                                <Button
                                  onClick={setAction}
                                  data="compressed"
                                  media={<Icon class="fas-chevron-up" />}
                                  render={lang.viewLess}
                                  class={ThemeButtonCircle}
                                  htmlElementProps={{ title: lang.viewLess }}
                                />
                              )}
                              <Button
                                onClick={setAction}
                                data="edit"
                                media={<Icon class="fas-edit" />}
                                render={lang.edit}
                                class={ThemeButtonCircle}
                                disabled={isClaimJobEditableByStatus || !isClaimJobEditable}
                                htmlElementProps={{ title: lang.edit }}
                              />
                              <Button
                                onClick={setAction}
                                data="delete"
                                media={<Icon class="fas-trash" />}
                                render={lang.delete}
                                class={ThemeButtonCircle}
                                disabled={
                                  isClaimJobEditableByStatus ||
                                  !isClaimAdmin ||
                                  (!isClaimOwner && !permissions.DELETE_CLAIMJOB)
                                }
                                htmlElementProps={{ title: lang.delete }}
                              />
                              <ClaimEditJobSubmitButton />
                            </>
                          </div>
                        </div>
                        {action !== 'compressed' && (
                          <>
                            <br />
                            <hr />
                            <ClaimEditJobDocuments />
                            {!claimTypeReport && <ClaimEditJobItems />}
                            <br />
                            <hr />
                            <ClaimEditJobComments />
                          </>
                        )}
                      </>
                    )
                  );
                }}
              />
            );
          }}
        />
      )}
    </>
  );
}
