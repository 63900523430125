import Button from 'assets/components/button/Button';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import { combineStrings } from 'assets/utils/data/String';
import { get, uniq } from 'lodash';
import EmailTemplate from 'models/core/claimEmailTemplate/Model.ClaimEmailTemplate';
import { getNextStatus } from 'module/claims/hooks/useClaimsGlobalStore';
import { EmailAction } from 'module/claims/views/claimEdit/ClaimEdit.Hooks';
import { useEffect, useMemo, useState } from 'react';

type Props = {
  emailTemplates: EmailTemplate[];
  statusInfo: Model.ClaimStatusInfo;
  disabled?: boolean;
  isStatusChanged?: boolean;
  ownerId: number;
  currentStatus: Model.Enum.ClaimStatusCode;
  changeStatus: ({
    nextStatus,
    ownerId,
  }: {
    nextStatus: Model.Enum.ClaimStatusCode;
    ownerId?: number;
    owner?: Model.IUser;
  }) => void;
  setEmailAction: React.Dispatch<React.SetStateAction<EmailAction>>;
};
export default function ClaimStatusStateMachine({
  emailTemplates,
  statusInfo: statusInfoProps,
  isStatusChanged,
  currentStatus,
  changeStatus,
  disabled,
  setEmailAction,
}: Props) {
  const { lang } = getLocales();
  const { currentPermissions, userId, user } = useAuthStore();
  const { currentStatusInfo, nextStatusList, statusTree } = useMemo(() => {
    const { currentStatusInfo, nextStatusList } = getNextStatus(
      emailTemplates,
      statusInfoProps,
      currentPermissions,
      currentStatus
    );
    const fullStatusList = nextStatusList?.map((it) => it.nextStatus);
    const primaryList = uniq(nextStatusList?.map((it) => it.statusInfo.primary));
    const secondaryList = uniq(nextStatusList?.map((it) => it.statusInfo.secondary));
    const reasonList = uniq(nextStatusList?.map((it) => it.statusInfo.reason));
    const statusTree = primaryList.reduce(
      (dataP, p) => ({
        ...dataP,
        [p]: secondaryList
          .filter((s) => fullStatusList.some((f) => f.indexOf(combineStrings('_', p, s)) === 0))
          .reduce(
            (dataS, s) => ({
              ...dataS,
              [s]: reasonList
                .filter((r) => fullStatusList.some((f) => f.indexOf(combineStrings('_', p, s, r)) === 0))
                .reduce(
                  (dataR, r) => ({
                    ...dataR,
                    [r]: true,
                  }),
                  {}
                ),
            }),
            {}
          ),
      }),
      {}
    );
    return {
      currentStatusInfo,
      nextStatusList,
      statusTree,
    };
  }, [emailTemplates, statusInfoProps, currentStatus, currentPermissions]);

  const [statusInfo, setStatusInfo] = useState<{ primary?: string; secondary?: string; reason?: string }>({});
  useEffect(() => {
    setStatusInfo({});
  }, [currentStatus]);
  const { list, value, key } = useMemo(
    () => ({
      list: !statusInfo.primary
        ? Object.keys(statusTree || {})
        : !statusInfo.secondary
        ? Object.keys(get(statusTree, statusInfo.primary) || {})
        : Object.keys(get(statusTree, `${statusInfo.primary}.${statusInfo.secondary}`) || {}),
      value: !statusInfo.primary
        ? statusInfo.primary
        : !statusInfo.secondary
        ? statusInfo.secondary
        : statusInfo.reason,
      key: !statusInfo.primary ? 'primary' : !statusInfo.secondary ? 'secondary' : 'reason',
    }),
    [statusInfo, statusTree]
  );

  const selected = useMemo(() => {
    return nextStatusList?.find(
      (it) => it.nextStatus === combineStrings('_', statusInfo.primary, statusInfo.secondary, statusInfo.reason)
    );
  }, [statusInfo, nextStatusList]);

  const statusString = combineStrings(' - ', statusInfo.primary, statusInfo.secondary, statusInfo.reason);
  return (
    <DropdownButton
      buttonBody={isStatusChanged ? statusString : lang.changeStatus}
      buttonMedia={<Icon class="fas-check-double" />}
      horizontalPosition="auto_fixed"
      verticalPosition="auto_fixed"
      subcomponents={ThemeDropdownButtonPopup}
      disabled={!currentStatusInfo || !list.length || disabled}
      tabIndex={null}
      title={<span title={statusString} children={statusString} />}
      htmlElementProps={{ title: lang.changeStatus }}
      dropdownOptions={({ animateOut }) =>
        selected ? (
          <>
            <Button
              onClick={(status) => {
                changeStatus(status);
                setEmailAction('changeStatusSendEmail');
                animateOut();
              }}
              data={{
                nextStatus: selected.nextStatus,
                ownerId: selected.ownershipAllowed ? userId : null,
                owner: user,
              }}
              render={lang.apply}
              htmlElementProps={{
                title: `${lang.apply}: ${statusString}, ${
                  selected.ownershipAllowed ? lang.youWillKeepOwnership : lang.ownershipWillBeRemoved
                }`,
              }}
              media={selected.ownershipAllowed ? 'fas-user-check' : 'fas-user-times'}
              class={ThemeButtonMenuItem}
              active
            />
            <Button
              onClick={setStatusInfo}
              data={{}}
              render={lang.cancel}
              class={ThemeButtonMenuItem}
              htmlElementProps={{ title: lang.cancel }}
            />
          </>
        ) : (
          <DataMap
            data={list}
            render={({ data: statusPartition }) =>
              statusPartition !== 'undefined' && (
                <Button
                  key={statusPartition}
                  onClick={setStatusInfo}
                  data={{
                    ...statusInfo,
                    [key]: statusPartition,
                  }}
                  render={statusPartition}
                  class={ThemeButtonMenuItem}
                  active={statusPartition === value}
                />
              )
            }
          />
        )
      }
    />
  );
}
