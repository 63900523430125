import Box from 'assets/components/box/Box';
import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Collection from 'assets/components/data/Collection';
import HeaderData from 'assets/components/data/rows/HeaderData';
import HeaderRow from 'assets/components/data/rows/HeaderRow';
import Row from 'assets/components/data/rows/Row';
import RowData from 'assets/components/data/rows/RowData';
import RowImage from 'assets/components/data/rows/RowImage';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import DataMap from 'assets/components/dataMap/DataMap';
import FormatValue from 'assets/components/formatValue/FormatValue';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageError from 'assets/components/page/themes/Theme.Page.Error';
import ThemeTagRounded from 'assets/components/tag/themes/Theme.Tag.Rounded';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import Theme from 'assets/themes/Theme.Common';
import { defaultDateTimeFormat, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { combineStrings } from 'assets/utils/data/String';
import dayjs from 'dayjs';
import StatementStatusCode from 'models/enums/StatementStatusCode';
import { StatementAction, useStatementProvider } from '../Statement.Hooks';

export default function StatementList() {
  const { lang } = getLocales();
  const { listApi, pageRouter, payStatement, setSelectedStatements, selectedStatements, exportStatementToPdf } =
    useStatementProvider();
  const { permissions } = useAuthStore();

  return !listApi.payload && !listApi.isExecuting && listApi.apiCounter > 0 ? (
    <Page
      class={ThemePageError}
      sidebar={<Icon class="fas-clipboard-list" />}
      header={lang.statements}
      render={lang.noInfo}
    />
  ) : (
    <Collection class={ThemeCollectionList}>
      <HeaderRow>
        <HeaderData>
          {lang.dealer}/{lang.contracts}
        </HeaderData>
        <HeaderData>{`${lang.statement} #`}</HeaderData>
        <HeaderData>{lang.createdAt}</HeaderData>
        <HeaderData>{lang.representative}</HeaderData>
        <HeaderData style={{ justifyContent: 'flex-end' }}>{lang.amount}</HeaderData>
        <HeaderData style={{ justifyContent: 'flex-end' }}>{lang.tax}</HeaderData>
        <HeaderData style={{ justifyContent: 'flex-end' }}>{lang.totalAmount}</HeaderData>
        <HeaderData style={{ justifyContent: 'flex-end' }} />
      </HeaderRow>
      <DataMap
        data={listApi.payload?.data}
        render={({ data: statement }) => {
          const currentStatement = selectedStatements?.find((it) => it.id === statement.id);
          return (
            <Row
              key={statement.id}
              children={
                <>
                  <RowImage render={<Icon class="fas-user-shield" />} />
                  <RowData
                    {...statement.displayInfo}
                    subtitle={
                      <>
                        {statement.isPayed && (
                          <Box class={ThemeTagRounded} style={{ backgroundColor: Theme.Color.success }}>
                            <Icon class="fas-check" />
                            &nbsp; Paid
                          </Box>
                        )}
                        {statement?.isEmailSent && (
                          <Box class={ThemeTagRounded} style={{ backgroundColor: Theme.Color.success }}>
                            <Icon class="fas-check" />
                            &nbsp; Email sent
                          </Box>
                        )}
                      </>
                    }
                  />
                  <RowData>{statement.statementNumber}</RowData>
                  <RowData>
                    {dayjs(statement?.createdAtLocal, defaultServerDateTimeFormat).format(defaultDateTimeFormat)}
                  </RowData>
                  <RowData>
                    {combineStrings(' ', statement.representative?.firstName, statement.representative?.lastName) ||
                      'N/A'}
                  </RowData>
                  <RowData style={{ alignItems: 'flex-end' }} disableAutoAlignment>
                    <b>
                      <FormatValue
                        value={`${(
                          statement.contracts.reduce((sum, obj) => sum + obj.paymentAmountCAD, 0) || 0
                        ).toFixed(2)}$`}
                      />
                    </b>
                  </RowData>
                  <RowData style={{ alignItems: 'flex-end' }} disableAutoAlignment>
                    <b>
                      <FormatValue
                        value={`${(
                          statement.contracts.reduce(
                            (sum, obj) => sum + (obj.client.taxExempt ? 0 : obj.paymentTaxCAD),
                            0
                          ) || 0
                        ).toFixed(2)}$`}
                      />
                    </b>
                  </RowData>
                  <RowData style={{ alignItems: 'flex-end' }} disableAutoAlignment>
                    <b>
                      <FormatValue value={`${(statement.totalAmountDueCAD || 0).toFixed(2)}$`} />
                    </b>
                  </RowData>
                  <RowData style={{ flexDirection: 'column', alignItems: 'flex-end' }} disableAutoAlignment>
                    <div style={{ display: 'flex' }}>
                      <Button
                        onClick={pageRouter.updateParams}
                        data={{
                          id: statement.id.toString(),
                          action: StatementAction.CHANGE_STATUS,
                        }}
                        disabled={
                          !permissions.UPDATE_STATEMENT_STATUS ||
                          statement.status === StatementStatusCode.CANCELED ||
                          statement.status === StatementStatusCode.RECONCILED
                        }
                        class={ThemeButtonCircle}
                        media={<Icon class="fas-check-double" />}
                        htmlElementProps={{ title: lang.changeStatus }}
                      />
                      <Button
                        onClick={pageRouter.updateParams}
                        data={{
                          id: statement.id.toString(),
                          action: StatementAction.UPLOAD_DOCUMENT,
                          router: pageRouter,
                        }}
                        class={ThemeButtonCircle}
                        media={<Icon class="fas-upload" />}
                        htmlElementProps={{ title: lang.upload }}
                      />
                      <Button
                        onClick={pageRouter.updateParams}
                        data={{
                          id: statement.id.toString(),
                          action: StatementAction.PRINT,
                          router: pageRouter,
                        }}
                        class={ThemeButtonCircle}
                        media={<Icon class="fas-print" />}
                        htmlElementProps={{ title: lang.print }}
                      />
                      <Button
                        onClick={() => exportStatementToPdf(statement.id, statement.statementNumber)}
                        class={ThemeButtonCircle}
                        media={<Icon class="fas-download" />}
                        htmlElementProps={{ title: lang.download }}
                      />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        onClick={pageRouter.updateParams}
                        data={{
                          id: statement.id.toString(),
                          action: StatementAction.SEND_EMAIL,
                          router: pageRouter,
                        }}
                        class={ThemeButtonCircle}
                        media={<Icon class="fas-envelope" />}
                        htmlElementProps={{ title: lang.sendEmail }}
                      />
                      <Button
                        onClick={() => payStatement(statement)}
                        disabled={statement.isPayed || statement.status === StatementStatusCode.RECONCILED}
                        class={ThemeButtonCircle}
                        media={<Icon class="fas-receipt" />}
                        htmlElementProps={{ title: lang.pay }}
                      />
                      <Button
                        onClick={pageRouter.updateParams}
                        data={{
                          id: statement.id.toString(),
                          action: StatementAction.DELETE,
                        }}
                        disabled={
                          !permissions.DELETE_STATEMENT ||
                          (statement.status !== StatementStatusCode.CANCELED &&
                            statement.status !== StatementStatusCode.OPEN)
                        }
                        class={ThemeButtonCircle}
                        media={<Icon class="fas-trash" />}
                        htmlElementProps={{ title: lang.delete }}
                      />
                      <Button
                        onClick={() => {
                          if (currentStatement)
                            setSelectedStatements((prevStatements) =>
                              prevStatements?.filter((it) => it.id !== statement.id)
                            );
                          else setSelectedStatements((prevStatements) => [...(prevStatements || []), statement]);
                        }}
                        disabled={!permissions.CREATE_STATEMENT || !permissions.EDIT_STATEMENT}
                        class={ThemeButtonCircle}
                        media={<Icon class={currentStatement ? 'fas-check-square' : 'far-square'} />}
                        htmlElementProps={{ title: currentStatement ? lang.unselect : lang.select }}
                      />
                      <div></div>
                    </div>
                  </RowData>
                </>
              }
            />
          );
        }}
      />
    </Collection>
  );
}
