import { combineStrings } from 'assets/utils/data/String';
import ClaimJobItemPayee from 'models/claims/claimJobItemPayee/Model.ClaimJobItemPayee';
import User from 'models/core/user/Model.User';
import Model from 'models/Model';

export default class ClaimJobItem
  extends Model<Model.IClaimJobItem>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IClaimJobItem>
{
  id: number;
  partNumber?: string;
  reason?: string;
  hoursWorked?: number = 0;
  claimJobItemPayee?: ClaimJobItemPayee;
  notes?: string;
  correction: number = 0;
  adjudication: number = 0;
  gstHst: number = 0;
  provincialSalesTax: number = 0;
  currency: string = 'CAD';
  jobItemTypeCode?: string;
  lossCode?: string;
  statusCode?: string;
  paymentMethodCode?: string = undefined;
  createdBy: User;

  labourRate?: number = 0;
  usBankVirtualCardId?: string;

  get total(): number {
    return this.adjudication + this.adjudication * (this.gstHst + this.provincialSalesTax);
  }

  constructor(data: Partial<Model.IClaimJobItem> | Utils.FormData<Model.IClaimJobItem>, language?: Locale.Languages) {
    super({ claimJobItemPayee: ClaimJobItemPayee, createdBy: User }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: combineStrings(', ', this.partNumber, this.jobItemTypeCode, this.lossCode) || this.id?.toString(),
    };
  }
}
