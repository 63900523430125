import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import DataMap from 'assets/components/dataMap/DataMap';
import useForm from 'assets/components/form/hooks/Form';
import EmailHtml from 'assets/components/html-templates/email/EmailTemplate';
import Icon from 'assets/components/icon/Icon';
import { inputStyles } from 'assets/components/inputs/Input';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import RichTextInput from 'assets/components/inputs/richTextInput/RichTextInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import CheckWrapper from 'assets/components/inputs/wrappers/CheckWrapper';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import Modal from 'assets/components/page/templates/Modal';
import ThemeModalDefault from 'assets/components/page/templates/themes/Theme.Modal.Default';
import { getLocales } from 'assets/locales/Locale';
import { isEmail, isEmailDomain, isEmpty } from 'assets/utils/parsersAndValidation/Validators';
import EmailTemplate from 'models/core/claimEmailTemplate/Model.ClaimEmailTemplate';
import EmailTemplateTags from 'module/management/components/claimEmailTemplate/ClaimEmailTemplateTags';
import { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useClaimEdit } from '../../ClaimEdit.Hooks';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import { applicationOrigin } from 'config/Api.Config';

export default function ClaimEditSendEmail() {
  const { lang } = getLocales();
  const { setEmailAction, sendEmail, emailTemplates } = useClaimEdit();
  const [existingEmailTemplate, setExistingEmailTemplate] = useState<Model.IClaimEmailTemplate>(undefined);

  const form = useForm<Api.Core.ClaimEmail.SendEmail.IRequest>({
    default: {
      htmlBody: renderToString(EmailHtml({ body: 'Enter custom message here.', origin: applicationOrigin })),
      additionalRecipientEmails: [],
    },
    validation: (data, errors) => {
      if (!data.senderEmail) errors.senderEmail = lang.mustNotBeEmpty;
      if (data.senderEmail && !isEmailDomain(data.senderEmail, 'goautoshield.com'))
        errors.senderEmail = lang.mustBeEmailDomain.replace('{domain}', 'goautoshield.com');
      if (!data.senderName) errors.senderName = lang.mustNotBeEmpty;
      if (!data.subject) errors.subject = lang.mustNotBeEmpty;
      if (!data.htmlBody) errors.htmlBody = lang.mustNotBeEmpty;
      if (
        !data.sendToClaimOwner &&
        !data.sendToClient &&
        !data.sendToContactEmail &&
        (!data.additionalRecipientEmails || data?.additionalRecipientEmails?.every((it) => !isEmail(it)))
      )
        errors.additionalRecipientEmails = lang.mustNotBeEmpty;
    },
    onSubmit: (data) => sendEmail(new EmailTemplate(data)),
  });

  useEffect(() => {
    if (existingEmailTemplate) {
      form.update({ ...existingEmailTemplate });
    }
  }, [existingEmailTemplate]);

  return (
    <Modal
      subcomponents={ThemeModalDefault}
      header={lang.emailTemplate}
      onClose={() => setEmailAction(null)}
      render={
        <FormWrapper>
          <InputWrapper>
            <TextInput
              name="senderName"
              label={lang.senderName}
              value={form.data.senderName}
              error={form.errors.senderName}
              onChange={form.update}
              icon={<Icon class="if-quill-pen" />}
            />
            <TextInput
              name="senderEmail"
              label={lang.senderEmail}
              value={form.data.senderEmail}
              error={form.errors.senderEmail}
              onChange={form.update}
              icon={<Icon class="if-quill-pen" />}
            />
          </InputWrapper>
          <h2>{lang.sendTo}</h2>
          <CheckWrapper>
            <CheckInput
              name="sendToClient"
              label={lang.client}
              class={inputStyles.check.switch}
              value={form.data.sendToClient}
              onChange={form.update}
              htmlElementProps={{ title: form.data.sendToClient ? lang.disable : lang.enable }}
            />
            <CheckInput
              name="sendToContactEmail"
              label={lang.contact}
              class={inputStyles.check.switch}
              value={form.data.sendToContactEmail}
              onChange={form.update}
              htmlElementProps={{ title: form.data.sendToContactEmail ? lang.disable : lang.enable }}
            />
            <CheckInput
              name="sendToClaimOwner"
              label={lang.claimOwner}
              class={inputStyles.check.switch}
              value={form.data.sendToClaimOwner}
              onChange={form.update}
              htmlElementProps={{ title: form.data.sendToClaimOwner ? lang.disable : lang.enable }}
            />
          </CheckWrapper>
          <h2>{lang.additionalRecipientEmails}</h2>
          <DataMap
            data={[...(form.data.additionalRecipientEmails || []), '']}
            render={({ data: email, index }) => (
              <TextInput
                key={index}
                name="email"
                label={lang.email}
                value={email}
                error={form.errors.additionalRecipientEmails}
                onChange={({ email }) => {
                  let additionalRecipientEmails = form?.data?.additionalRecipientEmails || [];
                  additionalRecipientEmails.splice(index, 1, email);
                  additionalRecipientEmails = additionalRecipientEmails?.filter((it) => it);
                  form.merge({ additionalRecipientEmails: { ...form?.data?.additionalRecipientEmails } });
                }}
                icon={<Icon class="if-quill-pen" />}
              />
            )}
          />
          <h2>{lang.email}</h2>
          <TextInput
            name="subject"
            label={lang.subject}
            value={form.data.subject}
            error={form.errors.subject}
            onChange={form.update}
            icon={<Icon class="if-quill-pen" />}
          />
          <RichTextInput
            name="htmlBody"
            label={lang.htmlBody}
            value={form.data.htmlBody}
            error={form.errors.htmlBody}
            onChange={form.update}
            icon={<Icon class="if-quill-pen" />}
            customActions={<EmailTemplateTags />}
          />
        </FormWrapper>
      }
      footer={
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <DropdownButton
            buttonBody={lang.chooseExisting}
            buttonMedia={<Icon class="fas-binoculars" />}
            horizontalPosition="auto_fixed"
            verticalPosition="auto_fixed"
            subcomponents={ThemeDropdownButtonPopup}
            disabled={!emailTemplates || isEmpty(emailTemplates)}
            tabIndex={null}
            title={
              <span>
                {lang.chooseExisting} {lang.emailTemplate}
              </span>
            }
            dropdownOptions={() => (
              <DataMap
                data={emailTemplates}
                render={({ data }) => (
                  <Button
                    key={data.id}
                    onClick={({ emailTemplate }) => setExistingEmailTemplate(emailTemplate)}
                    data={{
                      emailTemplate: data,
                    }}
                    render={data.name}
                    class={ThemeButtonMenuItem}
                    active={data?.id === existingEmailTemplate?.id}
                  />
                )}
              />
            )}
          />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button onClick={setEmailAction} data={null} media="fas-ban" render={lang.cancel} class={ThemeButtonCircle} />
            <Button onClick={form.submit} render={lang.submit} class={ThemeButtonCircle} active />
          </div>
        </div>
      }
    />
  );
}
