import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Icon from 'assets/components/icon/Icon';
import MultilineTextInput from 'assets/components/inputs/multilineText/MultilineTextInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import Modal from 'assets/components/page/templates/Modal';
import ThemeModalDefault from 'assets/components/page/templates/themes/Theme.Modal.Default';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import Contract from 'models/sales/contract/Model.Contract';
import ClaimDocumentForm from 'module/public-web/views/claimSubmit/components/Claim.DocumentForm';
import ClaimDocumentPreview from '../other/Claim.DocumentPreview';
import useClaimSimpleClaim from './Claim.SimpleClaim.Hooks';
import { combineStrings } from 'assets/utils/data/String';
import { useEffect, useMemo, useState } from 'react';
import { first, isEmpty } from 'lodash';
import FileInput from 'assets/components/inputs/file/FileInput';
import ThemeFileInputDropzone from 'assets/components/inputs/file/themes/Theme.FileInput.Dropzone';
import Card from 'assets/components/card/Card';
import ThemeCardGridItem from 'assets/components/card/themes/Theme.Card.GridItem';
import LinkButton from 'assets/components/button/LinkButton';
import TextInput from 'assets/components/inputs/text/TextInput';
import ObjectTable from 'assets/templates/objectTable/ObjectTable';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import ClaimTypeDataPointInputType from 'models/enums/ClaimTypeDataPointInputType';
import ValidationRegex from 'assets/utils/data/Utils.Data.ValidationRegex';
import DateInput from 'assets/components/inputs/dateInput/DateInput';
import useForm from 'assets/components/form/hooks/Form';

export default function ClaimSimpleClaimForm(props: { contract: Contract; onClose: () => void }) {
  const { lang } = getLocales();
  const { form, claimTypes, onlyClaimType, claimTypeValue, uploadableDocuments } = useClaimSimpleClaim(props);
  const claimJob = first(form.data?.claimJobs);
  function updateClaimJob(claimJob: Utils.FormData<Model.IClaimJob>) {
    form.merge({ claimJobs: [{ ...claimJob }] });
  }

  //#region Documents
  const [document, selectDocument] = useState<Model.IClaimTypeDocument>();
  useEffect(() => {
    selectDocument(uploadableDocuments[0]);
  }, [uploadableDocuments]);
  const docForm = useForm<Model.IClaimJobDocument>(
    {
      default: !document
        ? {}
        : {
            name: document.name,
            claimTypeDocumentId: document.id,
            approved: document.approvalRequired ? false : true,
            formFiles: [],
          },
      validation: (data, errors) => {
        if (!data.claimTypeDocumentId) errors.claimTypeDocumentId = lang.required;
        if (isEmpty(data?.formFiles) && document?.required) errors.formFiles = lang.required;
      },
      onSubmit: async (data) => {
        const updatedClaimJobs = [...(form.data?.claimJobs || [])];
        first(updatedClaimJobs).claimJobDocuments = data?.formFiles?.map((formFile) => ({
          ...data,
          formFile,
        }));
        form.merge({ claimJobs: updatedClaimJobs });
      },
    },
    [document]
  );
  const formFiles = useMemo(
    () =>
      docForm.data.formFiles &&
      docForm.data.formFiles.map((it) => ({
        original: it,
        type: it.type as Utils.File.MimeType,
        dataUrl: window.URL.createObjectURL(it),
      })),
    [docForm.data.formFiles]
  );
  //#endregion

  //#region DataPoints
  const updateValue = (value: string, id: number) => {
    const updatedDataPoints = first(form.data?.claimJobs)?.dataPoints?.map((item) =>
      item.claimTypeDataPointId === id ? { ...item, value } : item
    );
    updateClaimJob({ ...first(form.data?.claimJobs), dataPoints: updatedDataPoints });
  };
  useEffect(() => {
    const claimJob = first(form.data?.claimJobs);
    const newDataPoints = claimTypes
      ?.find((it) => it.id === claimJob?.claimTypeId)
      ?.claimTypeDataPoints?.map((it) => ({
        claimTypeDataPointId: it.id,
        claimTypeId: it.claimTypeId,
        name: it.name,
        type: it.type,
        version: it.version,
        isRequired: it.isRequired,
        value: undefined,
      }));

    updateClaimJob({ ...claimJob, dataPoints: newDataPoints });
  }, [first(form.data?.claimJobs)?.claimTypeId]);
  //#endregion

  return (
    <Modal
      subcomponents={ThemeModalDefault}
      header={lang.createSimpleClaim}
      onClose={props.onClose}
      render={
        <FormWrapper>
          {!onlyClaimType && (
            <SelectInput
              name="claimTypeId"
              label={lang.claimType}
              data={claimTypes?.map((it) => it.displayInfo)}
              value={claimTypeValue}
              onChange={({ claimTypeId }) => updateClaimJob({ ...claimJob, claimTypeId })}
              icon={<Icon class="fas-clipboard-list" />}
            />
          )}
          <MultilineTextInput
            name="customerComplaint"
            label={lang.customerComplaint}
            value={claimJob?.customerComplaint}
            onChange={({ customerComplaint }) => updateClaimJob({ ...claimJob, customerComplaint })}
          />
          <MultilineTextInput
            name="cause"
            label={lang.causeOfFailure}
            value={claimJob?.cause}
            onChange={({ cause }) => updateClaimJob({ ...claimJob, cause })}
          />
          <MultilineTextInput
            name="correction"
            label={lang.correctionDetails}
            value={claimJob?.correction}
            onChange={({ correction }) => updateClaimJob({ ...claimJob, correction })}
          />
          {!!uploadableDocuments?.length && (
            <div style={{ marginLeft: Theme.Size.L, marginRight: Theme.Size.L }}>
              <h2 style={{ display: 'flex', justifyContent: 'space-between' }}>
                {`${lang.claim} ${lang.documents}`}
                <span>
                  <SelectInput
                    name="docId"
                    style={{
                      formInput: {
                        maxWidth: Theme.Size.pixel(240),
                      },
                    }}
                    placeholder={`${lang.claim} ${lang.documents} ...`}
                    data={uploadableDocuments?.map((document) => ({
                      id: document.id,
                      title: combineStrings(' - ', document.name, document.source),
                    }))}
                    value={document?.id}
                    onChange={({ docId }) => selectDocument(uploadableDocuments.find((it) => it.id === docId))}
                    icon={<Icon class="fas-file" />}
                  />
                </span>
              </h2>
              <ClaimDocumentPreview
                documents={first(form.data?.claimJobs)?.claimJobDocuments?.map((it, docIdx) => ({
                  id: it.id,
                  name: it.name,
                  onDelete: () => {
                    const tempDocs = [...(first(form.data?.claimJobs)?.claimJobDocuments || [])];
                    const newDocs = tempDocs?.filter((_, tempIdx) => docIdx !== tempIdx);
                    const updatedClaimJobs = [...(form.data?.claimJobs || [])];
                    first(updatedClaimJobs).claimJobDocuments = [...newDocs];
                    form.merge({ claimJobs: updatedClaimJobs });
                  },
                }))}
              />
              <br />
              <FileInput
                name="files"
                fileLimit={document?.allowMultiple ? 5 : 1}
                value={formFiles}
                onChange={({ files }) => {
                  const updatedFormFiles = files?.map((formFile) => formFile && formFile?.original);
                  docForm.update({ formFiles: updatedFormFiles });
                }}
                class={ThemeFileInputDropzone}
                disabled={!document}
                error={docForm.errors.formFiles?._objectError}
                renderBefore={
                  !document ? (
                    lang.youNeedToSelectOneDocument
                  ) : (
                    <Card
                      class={ThemeCardGridItem}
                      style={{
                        cardMedia: { width: 0, height: 0 },
                        card: { width: '400px', borderRadius: '10px' },
                        cardActions: { marginBottom: '5px' },
                      }}
                      actions={
                        document.example && (
                          <LinkButton
                            class={ThemeButtonCircle}
                            style={{ fontSize: Theme.FontSize.M, margin: 0 }}
                            url={document.example}
                            media={<Icon class="fas-external-link-alt" />}
                            render={lang.example}
                            target="_blank"
                          />
                        )
                      }
                      info={document.source}
                      title={combineStrings(' - ', document.name)}
                      description={document.description}
                    />
                  )
                }
                renderAfter={
                  <>
                    <TextInput
                      name="name"
                      label={lang.name}
                      value={docForm.data.name}
                      onChange={docForm.update}
                      error={docForm.errors.name}
                    />
                    &nbsp;
                    {document?.allowNotes && (
                      <MultilineTextInput
                        name="notes"
                        label={lang.notes}
                        value={docForm.data.notes}
                        onChange={docForm.update}
                        error={docForm.errors.notes}
                      />
                    )}
                  </>
                }
              />
              <br />
              <Button
                render={lang.add}
                media={<Icon class="fas-upload" />}
                onClick={docForm.submit}
                disabled={docForm.hasErrors || !document}
                active={!docForm.hasErrors}
                class={ThemeButtonCircle}
                style={{ marginLeft: 'auto' }}
              />
              <br />
            </div>
          )}
          {!!first(form.data?.claimJobs)?.dataPoints?.length && (
            <div style={{ marginLeft: Theme.Size.L, marginRight: Theme.Size.L }}>
              <h2 style={{ display: 'flex', justifyContent: 'space-between' }}>{lang.details}</h2>
              <ObjectTable
                paths={[]}
                data={first(form.data?.claimJobs)?.dataPoints}
                renderRow={({ data: row }) => (
                  <tr key={row.claimTypeDataPointId}>
                    <td>{row.name}</td>
                    <td>
                      {row.type === ClaimTypeDataPointInputType.Number ? (
                        <NumberInput
                          name="value"
                          value={Number(row.value)}
                          error={row.isRequired && !row.value && lang.isRequired}
                          onChange={({ value }) => updateValue(String(value), row?.claimTypeDataPointId)}
                        />
                      ) : row.type === ClaimTypeDataPointInputType.Any ? (
                        <TextInput
                          name="value"
                          value={row.value}
                          error={row.isRequired && !row.value && lang.isRequired}
                          onChange={({ value }) => updateValue(value, row?.claimTypeDataPointId)}
                        />
                      ) : row.type === ClaimTypeDataPointInputType.Text ? (
                        <TextInput
                          name="value"
                          value={row.value}
                          error={row.isRequired && !row.value && lang.isRequired}
                          onChange={({ value }) => {
                            if (ValidationRegex.letters.test(value)) updateValue(value, row?.claimTypeDataPointId);
                          }}
                        />
                      ) : row.type === ClaimTypeDataPointInputType.Alphanumeric ? (
                        <TextInput
                          name="value"
                          value={row.value}
                          error={row.isRequired && !row.value && lang.isRequired}
                          onChange={({ value }) => {
                            if (!ValidationRegex.alphanumeric.test(value))
                              updateValue(value, row?.claimTypeDataPointId);
                          }}
                        />
                      ) : row.type === ClaimTypeDataPointInputType.Date ? (
                        <DateInput
                          name="value"
                          value={row.value}
                          error={row.isRequired && !row.value && lang.isRequired}
                          onChange={({ value }) => updateValue(value, row?.claimTypeDataPointId)}
                        />
                      ) : null}
                    </td>
                  </tr>
                )}
              />
            </div>
          )}
          <h2 style={{ display: 'flex', alignItems: 'center' }}>
            {lang.documents}
            <Button
              class={ThemeButtonCircle}
              style={{ marginLeft: 'auto', fontSize: Theme.FontSize.M }}
              render={form.data.isAddingNewDocument ? lang.cancel : lang.add}
              media={<Icon class={form.data.isAddingNewDocument ? 'fas-ban' : 'fas-plus'} />}
              data={{ isAddingNewDocument: !form.data.isAddingNewDocument }}
              onClick={form.update}
              active={form.data.isAddingNewDocument}
              htmlElementProps={{ title: form.data.isAddingNewDocument ? lang.ban : lang.add }}
            />
          </h2>
          <ClaimDocumentPreview
            documents={[
              ...form.data.claimDocuments.map((it) => ({
                id: it.id,
                url: it.mediaFile?.url,
                name: it?.name,
                extension: it.mediaFile?.extension,
                description: it?.notes,
                info: lang.new,
              })),
            ]}
          />
          <br />
          {form.data.isAddingNewDocument && (
            <ClaimDocumentForm
              onSubmit={(document) => form.update({ claimDocuments: [...form.data.claimDocuments, document] })}
            />
          )}
        </FormWrapper>
      }
      footer={
        <>
          <Button
            onClick={form.submit}
            media={<Icon class="fas-save" />}
            render={lang.save}
            active={!form.hasErrors && form.isUpdated}
            disabled={form.hasErrors || !form.isUpdated}
            isLoading={form.isSubmitted}
            class={ThemeButtonCircle}
            htmlElementProps={{ title: lang.save }}
          />
        </>
      }
    />
  );
}
