import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { groupBy, isEmpty } from 'lodash';
import ClaimJob from 'models/claims/claimJob/Model.ClaimJob';
import claimJobProcessUsBankPaymentApi from 'models/claims/claimJob/processUsBankPayment/Api.ClaimJob.ProcessUsBankPayment';
import ClaimJobItem from 'models/claims/claimJobItem/Model.ClaimJobItem';
import ClaimJobItemPayee from 'models/claims/claimJobItemPayee/Model.ClaimJobItemPayee';
import payeeListApi from 'models/claims/payee/list/Api.Payee.List';
import { CountryCode, CountryCodeFallback } from 'models/enums/CountryCode';
import LineItem from 'models/enums/LineItem';
import PaymentStatus from 'models/enums/PaymentStatus';
import { useEffect, useMemo } from 'react';
import ClaimEditJobAggregateTable from '../ClaimEdit.Job.AggregateTable';
import { useClaimJobEdit } from '../ClaimEdit.Job.Hooks';
import ClaimEditJobSubmitButton from '../ClaimEdit.Job.SubmitButton';
import ClaimEditJobDeductibleTable from './tables/ClaimEdit.Job.Deductible.Table';
import ClaimEditJobLabourTable from './tables/ClaimEdit.Job.Labour.Table';
import ClaimEditJobOtherTable from './tables/ClaimEdit.Job.Other.Table';
import ClaimEditJobPartTable from './tables/ClaimEdit.Job.Part.Table';

export default function ClaimEditJobItems() {
  const { lang } = getLocales();
  const {
    form,
    claimType,
    updateJobItem,
    removeJobItem,
    updateMultipleJobItems,
    reload,
    getJobItemDefaultValues,
    isWarrantyProduct,
    isClaimJobEditableByStatus,
    isClaimJobEditable,
    newPayeeId,
  } = useClaimJobEdit();

  const aggregates = ClaimJob.aggregates(new ClaimJob(form.data));
  const itemMap = useMemo(
    () =>
      groupBy(form?.data?.claimJobItems, (it) => {
        const countryCode =
          it.claimJobItemPayee?.payee?.countryCode ??
          it.claimJobItemPayee?.client?.countryCode ??
          it.claimJobItemPayee?.dealer?.countryCode ??
          CountryCode.CAN;

        return countryCode === CountryCode.CAN || countryCode === CountryCodeFallback.CA
          ? CountryCode.CAN
          : CountryCode.USA;
      }),
    [form.data.claimJobItems]
  );

  useEffect(() => {
    (async () => {
      if (newPayeeId) {
        const payeeList = await payeeListApi();
        const newPayee = payeeList?.payload?.data?.find((it) => it.id === newPayeeId);
        if (newPayee) {
          const tempClaimJobItems = form?.data?.claimJobItems || [];
          const claimJobItemPayee = new ClaimJobItemPayee({
            payee: newPayee,
            payeeId: newPayee.id,
            payeeType: newPayee.payeeType,
          });

          const updatedClaimJobItems = tempClaimJobItems?.map((it) =>
            it.claimJobItemPayee
              ? it
              : {
                  ...it,
                  claimJobItemPayee,
                  ...getJobItemDefaultValues({ jobItemTypeCode: it.jobItemTypeCode, claimJobItemPayee }),
                }
          );
          form.update({ claimJobItems: updatedClaimJobItems });
        }
      }
    })();
  }, [newPayeeId]);

  return (
    <>
      <div style={{ marginLeft: Theme.Size.XL, marginRight: Theme.Size.XL }}>
        <h2>{lang.jobDetails}</h2>
      </div>
      {!!itemMap[CountryCode.CAN]?.length && (
        <>
          <ClaimEditJobPartTable
            items={itemMap[CountryCode.CAN]
              .map((item, index) => ({
                item,
                index,
              }))
              .filter((it) => it.item?.jobItemTypeCode === LineItem.part)}
            isWarrantyProduct={isWarrantyProduct}
            isAddDisabled={isClaimJobEditableByStatus || !isClaimJobEditable}
            updateJobItem={updateJobItem}
            removeJobItem={removeJobItem}
            lossCodes={claimType?.lossCodes ?? []}
            currency="CAD"
          />
          <ClaimEditJobLabourTable
            items={itemMap[CountryCode.CAN]
              .map((item, index) => ({
                item,
                index,
              }))
              .filter((it) => it.item?.jobItemTypeCode === LineItem.labour)}
            isWarrantyProduct={isWarrantyProduct}
            isAddDisabled={isClaimJobEditableByStatus || !isClaimJobEditable}
            updateJobItem={updateJobItem}
            removeJobItem={removeJobItem}
            lossCodes={claimType?.lossCodes ?? []}
            currency="CAD"
          />
          {isWarrantyProduct && (
            <ClaimEditJobDeductibleTable
              items={itemMap[CountryCode.CAN]
                .map((item, index) => ({
                  item,
                  index,
                }))
                .filter((it) => it.item?.jobItemTypeCode === LineItem.deductible)}
              isWarrantyProduct={isWarrantyProduct}
              updateJobItem={updateJobItem}
            />
          )}
          <ClaimEditJobOtherTable
            items={itemMap[CountryCode.CAN]
              .map((item, index) => ({
                item,
                index,
              }))
              .filter((it) => it.item?.jobItemTypeCode === LineItem.other)}
            isWarrantyProduct={isWarrantyProduct}
            isAddDisabled={isClaimJobEditableByStatus || !isClaimJobEditable}
            updateJobItem={updateJobItem}
            removeJobItem={removeJobItem}
            lossCodes={claimType?.lossCodes ?? []}
            currency="CAD"
          />
          &nbsp;
          <ClaimEditJobAggregateTable
            currency="CAD"
            aggregates={aggregates.filter((it) => it.currency === 'CAD')}
            onUpdate={({ claimJobItemPayee, ...rest }) => updateMultipleJobItems(claimJobItemPayee, rest)}
            onPaymentInit={async (paymentInfo) => {
              await claimJobProcessUsBankPaymentApi(paymentInfo);
              reload();
            }}
          />
        </>
      )}
      {!!itemMap[CountryCode.USA]?.length && !!itemMap[CountryCode.CAN]?.length ? <br /> : undefined}
      {!!itemMap[CountryCode.USA]?.length && (
        <>
          <div style={{ marginLeft: Theme.Size.XL, marginRight: Theme.Size.XL }}>
            <h3>{CountryCode.USA}</h3>
          </div>
          <ClaimEditJobPartTable
            items={itemMap[CountryCode.USA]
              .map((item, index) => ({
                item,
                index,
              }))
              .filter((it) => it.item?.jobItemTypeCode === LineItem.part)}
            updateJobItem={updateJobItem}
            removeJobItem={removeJobItem}
            isAddDisabled={isClaimJobEditableByStatus || !isClaimJobEditable}
            lossCodes={claimType?.lossCodes ?? []}
            currency="USD"
          />
          <ClaimEditJobLabourTable
            items={itemMap[CountryCode.USA]
              .map((item, index) => ({
                item,
                index,
              }))
              .filter((it) => it.item?.jobItemTypeCode === LineItem.labour)}
            updateJobItem={updateJobItem}
            removeJobItem={removeJobItem}
            isAddDisabled={isClaimJobEditableByStatus || !isClaimJobEditable}
            lossCodes={claimType?.lossCodes ?? []}
            currency="USD"
          />
          <ClaimEditJobOtherTable
            items={itemMap[CountryCode.USA]
              .map((item, index) => ({
                item,
                index,
              }))
              .filter((it) => it.item?.jobItemTypeCode === LineItem.other)}
            updateJobItem={updateJobItem}
            removeJobItem={removeJobItem}
            isAddDisabled={isClaimJobEditableByStatus || !isClaimJobEditable}
            lossCodes={claimType?.lossCodes ?? []}
            currency="USD"
          />
          &nbsp;
          <ClaimEditJobAggregateTable
            currency="USD"
            aggregates={aggregates.filter((it) => it.currency === 'USD')}
            onUpdate={({ claimJobItemPayee, ...rest }) => updateMultipleJobItems(claimJobItemPayee, rest)}
          />
        </>
      )}
      <div
        style={{
          marginLeft: Theme.Size.XL,
          marginRight: Theme.Size.XL,
          display: 'flex',
          justifyContent: 'flex-end',
          gap: Theme.Size.S,
          marginTop: Theme.Size.L,
        }}
      >
        <Button
          class={ThemeButtonCircle}
          media="fas-plus"
          onClick={() =>
            updateJobItem(
              new ClaimJobItem({
                statusCode: PaymentStatus.unpaid,
                currency: 'USD',
                claimJobItemPayee: { payee: { countryCode: CountryCode.USA } } as Model.IClaimJobItemPayee,
              }).toJSON()
            )
          }
          render={`${lang.create} ${'USA'} table`}
          disabled={isClaimJobEditableByStatus || !isClaimJobEditable || !isEmpty(itemMap[CountryCode.USA])}
        />
        <ClaimEditJobSubmitButton />
      </div>
    </>
  );
}
