import DataMap from 'assets/components/dataMap/DataMap';
import { inputStyles } from 'assets/components/inputs/Input';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import FileInput from 'assets/components/inputs/file/FileInput';
import ThemeFileInputDropzone from 'assets/components/inputs/file/themes/Theme.FileInput.Dropzone';
import MultilineTextInput from 'assets/components/inputs/multilineText/MultilineTextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import { getLocales } from 'assets/locales/Locale';
import ObjectInfo from 'assets/templates/objectInfo/ObjectInfo';
import { combineStrings } from 'assets/utils/data/String';
import { pick } from 'lodash';
import { Fragment } from 'react';
import { useSalesNewContractProvider } from '../Sales.NewContract.Hooks';

export default function SalesNewContractReview() {
  const { lang } = getLocales();
  const { form, updateProduct } = useSalesNewContractProvider();
  const areThereProductTemplates = form.data.products?.some((p) => !!p.documentsFromTemplates?.length);
  return (
    <div style={areThereProductTemplates ? { display: 'grid', gridTemplateColumns: '60% 40%' } : null}>
      {areThereProductTemplates && (
        <FormWrapper>
          <h2>{lang.supportingDocumentation}</h2>
          <DataMap
            data={form.data.products}
            render={({ data: product }) => (
              <Fragment key={product.id}>
                <DataMap
                  data={product.documentsFromTemplates}
                  render={({ data: documentTemplate, isFirst }) => {
                    const isRequired =
                      !!documentTemplate.id &&
                      documentTemplate.required &&
                      (documentTemplate.uploadedId || documentTemplate.mediaFileId
                        ? documentTemplate.isDeleted && !documentTemplate.formFile
                        : !documentTemplate.formFile);
                    return (
                      <Fragment key={documentTemplate.id}>
                        {!isFirst && <hr />}

                        <FileInput
                          name="file"
                          fileLimit={1}
                          value={
                            documentTemplate.formFile
                              ? [
                                  {
                                    original: documentTemplate.formFile,
                                    type: documentTemplate.formFile?.type as Utils.File.MimeType,
                                    dataUrl:
                                      documentTemplate.formFile &&
                                      window.URL.createObjectURL(documentTemplate.formFile),
                                  },
                                ]
                              : []
                          }
                          onChange={({ file }) => {
                            documentTemplate.formFile = file && file[0]?.original;
                            updateProduct({ ...product });
                          }}
                          class={ThemeFileInputDropzone}
                          disabled={!documentTemplate.id}
                          error={isRequired ? lang.mustNotBeEmpty : undefined}
                          label={
                            <>
                              <b>
                                {product.name} | {combineStrings(' - ', documentTemplate.name, documentTemplate.source)}
                              </b>
                              {documentTemplate.description && <p>{documentTemplate.description}</p>}
                              {!documentTemplate.id && (
                                <p>{`This document doesn't have matching template! Only allowed action is to keep or delete it!`}</p>
                              )}
                            </>
                          }
                          renderAfter={
                            <>
                              {documentTemplate.allowNotes && (
                                <>
                                  <br />
                                  <MultilineTextInput
                                    name="notes"
                                    label={lang.notes}
                                    value={documentTemplate.notes}
                                    onChange={({ notes }) => {
                                      documentTemplate.notes = notes;
                                      updateProduct({ ...product });
                                    }}
                                  />
                                </>
                              )}
                              {!!(documentTemplate.uploadedId || documentTemplate.mediaFileId) && (
                                <CheckInput
                                  name="isDeleted"
                                  class={inputStyles.check.switch}
                                  label={
                                    documentTemplate.mediaFileId
                                      ? 'Include draft version of this document'
                                      : 'Include previous version of this document'
                                  }
                                  value={!documentTemplate.isDeleted}
                                  onChange={() => {
                                    documentTemplate.isDeleted = !documentTemplate.isDeleted;
                                    updateProduct({ ...product });
                                  }}
                                />
                              )}
                            </>
                          }
                        />
                      </Fragment>
                    );
                  }}
                />
              </Fragment>
            )}
          />
        </FormWrapper>
      )}
      <FormWrapper>
        <ObjectInfo
          object={pick(
            form.data?.dealer,
            'name',
            'primaryPhoneNumber',
            'primaryEmailAddress',
            'streetAddress',
            'city',
            'provinceCode',
            'countryCode',
            'postalCode'
          )}
          objectName={lang.dealer}
        />
        <ObjectInfo
          object={pick(
            form.data?.client,
            'email',
            'firstName',
            'lastName',
            'phone',
            'unitNumber',
            'streetNumber',
            'streetName',
            'postalCode',
            'muncipality',
            'provinceCode',
            'countryCode',
            'taxExempt',
            'taxExemptionBand',
            'bandNumber'
          )}
          objectName={lang.client}
        />
        <ObjectInfo
          object={pick(
            form.data?.vehicle,
            'vin',
            'year',
            'makeCode',
            'model',
            'bodyType',
            'posOdometerReading',
            'stockNumber',
            'purchasePrice',
            'trimLevel',
            'engineInfo',
            'engineType',
            'driveLine',
            'purchaseType',
            'inServiceDate',
            'mfgWholeVehicleWarrantyMonths',
            'mfgWholeVehicleWarrantyKm',
            'mfgPowertrainWarrantyMonths',
            'mfgPowertrainWarrantyKm',
            'financedAmount',
            'interestRate'
          )}
          objectName={lang.vehicle}
        />
        {form.data.vehicle?.lender && (
          <ObjectInfo
            object={pick(
              form.data.vehicle?.lender,
              'name',
              'email',
              'phoneNumber',
              'streetAddress',
              'city',
              'provinceCode',
              'countryCode',
              'postalCode'
            )}
            objectName={lang.lender}
          />
        )}
        <DataMap
          data={form.data.products}
          render={({ data: product, index }) => (
            <ObjectInfo
              key={product.id}
              object={pick(
                {
                  ...product,
                  totalPrice: form.data.client?.taxExempt ? product.salePriceCAD : product.totalPrice,
                  consentRequired: product?.isCustomerConsentRequired,
                },
                'name',
                'priceCAD',
                'termInMonths',
                'customInfo',
                'customInfoGroup',
                'salePriceCAD',
                'effectiveDate',
                'expiryDate',
                'termInMonths',
                'maxVehiclePriceCAD',
                'minVehiclePriceCAD',
                'warrantyOption',
                'class',
                'tier',
                'maxKm',
                'isPackage',
                'postSale',
                'totalPrice',
                'consentRequired'
              )}
              objectName={`#${index + 1} ${lang.contract}`}
              objectSubtitle={
                <div style={{ color: 'red', fontWeight: 'bold' }}>
                  {product.isCustomerConsentRequired ? lang.consentRequired : undefined}
                </div>
              }
            />
          )}
        />
      </FormWrapper>
    </div>
  );
}
