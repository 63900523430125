import Box from 'assets/components/box/Box';
import Button from 'assets/components/button/Button';
import ThemeButtonTab from 'assets/components/button/themes/Theme.Button.Tab';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageStatic1 from 'assets/components/page/themes/Theme.Page.Static.1';
import ThemeTableSimple from 'assets/components/table/themes/Theme.Table.Simple';
import ThemeTagRounded from 'assets/components/tag/themes/Theme.Tag.Rounded';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { sortBy } from 'lodash';
import { useMemo, useState } from 'react';
import { createNumberArray } from '../../../../../assets/utils/data/Number';
import { useClaimTypeProvider } from '../ClaimType.Hooks';

export default function ClaimTypeInfo() {
  const { lang } = getLocales();
  const { selected: claimType } = useClaimTypeProvider();
  const [selectedVersion, selectVersion] = useState(claimType.version);
  const aggregates = useMemo(
    () =>
      [...claimType.claimTypeAggregates, ...claimType.claimTypeAggregatesInactive].filter(
        (it) => it.version === selectedVersion
      ),
    [selectedVersion, claimType]
  );
  const documents = useMemo(
    () =>
      [...claimType.claimTypeDocuments, ...claimType.claimTypeDocumentsInactive].filter(
        (it) => it.version === selectedVersion
      ),
    [selectedVersion, claimType]
  );
  const dataPoints = useMemo(
    () =>
      [...claimType.claimTypeDataPoints, ...claimType.claimTypeDataPointsInactive].filter(
        (it) => it.version === selectedVersion
      ),
    [selectedVersion, claimType]
  );

  return (
    <Page
      class={ThemePageStatic1}
      header={
        <>
          <Card class={ThemeCardRow} media={<Icon class="fas-clipboard-list" />} title={claimType.name} />
          <div
            style={{
              position: 'absolute',
              bottom: Theme.Size.pixel(0),
              right: Theme.Size.pixel(10),
              height: 'fit-content',
              top: 'unset',
              fontSize: Theme.FontSize.M,
              display: 'flex',
            }}
            children={
              <DataMap
                data={createNumberArray(claimType.version)}
                render={({ data: version }) => (
                  <Button
                    key={version}
                    render={
                      <>
                        {lang.version} {version + 1}
                      </>
                    }
                    onClick={selectVersion}
                    data={version + 1}
                    active={version + 1 === selectedVersion}
                    class={ThemeButtonTab}
                  />
                )}
              />
            }
          />
        </>
      }
      render={
        <>
          <div style={{ display: 'flex' }}>
            <div style={{ width: Theme.Size.fill }}>
              <h1>{lang.info}</h1>
              {lang.name}: <b>{claimType.name}</b>
              <br />
              {lang.code}: <b>{claimType.code}</b>
              <br />
              {lang.description}: <b>{claimType.description}</b>
            </div>
            <div style={{ width: Theme.Size.fill }}>
              <h1>{lang.product}</h1>
              {lang.name}: <b>{claimType.product.name}</b>
              <br />
              {lang.code}: <b>{claimType.product.code}</b>
            </div>
            <div style={{ width: Theme.Size.fill }}>
              <h1>{lang.lossCode}</h1>
              <DataMap
                data={claimType.lossCodes}
                render={({ data: lossCode, index }) => (
                  <Box key={index} class={ThemeTagRounded} render={lossCode.code} />
                )}
              />
            </div>
          </div>
          <br />
          <hr />
          <br />
          <div>
            <div>
              <h1>{lang.documents}</h1>
              <table className={ThemeTableSimple}>
                <thead>
                  <tr>
                    <th>{lang.name}</th>
                    <th>{lang.source}</th>
                    <th>{lang.example}</th>
                    <th style={{ textAlign: 'center' }}>{lang.allowMultiple}</th>
                    <th style={{ textAlign: 'center' }}>{lang.required}</th>
                    <th style={{ textAlign: 'center' }}>{lang.allowNotes}</th>
                    <th style={{ textAlign: 'center' }}>{lang.approvalRequired}</th>
                  </tr>
                </thead>
                <tbody>
                  <DataMap
                    data={documents}
                    render={({ data: doc, index }) => (
                      <tr key={index}>
                        <td>
                          <b>{doc.name}</b>
                          <br />
                          {doc.description}
                        </td>
                        <td>{doc.source}</td>
                        <td>{doc.example}</td>
                        <td style={{ textAlign: 'center' }}>
                          <Icon class={doc.allowMultiple ? 'fas-check-square' : 'far-square'} />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <Icon class={doc.required ? 'fas-check-square' : 'far-square'} />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <Icon class={doc.allowNotes ? 'fas-check-square' : 'far-square'} />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <Icon class={doc.approvalRequired ? 'fas-check-square' : 'far-square'} />
                        </td>
                      </tr>
                    )}
                  />
                </tbody>
              </table>
            </div>
            <div>
              <h1>{lang.aggregate}</h1>
              <table className={ThemeTableSimple}>
                <thead>
                  <tr>
                    <th>{lang.insurer}</th>
                    <th>{lang.maxNumberOfClaims}</th>
                    <th>{lang.maxNumberOfClaimsPriority}</th>
                  </tr>
                </thead>
                <tbody>
                  <DataMap
                    data={sortBy(aggregates, (it) => it.maxNumberOfClaimsPriority)}
                    render={({ data: aggregate, index }) => (
                      <tr key={index}>
                        <td>{aggregate.insurer.name}</td>
                        <td>{aggregate.maxNumberOfClaims}</td>
                        <td>{aggregate.maxNumberOfClaimsPriority}</td>
                      </tr>
                    )}
                  />
                </tbody>
                <thead>
                  <tr>
                    <th>{lang.insurer}</th>
                    <th>{lang.maxClaimAmount}</th>
                    <th>{lang.maxClaimAmountPriority}</th>
                  </tr>
                </thead>
                <tbody>
                  <DataMap
                    data={sortBy(aggregates, (it) => it.maxClaimAmountPriority)}
                    render={({ data: aggregate, index }) => (
                      <tr key={index}>
                        <td>{aggregate.insurer.name}</td>
                        <td>{aggregate.maxClaimAmount}</td>
                        <td>{aggregate.maxClaimAmountPriority}</td>
                      </tr>
                    )}
                  />
                </tbody>
                <thead>
                  <tr>
                    <th>{lang.insurer}</th>
                    <th>{lang.totalAggregate}</th>
                    <th>{lang.totalAggregatePriority}</th>
                  </tr>
                </thead>
                <tbody>
                  <DataMap
                    data={sortBy(aggregates, (it) => it.totalAggregatePriority)}
                    render={({ data: aggregate, index }) => (
                      <tr key={index}>
                        <td>{aggregate.insurer.name}</td>
                        <td>{aggregate.totalAggregate}</td>
                        <td>{aggregate.totalAggregatePriority}</td>
                      </tr>
                    )}
                  />
                </tbody>
              </table>
            </div>
            <div>
              <h1>{lang.dataPoints}</h1>
              <table className={ThemeTableSimple}>
                <thead>
                  <tr>
                    <th>{lang.name}</th>
                    <th>{lang.type}</th>
                  </tr>
                </thead>
                <tbody>
                  <DataMap
                    data={sortBy(dataPoints, (it) => it.name)}
                    render={({ data: dataPoint, index }) => (
                      <tr key={index}>
                        <td>{dataPoint.name}</td>
                        <td>{dataPoint.type}</td>
                      </tr>
                    )}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </>
      }
    />
  );
}
