import { useModalForm } from 'assets/components/form/modalForm/ModalForm';
import DateInput from 'assets/components/inputs/dateInput/DateInput';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import { getLocales } from 'assets/locales/Locale';
import ObjectTable from 'assets/templates/objectTable/ObjectTable';
import ValidationRegex from 'assets/utils/data/Utils.Data.ValidationRegex';
import ClaimTypeDataPointInputType from 'models/enums/ClaimTypeDataPointInputType';

const ClaimEditJobFormDataPoints = () => {
  const { form } = useModalForm<Api.Core.ClaimJob.Save.IRequest>();
  const { lang } = getLocales();

  const updateValue = (value: string, id: number) => {
    const updatedDataPoints = form.data?.dataPoints?.map((item) =>
      id === item?.claimTypeDataPointId ? { ...item, value } : item
    );
    form.update({ dataPoints: updatedDataPoints });
  };

  return (
    <FormWrapper>
      <ObjectTable
        paths={[]}
        data={form?.data?.dataPoints}
        renderRow={({ data: row }) => {
          return (
            <tr key={row?.claimTypeDataPointId}>
              <td>{row.name}</td>
              <td>
                {row.type === ClaimTypeDataPointInputType.Number ? (
                  <NumberInput
                    name="value"
                    value={Number(row.value)}
                    error={row.isRequired && !row.value && lang.isRequired}
                    onChange={({ value }) => updateValue(String(value), row?.claimTypeDataPointId)}
                  />
                ) : row.type === ClaimTypeDataPointInputType.Any ? (
                  <TextInput
                    name="value"
                    value={row.value}
                    error={row.isRequired && !row.value && lang.isRequired}
                    onChange={({ value }) => updateValue(value, row?.claimTypeDataPointId)}
                  />
                ) : row.type === ClaimTypeDataPointInputType.Text ? (
                  <TextInput
                    name="value"
                    value={row.value}
                    error={row.isRequired && !row.value && lang.isRequired}
                    onChange={({ value }) => {
                      if (ValidationRegex.letters.test(value)) updateValue(value, row?.claimTypeDataPointId);
                    }}
                  />
                ) : row.type === ClaimTypeDataPointInputType.Alphanumeric ? (
                  <TextInput
                    name="value"
                    value={row.value}
                    error={row.isRequired && !row.value && lang.isRequired}
                    onChange={({ value }) => {
                      if (!ValidationRegex.alphanumeric.test(value)) updateValue(value, row?.claimTypeDataPointId);
                    }}
                  />
                ) : row.type === ClaimTypeDataPointInputType.Date ? (
                  <DateInput
                    name="value"
                    value={row.value}
                    error={row.isRequired && !row.value && lang.isRequired}
                    onChange={({ value }) => updateValue(value, row?.claimTypeDataPointId)}
                  />
                ) : null}
              </td>
            </tr>
          );
        }}
      />
    </FormWrapper>
  );
};

export default ClaimEditJobFormDataPoints;
