import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import DataMap from 'assets/components/dataMap/DataMap';
import { addLog } from 'assets/components/feedback/Feedback';
import Icon from 'assets/components/icon/Icon';
import FileInput from 'assets/components/inputs/file/FileInput';
import ThemeFileInputDropzone from 'assets/components/inputs/file/themes/Theme.FileInput.Dropzone';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import Modal from 'assets/components/page/templates/Modal';
import ThemeModalDefault from 'assets/components/page/templates/themes/Theme.Modal.Default';
import { getLocales } from 'assets/locales/Locale';
import { convertFileToBase64 } from 'assets/utils/data/File';
import { HttpStatus } from 'config/Api.Config';
import { isEmpty } from 'lodash';
import ClaimEmail from 'models/claims/claimEmail/ClaimEmail';
import React, { useState } from 'react';
import { PaymentRequestEmail } from '../ClaimEdit.Job.AggregateTable';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import { useClaimEdit } from '../../../ClaimEdit.Hooks';

type Props = {
  paymentRequestEmailData: PaymentRequestEmail;
  setPaymentRequestEmailData: React.Dispatch<React.SetStateAction<PaymentRequestEmail>>;
};

export default function ClaimEditJobSendPaymentRequestEmail({
  paymentRequestEmailData: { claimJobItem, emailData },
  setPaymentRequestEmailData,
}: Props) {
  const { lang } = getLocales();
  const [files, setFiles] = useState<Hooks.Input.File.Info[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { claim } = useClaimEdit();

  const updateEmailData = (field: keyof Utils.Email.Data, value: Utils.Email.Data[keyof Utils.Email.Data]) => {
    setPaymentRequestEmailData((prev) => ({
      ...prev,
      emailData: {
        ...prev.emailData,
        [field]: value,
      },
    }));
  };

  async function handleSendEmail() {
    setIsLoading(true);
    let attachments: Utils.Email.Attachment[] = [];

    if (!isEmpty(files)) {
      attachments = await Promise.all(
        files.map(async ({ original: file }) => {
          if (file) {
            const contentBase64 = await convertFileToBase64(file);
            return {
              contentBase64,
              fileName: file.name,
              type: file.type,
              disposition: 'attachment',
            } as Utils.Email.Attachment;
          }
        })
      );
    }

    const res = await ClaimEmail.sendPaymentRequestEmail({
      claimJobId: claimJobItem?.jobId,
      claimInvoiceId: claimJobItem?.claimInvoiceId,
      payeeId: claimJobItem?.payee?.payeeId,
      dealerId: claimJobItem?.payee?.dealerId,
      clientId: claimJobItem?.payee?.clientId,
      paymentMethodCode: claimJobItem?.paymentMethodCode,
      currency: claimJobItem?.currency,
      emailData: { ...emailData, attachments },
    });

    if (res.status !== HttpStatus.ok) {
      setIsLoading(false);
      addLog({ error: lang.unableToSendEmail });
    } else {
      setIsLoading(false);
      addLog({ success: lang.emailSuccessfullySent });
      setPaymentRequestEmailData(undefined);
    }
  }

  return (
    <Modal
      header={lang.sendPaymentRequestEmail}
      subcomponents={ThemeModalDefault}
      onClose={() => setPaymentRequestEmailData(undefined)}
      render={
        <FormWrapper>
          <SelectInput
            name="claimInvoiceId"
            label={lang.invoices}
            data={claim?.claimInvoices?.map((it) => it.displayInfo)}
            value={claimJobItem?.claimInvoiceId}
            error={!claimJobItem?.claimInvoiceId && lang.required}
            onChange={({ claimInvoiceId }) =>
              setPaymentRequestEmailData((prev) => ({
                ...prev,
                claimJobItem: { ...prev.claimJobItem, claimInvoiceId },
              }))
            }
          />
          <DataMap
            data={[...(emailData?.recipientEmails || []), '']}
            render={({ data: email, index }) => (
              <TextInput
                name="email"
                value={email}
                label={lang.enterAccountingEmail}
                onChange={({ email: newEmail }) => {
                  let additionalRecipientEmails = emailData?.recipientEmails || [];
                  additionalRecipientEmails.splice(index, 1, newEmail);
                  additionalRecipientEmails = additionalRecipientEmails.filter((it) => it);
                  updateEmailData('recipientEmails', additionalRecipientEmails);
                }}
              />
            )}
          />
          <FileInput
            name="files"
            label={lang.documents}
            value={files || []}
            onChange={({ files }) => setFiles(files)}
            class={ThemeFileInputDropzone}
          />
        </FormWrapper>
      }
      footer={
        <>
          <Button
            render={lang.sendEmail}
            class={ThemeButtonCircle}
            media={<Icon class="fas-envelope" />}
            htmlElementProps={{
              button: {
                title: lang.sendPaymentRequestEmail,
              },
            }}
            disabled={
              isEmpty(emailData?.recipientEmails) ||
              !emailData?.senderEmail ||
              !emailData?.senderName ||
              !emailData?.subject ||
              !claimJobItem?.claimInvoiceId
            }
            isLoading={isLoading}
            active
            onClick={handleSendEmail}
          />
        </>
      }
    />
  );
}
