type Props = {
  url: string;
  name?: string;
};
export function autoTriggerDownload({ url, name }: Props) {
  const a = document.createElement('a');

  a.href = url;
  a.download = name ?? 'Downloaded_ASC_File';

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  if (url.startsWith('blob:')) {
    window.URL.revokeObjectURL(url);
  }
}
