import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import FileInput from 'assets/components/inputs/file/FileInput';
import ThemeFileInputDropzone from 'assets/components/inputs/file/themes/Theme.FileInput.Dropzone';
import statementUploadFileApi from 'models/sales/statement/uploadFile/Api.Statement.UploadFile';
import { getLocales } from 'assets/locales/Locale';
import { useStatementProvider } from '../Statement.Hooks';
import { addLog } from 'assets/components/feedback/Feedback';
import { HttpStatus } from 'config/Api.Config';
import { useState } from 'react';
import { difference } from 'lodash';
import statementDeleteFileApi from 'models/sales/statement/deleteFile/Api.Statement.DeleteFile';
import { combineStrings } from 'assets/utils/data/String';

export type StatementForm = Omit<Api.Core.Statement.Save.IRequest, 'statementDocuments'> & {
  files: Hooks.Input.File.Info[];
};

export default function StatementUploadDocuments() {
  const { lang } = getLocales();
  const { pageRouter, selected, listApi } = useStatementProvider();
  const [hasFiles, setHasFiles] = useState<boolean>(false);

  return (
    <ModalForm<StatementForm>
      subcomponents={ThemeModalFormDefault}
      title={lang.statement}
      default={{
        files: selected?.mediaFiles?.map((it) => ({
          id: it.id,
          dataUrl: it.url,
          name: it.name,
          type: it.contentType as Utils.File.MimeType,
        })),
      }}
      onCancel={pageRouter.updateParams}
      validation={(data) => {
        data?.files?.length > 0 ? setHasFiles(true) : setHasFiles(false);
      }}
      disabled={!hasFiles}
      onSubmit={async ({ files }) => {
        let totalFiles = 0;
        let uploadedFiles = 0;
        let deletedFiles = 0;
        let totalDeletedFiles = 0;
        const deletedFileIds = difference(
          selected?.mediaFiles?.map((it) => it.id),
          files?.map((it) => it.id)
        );
        for (const fileId of deletedFileIds || []) {
          const fileUploadResponse = await statementDeleteFileApi(selected?.id, {
            fileId,
          });
          if (fileUploadResponse.status === HttpStatus.ok) {
            deletedFiles += 1;
          }
          totalDeletedFiles += 1;
        }
        for (const formFile of files?.filter((it) => !it.id && it.original) || []) {
          const fileUploadResponse = await statementUploadFileApi(selected?.id, {
            formFile: formFile.original,
          });
          if (fileUploadResponse.status === HttpStatus.ok) {
            uploadedFiles += 1;
          }
          totalFiles += 1;
        }
        listApi.execute((body) => body);
        if (uploadedFiles !== totalFiles || deletedFiles !== totalDeletedFiles) {
          addLog({
            warning: combineStrings(
              ' ',
              `Some files did not get deleted or uploaded!`,
              totalFiles > 0 ? `${uploadedFiles}/${totalFiles} files uploaded` : null,
              totalDeletedFiles > 0 ? `${deletedFiles}/${totalDeletedFiles} files got deleted!` : null
            ),
          });
        } else {
          addLog({ success: lang.saveSuccess });
        }
      }}
      render={({ form }) => (
        <FormWrapper>
          <FileInput
            name="files"
            label={lang.documents}
            value={form.data?.files || []}
            onChange={form.update}
            class={ThemeFileInputDropzone}
          />
        </FormWrapper>
      )}
    />
  );
}
