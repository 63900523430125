enum VeraForteDocumentStatuses {
  UNKNWON = 'UNKNOWN',
  NO_UPLOADING = 'NO_UPLOADING',
  NOT_UPLOADED = 'NOT_UPLOADED',
  UNSIGNED_UPLOADED = 'UNSIGNED_UPLOADED',
  SIGNED_UPLOADED = 'SIGNED_UPLOADED',
}

export default VeraForteDocumentStatuses;
export type VerifyVeraForteDocumentStatuses = Utils.VerifyExtends<
  Model.Enum.VeraForteDocumentStatuses,
  Utils.ValueOf<typeof VeraForteDocumentStatuses>
>;
