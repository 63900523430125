import { combineStrings } from 'assets/utils/data/String';
import Model from 'models/Model';
import Claim from '../claim/Model.Claim';
import PayeeType from '../payeeType/Model.PayeeType';

export default class ClaimPayee
  extends Model<Model.IClaimPayee>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IClaimPayee>
{
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
  address?: string;
  payeeTypeId: number;
  payeeType: PayeeType;
  claimId: number;
  claim: Claim;

  constructor(data: Partial<Model.IClaimPayee> | Utils.FormData<Model.IClaimPayee>, language?: Locale.Languages) {
    super(
      {
        payeeType: PayeeType,
        claim: Claim,
      },
      language
    );
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: combineStrings(' ', this.name) || '',
      subtitle: this.email,
    };
  }
}
