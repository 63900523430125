enum ClaimStatusCode {
  OPEN_ACTIVE_SUBMITTED_SELLING_DEALER = 'OPEN_ACTIVE_Submitted - Selling Dealer',
  OPEN_ACTIVE_SUBMITTED_DEALERSHIP = 'OPEN_ACTIVE_Submitted - Dealership',
  OPEN_ACTIVE_SUBMITTED_REPAIRFACILITY = 'OPEN_ACTIVE_Submitted - RepairFacility',
  OPEN_ACTIVE_SUBMITTED_CUSTOMER = 'OPEN_ACTIVE_Submitted - Customer',
  OPEN_ACTIVE_SUBMITTED_ASC = 'OPEN_ACTIVE_Submitted - ASC',
  OPEN_ACTIVE_SUBMITTED_OTHER = 'OPEN_ACTIVE_Submitted - Other',
  OPEN_ACTIVE_IN_PROGRESS = 'OPEN_ACTIVE_In Progress',
  OPEN_PENDING = 'OPEN_PENDING',
  OPEN_APPROVED = 'OPEN_APPROVED',
  OPEN_APPROVED_TO_PAY = 'OPEN_APPROVED-TO-PAY',
  DENIED_DECLINED = 'DENIED_DECLINED',
  DENIED_DECLINED_APPEAL = 'DENIED_DECLINED_Appeal',
  PAID_APPEAL_APPROVED = 'PAID_APPEAL-APPROVED',
  PAID_CLOSED_PAID = 'PAID_CLOSED-PAID',
  CANCELED_VOID = 'CANCELED_VOID',
}

export default ClaimStatusCode;
export type VerifyClaimStatusCode = Utils.VerifyExtends<
  Model.Enum.ClaimStatusCode,
  Utils.ValueOf<typeof ClaimStatusCode>
>;
