import Button from 'assets/components/button/Button';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import Icon from 'assets/components/icon/Icon';
import SearchInput from 'assets/components/inputs/search/SearchInput';
import { getLocales } from 'assets/locales/Locale';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import { OrderType } from 'config/Api.Config';
import { useDealerProvider } from '../Dealer.Hooks';
import React from 'react';
import FilterForm from 'assets/components/filters/filterForm/FilterForm';
import ThemeFilterFormDrawer from 'assets/components/filters/filterForm/themes/Theme.FilterForm.Drawer';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import { inputStyles } from 'assets/components/inputs/Input';

export default function DealerFilters() {
  const { lang } = getLocales();
  const { listApi } = useDealerProvider();

  return (
    <>
      <SearchInput
        value={listApi.body?.search}
        onSearch={(search) => listApi.execute((body) => ({ ...body, search }))}
        threshold={2}
        placeholder={`${lang.search}...`}
      />
      &nbsp;
      <DropdownButton
        buttonMedia={<Icon class="fas-sort" />}
        horizontalPosition="auto_fixed"
        verticalPosition="auto_fixed"
        htmlElementProps={{ title: lang.sort }}
        subcomponents={ThemeDropdownButtonPopup}
        dropdownOptions={
          <>
            <Button
              class={ThemeButtonMenuItem}
              render={lang.newerFirst}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: undefined,
                orderType: undefined,
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.olderFirst}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'id',
                orderType: OrderType.asc,
              })}
            />
          </>
        }
      />
      &nbsp;
      <FilterForm
        subcomponents={ThemeFilterFormDrawer}
        filters={listApi.body}
        onFilter={(newFilters) => listApi.execute(newFilters)}
        onReset={listApi.reset}
        onOpen={() => {
          if (!listApi.payload) listApi.execute();
        }}
        render={({ tempFilters, setFilters }) => (
          <>
            <CheckInput
              name="isInactive"
              label={lang.withInactive}
              class={inputStyles.check.switch}
              value={tempFilters?.isInactive}
              onChange={({ isInactive }) => setFilters({ ...tempFilters, isInactive: Boolean(isInactive) })}
              htmlElementProps={{ title: tempFilters?.isInactive ? lang.disable : lang.enable }}
            />
          </>
        )}
      />
    </>
  );
}
