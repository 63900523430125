import MediaFile from 'models/core/mediaFile/Model.MediaFile';
import Model from 'models/Model';
import ProductAdditionalField from '../productAdditionalField/Model.ProductAdditionalField';
import productDocumentDeleteApi from './delete/Api.ProductDocument.Delete';
import productDocumentSaveApi from './save/Api.ProductDocument.Save';
import ProductSignatureField from '../productSignatureField/Model.ProductSignatureField';

export default class ProductDocument
  extends Model<Model.IProductDocument>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IProductDocument>
{
  id: number;
  productId: number;
  mediaFileId: number;
  mediaFile: MediaFile;
  formFile?: File;
  productDocumentType: Model.Enum.EngineType;
  productAdditionalFields: Array<ProductAdditionalField>;
  productSignatureFields: Array<ProductSignatureField>;

  constructor(
    data: Partial<Model.IProductDocument> | Utils.FormData<Model.IProductDocument>,
    language?: Locale.Languages
  ) {
    super(
      {
        mediaFile: MediaFile,
        productAdditionalFields: [ProductAdditionalField],
        productSignatureFields: [ProductSignatureField],
      },
      language
    );
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.mediaFile?.name || this.id.toString(),
    };
  }
  async save() {
    return await productDocumentSaveApi(this);
  }
  async delete() {
    return await productDocumentDeleteApi({ id: this.productId, documentId: this.id });
  }
}
