import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import { inputStyles } from 'assets/components/inputs/Input';
import RichTextInput from 'assets/components/inputs/richTextInput/RichTextInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import CheckWrapper from 'assets/components/inputs/wrappers/CheckWrapper';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import Modal from 'assets/components/page/templates/Modal';
import ThemeModalDefault from 'assets/components/page/templates/themes/Theme.Modal.Default';
import { getLocales } from 'assets/locales/Locale';
import ClaimEmailTemplateTags from 'module/management/components/claimEmailTemplate/ClaimEmailTemplateTags';
import { useClaimJobEdit } from './ClaimEdit.Job.Hooks';
import ClaimEditJobSubmitButton from './ClaimEdit.Job.SubmitButton';

export default function ClaimEditJobEmailTemplate() {
  const { lang } = getLocales();
  const { form } = useClaimJobEdit();

  return !form.data.emailTemplate ? null : (
    <>
      <Modal
        subcomponents={ThemeModalDefault}
        header={lang.emailTemplate}
        onClose={() => form.update({ emailTemplate: null })}
        render={
          <FormWrapper>
            <TextInput
              name="name"
              label={lang.name}
              value={form.data.emailTemplate.name}
              error={form.errors.emailTemplate?.name}
              onChange={form.update}
              icon={<Icon class="if-quill-pen" />}
            />
            <InputWrapper>
              <TextInput
                name="senderName"
                label={lang.senderName}
                value={form.data.emailTemplate.senderName}
                error={form.errors.emailTemplate?.senderName}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <TextInput
                name="senderEmail"
                label={lang.senderEmail}
                value={form.data.emailTemplate.senderEmail}
                error={form.errors.emailTemplate?.senderEmail}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
            </InputWrapper>
            <h2>{lang.sendTo}</h2>
            <CheckWrapper>
              <CheckInput
                name="sendToClient"
                label={lang.client}
                class={inputStyles.check.switch}
                value={form.data.emailTemplate?.sendToClient}
                onChange={form.update}
                htmlElementProps={{ title: form.data.emailTemplate?.sendToClient ? lang.disable : lang.enable }}
              />
              <CheckInput
                name="sendToContactEmail"
                label={lang.contact}
                class={inputStyles.check.switch}
                value={form.data.emailTemplate?.sendToContactEmail}
                onChange={form.update}
                htmlElementProps={{ title: form.data.emailTemplate?.sendToContactEmail ? lang.disable : lang.enable }}
              />
              <CheckInput
                name="sendToClaimOwner"
                label={lang.claimOwner}
                class={inputStyles.check.switch}
                value={form.data.emailTemplate?.sendToClaimOwner}
                onChange={form.update}
                htmlElementProps={{ title: form.data.emailTemplate?.sendToClaimOwner ? lang.disable : lang.enable }}
              />
            </CheckWrapper>
            <h2>{lang.additionalRecipientEmails}</h2>
            <DataMap
              data={[...(form.data.emailTemplate?.additionalRecipientEmails || []), '']}
              render={({ data: email, index }) => (
                <TextInput
                  key={index}
                  name="email"
                  label={lang.email}
                  value={email}
                  error={form.errors.emailTemplate?.additionalRecipientEmails}
                  onChange={({ email }) => {
                    let additionalRecipientEmails = form.data.emailTemplate?.additionalRecipientEmails || [];
                    additionalRecipientEmails.splice(index, 1, email);
                    additionalRecipientEmails = additionalRecipientEmails.filter((it) => it);
                    form.merge({ emailTemplate: { additionalRecipientEmails } });
                  }}
                  icon={<Icon class="if-quill-pen" />}
                />
              )}
            />
            <h2>{lang.email}</h2>
            <TextInput
              name="subject"
              label={lang.subject}
              value={form.data.emailTemplate.subject}
              error={form.errors.emailTemplate?.subject}
              onChange={form.update}
              icon={<Icon class="if-quill-pen" />}
            />
            <RichTextInput
              name="htmlBody"
              label={lang.htmlBody}
              value={form.data.emailTemplate.htmlBody}
              error={form.errors.emailTemplate?.htmlBody}
              onChange={form.update}
              icon={<Icon class="if-quill-pen" />}
              customActions={<ClaimEmailTemplateTags />}
            />
          </FormWrapper>
        }
        footer={
          <>
            <Button
              onClick={form.update}
              data={{ emailTemplate: null }}
              media="fas-ban"
              render={lang.dontSendEmail}
              class={ThemeButtonCircle}
            />
            <ClaimEditJobSubmitButton />
          </>
        }
      />
    </>
  );
}
