import React from 'react';
import Button from 'assets/components/button/Button';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import useUIEditor from 'assets/hooks/uiEditor/UIEditor';
import { getLocales } from 'assets/locales/Locale';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import { UICommand } from 'assets/utils/dom/Commands';

export default function StatementEmailTemplateTags() {
  const { lang } = getLocales();
  const uiEditor = useUIEditor();
  return (
    <DropdownButton
      buttonMedia={<Icon class="fas-plus" />}
      buttonBody={lang.addPlaceholder}
      horizontalPosition="auto_fixed"
      verticalPosition="auto_fixed"
      htmlElementProps={{
        title: lang.addEmailTemplatePlaceholder,
      }}
      subcomponents={ThemeDropdownButtonPopup}
      dropdownOptions={
        <DataMap
          data={[
            { id: '{{IEmailTemplate_SenderName}}', title: lang.senderName },
            { id: '{{IEmailTemplate_SenderEmail}}', title: lang.senderEmail },
            { id: '{{Dealer_Name}}', title: `${lang.dealer} ${lang.name}` },
            { id: '{{Dealer_PrimaryPhoneNumber}}', title: `${lang.dealer} ${lang.phoneNumber}` },
            { id: '{{Dealer_PrimaryEmailAddress}}', title: `${lang.dealer} ${lang.email}` },
            { id: '{{Statement_StatementNumber}}', title: `${lang.statement} ${lang.number}` },
            { id: '{{Statement_TotalAmountDueCAD}}', title: `${lang.statement} ${lang.totalAmount}` },
            {
              id: '{{User_FirstName}} {{User_LastName}}',
              title: `${lang.statement} ${lang.representative}`,
            },
          ]}
          render={({ data }) => (
            <Button
              key={data.id}
              class={ThemeButtonMenuItem}
              render={data.title}
              onClick={(data) => uiEditor.applyUiCommand(UICommand.insertHTML, data)}
              data={data.id}
            />
          )}
        />
      }
    />
  );
}
