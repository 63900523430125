import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import claimSaveApi from 'models/claims/claim/save/Api.Claim.Save';
import ClaimType from 'models/claims/claimType/Model.ClaimType';
import PayeeType from 'models/claims/payeeType/Model.PayeeType';
import ClaimStatusCode from 'models/enums/ClaimStatusCode';
import Contract from 'models/sales/contract/Model.Contract';
import { claimEditIndexRoute } from '../views/claimEdit/ClaimEdit.Index';

export default function useClaimCreator() {
  const { user, userId, permissions, dealerId } = useAuthStore();
  const isClaimAdmin = permissions?.CLAIM_ADMIN;
  const canCreateClaim = isClaimAdmin || permissions?.CREATE_CLAIM;
  const canCreateSimpleClaim = isClaimAdmin || permissions?.CREATE_SIMPLE_CLAIM;
  const dealer = user?.dealers?.find((d) => d.id === dealerId);

  const claimTypesApi = useApi({ action: ClaimType.list });
  const claimTypes = claimTypesApi.payload?.data;

  const { redirect: redirectToClaim } = usePageRouter<Module.Claims.Claim.Params, Module.Claims.Claim.Query>({
    route: claimEditIndexRoute,
  });

  async function createClaim(contract: Contract) {
    const claimTypeWithReport = claimTypes?.find(
      (it) => it.product?.id === (contract?.product?.id || contract?.productId)
    );

    const { payload: claimId } = await claimSaveApi({
      statusCode: ClaimStatusCode.OPEN_ACTIVE_IN_PROGRESS,
      dealerId: dealerId,
      reporterId: userId,
      ownerId: userId,
      contractId: contract.id,
      createdByPayee: {
        name: dealer?.name ?? `${user?.firstName} ${user?.lastName}`,
        email: dealer?.primaryEmailAddress ?? user?.email,
        phoneNumber: dealer?.primaryPhoneNumber,
        payeeTypeId: PayeeType.DEALER_ID,
      },
      contract: { id: contract.id },
      claimJobs: !claimTypeWithReport?.claimTypeReportSettings?.length
        ? []
        : [
            {
              jobNumber: 1,
              claimType: { id: claimTypeWithReport.id },
            },
          ],
      contactEmail: contract.client?.email,
      contactPhone: contract.client?.phone,
      streetAddress: contract.client?.streetAddress,
      city: contract.client?.city,
      postalCode: contract.client?.postalCode,
      countryCode: contract.client?.countryCode,
      provinceCode: contract.client?.provinceCode,
    });
    if (claimId) {
      redirectToClaim({ claimId: claimId.toString() });
    }
  }

  return { canCreateClaim, canCreateSimpleClaim, createClaim };
}
