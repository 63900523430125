import { addLog } from 'assets/components/feedback/Feedback';
import useForm from 'assets/components/form/hooks/Form';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { date, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import { isEmail, isValidPhoneNumber } from 'assets/utils/parsersAndValidation/Validators';
import { HttpStatus } from 'config/Api.Config';
import { first, isEmpty, set } from 'lodash';
import claimSavePublic from 'models/claims/claim/savePublic/Api.Claim.SavePublic';
import claimDocumentSavePublicApi from 'models/claims/claimDocument/savePublic/Api.ClaimDocument.SavePublic';
import ClaimType from 'models/claims/claimType/Model.ClaimType';
import Payee from 'models/claims/payee/Model.Payee';
import PayeeType from 'models/claims/payeeType/Model.PayeeType';
import Dealer from 'models/dealerManagement/dealer/Model.Dealer';
import { ClaimCreatorOptions } from 'models/enums/ClaimCreation';
import ClaimStatusCode from 'models/enums/ClaimStatusCode';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import payeeSavePublic from 'models/claims/payee/savePublic/Api.Payee.SavePublic';
import { publicClaimResponsePageIndexRoute } from './components/Claim.Submit.ResponsePage';
import claimJobDocumentSavePublicApi from 'models/claims/claimJobDocument/savePublic/Api.ClaimJobDocument.SavePublic';

export enum PublicClaimSubmitActions {
  CREATE_REPAIR_FACILITY = 'createRepairFacility',
}

export default function usePublicClaimSubmit() {
  const { lang } = getLocales();
  const _history = useHistory();
  const [action, setAction] = useState<PublicClaimSubmitActions>(undefined);

  const form = useForm<Module.Claims.Claim.Submit.Form>({
    default: {
      claim: {
        submitedDateUtc: date().tz('UTC').format(defaultServerDateTimeFormat),
        statusCode: ClaimStatusCode.OPEN_ACTIVE_SUBMITTED_OTHER,
        createdByPayee: {
          name: undefined,
          email: undefined,
          phoneNumber: undefined,
          payeeTypeId: PayeeType.OTHER_ID,
        },
        claimJobs: [
          {
            claimTypeId: null,
            cause: undefined,
            customerComplaint: undefined,
            correction: undefined,
            claimJobDocuments: [],
            dataPoints: [],
            claimJobItems: [],
          },
        ],
      },
      newPayee: null,
      claimDocuments: [],
    },
    onSubmit: async (data) => {
      if (data.newPayee) {
        const payeeResponse = await payeeSavePublic(data.newPayee);
        if (payeeResponse.status !== HttpStatus.ok) {
          addLog({ error: payeeResponse.message });
          return;
        }
      }

      const finalClaimJob = first(data?.claim?.claimJobs);
      const claimJobDocuments = [...(finalClaimJob?.claimJobDocuments || [])];
      delete finalClaimJob.claimJobDocuments;

      const claimResponse = await claimSavePublic({
        body: {
          ...data.claim,
          claimJobs: [
            {
              jobNumber: 1,
              ...finalClaimJob,
            },
          ],
        },
        params: {
          clientName: data.claim.contract?.client?.lastName,
          vin: data.claim.contract?.vehicle?.vin,
          contractNumber: data.claim.contract?.appNumber,
        },
      });

      const newClaim = claimResponse.payload;
      if (newClaim?.id && claimResponse.status === HttpStatus.ok) {
        for (const document of data.claimDocuments) {
          document.claimId = newClaim?.id;
          await claimDocumentSavePublicApi({
            body: document,
            params: {
              clientName: data.claim.contract?.client?.lastName,
              vin: data.claim.contract?.vehicle?.vin,
              contractNumber: data.claim.contract?.appNumber,
              id: newClaim?.id?.toString(),
            },
          });
        }

        if (!isEmpty(claimJobDocuments) && !isEmpty(newClaim?.claimJobs)) {
          const claimJob = first(newClaim?.claimJobs);
          for (const claimJobDoc of claimJobDocuments) {
            await claimJobDocumentSavePublicApi({
              body: { ...claimJobDoc, claimJobId: claimJob?.id },
              params: {
                claimJobId: claimJob?.id,
              },
            });
          }
        }

        addLog({ success: claimResponse.message || lang.saveSuccess });
        _history.push(createPathFromRoute(publicClaimResponsePageIndexRoute, { claimNumber: newClaim?.number }));
      } else {
        addLog({ error: claimResponse.message || lang.saveError });
      }
    },
    validation: (data, errors) => {
      if (!data.claimDocuments) set(errors, 'claimDocuments._objectError', lang.required);
      if (!data.claim?.contract) set(errors, 'claim.contract._objectError', lang.required);
      if (first(data?.claim?.claimJobs)?.dataPoints?.some((it) => it.isRequired && !it.value))
        set(errors, 'claim.claimJobs._objectError', lang.required);

      // Payee as new repair facility validation
      if (!data.repairFacilityId && data?.claimCreationOption === ClaimCreatorOptions.REPAIR_FACILITY && !action)
        set(errors, 'repairFacilityId', lang.required);
      if (
        data?.claimCreationOption === ClaimCreatorOptions.REPAIR_FACILITY &&
        action === PublicClaimSubmitActions.CREATE_REPAIR_FACILITY
      ) {
        if (!data.newPayee?.name) set(errors, 'newPayee.name', lang.required);
        if (!data.newPayee?.email) set(errors, 'newPayee.email', lang.required);
        if (!isEmail(data.newPayee?.email))
          set(errors, 'newPayee.email', lang.invalidFormatRequires.replace('{format}', 'example@example.com'));
        // TODO: See if this is required???
        // if (!data.newPayee?.phoneNumber) set(errors, 'newPayee.phoneNumber', lang.required);
        if (data.newPayee?.phoneNumber && !isValidPhoneNumber(data.newPayee?.phoneNumber))
          set(errors, 'newPayee.phoneNumber', lang.invalidFormatRequires.replace('{format}', '###-###-####'));
      }
      //

      if (data.claimCreationOption === ClaimCreatorOptions.DEALERSHIP && !data.claim?.dealerId)
        set(errors, 'claim.dealerId', lang.required);
      if (!data?.claimCreationOption) set(errors, 'claimCreationOption', lang.required);
      if (!data.claim?.createdByPayee?.email) set(errors, 'claim.createdByPayee.email', lang.required);
      if (!isEmail(data.claim?.createdByPayee?.email))
        set(
          errors,
          'claim.createdByPayee.email',
          lang.invalidFormatRequires.replace('{format}', 'example@example.com')
        );
      if (!data.claim?.createdByPayee?.name) set(errors, 'claim.createdByPayee.name', lang.required);
      if (!data.claim?.createdByPayee?.email) set(errors, 'claim.createdByPayee.email', lang.required);
      if (data.claim?.createdByPayee?.phoneNumber && !isValidPhoneNumber(data.claim?.createdByPayee?.phoneNumber))
        set(errors, 'claim.createdByPayee.phoneNumber', lang.invalidFormatRequires.replace('{format}', '###-###-####'));
    },
    beforeUpdate: (data) => {
      if (ClaimCreatorOptions.DEALERSHIP === data.claimCreationOption) {
        data.claim.statusCode = ClaimStatusCode.OPEN_ACTIVE_SUBMITTED_DEALERSHIP;
        data.claim.createdByPayee.payeeTypeId = PayeeType.DEALER_ID;
      } else if (ClaimCreatorOptions.REPAIR_FACILITY === data.claimCreationOption) {
        const payee = repairFacilityListApi?.payload?.data?.find((it) => it.id === data?.repairFacilityId);

        data.claim.statusCode = ClaimStatusCode.OPEN_ACTIVE_SUBMITTED_REPAIRFACILITY;
        data.claim.createdByPayee.payeeTypeId = PayeeType.REPAIR_FACILITY_ID;
        data.claim.createdByPayee.email = data.claim.createdByPayee.email ?? payee?.email;
        data.claim.createdByPayee.phoneNumber = data.claim.createdByPayee.phoneNumber ?? payee?.phoneNumber;
        data.claim.createdByPayee.name = data.claim.createdByPayee.name ?? payee?.name;
      } else if (ClaimCreatorOptions.OTHER === data.claimCreationOption) {
        data.claim.statusCode = ClaimStatusCode.OPEN_ACTIVE_SUBMITTED_OTHER;
        data.claim.createdByPayee.payeeTypeId = PayeeType.OTHER_ID;
      } else if (ClaimCreatorOptions.CUSTOMER === data.claimCreationOption) {
        data.claim.statusCode = ClaimStatusCode.OPEN_ACTIVE_SUBMITTED_CUSTOMER;
        data.claim.createdByPayee.payeeTypeId = PayeeType.CLIENT_ID;
      } else {
        data.claim.createdByPayee.email = undefined;
        data.claim.createdByPayee.phoneNumber = undefined;
        data.claim.createdByPayee.name = undefined;
      }

      return data;
    },
  });

  //#region APIS
  const repairFacilityListApi = useApi({
    action: Payee.list,
    wait: form.data.claimCreationOption === ClaimCreatorOptions.REPAIR_FACILITY,
    body: { payeeTypeId: [PayeeType.REPAIR_FACILITY_ID] },
  });
  const dealerListPublicApi = useApi({
    action: Dealer.listPublic,
    wait: form.data.claimCreationOption === ClaimCreatorOptions.DEALERSHIP,
  });
  const claimTypesListPublicApi = useApi({
    action: ClaimType.listPublic,
    body: { productId: [form.data?.claim?.contract?.productId] },
    wait: !form.data?.claim?.contract?.productId,
  });
  //#endregion

  useEffect(() => {
    if (form.data.claim?.contract?.dealer?.representativeId)
      dealerListPublicApi.execute({
        distributorId: [form.data.claim?.contract?.dealer?.representativeId],
      });
  }, [form.data.claim?.contract?.dealer?.representativeId]);

  //#region Claim Types
  useEffect(() => {
    if (form.data.claim?.contract?.productId)
      claimTypesListPublicApi.execute({ productId: [form.data.claim?.contract?.productId] });
  }, [form.data.claim?.contract?.productId]);

  const onlyClaimType = claimTypesListPublicApi?.payload?.data?.length === 1;
  useEffect(() => {
    if (onlyClaimType) {
      form.merge({ claim: { claimJobs: [{ claimTypeId: first(claimTypesListPublicApi?.payload?.data)?.id }] } });
    }
  }, [claimTypesListPublicApi?.payload?.data]);

  const claimTypeValue = onlyClaimType
    ? first(claimTypesListPublicApi?.payload?.data)?.id
    : first(form.data?.claim?.claimJobs)?.claimTypeId;

  const uploadableDocuments = useMemo(
    () => claimTypesListPublicApi?.payload?.data?.find((it) => it.id === claimTypeValue)?.claimTypeDocuments ?? [],
    [claimTypeValue]
  );
  //#endregion

  return {
    form,
    dealerListPublicApi,
    claimTypesListPublicApi,
    repairFacilityListApi,
    action,
    setAction,
    claimTypeValue,
    onlyClaimType,
    uploadableDocuments,
  };
}
