import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import Claim from 'models/claims/claim/Model.Claim';
import { useState } from 'react';
import { getNextStatus } from './useClaimsGlobalStore';
import { HttpStatus } from 'config/Api.Config';
import { addLog } from 'assets/components/feedback/Feedback';
import { getLocales } from 'assets/locales/Locale';
import { ClaimTab, useClaimEditPageRouter } from '../views/claimEdit/ClaimEdit.Hooks';
import EmailTemplate from 'models/core/claimEmailTemplate/Model.ClaimEmailTemplate';

type RedirectInfo = { claim: Claim };
type Props = {
  emailTemplates: EmailTemplate[];
  statusInfo: Model.ClaimStatusInfo;
  navigateToTab?: (tab: ClaimTab) => void;
};

export default function useClaimRedirect({ emailTemplates, statusInfo, navigateToTab }: Props) {
  const { lang } = getLocales();
  const { currentPermissions, userId } = useAuthStore();
  const pageRouter = useClaimEditPageRouter();
  const [redirectInfo, setRedirectInfo] = useState<RedirectInfo>();

  const finalRedirect = (claim: Model.IClaim) =>
    pageRouter.redirect({
      claimId: claim.id.toString(),
    });

  async function redirectAndTakeOwnership({ claim }: RedirectInfo) {
    const { nextStatusList } = getNextStatus(emailTemplates, statusInfo, currentPermissions, claim.statusCode);
    if (navigateToTab) navigateToTab('info');
    if (!claim.ownerId && nextStatusList.length > 0) {
      const { status } = await Claim.takeOwnership({
        claimId: claim.id,
        ownerId: userId,
      });
      if (status !== HttpStatus.ok) addLog({ error: lang.statusError });
      else finalRedirect(claim);
    } else finalRedirect(claim);
    setRedirectInfo(null);
  }

  return {
    redirectInfo,
    redirectAndTakeOwnership,
    setRedirectInfo,
  };
}
