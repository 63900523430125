import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { cleanValues } from 'assets/utils/data/Object';
import { compact, pick, reduce, sortBy, uniq, uniqBy } from 'lodash';
import NonWarrantyPricePoint from 'models/productManagement/nonWarrantyPricePoint/Model.NonWarrantyPricePoint';
import nonWarrantyPricePointSaveApi from 'models/productManagement/nonWarrantyPricePoint/save/Api.NonWarrantyPricePoint.Save';
import Product from 'models/productManagement/product/Model.Product';
import { useEffect, useMemo, useState } from 'react';
import { nonWarrantyPricingRoute } from './NonWarrantyPricing.Index';
import nonWarrantyPricePointDeleteApi from 'models/productManagement/nonWarrantyPricePoint/delete/Api.NonWarrantyPricePoint.Delete';
import useDistributorPricingDistributions from 'module/productManagement/hooks/useDistributorPricingDistributions';
import useDealerPricingSettings from 'module/productManagement/hooks/useDealerPricingSettings';
import iterateWithProgress, { ProgressInfo } from 'assets/utils/data/Iterations';
import { ProgressKey } from 'assets/components/progressWindow/ProgressWindow';

type Term = number;
export type Limits = {
  maxVehiclePriceCAD: number;
  minVehiclePriceCAD: number;
};
type PricePoint = Model.INonWarrantyPricePoint & {
  isUpdated?: boolean;
};
export default function useNonWarrantyPricing() {
  const { permissions } = useAuthStore();
  const pageRouter = usePageRouter<
    Module.ProductManagement.NonWarrantyPricing.Params,
    Module.ProductManagement.NonWarrantyPricing.Query
  >({
    route: nonWarrantyPricingRoute,
  });
  const productId = parseInt(pageRouter.params.productId) || 0;
  const productProvinceGroupId = parseInt(pageRouter.params.productProvinceGroupId) || undefined;
  const hasAccess =
    permissions.VIEW_NONWARRANTYPRICEPOINT ||
    permissions.CREATE_NONWARRANTYPRICEPOINT ||
    permissions.EDIT_NONWARRANTYPRICEPOINT ||
    permissions.DELETE_NONWARRANTYPRICEPOINT;

  const listApi = useApi({
    action: NonWarrantyPricePoint.list,
    body: { productId: [productId] },
    wait: !hasAccess,
  });

  const productApi = useApi({
    action: Product.find,
    body: { id: productId },
    wait: !hasAccess,
  });

  const distributorPricingDistributions = useDistributorPricingDistributions({
    productId,
  });

  const dealerPricingSettings = useDealerPricingSettings({
    productId,
  });

  //#region PRICES
  const [data, setData] = useState<PricePoint[]>([]);
  const isUpdated = useMemo(
    () =>
      data?.some((it) => it.isUpdated) ||
      distributorPricingDistributions.distributorData?.isUpdated ||
      distributorPricingDistributions.distributorData?.distributions?.some((it) => it.isUpdated) ||
      (dealerPricingSettings.isUpdated && !dealerPricingSettings.hasErrors),
    [
      data,
      distributorPricingDistributions.distributorData,
      dealerPricingSettings.isUpdated,
      dealerPricingSettings.hasErrors,
    ]
  );
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setData(
      () =>
        listApi.payload?.data
          ?.filter((it) => it.productProvinceGroupId == productProvinceGroupId)
          .map((it) => it.toJSON()) || []
    );
  }, [listApi.payload?.data, productProvinceGroupId]);

  async function saveAll(onSaveProgress?: (key: ProgressKey, progressInfo: ProgressInfo) => void) {
    setIsSaving(true);
    distributorPricingDistributions.saveAll(onSaveProgress);
    dealerPricingSettings.saveAll(onSaveProgress);
    const updatedPricesCount = await iterateWithProgress(
      data.filter((it) => it.isUpdated),
      async (pricePoint) => {
        if (pricePoint.priceCAD == null && pricePoint.id) {
          await nonWarrantyPricePointDeleteApi(pricePoint.id);
        } else if (pricePoint.priceCAD != null) {
          await nonWarrantyPricePointSaveApi(cleanValues(pricePoint));
        }
      },
      (p) => onSaveProgress('priceDistribution', p)
    );
    if (updatedPricesCount) {
      await listApi.execute((body) => body);
    }
    setIsSaving(false);
  }

  function getPrice(termInMonths: Term, { minVehiclePriceCAD, maxVehiclePriceCAD }: Omit<Limits, 'label'>) {
    return data.find(
      (it) =>
        it.termInMonths === termInMonths &&
        it.minVehiclePriceCAD === minVehiclePriceCAD &&
        it.maxVehiclePriceCAD === maxVehiclePriceCAD
    );
  }
  function updatePrice(
    termInMonths: Term,
    { minVehiclePriceCAD, maxVehiclePriceCAD }: Omit<Limits, 'label'>,
    pricingDistributions?: Partial<Utils.PricingDistribution>
  ) {
    const d = getPrice(termInMonths, { minVehiclePriceCAD, maxVehiclePriceCAD });
    if (d) {
      d.isUpdated = true;
      if (pricingDistributions == null) {
        d.priceCAD = null;
        d.pricingDistributions = null;
        d.subfees = null;
      } else {
        d.pricingDistributions = {
          ...productApi?.payload?.pricingDistribution,
          ...d.pricingDistributions,
          ...pricingDistributions,
        };
        d.priceCAD = reduce(d.pricingDistributions || {}, (prev, curr) => prev + curr.amount, 0);
      }
      setData((old) => [...old]);
    } else {
      const newD: PricePoint = {
        id: undefined,
        isUpdated: true,
        termInMonths,
        maxVehiclePriceCAD,
        minVehiclePriceCAD,
        priceCAD: reduce(pricingDistributions || {}, (prev, curr) => prev + curr.amount, 0),
        pricingDistributions,
        productProvinceGroupId,
        productId,
      };
      setData((old) => [...old, newD]);
    }
  }
  function updateSubfee(termInMonths: Term, limits: Omit<Limits, 'label'>, subfees?: Utils.SurchargeInfo) {
    const d = getPrice(termInMonths, limits);
    if (d) {
      d.isUpdated = true;
      d.subfees = uniqBy(compact([subfees, ...(d.subfees ?? [])]), (it) => it.subfeeId);
      setData((old) => [...old]);
    }
  }
  //#endregion

  //#region TERMS
  const terms = useMemo(() => sortBy(uniq(data.map((it) => it.termInMonths))), [data]);

  function removeTerm(termInMonths: Term) {
    setData((old) => old.filter((it) => it.termInMonths === termInMonths));
  }
  //#endregion

  //#region LIMITS
  const limits = useMemo(
    () =>
      sortBy(
        uniqBy(
          data?.map((it) => pick(it, 'minVehiclePriceCAD', 'maxVehiclePriceCAD') as Limits),
          (it) => it.minVehiclePriceCAD + ' ' + it.maxVehiclePriceCAD
        ),
        (it) => it.minVehiclePriceCAD,
        (it) => it.maxVehiclePriceCAD
      ),
    [data]
  );

  function addEmptyPrices(newTerms: number[], newLimits: Limits[]) {
    const prices: PricePoint[] = [];
    for (const termInMonths of newTerms) {
      for (const limit of newLimits) {
        if (
          !data.some(
            (it) =>
              it.termInMonths === termInMonths &&
              it.maxVehiclePriceCAD === limit.maxVehiclePriceCAD &&
              it.minVehiclePriceCAD === limit.minVehiclePriceCAD
          )
        ) {
          prices.push({
            id: undefined,
            isUpdated: false,
            termInMonths,
            maxVehiclePriceCAD: limit.maxVehiclePriceCAD,
            minVehiclePriceCAD: limit.minVehiclePriceCAD,
            priceCAD: null,
            productProvinceGroupId,
            productId,
          });
        }
      }
    }
    setData((old) => [...old, ...prices]);
  }

  function removeLimits(newLimits: Omit<Limits, 'label'>) {
    setData((old) =>
      old.filter(
        (it) =>
          !(
            it.maxVehiclePriceCAD === newLimits.maxVehiclePriceCAD &&
            it.minVehiclePriceCAD === newLimits.minVehiclePriceCAD
          )
      )
    );
  }
  //#endregion

  const isAllowedToCopy = !!productProvinceGroupId && data.length === 0;
  async function copy() {
    setIsSaving(true);
    if (!listApi.payload?.data?.some((it) => it.productProvinceGroupId === productProvinceGroupId)) {
      const defaultNonWarrantyPricePoints = listApi.payload?.data?.filter((it) => !it.productProvinceGroupId);
      for (const nonWarrantyPricePoint of defaultNonWarrantyPricePoints || []) {
        await nonWarrantyPricePointSaveApi({
          termInMonths: nonWarrantyPricePoint.termInMonths,
          priceCAD: nonWarrantyPricePoint.priceCAD,
          productId: nonWarrantyPricePoint.productId,
          maxVehiclePriceCAD: nonWarrantyPricePoint.maxVehiclePriceCAD,
          minVehiclePriceCAD: nonWarrantyPricePoint.minVehiclePriceCAD,
          pricingDistributions: nonWarrantyPricePoint.pricingDistributions,
          productProvinceGroupId,
        });
      }
    }
    setIsSaving(false);
    listApi.execute((body) => body);
  }
  return {
    distributorPricingDistributions,
    dealerPricingSettings,
    hasAccess,
    provinceGroup: productApi?.payload?.productProvinceGroups?.find((g) => g.id === productProvinceGroupId),
    isAllowedToCopy,
    copy,
    isSaving,
    isUpdated,
    addEmptyPrices,
    removeTerm,
    removeLimits,
    updatePrice,
    updateSubfee,
    getPrice,
    data,
    terms,
    limits,
    saveAll,
    pageRouter,
    productApi,
    listApi,
    permissions,
  };
}

export function useNonWarrantyPricingProvider() {
  return useDataProvider<ReturnType<typeof useNonWarrantyPricing>>();
}
