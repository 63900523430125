import Button from 'assets/components/button/Button';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import FilterForm from 'assets/components/filters/filterForm/FilterForm';
import ThemeFilterFormDrawer from 'assets/components/filters/filterForm/themes/Theme.FilterForm.Drawer';
import Icon from 'assets/components/icon/Icon';
import DateInput from 'assets/components/inputs/dateInput/DateInput';
import SearchInput from 'assets/components/inputs/search/SearchInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { getLocales } from 'assets/locales/Locale';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import { defaultInputDateTimeFormat } from 'assets/utils/data/Date';
import { OrderType } from 'config/Api.Config';
import StatementStatusCode from 'models/enums/StatementStatusCode';
import { useStatementProvider } from '../Statement.Hooks';

export default function StatementFilters() {
  const { lang } = getLocales();
  const { listApi, dealersApi, dealerGroupsApi } = useStatementProvider();

  return (
    <>
      <SearchInput
        value={listApi.body?.search}
        onSearch={(search) => listApi.execute((body) => ({ ...body, search }))}
        threshold={2}
        placeholder={`${lang.search}...`}
      />
      &nbsp;
      <DropdownButton
        buttonMedia={<Icon class="fas-sort" />}
        horizontalPosition="auto_fixed"
        verticalPosition="auto_fixed"
        htmlElementProps={{ title: lang.sort }}
        subcomponents={ThemeDropdownButtonPopup}
        dropdownOptions={
          <>
            <Button
              class={ThemeButtonMenuItem}
              render={lang.newerFirst}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: undefined,
                orderType: undefined,
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.olderFirst}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'id',
                orderType: OrderType.asc,
              })}
            />
          </>
        }
      />
      &nbsp;
      <FilterForm
        subcomponents={ThemeFilterFormDrawer}
        filters={listApi.body}
        onFilter={(newFilters) => listApi.execute(newFilters)}
        onReset={listApi.reset}
        onOpen={() => {
          if (!dealersApi.payload) dealersApi.execute((body) => body);
        }}
        render={({ tempFilters, setFilters }) => (
          <>
            <SelectInput
              name="status"
              label={lang.status}
              data={Object.values(StatementStatusCode).map((it) => ({
                id: it,
                title: it,
                icon: <Icon class="fas-double-check" />,
              }))}
              isMultiselect
              value={tempFilters?.status}
              onChange={({ status }) => setFilters({ ...tempFilters, status })}
              icon={<Icon class="fas-cloud" />}
            />
            {dealersApi?.payload?.data?.length > 1 && (
              <SelectInput
                name="dealerId"
                label={lang.dealers}
                data={dealersApi?.payload?.data?.map((item) => ({
                  ...item.displayInfo,
                  icon: <Icon class="fas-cloud" />,
                }))}
                isMultiselect
                value={tempFilters?.dealerId}
                onChange={({ dealerId }) => setFilters({ ...tempFilters, dealerId })}
                icon={<Icon class="fas-cloud" />}
              />
            )}
            {dealerGroupsApi?.payload?.data?.length > 1 && (
              <SelectInput
                name="dealerGroupId"
                label={lang.dealerGroup}
                data={dealerGroupsApi?.payload?.data?.map((item) => ({
                  ...item.displayInfo,
                  icon: <Icon class="fas-cloud" />,
                }))}
                isMultiselect
                value={tempFilters?.dealerGroupId}
                onChange={({ dealerGroupId }) => setFilters({ ...tempFilters, dealerGroupId })}
                icon={<Icon class="fas-cloud" />}
              />
            )}
            <InputWrapper>
              <DateInput
                name="invoicingFromDateUtc"
                label={lang.invoicingFromDate}
                dateTimeFormat={defaultInputDateTimeFormat}
                value={tempFilters?.invoicingFromDateUtc}
                onChange={({ invoicingFromDateUtc }) => setFilters({ ...tempFilters, invoicingFromDateUtc })}
                icon={<Icon class="fas-calendar-alt" />}
              />
              <DateInput
                name="invoicingToDateUtc"
                label={lang.invoicingToDate}
                dateTimeFormat={defaultInputDateTimeFormat}
                value={tempFilters?.invoicingToDateUtc}
                onChange={({ invoicingToDateUtc }) => setFilters({ ...tempFilters, invoicingToDateUtc })}
                icon={<Icon class="fas-calendar-alt" />}
              />
            </InputWrapper>
          </>
        )}
      />
    </>
  );
}
