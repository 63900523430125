import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageStatic2 from 'assets/components/page/themes/Theme.Page.Static.2';
import { getLocales } from 'assets/locales/Locale';
import BigHr from 'assets/templates/dividers/BigHr';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';
import { HttpStatus, claimsHomeRoute } from 'config/Api.Config';
import ClaimCommentType from 'models/enums/ClaimCommentType';
import ClaimChangeOwnership from 'module/claims/components/claimsChangeOwnership/Claim.ChangeOwnership';
import ClaimStatusStateMachine from 'module/claims/components/claimsStateMachine/Claim.StatusStateMachine';
import ClaimInfoCard from '../claim/other/Claim.InfoCard';
import { ClaimEditProvider } from './ClaimEdit.Hooks';
import ClaimEditClaimHistory from './sections/ClaimEdit.ClaimHistory';
import ClaimEditComments from './sections/ClaimEdit.Comments';
import ClaimEditDocuments from './sections/ClaimEdit.Documents';
import ClaimEditInvoices from './sections/ClaimEdit.Invoices';
import ClaimEditJobs from './sections/ClaimEdit.Jobs';
import ClaimEditLogs from './sections/ClaimEdit.Logs';
import ClaimEditSubmitButton from './sections/ClaimEdit.SubmitButton';
import ClaimEditTabs from './sections/ClaimEdit.Tabs';
import ClaimEditEmails from './sections/claimEditEmail/ClaimEdit.Emails';
import ClaimEditEmailTemplate from './sections/claimEditEmail/ClaimEdit.EmailTemplate';
import ClaimEditSendEmail from './sections/claimEditEmail/ClaimEdit.SendEmail';
import ClaimEditInfo from './sections/claimEditInfo/ClaimEdit.Info';
import ClaimEditJobForm from './sections/claimJobEdit/claimJobForm/ClaimEdit.Job.Form';
import { ClaimEditJobPayeeItemProvider, ClaimJobEditProvider } from './sections/claimJobEdit/ClaimEdit.Job.Hooks';
import ClaimDownloadDocuments from 'module/claims/components/claimsDownloadDocuments/Claim.DownloadDocuments';

export const claimEditIndexRoute = claimsHomeRoute + '/claim-edit/:claimId?/:jobId?';

export default function ClaimEditIndex() {
  const { lang } = getLocales();
  return (
    <ClaimEditProvider
      render={({
        form,
        hasReports,
        hasAnyPermission,
        isLoading,
        emailTemplates,
        statusInfo,
        isStatusChanged,
        currentStatus,
        claim,
        product,
        contract,
        changeStatus,
        permissions,
        emailAction,
        setEmailAction,
        isEditable,
        isClaimAdmin,
        isClaimOwner,
        isClaimCanceled,
        reloadClaim,
        currentUserPermissions,
        isClaimJobEditableByStatus,
      }) => {
        const isDisabled = isClaimAdmin || (isClaimOwner && isEditable);
        const isJobAddable = isClaimJobEditableByStatus || hasReports || !isDisabled;

        return !hasAnyPermission ? (
          <ErrorPage status={HttpStatus.unauthorized} />
        ) : (
          <ClaimEditJobPayeeItemProvider>
            <Page
              class={ThemePageStatic2}
              isLoading={isLoading}
              htmlElementProps={{ pageHeader: { id: 'info' } }}
              header={isLoading ? null : <ClaimInfoCard claim={claim} product={product} contract={contract} />}
              render={
                <>
                  {form.data.emailTemplate && emailAction === 'changeStatusSendEmail' && <ClaimEditEmailTemplate />}
                  <ClaimEditClaimHistory />
                  <BigHr />
                  <ClaimEditInfo />
                  <BigHr id="jobs" />
                  <ClaimEditJobs />
                  {permissions.EXTERNAL_COMMENT && (
                    <>
                      <BigHr id="comments" />
                      <ClaimEditComments commentType={ClaimCommentType.external} />
                    </>
                  )}
                  <BigHr id="documents" />
                  <ClaimEditDocuments />
                  <BigHr id="invoices" />
                  <ClaimEditInvoices />
                  <BigHr id="emails" />
                  <ClaimEditEmails />
                  <BigHr id="logs" />
                  <ClaimEditLogs />
                </>
              }
              subHeader={
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '10px',
                    marginBottom: '10px',
                    gap: '5px',
                  }}
                >
                  <ClaimEditTabs />
                  <div style={{ display: 'flex' }}>
                    {claim && (
                      <ClaimJobEditProvider
                        render={({ setAction, action }) => (
                          <>
                            {action === 'edit' && <ClaimEditJobForm />}
                            <Button
                              class={ThemeButtonCircle}
                              media={<Icon class="fas-plus" />}
                              render={lang.newJob}
                              onClick={setAction}
                              data="edit"
                              disabled={isJobAddable}
                              htmlElementProps={{ title: `${lang.add} ${lang.newJob}` }}
                            />
                          </>
                        )}
                      />
                    )}
                    <ClaimStatusStateMachine
                      emailTemplates={emailTemplates}
                      statusInfo={statusInfo}
                      disabled={!(form.data.id && isDisabled) || isClaimCanceled}
                      isStatusChanged={isStatusChanged}
                      ownerId={form.data.owner?.id}
                      currentStatus={currentStatus}
                      changeStatus={changeStatus}
                      setEmailAction={setEmailAction}
                    />
                    <ClaimChangeOwnership
                      emailTemplates={emailTemplates}
                      statusInfo={statusInfo}
                      hasPermission={!isDisabled || isClaimCanceled}
                      claim={claim}
                      currentPermissions={currentUserPermissions}
                      reloadClaim={reloadClaim}
                    />
                    <ClaimDownloadDocuments claim={claim}/>
                    <>
                      <Button
                        class={ThemeButtonCircle}
                        media={<Icon class="fas-envelope" />}
                        render={lang.sendEmail}
                        onClick={setEmailAction}
                        data="sendEmail"
                        htmlElementProps={{ title: lang.sendEmail }}
                      />
                      {emailAction === 'sendEmail' && <ClaimEditSendEmail />}
                    </>
                    <ClaimEditSubmitButton />
                  </div>
                </div>
              }
            />
          </ClaimEditJobPayeeItemProvider>
        );
      }}
    />
  );
}
