import { combineStrings } from 'assets/utils/data/String';
import Model from 'models/Model';
import ClaimTypeAggregate from '../claimTypeAggregate/Model.ClaimTypeAggregate';
import ClaimTypeDocument from '../claimTypeDocument/Model.ClaimTypeDocument';
import ClaimTypeReportSettings from '../claimTypeReportSettings/Model.ClaimTypeReportSettings';
import LossCode from '../lossCode/Model.LossCode';
import Product from '../../productManagement/product/Model.Product';
import claimTypeDeleteApi from './delete/Api.ClaimType.Delete';
import claimTypeListApi from './list/Api.ClaimType.List';
import claimTypeSaveApi from './save/Api.ClaimType.Save';
import ClaimJob from '../claimJob/Model.ClaimJob';
import claimTypeListPublicApi from './listPublic/Api.ClaimType.ListPublic';
import ClaimTypeDataPoint from '../claimTypeDataPoint/Model.ClaimTypeDataPoint';

export default class ClaimType
  extends Model<Model.IClaimType>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IClaimType>
{
  id: number;
  version: number;
  code: string;
  name: string;
  description?: string;
  product: Product;

  lossCodes: LossCode[];
  claimTypeAggregates: ClaimTypeAggregate[];
  claimTypeDocuments: ClaimTypeDocument[];
  claimTypeReportSettings: ClaimTypeReportSettings[];
  claimTypeDataPoints: ClaimTypeDataPoint[];

  claimTypeAggregatesInactive: ClaimTypeAggregate[];
  claimTypeDocumentsInactive: ClaimTypeDocument[];
  claimTypeReportSettingsInactive: ClaimTypeReportSettings[];
  claimTypeDataPointsInactive: ClaimTypeDataPoint[];

  isDeleted?: boolean;
  claimJobs: ClaimJob[];

  constructor(data: Partial<Model.IClaimType> | Utils.FormData<Model.IClaimType>, language?: Locale.Languages) {
    super(
      {
        product: Product,
        lossCodes: [LossCode],
        claimTypeAggregates: [ClaimTypeAggregate],
        claimTypeDocuments: [ClaimTypeDocument],
        claimTypeDataPoints: [ClaimTypeDataPoint],
        claimTypeReportSettings: [ClaimTypeReportSettings],
        claimTypeAggregatesInactive: [ClaimTypeAggregate],
        claimTypeDocumentsInactive: [ClaimTypeDocument],
        claimTypeReportSettingsInactive: [ClaimTypeReportSettings],
        claimTypeDataPointsInactive: [ClaimTypeDataPoint],
      },
      language
    );
    this.update(data);

    //if(this.product?.code === Product.CODE_TRUE_GAP) this.claimTypeReportSettings = [trueGapReportMocup]
    //if(this.product?.code === Product.CODE_FLM) this.claimTypeReportSettings = [flmReportMocup]
  }
  update(data: Partial<Model.IClaimType> | Utils.FormData<Model.IClaimType>) {
    data.claimTypeAggregatesInactive = data.claimTypeAggregates?.filter((it) => it.version !== data.version);
    data.claimTypeDocumentsInactive = data.claimTypeDocuments?.filter((it) => it.version !== data.version);
    data.claimTypeReportSettingsInactive = data.claimTypeReportSettings?.filter((it) => it.version !== data.version);
    data.claimTypeDataPointsInactive = data.claimTypeDataPoints?.filter((it) => it.version !== data.version);

    data.claimTypeAggregates = data.claimTypeAggregates?.filter((it) => it.version == data.version);
    data.claimTypeDocuments = data.claimTypeDocuments?.filter((it) => it.version == data.version);
    data.claimTypeReportSettings = data.claimTypeReportSettings?.filter((it) => it.version == data.version);
    data.claimTypeDataPoints = data.claimTypeDataPoints?.filter((it) => it.version == data.version);
    super.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: combineStrings(' - ', this.name, this.code) || this.id?.toString(),
      info: this.product?.name,
      subtitle: this.description,
    };
  }
  static async list(body?: Api.Core.ClaimType.List.IRequest) {
    const { payload, ...rest } = await claimTypeListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new ClaimType(it)),
        count: payload.count,
      },
    };
  }
  static async listPublic(req: Api.Core.ClaimType.ListPublic.IRequest) {
    const { payload, ...rest } = await claimTypeListPublicApi(req);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new ClaimType(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await claimTypeSaveApi(this);
  }
  async delete() {
    return await claimTypeDeleteApi(this.id);
  }
}
