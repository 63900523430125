import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import { ApiHeaderApplication } from 'config/Api.Config';
import { AuthPermission } from 'config/Auth.Config';
import { first } from 'lodash';
import { accountChangePasswordRoute } from 'module/account/views/changePassword/Account.ChangePassword.Index';
import { accountEditUserSettingsRoute } from 'module/account/views/editUserSettings/Account.EditUserSettings.Index';
import { accountResetPasswordRoute } from 'module/account/views/resetPassword/Account.ResetPassword.Index';
import { accountUserWelcomeRoute } from 'module/account/views/userWelcome/Account.UserWelcome.Index';
import { translationInfoIndexRoute } from 'module/management/views/translationInfo/TranslationInfo.Index';
import { salesDraftIndexRoute } from 'module/sales/views/draft/Sales.Draft.Index';
import { salesQuoteIndexRoute } from 'module/sales/views/quote/Sales.Quote.Index';
import { statementSummaryIndexRoute } from 'module/sales/views/statementSummary/StatementSummary.Index';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { claimIndexRoute } from '../../module/claims/views/claim/Claim.Index';
import { claimTypeIndexRoute } from '../../module/claims/views/claimType/ClaimType.Index';
import { contractIndexRoute } from '../../module/claims/views/contract/Contract.Index';
import { lossCodeIndexRoute } from '../../module/claims/views/lossCode/LossCode.Index';
import { payeeIndexRoute } from '../../module/claims/views/payee/Payee.Index';
import { payeeTypeIndexRoute } from '../../module/claims/views/payeeType/PayeeType.Index';
import { dealerIndexRoute } from '../../module/dealerManagement/views/dealer/Dealer.Index';
import { dealerGroupIndexRoute } from '../../module/dealerManagement/views/dealerGroup/DealerGroup.Index';
import { accessLogIndexRoute } from '../../module/management/views/accessLog/AccessLog.Index';
import { auditLogIndexRoute } from '../../module/management/views/auditLog/AuditLog.Index';
import { claimEmailTemplateIndexRoute } from '../../module/management/views/claimEmailTemplate/ClaimEmailTemplate.Index';
import { permissionIndexRoute } from '../../module/management/views/permission/Permission.Index';
import { roleIndexRoute } from '../../module/management/views/role/Role.Index';
import { statementEmailTemplateIndexRoute } from '../../module/management/views/statementEmailTemplate/StatementEmailTemplate.Index';
import { userIndexRoute } from '../../module/management/views/user/User.Index';
import { insurerIndexRoute } from '../../module/productManagement/views/insurer/Insurer.Index';
import { pricingDistributionTemplateIndexRoute } from '../../module/productManagement/views/pricingDistributionTemplate/PricingDistributionTemplate.Index';
import { pricingLimitTemplateIndexRoute } from '../../module/productManagement/views/pricingLimitTemplate/PricingLimitTemplate.Index';
import { productIndexRoute } from '../../module/productManagement/views/product/Product.Index';
import { productCategoryIndexRoute } from '../../module/productManagement/views/productCategory/ProductCategory.Index';
import { subfeeIndexRoute } from '../../module/productManagement/views/subfee/Subfee.Index';
import { tierIndexRoute } from '../../module/productManagement/views/tier/Tier.Index';
import { vehicleClassIndexRoute } from '../../module/productManagement/views/vehicleClass/VehicleClass.Index';
import { vehicleMakeIndexRoute } from '../../module/productManagement/views/vehicleMake/VehicleMake.Index';
import { vehicleModelIndexRoute } from '../../module/productManagement/views/vehicleModel/VehicleModel.Index';
import { salesContractIndexRoute } from '../../module/sales/views/contract/Sales.Contract.Index';
import { distributorIndexRoute } from '../../module/sales/views/distributor/Distributor.Index';
import { lenderIndexRoute } from '../../module/sales/views/lender/Lender.Index';
import { statementIndexRoute } from '../../module/sales/views/statements/Statement.Index';
import useCurrentApp from './useCurrentApp';

export function getRoutes() {
  const { lang } = getLocales();
  const routes: Array<Component.Navigation.Route> = [
    //Sales
    {
      icon: 'fas-file-contract',
      title: lang.contracts,
      permissions: [AuthPermission.editContract, AuthPermission.deleteContract, AuthPermission.createContract],
      applicationId: ApiHeaderApplication.sales,
      path: createPathFromRoute(salesContractIndexRoute, {}),
      route: salesContractIndexRoute,
    },
    {
      icon: 'fa-clipboard-question',
      title: lang.quote,
      permissions: [AuthPermission.editContract, AuthPermission.deleteContract, AuthPermission.createContract],
      applicationId: ApiHeaderApplication.sales,
      path: createPathFromRoute(salesQuoteIndexRoute, {}),
      route: salesQuoteIndexRoute,
    },
    {
      icon: 'fas-drafting-compass',
      title: lang.drafts,
      permissions: [AuthPermission.createContract],
      applicationId: ApiHeaderApplication.sales,
      path: createPathFromRoute(salesDraftIndexRoute, {}),
      route: salesDraftIndexRoute,
    },
    {
      icon: 'fas-building',
      title: lang.lenders,
      permissions: [AuthPermission.editLender, AuthPermission.deleteLender, AuthPermission.createLender],
      applicationId: ApiHeaderApplication.sales,
      path: createPathFromRoute(lenderIndexRoute, {}),
      route: lenderIndexRoute,
    },
    {
      icon: 'fas-users',
      title: lang.distributors,
      permissions: [AuthPermission.editDistributor, AuthPermission.deleteDistributor, AuthPermission.createDistributor],
      applicationId: ApiHeaderApplication.sales,
      path: createPathFromRoute(distributorIndexRoute, {}),
      route: distributorIndexRoute,
    },
    {
      icon: 'fas-file',
      title: lang.statements,
      permissions: [AuthPermission.createStatement, AuthPermission.editStatement, AuthPermission.deleteStatement],
      applicationId: ApiHeaderApplication.sales,
      path: createPathFromRoute(statementIndexRoute, {}),
      route: statementIndexRoute,
    },
    {
      icon: 'fas-folder',
      title: lang.statementSummary,
      permissions: [AuthPermission.createStatementSummary, AuthPermission.deleteStatementSummary],
      applicationId: ApiHeaderApplication.sales,
      path: createPathFromRoute(statementSummaryIndexRoute, {}),
      route: statementSummaryIndexRoute,
    },
    //Claims
    {
      icon: 'fas-car-crash',
      title: lang.claims,
      permissions: [
        AuthPermission.claimAdmin,
        AuthPermission.createClaim,
        AuthPermission.createSimpleClaim,
        AuthPermission.editClaim,
        AuthPermission.deleteClaim,
        AuthPermission.editClaimCustomerInfo,
        AuthPermission.deleteClaimJob,
      ],
      applicationId: ApiHeaderApplication.claims,
      path: createPathFromRoute(claimIndexRoute, {}),
      route: claimIndexRoute,
    },
    {
      icon: 'fas-file-contract',
      title: lang.contracts,
      permissions: [
        AuthPermission.claimAdmin,
        AuthPermission.createClaim,
        AuthPermission.createSimpleClaim,
        AuthPermission.editClaim,
        AuthPermission.deleteClaim,
        AuthPermission.editClaimCustomerInfo,
      ],
      applicationId: ApiHeaderApplication.claims,
      path: createPathFromRoute(contractIndexRoute, {}),
      route: contractIndexRoute,
    },
    {
      icon: 'fas-cogs',
      title: lang.settings,
      permissions: [
        AuthPermission.editClaimType,
        AuthPermission.deleteClaimType,
        AuthPermission.createClaimType,
        AuthPermission.editLossCode,
        AuthPermission.deleteLossCode,
        AuthPermission.createLossCode,
        AuthPermission.importLossCode,
        AuthPermission.editLossCodeCategory,
        AuthPermission.deleteLossCodeCategory,
        AuthPermission.createLossCodeCategory,
        AuthPermission.editPayee,
        AuthPermission.deletePayee,
        AuthPermission.createPayee,
      ],
      applicationId: ApiHeaderApplication.claims,
      groups: [
        {
          icon: 'fas-clipboard-list',
          title: lang.claimType,
          permissions: [AuthPermission.editClaimType, AuthPermission.deleteClaimType, AuthPermission.createClaimType],
          applicationId: ApiHeaderApplication.claims,
          path: createPathFromRoute(claimTypeIndexRoute, {}),
          route: claimTypeIndexRoute,
        },
        {
          icon: 'fas-list',
          title: lang.lossCode,
          permissions: [AuthPermission.editLossCode, AuthPermission.deleteLossCode, AuthPermission.createLossCode],
          applicationId: ApiHeaderApplication.claims,
          path: createPathFromRoute(lossCodeIndexRoute, {}),
          route: lossCodeIndexRoute,
        },
        {
          icon: 'fas-coins',
          title: lang.payeeType,
          permissions: [AuthPermission.editPayee, AuthPermission.deletePayee, AuthPermission.createPayee],
          applicationId: ApiHeaderApplication.claims,
          path: createPathFromRoute(payeeTypeIndexRoute, {}),
          route: payeeTypeIndexRoute,
        },
        {
          icon: 'fas-hand-holding-usd',
          title: lang.payee,
          permissions: [AuthPermission.editPayee, AuthPermission.deletePayee, AuthPermission.createPayee],
          applicationId: ApiHeaderApplication.claims,
          path: createPathFromRoute(payeeIndexRoute, {}),
          route: payeeIndexRoute,
        },
      ],
    },

    //Management
    {
      icon: 'fas-users',
      title: lang.users,
      permissions: [
        AuthPermission.createUser,
        AuthPermission.editUser,
        AuthPermission.deleteUser,
        AuthPermission.resetPassword,
      ],
      applicationId: ApiHeaderApplication.management,
      path: createPathFromRoute(userIndexRoute, {}),
      route: userIndexRoute,
    },
    {
      icon: 'fas-user-shield',
      title: lang.roles,
      permissions: [AuthPermission.createRole, AuthPermission.editRole, AuthPermission.deleteRole],
      applicationId: ApiHeaderApplication.management,
      path: createPathFromRoute(roleIndexRoute, {}),
      route: roleIndexRoute,
    },
    {
      icon: 'fas-shield-alt',
      title: lang.permissions,
      permissions: [AuthPermission.viewPermission],
      applicationId: ApiHeaderApplication.management,
      path: createPathFromRoute(permissionIndexRoute, {}),
      route: permissionIndexRoute,
    },
    {
      icon: 'if-login',
      title: lang.accessLogs,
      permissions: [AuthPermission.viewAccessLogs],
      applicationId: ApiHeaderApplication.management,
      path: createPathFromRoute(accessLogIndexRoute, {}),
      route: accessLogIndexRoute,
    },
    {
      icon: 'if-database',
      title: lang.changeLogs,
      permissions: [AuthPermission.viewAuditLogs],
      applicationId: ApiHeaderApplication.management,
      path: createPathFromRoute(auditLogIndexRoute, {}),
      route: auditLogIndexRoute,
    },
    {
      icon: 'fas-envelope',
      title: lang.emailTemplates,
      permissions: [
        AuthPermission.createEmailTemplate,
        AuthPermission.editEmailTemplate,
        AuthPermission.deleteEmailTemplate,
      ],
      applicationId: ApiHeaderApplication.management,
      groups: [
        {
          icon: 'fas-envelope',
          title: lang.claimEmailTemplates,
          permissions: [
            AuthPermission.createEmailTemplate,
            AuthPermission.editEmailTemplate,
            AuthPermission.deleteEmailTemplate,
          ],
          applicationId: ApiHeaderApplication.management,
          path: createPathFromRoute(claimEmailTemplateIndexRoute, {}),
          route: claimEmailTemplateIndexRoute,
        },
        {
          icon: 'fas-envelope',
          title: lang.statementEmailTemplates,
          permissions: [
            AuthPermission.createEmailTemplate,
            AuthPermission.editEmailTemplate,
            AuthPermission.deleteEmailTemplate,
          ],
          applicationId: ApiHeaderApplication.management,
          path: createPathFromRoute(statementEmailTemplateIndexRoute, {}),
          route: statementEmailTemplateIndexRoute,
        },
      ],
    },

    //Product management
    {
      icon: 'fas-box',
      title: lang.products,
      permissions: [
        AuthPermission.editProduct,
        AuthPermission.deleteProduct,
        AuthPermission.createProduct,
        AuthPermission.viewProductCustomPricePoint,
        AuthPermission.createProductCustomPricePoint,
        AuthPermission.editProductCustomPricePoint,
        AuthPermission.deleteProductCustomPricePoint,
        AuthPermission.viewProductNonWarrantyPricePoint,
        AuthPermission.createProductNonWarrantyPricePoint,
        AuthPermission.editProductNonWarrantyPricePoint,
        AuthPermission.deleteProductNonWarrantyPricePoint,
        AuthPermission.viewProductTierPricePoint,
        AuthPermission.createProductTierPricePoint,
        AuthPermission.editProductTierPricePoint,
        AuthPermission.deleteProductTierPricePoint,
        AuthPermission.viewProductClassPricePoint,
        AuthPermission.createProductClassPricePoint,
        AuthPermission.editProductClassPricePoint,
        AuthPermission.deleteProductClassPricePoint,
        AuthPermission.pricingLimitsDealer,
      ],
      applicationId: ApiHeaderApplication.productManagement,
      path: createPathFromRoute(productIndexRoute, {}),
      route: productIndexRoute,
    },
    {
      icon: 'fas-list',
      title: lang.programs,
      permissions: [AuthPermission.editProduct, AuthPermission.deleteProduct, AuthPermission.createProduct],
      applicationId: ApiHeaderApplication.productManagement,
      path: createPathFromRoute(productCategoryIndexRoute, {}),
      route: productCategoryIndexRoute,
    },
    {
      icon: 'fas-layer-group',
      title: lang.tiers,
      permissions: [AuthPermission.editTier, AuthPermission.deleteTier, AuthPermission.createTier],
      applicationId: ApiHeaderApplication.productManagement,
      path: createPathFromRoute(tierIndexRoute, {}),
      route: tierIndexRoute,
    },
    {
      icon: 'fas-coins',
      title: lang.limits,
      permissions: [
        AuthPermission.editPricingLimitTemplate,
        AuthPermission.deletePricingLimitTemplate,
        AuthPermission.createPricingLimitTemplate,
      ],
      applicationId: ApiHeaderApplication.productManagement,
      path: createPathFromRoute(pricingLimitTemplateIndexRoute, {}),
      route: pricingLimitTemplateIndexRoute,
    },
    {
      icon: 'fas-balance-scale',
      title: lang.pricingDistributionTemplates,
      permissions: [
        AuthPermission.editPricingDistributionTemplate,
        AuthPermission.deletePricingDistributionTemplate,
        AuthPermission.createPricingDistributionTemplate,
      ],
      applicationId: ApiHeaderApplication.productManagement,
      path: createPathFromRoute(pricingDistributionTemplateIndexRoute, {}),
      route: pricingDistributionTemplateIndexRoute,
    },
    {
      icon: 'fas-car',
      title: lang.vehicleMakes,
      permissions: [
        AuthPermission.editVehicleMake,
        AuthPermission.deleteVehicleMake,
        AuthPermission.createVehicleMake,
        AuthPermission.importVehicleMakeFromExcel,
      ],
      applicationId: ApiHeaderApplication.productManagement,
      path: createPathFromRoute(vehicleMakeIndexRoute, {}),
      route: vehicleMakeIndexRoute,
    },
    {
      icon: 'fas-car',
      title: lang.vehicleModels,
      permissions: [
        AuthPermission.editVehicleModel,
        AuthPermission.deleteVehicleModel,
        AuthPermission.createVehicleModel,
      ],
      applicationId: ApiHeaderApplication.productManagement,
      path: createPathFromRoute(vehicleModelIndexRoute, {}),
      route: vehicleModelIndexRoute,
    },
    {
      icon: 'fas-oil-can',
      title: lang.vehicleClasses,
      permissions: [
        AuthPermission.editVehicleClass,
        AuthPermission.deleteVehicleClass,
        AuthPermission.createVehicleClass,
      ],
      applicationId: ApiHeaderApplication.productManagement,
      path: createPathFromRoute(vehicleClassIndexRoute, {}),
      route: vehicleClassIndexRoute,
    },
    {
      icon: 'fas-users',
      title: lang.insurers,
      permissions: [AuthPermission.editInsurer, AuthPermission.deleteInsurer, AuthPermission.createInsurer],
      applicationId: ApiHeaderApplication.productManagement,
      path: createPathFromRoute(insurerIndexRoute, {}),
      route: insurerIndexRoute,
    },
    {
      icon: 'fas-plus',
      title: lang.surcharges,
      permissions: [AuthPermission.editInsurer, AuthPermission.deleteInsurer, AuthPermission.createInsurer],
      applicationId: ApiHeaderApplication.productManagement,
      path: createPathFromRoute(subfeeIndexRoute, {}),
      route: subfeeIndexRoute,
    },

    //Dealer management
    {
      icon: 'fas-user-friends',
      title: lang.dealers,
      applicationId: ApiHeaderApplication.dealerManagement,
      permissions: [AuthPermission.editDealer, AuthPermission.deleteDealer, AuthPermission.createDealer],
      path: createPathFromRoute(dealerIndexRoute, {}),
      route: dealerIndexRoute,
    },
    {
      icon: 'fas-users',
      title: `${lang.dealer} ${lang.groups}`,
      permissions: [AuthPermission.editDealerGroup, AuthPermission.deleteDealerGroup, AuthPermission.createDealerGroup],
      applicationId: ApiHeaderApplication.dealerManagement,
      path: createPathFromRoute(dealerGroupIndexRoute, {}),
      route: dealerGroupIndexRoute,
    },

    //Extra hidden routes
    {
      icon: 'fas-user',
      title: lang.accountInfo,
      permissions: [],
      applicationId: ApiHeaderApplication.management,
      path: createPathFromRoute(accountUserWelcomeRoute, {}),
      route: accountUserWelcomeRoute,
      hidden: true,
    },
    {
      icon: 'fas-lock',
      title: lang.resetPassword,
      permissions: [],
      applicationId: ApiHeaderApplication.management,
      path: createPathFromRoute(accountResetPasswordRoute, {}),
      route: accountResetPasswordRoute,
      hidden: true,
    },
    {
      icon: 'fas-lock',
      title: lang.changePassword,
      permissions: [],
      applicationId: ApiHeaderApplication.management,
      path: createPathFromRoute(accountChangePasswordRoute, {}),
      route: accountChangePasswordRoute,
      hidden: true,
    },
    {
      icon: 'fas-user',
      title: lang.editMySettings,
      permissions: [],
      applicationId: ApiHeaderApplication.management,
      path: createPathFromRoute(accountEditUserSettingsRoute, {}),
      route: accountEditUserSettingsRoute,
      hidden: true,
    },
    {
      icon: 'fas-globe',
      title: lang.language,
      permissions: [],
      applicationId: ApiHeaderApplication.management,
      path: createPathFromRoute(translationInfoIndexRoute, {}),
      route: translationInfoIndexRoute,
      hidden: true,
    },
  ];

  return routes;
}

export default function useRouter() {
  const allRoutes = getRoutes();
  const authInfo = useAuthStore();
  const currentApp = useCurrentApp();
  const history = useHistory();
  const location = useLocation();
  const routesWithAccess = allRoutes.filter((it) => !it.permissions.length || authInfo.hasAccess(...it.permissions));
  const routesWithAccessForCurrentApp = routesWithAccess.filter((it) => it.applicationId === currentApp.id);
  let homeRoute = first(routesWithAccess);
  if (homeRoute?.groups && !homeRoute?.path) {
    homeRoute = first(homeRoute?.groups);
  }

  useEffect(() => {
    if (location.pathname === '/') {
      history.push(homeRoute?.path);
    }
  }, [location.pathname]);

  return {
    allRoutes: routesWithAccess,
    routes: routesWithAccessForCurrentApp,
    homeRoute,
  };
}
