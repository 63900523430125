import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import DateInput from 'assets/components/inputs/dateInput/DateInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import { getLocales } from 'assets/locales/Locale';
import { dateDef, defaultInputDateTimeFormat, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { useStatementProvider } from '../Statement.Hooks';
import { useEffect } from 'react';

export default function StatementCreate() {
  const { lang } = getLocales();
  const { createApi, pageRouter, dealersApi } = useStatementProvider();

  return (
    <ModalForm<Api.Core.Statement.CreateForDealer.IRequest>
      subcomponents={ThemeModalFormDefault}
      title={lang.statement}
      default={{}}
      onCancel={pageRouter.updateParams}
      onSubmit={createApi.execute}
      validation={(data, errors) => {
        if (!data.dealerId) errors.dealerId = lang.mustNotBeEmpty;
        if (!data.from) errors.from = lang.mustNotBeEmpty;
        if (!data.to) errors.to = lang.mustNotBeEmpty;
      }}
      render={({ form }) => {
        useEffect(() => {
          if (!form.data?.createdAtLocal) {
            form.update({
              createdAtLocal: dateDef().format(defaultServerDateTimeFormat),
            });
          }
        }, []);

        return (
          <>
            <FormWrapper>
              <SelectInput
                name="dealerId"
                label={lang.dealer}
                data={dealersApi?.payload?.data?.map((it) => ({
                  ...it.displayInfo,
                  id: it.id,
                }))}
                value={form.data?.dealerId}
                error={form.errors.dealerId}
                onChange={({ dealerId }) => {
                  form.update({
                    dealerId,
                  });
                }}
                icon={<Icon class="if-cloud" />}
              />
              <DateInput
                dateTimeFormat={defaultInputDateTimeFormat}
                name="from"
                value={form.data?.from}
                error={form.errors.from}
                label={lang.date}
                isLocal={true}
                max={dateDef().endOf('day').add(-1, 'second').format(defaultServerDateTimeFormat)}
                onChange={({ from }) => {
                  form.update({
                    from,
                  });
                }}
                icon={<Icon class="fas-calendar-alt" />}
              />
              <DateInput
                dateTimeFormat={defaultInputDateTimeFormat}
                name="to"
                value={form.data?.to}
                error={form.errors.to}
                label={lang.toDate}
                min={form.data.from}
                isLocal={true}
                max={dateDef().endOf('day').add(-1, 'second').format(defaultServerDateTimeFormat)}
                onChange={({ to }) => {
                  form.update({
                    to,
                  });
                }}
                icon={<Icon class="fas-calendar-alt" />}
              />
              <DateInput
                dateTimeFormat={defaultInputDateTimeFormat}
                name="createdAtLocal"
                value={form.data?.createdAtLocal}
                error={form.errors.createdAtLocal}
                label={lang.createdAtLocal}
                isLocal={true}
                max={dateDef().endOf('day').add(-1, 'second').format(defaultServerDateTimeFormat)}
                onChange={({ createdAtLocal }) => {
                  form.update({
                    createdAtLocal,
                  });
                }}
                icon={<Icon class="fas-calendar-alt" />}
              />
            </FormWrapper>
          </>
        );
      }}
    />
  );
}
