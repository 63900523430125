import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.Statement.DeleteFile.IRequest;
type Response = Api.Core.Statement.DeleteFile.IResponse;
type Headers = Api.Core.Statement.DeleteFile.IHeaders;
const apiStatementDeleteFilePath = '/api/DealerManagement/Statement/:id/DeleteFile/:fileId';
export default async function statementDeleteFileApi(id: number, request: Request): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    body: request,
    url: createPathFromRoute(apiStatementDeleteFilePath, { id, fileId: request.fileId }),
    method: HttpMethod.delete,
    headers: getDefaultHeaders(),
  });
}
