import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import Icon from 'assets/components/icon/Icon';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import Page from 'assets/components/page/Page';
import ThemePageStatic2 from 'assets/components/page/themes/Theme.Page.Static.2';
import { getLocales } from 'assets/locales/Locale';
import { ascLogoWhite } from 'assets/themes/assets/Images';
import Theme from 'assets/themes/Theme.Common';
import { formatFormPhone } from 'assets/utils/forms/formatFormPhone';
import { keys } from 'lodash';
import Client from 'models/claims/client/Model.Client';
import { ClaimCreatorOptions } from 'models/enums/ClaimCreation';
import ClaimContractSearchForm from 'module/claims/views/claim/other/Claim.ContractSearchForm';
import ClaimDocumentPreview from 'module/claims/views/claim/other/Claim.DocumentPreview';
import ClaimDocumentForm from 'module/public-web/views/claimSubmit/components/Claim.DocumentForm';
import usePublicClaimSubmit, { PublicClaimSubmitActions } from './Claim.Submit.Hooks';
import PublicClaimSubmitClaimJobForm from './components/Claim.Submit.ClaimJobForm';
import { PublicClaimSubmitRepairFacilityForm } from './components/Claim.Submit.RepairFacility.Form';
import BigHr from 'assets/templates/dividers/BigHr';

export const publicClaimSubmitIndexRoute = '/public/claim/submit';
export default function PublicClaimSubmitIndex() {
  const { lang } = getLocales();
  const {
    form,
    dealerListPublicApi,
    repairFacilityListApi,
    claimTypesListPublicApi,
    claimTypeValue,
    uploadableDocuments,
    onlyClaimType,
    action,
    setAction,
  } = usePublicClaimSubmit();

  return (
    <Page
      class={ThemePageStatic2}
      isLoading={
        claimTypesListPublicApi.isExecuting || dealerListPublicApi.isExecuting || repairFacilityListApi.isExecuting
      }
      header={
        <>
          <Card
            class={ThemeCardRow}
            media={<img style={{ objectFit: 'contain' }} src={ascLogoWhite} />}
            style={{
              card: { flexDirection: 'column' },
              cardMedia: { width: '400px', objectFit: 'contain' },
              cardInfoTitle: { color: Theme.Color.highlightLight },
            }}
            title={lang.submitANewClaim}
            subtitle={lang.searchContractEnteringVinAndContractNumberOrClientLastName}
          />
        </>
      }
      render={
        <>
          {form.data.claim?.contract || form.data.claim?.contractId ? (
            <>
              <FormWrapper>
                <h2>
                  <Card
                    class={ThemeCardRow}
                    style={{ padding: 0 }}
                    title={
                      <>
                        {lang.contractNumber}: {form.data?.claim?.contract?.appNumber}
                      </>
                    }
                    subtitle={
                      <>
                        {lang.clientName}: {new Client(form.data?.claim?.contract?.client)?.displayInfo.title}
                      </>
                    }
                    info={
                      <>
                        {lang.vin}: {form.data?.claim?.contract?.vehicle?.vin}
                      </>
                    }
                  />
                </h2>
                <hr style={{ width: Theme.Size.fill }} />
                <h2 style={{ display: 'flex', alignItems: 'center' }}>{lang.details}</h2>
                <PublicClaimSubmitClaimJobForm
                  form={form}
                  claimTypeValue={claimTypeValue}
                  onlyClaimType={onlyClaimType}
                  claimTypes={claimTypesListPublicApi?.payload?.data}
                  uploadableDocuments={uploadableDocuments}
                />
                <BigHr />
                <SelectInput
                  name="claimCreationOption"
                  label={lang.whoIsSubmittingTheClaim}
                  data={keys(ClaimCreatorOptions).map((it) => ({
                    id: it,
                    title: it.replace(/_/g, ' '),
                  }))}
                  value={form.data?.claimCreationOption}
                  error={form.errors?.claimCreationOption}
                  onChange={form.update}
                />
                {ClaimCreatorOptions.REPAIR_FACILITY === form.data?.claimCreationOption && (
                  <>
                    {!action && (
                      <SelectInput
                        name="repairFacilityId"
                        label={lang.repairFacility}
                        data={repairFacilityListApi.payload?.data?.map((it) => it.displayInfo)}
                        value={form.data?.repairFacilityId}
                        error={form.errors?.repairFacilityId}
                        onAdd={() => {
                          setAction(PublicClaimSubmitActions.CREATE_REPAIR_FACILITY);
                          form.update({
                            newPayee: {} as Model.IPayee,
                          });
                        }}
                        onChange={({ repairFacilityId }) => form.merge({ repairFacilityId })}
                      />
                    )}
                    {action === PublicClaimSubmitActions.CREATE_REPAIR_FACILITY && (
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>
                          <h2>
                            {lang.create} {lang.repairFacility}
                          </h2>
                          <Button
                            class={ThemeButtonCircle}
                            render={lang.cancel}
                            media={<Icon class="fas-ban" />}
                            onClick={() => {
                              setAction(undefined);
                              form.update({
                                newPayee: null,
                              });
                            }}
                            htmlElementProps={{ title: lang.chooseExistingRepairFacility }}
                          />
                        </div>
                        <PublicClaimSubmitRepairFacilityForm form={form} />
                      </div>
                    )}
                  </>
                )}
                {ClaimCreatorOptions.DEALERSHIP === form.data?.claimCreationOption && (
                  <SelectInput
                    name="dealerId"
                    label={lang.dealership}
                    data={dealerListPublicApi.payload?.data?.map((it) => it.displayInfo)}
                    value={form.data?.claim?.dealerId}
                    error={form.errors?.claim?.dealerId}
                    onChange={({ dealerId }) => form.merge({ claim: { dealerId } })}
                  />
                )}
                <h2 style={{ display: 'flex', alignItems: 'center' }}>{`${lang.contact} ${lang.info}`}</h2>
                <InputWrapper>
                  <TextInput
                    label={lang.contactName}
                    name="name"
                    value={form.data?.claim?.createdByPayee?.name}
                    error={form.errors?.claim?.createdByPayee?.name}
                    onChange={({ name }) => form.merge({ claim: { createdByPayee: { name: name } } })}
                  />
                  <TextInput
                    label={lang.phoneNumber}
                    name="phone"
                    value={form.data?.claim?.createdByPayee?.phoneNumber}
                    error={form.errors?.claim?.createdByPayee?.phoneNumber}
                    onChange={({ phone }) =>
                      form.merge({
                        claim: {
                          createdByPayee: {
                            phoneNumber: formatFormPhone(form.data?.claim?.createdByPayee?.phoneNumber, phone),
                          },
                        },
                      })
                    }
                  />
                  <TextInput
                    label={lang.email}
                    name="email"
                    value={form.data?.claim?.createdByPayee?.email}
                    error={form.errors?.claim?.createdByPayee?.email}
                    onChange={({ email }) => form.merge({ claim: { createdByPayee: { email: email } } })}
                  />
                </InputWrapper>
                <h2 style={{ display: 'flex', alignItems: 'center' }}>
                  {lang.documents}
                  <Button
                    class={ThemeButtonCircle}
                    style={{ marginLeft: 'auto', fontSize: Theme.FontSize.M }}
                    render={form.data.isAddingNewDocument ? lang.cancel : lang.add}
                    media={<Icon class={form.data.isAddingNewDocument ? 'fas-ban' : 'fas-plus'} />}
                    data={{ isAddingNewDocument: !form.data.isAddingNewDocument }}
                    onClick={form.update}
                    active={form.data.isAddingNewDocument}
                    htmlElementProps={{ title: form.data.isAddingNewDocument ? lang.ban : lang.add }}
                  />
                </h2>
                <ClaimDocumentPreview
                  documents={[
                    ...form.data.claimDocuments.map((it, docIdx) => ({
                      id: it.id,
                      url: it.mediaFile?.url,
                      name: it.name,
                      extension: it.mediaFile?.extension,
                      description: it.notes,
                      info: lang.new,
                      onDelete: () => {
                        const docs = [...form.data.claimDocuments];
                        const newDocs = docs.filter((_, tempIdx) => docIdx !== tempIdx);
                        form.update({ claimDocuments: newDocs });
                      },
                    })),
                  ]}
                  isDisabled={false}
                />
                <br />
                {form.data.isAddingNewDocument && (
                  <ClaimDocumentForm
                    onSubmit={(document) => form.update({ claimDocuments: [...form.data.claimDocuments, document] })}
                  />
                )}
              </FormWrapper>
            </>
          ) : (
            <FormWrapper>
              <ClaimContractSearchForm onSubmit={(contract) => form.merge({ claim: { contract } })} />
            </FormWrapper>
          )}
        </>
      }
      footer={
        <>
          <Button
            onClick={form.submit}
            media={<Icon class="fas-save" />}
            render={
              <>
                {lang.save}
                {form.data.claimDocuments.length > 0 && (
                  <>
                    &nbsp;+&nbsp;{form.data.claimDocuments.length}
                    &nbsp;
                    <Icon class="fas-paperclip" />
                  </>
                )}
              </>
            }
            active={!form.hasErrors && form.isUpdated}
            disabled={form.hasErrors || !form.isUpdated}
            isLoading={form.isSubmitted}
            class={ThemeButtonCircle}
            htmlElementProps={{ title: lang.save }}
          />
        </>
      }
    />
  );
}
