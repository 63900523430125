import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { cleanValues } from 'assets/utils/data/Object';
import { compact, reduce, sortBy, uniq, uniqBy } from 'lodash';
import CustomPricePoint from 'models/productManagement/customPricePoint/Model.CustomPricePoint';
import customPricePointDeleteApi from 'models/productManagement/customPricePoint/delete/Api.CustomPricePoint.Delete';
import customPricePointSaveApi from 'models/productManagement/customPricePoint/save/Api.CustomPricePoint.Save';
import Product from 'models/productManagement/product/Model.Product';
import useDistributorPricingDistributions from 'module/productManagement/hooks/useDistributorPricingDistributions';
import { useEffect, useMemo, useState } from 'react';
import { customPricingRoute } from './CustomPricing.Index';
import useDealerPricingSettings from 'module/productManagement/hooks/useDealerPricingSettings';
import iterateWithProgress, { ProgressInfo } from 'assets/utils/data/Iterations';
import { ProgressKey } from 'assets/components/progressWindow/ProgressWindow';

type Term = number;
type CustomInfo = string;
type CustomInfoGroup = string;
type PricePoint = Model.ICustomPricePoint & {
  isUpdated?: boolean;
};
export default function useCustomPricing() {
  const { permissions, isDistributor } = useAuthStore();
  const pageRouter = usePageRouter<
    Module.ProductManagement.CustomPricing.Params,
    Module.ProductManagement.CustomPricing.Query
  >({
    route: customPricingRoute,
  });
  const productId = parseInt(pageRouter.params.productId) || 0;
  const productProvinceGroupId = parseInt(pageRouter.params.productProvinceGroupId) || undefined;
  const hasAccess =
    permissions.VIEW_CUSTOMPRICEPOINT ||
    permissions.CREATE_CUSTOMPRICEPOINT ||
    permissions.EDIT_CUSTOMPRICEPOINT ||
    permissions.DELETE_CUSTOMPRICEPOINT;

  const listApi = useApi({
    action: CustomPricePoint.list,
    body: { productId: [productId] },
    wait: !hasAccess,
  });

  const productApi = useApi({
    action: Product.find,
    body: { id: productId },
    wait: !hasAccess,
  });

  const distributorPricingDistributions = useDistributorPricingDistributions({
    productId,
  });

  const dealerPricingSettings = useDealerPricingSettings({
    productId,
  });

  //#region PRICES
  const [data, setData] = useState<PricePoint[]>([]);
  const isUpdated = useMemo(
    () =>
      data?.some((it) => it.isUpdated) ||
      distributorPricingDistributions.distributorData?.isUpdated ||
      distributorPricingDistributions.distributorData?.distributions?.some((it) => it.isUpdated) ||
      (dealerPricingSettings.isUpdated && !dealerPricingSettings.hasErrors),
    [
      data,
      distributorPricingDistributions.distributorData,
      dealerPricingSettings.isUpdated,
      dealerPricingSettings.hasErrors,
    ]
  );
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setData(
      () =>
        listApi.payload?.data
          ?.filter((it) => it.productProvinceGroupId == productProvinceGroupId)
          .map((it) => it.toJSON()) || []
    );
  }, [listApi.payload?.data, productProvinceGroupId]);

  async function saveAll(onSaveProgress?: (key: ProgressKey, progressInfo: ProgressInfo) => void) {
    setIsSaving(true);
    distributorPricingDistributions.saveAll(onSaveProgress);
    dealerPricingSettings.saveAll(onSaveProgress);
    const updatedPricesCount = await iterateWithProgress(
      data.filter((it) => it.isUpdated),
      async (pricePoint) => {
        if (pricePoint.priceCAD == null && pricePoint.id) {
          await customPricePointDeleteApi(pricePoint.id);
        } else if (pricePoint.priceCAD != null) {
          await customPricePointSaveApi(cleanValues(pricePoint));
        }
      },
      (p) => onSaveProgress('priceDistribution', p)
    );
    if (updatedPricesCount) {
      await listApi.execute((body) => body);
    }
    setIsSaving(false);
  }

  function getPrice(termInMonths: Term, customInfo: string, customInfoGroup: string) {
    return data.find(
      (it) => it.termInMonths === termInMonths && it.customInfo === customInfo && it.customInfoGroup === customInfoGroup
    );
  }
  function updatePrice(
    termInMonths: Term,
    customInfo: string,
    customInfoGroup: string,
    pricingDistributions?: Partial<Utils.PricingDistribution>
  ) {
    const d = getPrice(termInMonths, customInfo, customInfoGroup);
    if (d) {
      d.isUpdated = true;
      if (pricingDistributions == null) {
        d.priceCAD = null;
        d.pricingDistributions = null;
        d.subfees = null;
      } else {
        d.pricingDistributions = {
          ...productApi?.payload?.pricingDistribution,
          ...d.pricingDistributions,
          ...pricingDistributions,
        };
        d.priceCAD = reduce(d.pricingDistributions || {}, (prev, curr) => prev + curr.amount, 0);
      }
      setData((old) => [...old]);
    } else {
      const newD: PricePoint = {
        id: undefined,
        isUpdated: true,
        termInMonths,
        customInfo,
        customInfoGroup,
        priceCAD: reduce(pricingDistributions || {}, (prev, curr) => prev + curr.amount, 0),
        pricingDistributions,
        productProvinceGroupId,
        productId,
      };
      setData((old) => [...old, newD]);
    }
  }
  function updateSubfee(
    termInMonths: Term,
    customInfo: string,
    customInfoGroup: string,
    subfees?: Utils.SurchargeInfo
  ) {
    const d = getPrice(termInMonths, customInfo, customInfoGroup);
    if (d) {
      d.isUpdated = true;
      d.subfees = uniqBy(compact([subfees, ...(d.subfees ?? [])]), (it) => it.subfeeId);
      setData((old) => [...old]);
    }
  }
  //#endregion

  //#region TERMS
  const terms = useMemo(() => sortBy(uniq(data.map((it) => it.termInMonths))), [data]);

  function removeTerm(termInMonths: Term) {
    setData((old) => old.filter((it) => it.termInMonths === termInMonths));
  }
  //#endregion

  //#region LIMITS
  const customInfoList = useMemo(() => sortBy(uniq(data?.map((it) => it.customInfo))), [data]);
  const customInfoGroupList = useMemo(() => sortBy(uniq(data?.map((it) => it.customInfoGroup ?? null) ?? [])), [data]);

  function addEmptyPrices(newTerms: number[], newCustomInfos: CustomInfo[], newCustomInfoGroups: CustomInfoGroup[]) {
    const prices: PricePoint[] = [];
    for (const customInfoGroup of newCustomInfoGroups) {
      for (const termInMonths of newTerms) {
        for (const customInfo of newCustomInfos) {
          if (
            !data.some(
              (it) =>
                it.termInMonths === termInMonths &&
                it.customInfo === customInfo &&
                it.customInfoGroup === customInfoGroup
            )
          ) {
            prices.push({
              id: undefined,
              isUpdated: false,
              termInMonths,
              customInfo,
              customInfoGroup,
              priceCAD: null,
              productProvinceGroupId,
              productId,
            });
          }
        }
      }
    }
    setData((old) => [...old, ...prices]);
  }

  function removeCustomInfo(customInfo: string) {
    setData((old) => old.filter((it) => !(it.customInfo === customInfo)));
  }

  function removeCustomInfoGroup(customInfoGroup: string) {
    setData((old) => old.filter((it) => !(it.customInfoGroup === customInfoGroup)));
  }
  //#endregion

  const isAllowedToCopy = !!productProvinceGroupId && data.length === 0;
  async function copy() {
    setIsSaving(true);
    if (!listApi.payload?.data?.some((it) => it.productProvinceGroupId === productProvinceGroupId)) {
      const defaultCustomPricingPoints = listApi.payload?.data?.filter((it) => !it.productProvinceGroupId);
      for (const customPricingPoint of defaultCustomPricingPoints || []) {
        await customPricePointSaveApi({
          termInMonths: customPricingPoint.termInMonths,
          priceCAD: customPricingPoint.priceCAD,
          productId: customPricingPoint.productId,
          customInfo: customPricingPoint.customInfo,
          customInfoGroup: customPricingPoint.customInfoGroup,
          pricingDistributions: customPricingPoint.pricingDistributions,
          productProvinceGroupId,
        });
      }
    }
    setIsSaving(false);
    listApi.execute((body) => body);
  }
  return {
    isDistributor,
    distributorPricingDistributions,
    dealerPricingSettings,
    provinceGroup: productApi?.payload?.productProvinceGroups?.find((g) => g.id === productProvinceGroupId),
    hasAccess,
    isAllowedToCopy,
    copy,
    isSaving,
    isUpdated,
    addEmptyPrices,
    removeTerm,
    removeCustomInfo,
    removeCustomInfoGroup,
    updatePrice,
    updateSubfee,
    getPrice,
    data,
    terms,
    customInfoList,
    customInfoGroupList,
    saveAll,
    pageRouter,
    productApi,
    listApi,
    permissions,
  };
}

export function useCustomPricingProvider() {
  return useDataProvider<ReturnType<typeof useCustomPricing>>();
}
