import { combineStrings } from 'assets/utils/data/String';
import Model from 'models/Model';
import Client from 'models/claims/client/Model.Client';

export default class VeraForteClient
  extends Model<Model.IVeraForteClient>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IVeraForteClient>
{
  id: number;
  email: string;
  firstName?: string;
  lastName?: string;
  veraForteCustomerNumber?: string;
  clients?: Client[];

  constructor(
    data: Partial<Model.IVeraForteClient> | Utils.FormData<Model.IVeraForteClient>,
    language?: Locale.Languages
  ) {
    super(
      {
        clients: [Client],
      },
      language
    );
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: combineStrings(' ', this.firstName, this.lastName) || '',
      subtitle: this.email,
      info: this.veraForteCustomerNumber,
    };
  }
}
