import Box from 'assets/components/box/Box';
import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import Collection from 'assets/components/data/Collection';
import Row from 'assets/components/data/rows/Row';
import RowActions from 'assets/components/data/rows/RowActions';
import RowData from 'assets/components/data/rows/RowData';
import RowImage from 'assets/components/data/rows/RowImage';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageError from 'assets/components/page/themes/Theme.Page.Error';
import ThemeTagRounded from 'assets/components/tag/themes/Theme.Tag.Rounded';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import Theme from 'assets/themes/Theme.Common';
import { DealerAction, useDealerProvider } from '../Dealer.Hooks';

export default function DealerList() {
  const { lang } = getLocales();
  const { listApi, pageRouter } = useDealerProvider();
  const { permissions } = useAuthStore();

  return !listApi.payload && !listApi.isExecuting && listApi.apiCounter > 0 ? (
    <Page
      class={ThemePageError}
      sidebar={<Icon class="fas-user-friends" />}
      header={lang.dealers}
      render={lang.noInfo}
    />
  ) : (
    <Collection class={ThemeCollectionList}>
      <DataMap
        data={listApi.payload?.data}
        render={({ data: dealer }) => (
          <Row
            key={dealer.id}
            children={
              <>
                <RowImage
                  render={
                    dealer?.uiSettingsFile ? (
                      <img src={dealer?.uiSettingsFile?.url} />
                    ) : (
                      <Icon class="fas-user-friends" />
                    )
                  }
                />
                <RowData
                  {...dealer.displayInfo}
                  subtitle={
                    <>
                      {dealer?.isInactive && (
                        <Box class={ThemeTagRounded} style={{ backgroundColor: Theme.Color.error }}>
                          <Icon class="fas-ban" />
                          &nbsp; Inactive
                        </Box>
                      )}
                    </>
                  }
                />
                <RowActions isVertical>
                  <DropdownButton
                    buttonMedia={<Icon class="fas-download" />}
                    horizontalPosition="auto_fixed"
                    verticalPosition="auto_fixed"
                    subcomponents={ThemeDropdownButtonPopup}
                    disabled={!dealer.mediaFiles?.length}
                    htmlElementProps={{ title: lang.download }}
                    dropdownOptions={
                      <DataMap
                        data={dealer.mediaFiles}
                        render={({ data: doc }) => (
                          <Button
                            key={doc.id}
                            class={ThemeButtonMenuItem}
                            style={{ buttonText: { textTransform: 'unset' } }}
                            url={doc.url}
                            media={<Icon class="fas-download" />}
                            target="_blank"
                            children={doc.name}
                          />
                        )}
                      />
                    }
                  />
                  <Button
                    onClick={pageRouter.updateParams}
                    data={{
                      id: dealer.id.toString(),
                      action: DealerAction.EDIT,
                    }}
                    disabled={!permissions.EDIT_DEALER}
                    class={ThemeButtonCircle}
                    media={<Icon class="fas-edit" />}
                    htmlElementProps={{
                      title: lang.edit,
                    }}
                  />
                  <Button
                    onClick={pageRouter.updateParams}
                    data={{
                      id: dealer.id.toString(),
                      action: DealerAction.DELETE,
                    }}
                    disabled={!permissions.DELETE_DEALER}
                    class={ThemeButtonCircle}
                    media={<Icon class="fas-trash" />}
                    htmlElementProps={{
                      title: lang.delete,
                    }}
                  />
                </RowActions>
              </>
            }
          />
        )}
      />
    </Collection>
  );
}
