enum CountryCode {
  CAN = 'CAN',
  USA = 'USA',
}

// Fallback country codes
enum CountryCodeFallback {
  CA = 'CA',
}

export { CountryCode, CountryCodeFallback };
export type VerifyCountryCode = Utils.VerifyExtends<Model.Enum.CountryCode, Utils.ValueOf<typeof CountryCode>>;
