import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.Payee.SavePublic.IRequest;
type Response = Api.Core.Payee.SavePublic.IResponse;
type Headers = Api.Core.Payee.SavePublic.IHeaders;
const url = '/api/Public/Payee';
export default async function payeeSavePublic(body: Request): Promise<Api.Res<Response>> {
  const serverResponse = await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.post,
    url,
    body,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
