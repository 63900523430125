import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import DataMap from 'assets/components/dataMap/DataMap';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import ThemeTableSimple2 from 'assets/components/table/themes/Theme.Table.Simple.2';
import ToString from 'assets/components/textBox/ToString';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import PricingDistributionTable from 'module/productManagement/components/pricingDistributionTable/PricingDistributionTable';
import { useState } from 'react';
import { useWarrantyTierPricingProvider } from '../WarrantyTierPricing.Hooks';
import SubfeeDistributionTable from 'module/productManagement/components/pricingDistributionTable/SubfeeDistributionTable';
import Icon from 'assets/components/icon/Icon';
import FormatValue from 'assets/components/formatValue/FormatValue';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import DistributorDistributionTable from 'module/productManagement/components/pricingDistributionTable/DistributorDistributionTable';
import { compact, reduce } from 'lodash';
import DealerPricingLimitationTable from 'module/productManagement/components/pricingDistributionTable/DealerPricingLimitationTable';
import DistributionPriceSwitch from 'module/productManagement/components/pricingDistributionTable/DistributionPriceSwitch';
import DealerDistributionDiscountTable from 'module/productManagement/components/pricingDistributionTable/DealerDistributionDiscountTable';
import ThemeOtherVerticalDivider from 'assets/themes/other/Theme.Other.VerticalDivider';
import { addProgressToWindow } from 'assets/components/progressWindow/ProgressWindow';
import { combineStrings } from 'assets/utils/data/String';

export default function WarrantyTierPricingTable() {
  const { lang } = getLocales();
  const {
    getPrice,
    tiers,
    terms,
    updatePrice,
    updateSubfee,
    isAllowedToCopy,
    copy,
    unselectedTiers,
    data,
    addEmptyPrices,
    removeTerm,
    removeTier,
    productApi,
    distributorPricingDistributions,
    dealerPricingSettings,
  } = useWarrantyTierPricingProvider();
  const [tempTerm, setTempTerm] = useState<number>();
  const [tempTier, setTempTier] = useState<Model.ITier>();
  const disableInputs = !data.length ? !tempTerm || !tempTier : !tempTerm && !tempTier;

  const _addEmptyPrices = () => {
    addEmptyPrices(tempTerm ? [tempTerm] : terms, tempTier ? [tempTier] : tiers);
    setTempTerm(null);
    setTempTier(null);
  };
  function updateOriginalPrice({
    termInMonths,
    tier,
    distributionKey,
    distributedValue,
  }: {
    termInMonths: number;
    tier: Model.ITier;
    distributionKey: string;
    distributedValue: Utils.PricingInfo;
  }) {
    const originalDistributions = getPrice(termInMonths, tier.id)?.pricingDistributions;
    if (originalDistributions) {
      updatePrice(termInMonths, tier.id, {
        ...originalDistributions,
        [distributionKey]: distributedValue,
      });
    }
  }
  function updateDistributorPrice({
    termInMonths,
    tier,
    distributionKey,
    distributedValue,
  }: {
    termInMonths: number;
    tier: Model.ITier;
    distributionKey: string;
    distributedValue: Utils.PricingInfo;
  }) {
    const currentPricePoint = getPrice(termInMonths, tier.id);
    if (currentPricePoint?.pricingDistributions) {
      const currentDistribution = distributorPricingDistributions.getDistributorPrice(
        currentPricePoint?.id,
        'ProductTierPricePoint'
      )?.distributions;
      distributorPricingDistributions.updateDistributorPrice({
        pricePointId: currentPricePoint.id,
        pricePointType: 'ProductTierPricePoint',
        distributions: {
          ...currentDistribution,
          [distributionKey]: distributedValue,
        },
      });
    }
  }
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
          marginBottom: Theme.Size.M,
          gap: Theme.Size.M,
          justifyContent: 'flex-end',
        }}
      >
        {isAllowedToCopy && (
          <Button
            class={ThemeButtonCircle}
            media="fas-copy"
            onClick={copy}
            render={lang.copyDefaultPricesHere}
            style={{ marginRight: 'auto' }}
            htmlElementProps={{ title: lang.copyDefaultPricesHere }}
          />
        )}
        {distributorPricingDistributions.canView && (
          <InputWrapper>
            {distributorPricingDistributions.distributors?.length &&
              !distributorPricingDistributions.isDealerOrDistributor && (
                <SelectInput
                  name="distributorId"
                  value={distributorPricingDistributions.distributorId}
                  label={lang.distributors}
                  data={distributorPricingDistributions.distributors?.map((item) => ({
                    ...item.displayInfo,
                    disabled:
                      !!dealerPricingSettings.dealerId && item.id !== dealerPricingSettings.dealer?.representativeId,
                  }))}
                  onChange={({ distributorId }) => distributorPricingDistributions.setDistributorId(distributorId)}
                  style={{ width: '200px' }}
                />
              )}
            <SelectInput
              name="templateId"
              value={distributorPricingDistributions.distributorData?.templateId}
              label={lang.pricingDistributionTemplate}
              data={distributorPricingDistributions.templates?.map((item) => item.displayInfo)}
              onChange={({ templateId }) =>
                distributorPricingDistributions.setDistributorData({
                  templateId,
                  isUpdated: true,
                  distributions: [],
                  template: distributorPricingDistributions.templates?.find((it) => it.id === templateId),
                })
              }
              disabled={!!distributorPricingDistributions.productRelation?.pricingDistributionTemplateId}
              style={{ width: '200px' }}
            />
          </InputWrapper>
        )}
        {dealerPricingSettings.canView && (
          <InputWrapper>
            {!!dealerPricingSettings.dealers?.length && (
              <SelectInput
                name="dealerId"
                value={dealerPricingSettings.dealerId}
                label={lang.dealers}
                data={dealerPricingSettings.dealers?.map((item) => ({
                  ...item.displayInfo,
                  actionIcon: <Icon class="fas-copy" />,
                  actionTitle: 'Copy adjustments to this dealer',
                  disableEdit:
                    !dealerPricingSettings.dealerId ||
                    dealerPricingSettings.dealerId === item.id ||
                    !distributorPricingDistributions.distributorId ||
                    item.representativeId !== distributorPricingDistributions.distributorId,
                  disabled:
                    !!distributorPricingDistributions.distributorId &&
                    item.representativeId !== distributorPricingDistributions.distributorId,
                }))}
                onChange={({ dealerId }) => {
                  dealerPricingSettings.setDealerId(dealerId);
                  const distributorId = dealerPricingSettings.dealers?.find(
                    (it) => it.id === dealerId
                  )?.representativeId;
                  if (distributorId) distributorPricingDistributions.setDistributorId(distributorId);
                }}
                style={{ width: '200px' }}
                onEdit={(id) => dealerPricingSettings.copyTo(id, addProgressToWindow)}
              />
            )}
          </InputWrapper>
        )}
        {distributorPricingDistributions.canEdit && !distributorPricingDistributions.isDealerOrDistributor && (
          <InputWrapper>
            <NumberInput
              label={`${lang.add}: ${lang.term}`}
              name="term"
              value={tempTerm}
              onChange={({ term }) => setTempTerm(term)}
              style={{ formInput: { maxWidth: '200px' } }}
              step={1}
              min={0}
              roundOnBlur
            />
            <SelectInput
              name="tier"
              value={tempTier?.id}
              label={lang.tier}
              data={unselectedTiers?.map((item) => item.displayInfo)}
              onChange={({ tier: id }) => setTempTier(unselectedTiers.find((t) => t.id === id))}
              disabled={!unselectedTiers.length}
              style={{ width: '200px' }}
            />
            <Button
              class={ThemeButtonCircle}
              media="fas-plus"
              disabled={disableInputs}
              onClick={_addEmptyPrices}
              style={{ border: `${Theme.Size.XXS} solid ${Theme.Color.shadowDark}` }}
              htmlElementProps={{ title: lang.add }}
            />
          </InputWrapper>
        )}
      </div>
      <table className={ThemeTableSimple2}>
        <thead>
          <tr>
            <th style={{ textAlign: 'right' }}>{lang.price}</th>
            <DataMap
              data={terms}
              render={({ data: termInMonths }) => (
                <th key={termInMonths} style={{ textAlign: 'right', position: 'relative', whiteSpace: 'nowrap' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                      {dealerPricingSettings.canView && !!dealerPricingSettings.dealer && (
                        <>
                          <DealerPricingLimitationTable
                            isEditDisabled={dealerPricingSettings.canEdit}
                            columnKey={`DealerLimitations-${termInMonths.toString()}`}
                            rows={tiers}
                            getDiscountPrices={(tier) => {
                              const pricePoint = getPrice(termInMonths, tier.id);
                              return {
                                base: dealerPricingSettings.getDiscountPrice(
                                  pricePoint?.id,
                                  'ProductTierPricePoint',
                                  pricePoint?.pricingDistributions
                                ),
                                distributor: dealerPricingSettings.getDistributorDiscountPrice(
                                  pricePoint?.id,
                                  'ProductTierPricePoint',
                                  distributorPricingDistributions.getDistributorPrice(
                                    pricePoint?.id,
                                    'ProductTierPricePoint'
                                  )?.distributions
                                ),
                              };
                            }}
                            getLimitation={(tier) =>
                              dealerPricingSettings.getDistributionLimitation(
                                getPrice(termInMonths, tier.id)?.id,
                                'ProductTierPricePoint'
                              )?.limitations
                            }
                            updateLimitation={(tier, limitations) =>
                              dealerPricingSettings.updateDistributionLimitation({
                                limitations,
                                pricePointId: getPrice(termInMonths, tier.id)?.id,
                                pricePointType: 'ProductTierPricePoint',
                              })
                            }
                            priceOption={({ delta, property, unit }) => (
                              <DropdownButton
                                buttonMedia="fas-copy"
                                horizontalPosition="auto_fixed"
                                verticalPosition="auto_fixed"
                                tabIndex={null}
                                subcomponents={ThemeDropdownButtonPopup}
                                htmlElementProps={{ title: lang.copy }}
                                title="Increase all:"
                                disabled={unit !== 'replacement' || delta < 0}
                                dropdownOptions={({ animateOut }) => (
                                  <Button
                                    htmlElementProps={{ title: '' }}
                                    class={ThemeButtonMenuItem}
                                    onClick={() => {
                                      animateOut();
                                      dealerPricingSettings.updateDistributionLimitation(
                                        compact(
                                          data.map((pricePoint) => {
                                            const currentLimitations = dealerPricingSettings.getDistributionLimitation(
                                              pricePoint?.id,
                                              'ProductTierPricePoint'
                                            );
                                            if (
                                              !currentLimitations?.limitations?.unit ||
                                              currentLimitations?.limitations.unit === unit
                                            ) {
                                              const currentBasePrice =
                                                dealerPricingSettings.getDiscountPrice(
                                                  pricePoint?.id,
                                                  'ProductTierPricePoint',
                                                  pricePoint.pricingDistributions
                                                ) +
                                                reduce(
                                                  distributorPricingDistributions.getDistributorPrice(
                                                    pricePoint?.id,
                                                    'ProductTierPricePoint'
                                                  )?.distributions || {},
                                                  (prev, curr) => prev + curr.amount,
                                                  0
                                                );
                                              return {
                                                ...currentLimitations,
                                                limitations: {
                                                  ...currentLimitations?.limitations,
                                                  unit,
                                                  [property]: delta === 0 ? null : currentBasePrice + delta,
                                                },
                                                pricePointId: pricePoint?.id,
                                                pricePointType: 'ProductTierPricePoint',
                                              };
                                            }
                                            return null;
                                          })
                                        )
                                      );
                                    }}
                                  >
                                    <span>
                                      {property === 'minAmount' ? 'Sug. retail' : 'Max'} prices by{' '}
                                      <FormatValue value={`${delta.toFixed(2)}$`} />
                                    </span>
                                  </Button>
                                )}
                              />
                            )}
                          />
                          <DealerDistributionDiscountTable
                            isEditDisabled={dealerPricingSettings.canEdit}
                            columnKey={`DealerDistributionDiscount-${termInMonths.toString()}`}
                            rows={tiers}
                            columns={productApi?.payload?.templateKeys}
                            getDiscount={(tier) =>
                              dealerPricingSettings.getDistributionDiscount(
                                getPrice(termInMonths, tier.id).id,
                                'ProductTierPricePoint'
                              )?.distributionDiscount
                            }
                            updateDiscount={(tier, distribution) => {
                              const pricePoint = getPrice(termInMonths, tier.id);
                              if (productApi.payload.id && dealerPricingSettings.dealerId) {
                                const dealerSettings = dealerPricingSettings.getDistributionDiscount(
                                  pricePoint?.id,
                                  'ProductTierPricePoint'
                                );
                                dealerPricingSettings.updateDistributionDiscount({
                                  ...dealerSettings,
                                  productId: productApi.payload.id,
                                  dealerId: dealerPricingSettings.dealerId,
                                  pricePointId: pricePoint?.id,
                                  pricePointType: 'ProductTierPricePoint',
                                  distributionDiscount: {
                                    ...dealerSettings?.distributionDiscount,
                                    ...distribution,
                                  },
                                });
                              }
                            }}
                          />
                          <div className={ThemeOtherVerticalDivider} />
                        </>
                      )}
                      {distributorPricingDistributions.canView && !!distributorPricingDistributions.distributor && (
                        <>
                          <DistributorDistributionTable
                            isEditDisabled={distributorPricingDistributions.canEdit}
                            columnKey={`Distributor-${termInMonths.toString()}`}
                            rows={tiers}
                            columns={distributorPricingDistributions.distributorData?.template?.keys}
                            getPrice={(tier) =>
                              distributorPricingDistributions.getDistributorPrice(
                                getPrice(termInMonths, tier.id)?.id,
                                'ProductTierPricePoint'
                              )?.distributions
                            }
                            updatePrice={(tier, distributions) =>
                              distributorPricingDistributions.updateDistributorPrice({
                                distributions,
                                pricePointId: getPrice(termInMonths, tier.id)?.id,
                                pricePointType: 'ProductTierPricePoint',
                              })
                            }
                            priceOption={({ distributedValue, distributionKey }) => (
                              <DropdownButton
                                buttonMedia="fas-copy"
                                horizontalPosition="auto_fixed"
                                verticalPosition="auto_fixed"
                                tabIndex={null}
                                subcomponents={ThemeDropdownButtonPopup}
                                htmlElementProps={{ title: lang.copy }}
                                title="Copy to:"
                                dropdownOptions={({ animateOut }) => (
                                  <>
                                    <Button
                                      class={ThemeButtonMenuItem}
                                      htmlElementProps={{ title: '' }}
                                      onClick={() => {
                                        animateOut();
                                        for (const tier of tiers) {
                                          updateDistributorPrice({
                                            tier,
                                            termInMonths,
                                            distributedValue,
                                            distributionKey,
                                          });
                                        }
                                      }}
                                    >
                                      This column
                                    </Button>
                                  </>
                                )}
                              />
                            )}
                          />
                          {dealerPricingSettings.canView && !!dealerPricingSettings.dealer && (
                            <DealerDistributionDiscountTable
                              isEditDisabled={dealerPricingSettings.canEdit}
                              isDistributorDiscount
                              columnKey={`DealerDistributorDistributionDiscount-${termInMonths.toString()}`}
                              rows={tiers}
                              columns={distributorPricingDistributions.distributorData?.template?.keys}
                              getDiscount={(tier) =>
                                dealerPricingSettings.getDistributionDiscount(
                                  getPrice(termInMonths, tier.id).id,
                                  'ProductTierPricePoint'
                                )?.distributorDistributionDiscount
                              }
                              updateDiscount={(tier, distribution) => {
                                const pricePoint = getPrice(termInMonths, tier.id);
                                if (productApi.payload.id && dealerPricingSettings.dealerId) {
                                  const dealerSettings = dealerPricingSettings.getDistributionDiscount(
                                    pricePoint?.id,
                                    'ProductTierPricePoint'
                                  );
                                  dealerPricingSettings.updateDistributionDiscount({
                                    ...dealerSettings,
                                    productId: productApi.payload.id,
                                    dealerId: dealerPricingSettings.dealerId,
                                    pricePointId: pricePoint?.id,
                                    pricePointType: 'ProductTierPricePoint',
                                    distributorDistributionDiscount: {
                                      ...dealerSettings?.distributorDistributionDiscount,
                                      ...distribution,
                                    },
                                  });
                                }
                              }}
                            />
                          )}
                          <div className={ThemeOtherVerticalDivider} />
                        </>
                      )}

                      {!distributorPricingDistributions.isDealerOrDistributor && (
                        <>
                          <PricingDistributionTable
                            isEditDisabled={distributorPricingDistributions.canEdit}
                            rows={tiers}
                            columnKey={`PricingDistribution-${termInMonths}`}
                            columns={productApi?.payload?.templateKeys}
                            getPrice={(tier) => getPrice(termInMonths, tier.id)?.pricingDistributions}
                            updatePrice={(tier, distribution) => updatePrice(termInMonths, tier.id, distribution)}
                            priceOption={({ distributedValue, distributionKey }) => (
                              <DropdownButton
                                buttonMedia="fas-copy"
                                horizontalPosition="auto_fixed"
                                verticalPosition="auto_fixed"
                                tabIndex={null}
                                subcomponents={ThemeDropdownButtonPopup}
                                htmlElementProps={{ title: lang.copy }}
                                title="Copy to:"
                                dropdownOptions={({ animateOut }) => (
                                  <>
                                    <Button
                                      class={ThemeButtonMenuItem}
                                      onClick={() => {
                                        animateOut();
                                        for (const tier of tiers) {
                                          updateOriginalPrice({
                                            termInMonths,
                                            tier,
                                            distributedValue,
                                            distributionKey,
                                          });
                                        }
                                      }}
                                    >
                                      This column
                                    </Button>
                                  </>
                                )}
                              />
                            )}
                          />
                          <SubfeeDistributionTable
                            isEditDisabled={distributorPricingDistributions.canEdit}
                            columnKey={`Subfees-${termInMonths.toString()}`}
                            rows={tiers}
                            columns={productApi?.payload?.subfee}
                            getPrice={(tier) => getPrice(termInMonths, tier.id)?.subfees}
                            updatePrice={(tier, subfees) => updateSubfee(termInMonths, tier.id, subfees)}
                          />
                          <Button
                            class={ThemeButtonCircle}
                            style={{ button: { display: 'inline-block' } }}
                            media="fas-trash"
                            onClick={removeTerm}
                            data={termInMonths}
                            disabled={data.some((d) => d.term === termInMonths && d.id)}
                            htmlElementProps={{ title: lang.remove }}
                          />
                        </>
                      )}
                    </div>
                    <ToString value={`${termInMonths}m`} />
                  </div>
                </th>
              )}
            />
          </tr>
        </thead>
        <tbody>
          <DataMap
            data={tiers}
            render={({ data: tier }) => (
              <tr key={tier.id}>
                <td style={{ textAlign: 'right', cursor: 'pointer', whiteSpace: 'nowrap' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {!distributorPricingDistributions.isDealerOrDistributor && (
                      <Button
                        class={ThemeButtonCircle}
                        style={{ button: { display: 'inline-block' } }}
                        media="fas-trash"
                        onClick={removeTier}
                        data={tier}
                        disabled={data.some((d) => d.tierId === tier.id && d.id)}
                        htmlElementProps={{ title: `${lang.remove} ${lang.tier}` }}
                      />
                    )}
                    {tier.name}
                  </div>
                </td>
                <DataMap
                  data={terms}
                  render={({ data: termInMonths }) => {
                    const pricePoint = getPrice(termInMonths, tier.id);
                    const isUnset = pricePoint?.priceCAD == null;

                    const distributorDistribution = distributorPricingDistributions.getDistributorPrice(
                      pricePoint?.id,
                      'ProductTierPricePoint'
                    );
                    const distributorPriceSegment = reduce(
                      distributorDistribution?.distributions || {},
                      (prev, curr) => prev + curr.amount,
                      0
                    );
                    const basePrice = pricePoint?.priceCAD ?? 0;
                    const distributorPrice = basePrice + distributorPriceSegment;
                    const basePriceWithDiscount = dealerPricingSettings.getDiscountPrice(
                      pricePoint?.id,
                      'ProductTierPricePoint',
                      pricePoint?.pricingDistributions
                    );
                    const distributorPriceWithDiscount = dealerPricingSettings.getDistributorDiscountPrice(
                      pricePoint?.id,
                      'ProductTierPricePoint',
                      distributorDistribution?.distributions
                    );
                    const finalPrice = basePriceWithDiscount + distributorPriceWithDiscount;

                    const showDealerPrice = distributorPrice > 0 && !!dealerPricingSettings.dealerId;
                    const showDistributorPrice =
                      distributorPrice > 0 && !!distributorPricingDistributions.distributorId;
                    return (
                      <td style={{ textAlign: 'right' }} key={termInMonths}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div style={{ display: 'flex' }}>
                            {distributorPricingDistributions.canView &&
                              !!distributorPricingDistributions.distributorData?.template?.keys && (
                                <Button
                                  disabled={!distributorDistribution}
                                  onClick={() =>
                                    distributorPricingDistributions.updateDistributorPrice({
                                      pricePointId: pricePoint?.id,
                                      distributions: {},
                                      pricePointType: 'ProductTierPricePoint',
                                    })
                                  }
                                  class={{ button: ThemeButtonCircle }}
                                  media={<Icon class="fas-user-slash" />}
                                  htmlElementProps={{ title: lang.clearDistributorPrice }}
                                />
                              )}
                            {!distributorPricingDistributions.isDealerOrDistributor && (
                              <Button
                                disabled={isUnset}
                                onClick={() => updatePrice(termInMonths, tier.id, null)}
                                class={{ button: ThemeButtonCircle }}
                                media={<Icon class="fas-trash-alt" />}
                                htmlElementProps={{ title: `${lang.clear} ${lang.price}` }}
                              />
                            )}
                          </div>
                          {showDealerPrice || showDistributorPrice ? (
                            <span
                              style={{
                                fontWeight: isUnset ? undefined : 'bold',
                                color: isUnset ? Theme.Color.warning : undefined,
                              }}
                            >
                              <span style={{ fontSize: Theme.FontSize.S }}>
                                {combineStrings(
                                  '/',
                                  showDealerPrice ? lang.dealer : undefined,
                                  showDistributorPrice ? lang.distributor : undefined,
                                  lang.base
                                )}
                              </span>
                              <br />
                              <span style={{ fontSize: Theme.FontSize.S }}>
                                {showDealerPrice && (
                                  <>
                                    <FormatValue value={isUnset ? 'N/A' : `${finalPrice.toFixed(2)}$`} />/
                                  </>
                                )}
                                {showDistributorPrice && (
                                  <>
                                    <FormatValue value={isUnset ? 'N/A' : `${distributorPrice.toFixed(2)}$`} />/
                                  </>
                                )}
                                <FormatValue value={isUnset ? 'N/A' : `${basePrice.toFixed(2)}$`} />
                              </span>
                            </span>
                          ) : (
                            <span
                              style={{
                                fontWeight: isUnset ? undefined : 'bold',
                                color: isUnset ? Theme.Color.warning : undefined,
                              }}
                            >
                              <FormatValue value={isUnset ? 'N/A' : `${basePrice.toFixed(2)}$`} />
                            </span>
                          )}
                        </div>
                      </td>
                    );
                  }}
                />
              </tr>
            )}
          />
        </tbody>
      </table>
      <DistributionPriceSwitch
        templateKeys={productApi?.payload?.templateKeys ?? []}
        onUpdate={(firstKey, secondKey) => {
          for (const termInMonths of terms) {
            for (const tier of tiers) {
              const originalDistributions = getPrice(termInMonths, tier.id)?.pricingDistributions;
              if (originalDistributions) {
                updatePrice(termInMonths, tier.id, {
                  ...originalDistributions,
                  [firstKey]: originalDistributions[secondKey],
                  [secondKey]: originalDistributions[firstKey],
                });
              }
            }
          }
        }}
      />
    </>
  );
}
