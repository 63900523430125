import TextBox, { textBoxStyles } from 'assets/components/textBox/TextBox';
import useApi from 'assets/hooks/api/useApi';
import { getClaimCreatedByApp } from 'assets/utils/other/Utils.Other.ClaimEdit';
import PayeeType from 'models/claims/payeeType/Model.PayeeType';
import dealerFindApi from 'models/dealerManagement/dealer/find/Api.Dealer.Find';
import { ClaimCreationType } from 'models/enums/ClaimCreation';
import { paragraphStyles } from '../ClaimEdit.Info';

type Props = {
  claim: Model.IClaim;
};

export default function ClaimEditContactInfo({ claim }: Props) {
  const { payload: dealer } = useApi({ action: dealerFindApi, body: { id: claim?.dealerId }, wait: !claim?.dealerId });


  const getClaimCreatedAs = () => {
    let createdAs = undefined;

    if (claim.createdByPayee?.payeeTypeId === PayeeType.DEALER_ID) {
      if (getClaimCreatedByApp(claim) === ClaimCreationType.PUBLIC) {
        createdAs = 'Dealership';
      } else createdAs = 'Dealer';
    } else if (claim.createdByPayee?.payeeTypeId === PayeeType.REPAIR_FACILITY_ID) createdAs = 'Repair Facility';
    else if (claim.createdByPayee?.payeeTypeId === PayeeType.OTHER_ID) createdAs = 'Other';
    else if (claim.createdByPayee?.payeeTypeId === PayeeType.CLIENT_ID) createdAs = 'Customer';
    else createdAs = 'N/A';

    return createdAs;
  };

  return (
    <>
      <h2>Contact info</h2>
      <div style={paragraphStyles}>
        <TextBox class={textBoxStyles.block.flexBlock} title="Created by:" subtitle={getClaimCreatedByApp(claim)} />
        <TextBox class={textBoxStyles.block.flexBlock} title="Created as:" subtitle={getClaimCreatedAs()} />
        {dealer && (
          <TextBox
            class={textBoxStyles.block.flexBlock}
            title={getClaimCreatedByApp(claim) === ClaimCreationType.ASC ? 'Dealer:' : 'Dealership:'}
            subtitle={dealer?.name}
          />
        )}
        <TextBox
          class={textBoxStyles.block.flexBlock}
          title="Contact name:"
          subtitle={claim?.createdByPayee?.name ?? 'N/A'}
        />
        <TextBox
          class={textBoxStyles.block.flexBlock}
          title="Phone number:"
          subtitle={claim?.createdByPayee?.phoneNumber ?? 'N/A'}
        />
        <TextBox
          class={textBoxStyles.block.flexBlock}
          title="Email:"
          subtitle={claim?.createdByPayee?.email ?? 'N/A'}
        />
      </div>
    </>
  );
}
