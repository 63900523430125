import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonTab from 'assets/components/button/themes/Theme.Button.Tab';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageError from 'assets/components/page/themes/Theme.Page.Error';
import ThemePageStatic1 from 'assets/components/page/themes/Theme.Page.Static.1';
import { getLocales } from 'assets/locales/Locale';
import { DataProvider } from 'assets/providers/data/DataProvider';
import Theme from 'assets/themes/Theme.Common';
import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import { HttpStatus, productManagementHomeRoute } from 'config/Api.Config';
import { productIndexRoute } from '../product/Product.Index';
import useCustomPricing from './CustomPricing.Hooks';
import CustomPricingTable from './sections/CustomPricing.Table';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';
import ProgressWindow, { addProgressToWindow } from 'assets/components/progressWindow/ProgressWindow';

export const customPricingRoute = productManagementHomeRoute + '/custom-pricing/:productId/:productProvinceGroupId?';
export const buildCustomPricingPath = (params: Module.ProductManagement.CustomPricing.Params) =>
  createPathFromRoute(customPricingRoute, params);
export default function CustomPricingIndex() {
  const { lang } = getLocales();
  const customPricingHook = useCustomPricing();

  if (!customPricingHook.hasAccess) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      <ProgressWindow />
      <DataProvider
        data={customPricingHook}
        render={({ productApi, saveAll, isUpdated, isSaving, provinceGroup, pageRouter, permissions }) =>
          !productApi?.payload && !productApi.isExecuting ? (
            <Page
              class={ThemePageError}
              sidebar={<Icon class="fas-clipboard-list" />}
              header={lang['NON-WARRANTY']}
              render={lang.noInfo}
            />
          ) : (
            <Page
              class={ThemePageStatic1}
              isLoading={isSaving}
              header={
                <>
                  <Card
                    class={ThemeCardRow}
                    media={<Icon class="fas-car-crash" />}
                    {...productApi.payload?.displayInfo}
                  />
                  {!!productApi?.payload?.productProvinceGroups?.length && (
                    <div
                      style={{
                        position: 'absolute',
                        bottom: Theme.Size.pixel(0),
                        right: Theme.Size.pixel(10),
                        height: 'fit-content',
                        top: 'unset',
                        fontSize: Theme.FontSize.M,
                        display: 'flex',
                      }}
                      children={
                        <>
                          <Button
                            render={lang.default}
                            onClick={pageRouter.updateParams}
                            data={{ ...pageRouter.params, productProvinceGroupId: null }}
                            active={null == provinceGroup?.id}
                            class={ThemeButtonTab}
                          />
                          <DataMap
                            data={productApi?.payload?.productProvinceGroups}
                            render={({ data: group }) => (
                              <Button
                                key={group.id}
                                render={group.name}
                                onClick={pageRouter.updateParams}
                                data={{ ...pageRouter.params, productProvinceGroupId: group.id.toString() }}
                                active={group.id === provinceGroup?.id}
                                class={ThemeButtonTab}
                              />
                            )}
                          />
                        </>
                      }
                    />
                  )}
                </>
              }
              render={<CustomPricingTable />}
              footer={
                <>
                  <Button
                    onClick={() =>
                      document.location.replace(
                        createPathFromRoute(productIndexRoute, { action: 'edit', id: productApi?.payload?.id })
                      )
                    }
                    disabled={!permissions.EDIT_PRODUCT}
                    class={ThemeButtonCircle}
                    media={<Icon class="fas-edit" />}
                    render={lang.goToProductEdit}
                    htmlElementProps={{ title: lang.edit }}
                  />
                  <Button
                    media={<Icon class="fas-save" />}
                    render={lang.save}
                    class={ThemeButtonCircle}
                    onClick={saveAll}
                    data={addProgressToWindow}
                    active={isUpdated}
                    disabled={!isUpdated || isSaving}
                    htmlElementProps={{ title: lang.save }}
                  />
                </>
              }
            />
          )
        }
      />
    </>
  );
}
