import Model from 'models/Model';
import VehicleMake from '../vehicleMake/Model.VehicleMake';
import tierDeleteApi from './delete/Api.Tier.Delete';
import tierListApi from './list/Api.Tier.List';
import tierSaveApi from './save/Api.Tier.Save';
import VehicleModel from '../vehicleModel/Model.VehicleModel';

export default class Tier extends Model<Model.ITier>({ icon: 'fas-cloud' }) implements Model.Instance<Model.ITier> {
  id: number;
  name: string;
  orderNumber?: number | null;
  vehicleMakes?: VehicleMake[];
  vehicleModels?: VehicleModel[];

  constructor(data: Partial<Model.ITier> | Utils.FormData<Model.ITier>, language?: Locale.Languages) {
    super({ vehicleMakes: [VehicleMake], vehicleModels: [VehicleModel] }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id.toString(),
      subtitle: this.vehicleMakes?.map((it) => it.makeCode)?.join(' | '),
      info: this.orderNumber ? `#${this.orderNumber}` : undefined,
    };
  }

  static async list(body?: Api.Core.Tier.List.IRequest) {
    const { payload, ...rest } = await tierListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new Tier(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await tierSaveApi(this);
  }
  async delete() {
    return await tierDeleteApi(this.id);
  }
}
