import useForm from 'assets/components/form/hooks/Form';
import FileInput from 'assets/components/inputs/file/FileInput';
import ThemeFileInputDropzone from 'assets/components/inputs/file/themes/Theme.FileInput.Dropzone';
import MultilineTextInput from 'assets/components/inputs/multilineText/MultilineTextInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import { getLocales } from 'assets/locales/Locale';
import { useEffect, useMemo } from 'react';

export default function ClaimDocumentForm(props: {
  onSubmit: (document: Utils.FormData<Model.IClaimDocument>) => void;
}) {
  const { lang } = getLocales();
  const form = useForm<Utils.FormData<Model.IClaimDocument>>({
    default: {},
    validation: (data, errors) => {
      if (!data.formFile) errors.formFile = lang.required;
      if (!data.name) {
        errors.formFile = lang.required;
        errors.name = lang.required;
      }
    },
    onSubmit: (document) => {
      props.onSubmit(document);
      form.reload();
    },
  });
  const formFile = useMemo(
    () =>
      form.data.formFile && {
        original: form.data.formFile,
        type: form.data.formFile.type as Utils.File.MimeType,
        dataUrl: window.URL.createObjectURL(form.data.formFile),
      },
    [form.data.formFile]
  );

  useEffect(() => {
    if (form.data.formFile) {
      form.submit();
    }
  }, [form.data.formFile]);

  return (
    <FileInput
      name="file"
      fileLimit={1}
      value={formFile ? [formFile] : []}
      onChange={({ file }) => {
        const formFile = file && file[0]?.original;
        form.update({ formFile });
      }}
      class={ThemeFileInputDropzone}
      error={form.errors.name}
      label={!form.data.name ? `${lang.documents} ${lang.name} ${lang.required}` : lang.documentUpload}
      disabled={!form.data.name}
      renderAfter={
        <>
          <TextInput
            name="name"
            label={lang.name}
            value={form.data.name}
            onChange={form.update}
            error={form.errors.name}
          />
          <br />
          <MultilineTextInput
            name="notes"
            label={lang.notes}
            value={form.data.notes}
            onChange={form.update}
            error={form.errors.notes}
          />
        </>
      }
    />
  );
}
