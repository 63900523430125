import Box from 'assets/components/box/Box';
import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Collection from 'assets/components/data/Collection';
import HeaderData from 'assets/components/data/rows/HeaderData';
import HeaderRow from 'assets/components/data/rows/HeaderRow';
import Row from 'assets/components/data/rows/Row';
import RowActions from 'assets/components/data/rows/RowActions';
import RowData from 'assets/components/data/rows/RowData';
import RowImage from 'assets/components/data/rows/RowImage';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import Confirm from 'assets/components/page/templates/Confirm';
import ThemeConfirmDefault from 'assets/components/page/templates/themes/Theme.Confirm.Default';
import ThemePageError from 'assets/components/page/themes/Theme.Page.Error';
import ThemeTagRounded from 'assets/components/tag/themes/Theme.Tag.Rounded';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { date, defaultDateTimeFormat, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { combineStrings } from 'assets/utils/data/String';
import useClaimRedirect from 'module/claims/hooks/useClaimRedirect';
import React from 'react';
import { useClaimProvider } from '../Claim.Hooks';

const actionsStyle: React.CSSProperties = {
  minWidth: '150px',
  width: '150px',
  margin: 0,
  boxSizing: 'border-box',
  justifyContent: 'center',
};

export default function ClaimList() {
  const { lang } = getLocales();
  const { listApi, pageRouter, isEditable, statusInfo, emailTemplates, productsApi } = useClaimProvider();
  const { redirectAndTakeOwnership, setRedirectInfo, redirectInfo } = useClaimRedirect({ emailTemplates, statusInfo });

  return !listApi.payload && !listApi.isExecuting && listApi.apiCounter > 0 ? (
    <Page
      class={ThemePageError}
      sidebar={<Icon class="fas-clipboard-list" />}
      header={lang.claims}
      render={lang.noInfo}
    />
  ) : (
    <>
      {redirectInfo?.claim && !redirectInfo?.claim?.owner && (
        <Confirm
          onConfirm={() => redirectAndTakeOwnership(redirectInfo)}
          onClose={() => setRedirectInfo(null)}
          actionName={lang.takeOwnershipOfTheClaim}
          itemName={redirectInfo?.claim?.id?.toString()}
          confirmationType="nextButton"
          subcomponents={ThemeConfirmDefault}
        />
      )}
      <Collection class={ThemeCollectionList}>
        <HeaderRow>
          <HeaderData>{lang.claim}</HeaderData>
          <HeaderData>{lang.info}</HeaderData>
          <HeaderData>{lang.createdAt}</HeaderData>
          <HeaderData>{lang.lastModified}</HeaderData>
          <HeaderData>{lang.status}</HeaderData>
          <HeaderData>{lang.owner}</HeaderData>
          <HeaderData style={actionsStyle}>{lang.actions}</HeaderData>
        </HeaderRow>
        <DataMap
          data={listApi.payload?.data}
          render={({ data: claim }) => {
            const isNew = pageRouter.query.newClaimId === claim.id;
            return (
              <Row
                key={claim.id}
                children={
                  <>
                    <RowImage render={<Icon class={isNew ? 'fas-plus' : 'fas-clipboard-list'} />} />
                    <RowData
                      title={
                        claim.number ??
                        combineStrings(
                          '-',
                          productsApi?.payload?.data?.find(
                            (it) => it.id === (claim?.contract?.product?.id || claim?.contract?.productId)
                          )?.category?.code,
                          claim.id.toString()
                        )
                      }
                      info={
                        <Box
                          class={ThemeTagRounded}
                          style={{
                            backgroundColor: claim.claimJobCount ? null : Theme.Color.error,
                            fontSize: Theme.FontSize.S,
                          }}
                        >
                          {claim.claimJobCount > 1
                            ? `${claim.claimJobCount} ${lang.jobs}`
                            : claim.claimJobCount === 1
                            ? `1 ${lang.job}`
                            : lang.noJobs}
                        </Box>
                      }
                      subtitle={claim.contract?.product?.name ?? lang.unknownProduct}
                    />
                    <RowData>
                      <div>
                        {lang.contact}: <b>{claim.createdByPayee?.name || 'N/A'}</b>
                        <br />
                        {lang.client}:{' '}
                        <b>
                          {combineStrings(' ', claim.contract?.client?.firstName, claim.contract?.client?.lastName) ??
                            'N/A'}
                        </b>
                      </div>
                    </RowData>
                    <RowData>
                      {date(claim?.createdAtUtc, defaultServerDateTimeFormat).format(defaultDateTimeFormat)}
                      {isNew && (
                        <span
                          style={{
                            backgroundColor: Theme.Color.success,
                            fontSize: Theme.FontSize.S,
                            fontWeight: 'bold',
                            borderRadius: '100px',
                            color: 'white',
                            padding: '3px 6px',
                            marginTop: Theme.Size.XS,
                          }}
                        >
                          {lang.newlyCreated.toUpperCase()}
                        </span>
                      )}
                    </RowData>
                    <RowData>
                      {date(claim?.modifiedAtUtc, defaultServerDateTimeFormat).format(defaultDateTimeFormat)}
                    </RowData>
                    <RowData>
                      <b>{claim.statusCode.replace(/_/g, ' - ') || lang.noStatusDefined}</b>
                    </RowData>
                    <RowData>{claim.owner ? `${claim.owner?.firstName} ${claim.owner?.lastName}` : 'N / A'}</RowData>
                    <RowActions
                      style={actionsStyle}
                      children={
                        <Button
                          onClick={!claim.owner ? setRedirectInfo : redirectAndTakeOwnership}
                          data={{ claim }}
                          class={ThemeButtonCircle}
                          disabled={!isEditable ? true : false}
                          media="fas-folder-open"
                          htmlElementProps={{
                            title: `${lang.open} ${lang.claim}`,
                          }}
                          render={lang.open}
                        />
                      }
                    />
                  </>
                }
              />
            );
          }}
        />
      </Collection>
    </>
  );
}
