import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Response = Api.Core.DealerGroup.DeleteProductDocument.IResponse;
type Headers = Api.Core.DealerGroup.DeleteProductDocument.IHeaders;
const apiDealerGroupDeleteProductDocumentPath =
  '/api/DealerManagement/DealerGroup/DeleteProductDealerGroupDocument/:productDealerGroupDocumentId';
export default async function dealerGroupDeleteProductDocumentApi(params: {
  productDealerGroupDocumentId: number;
}): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    url: createPathFromRoute(apiDealerGroupDeleteProductDocumentPath, {
      productDealerGroupDocumentId: params.productDealerGroupDocumentId,
    }),
    method: HttpMethod.delete,
    headers: getDefaultHeaders(),
  });
}
