import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import useInput from '../../Input';
import { defaultServerDateTimeFormat, defaultInputDateTimeFormat, date, dateDef } from 'assets/utils/data/Date';

export default function useDateInput<Name>(props: Hooks.Input.Date.Import<Name>) {
  const { value: d, setValue } = useInput(props);
  const isLocal = props.isLocal ?? false;

  const format = props.dateTimeFormat || defaultInputDateTimeFormat;
  const dateFunc = isLocal ? dateDef : date;
  const dateMoment = d ? dateFunc(d, defaultServerDateTimeFormat) : null;
  const formattedDate = dateMoment?.format(format);
  const unitsOfTime = convertDateToUnitsOfTime(format);
  const [tempDate, setTempDate] = useState<string>('');

  useEffect(() => {
    if (dateMoment) {
      const formatedDate = formattedDate;
      if (tempDate !== formatedDate) setTempDate(formatedDate);
    }
  }, [d]);

  const isValidDate = (d: dayjs.Dayjs) => {
    if (!d.isValid()) return false;
    if (props.min) {
      const minDate = dateFunc(props.min, defaultServerDateTimeFormat);
      if (minDate.isAfter(d)) return false;
    }
    if (props.max) {
      const maxDate = dateFunc(props.max, defaultServerDateTimeFormat);
      if (maxDate.isBefore(d)) return false;
    }
    return true;
  };

  function convertDateToUnitsOfTime(f: string) {
    return (f.match(/[a-zA-Z]+/g) || []).map((unit) => ({
      unit,
      separator: f[f.indexOf(unit) + unit.length] || undefined,
    }));
  }

  const selectDate = (newDate: string, usedFormat: string = format) => {
    const dd = dayjs(newDate, usedFormat, true);
    if (isValidDate(dd)) {
      const formattedValue = isLocal
        ? dd.format(defaultServerDateTimeFormat)
        : dd.tz('UTC').format(defaultServerDateTimeFormat);
      setValue(formattedValue);
      setTempDate(dd.format(format));
    } else {
      setTempDate('');
      setValue('');
    }
  };

  function updateTempDate(updatedDate: string, updatedFormat?: string) {
    const newLength = updatedDate.length;
    const newTempValue = dayjs(updatedDate, updatedFormat || format);
    const isValid = newTempValue.isValid();
    let newTempDate =
      newLength > format.length && isValid ? newTempValue.format(format) : updatedDate.slice(0, format.length);
    let i = 0;

    for (const unitOfTime of unitsOfTime) {
      i += unitOfTime.unit.length;
      if (newTempDate.length === i && unitOfTime.separator) newTempDate += unitOfTime.separator;
      i += unitOfTime.separator ? unitOfTime.separator.length : 0;
    }
    setTempDate(newTempDate);
  }

  return {
    tempDate,
    updateTempDate,
    format,
    isTempDateUpdated: (formattedDate ?? '') !== tempDate,
    date: d,
    dateMoment,
    min: props.min,
    max: props.max,
    selectDate,
    unitsOfTime,
  };
}
