import Model from 'models/Model';
import MediaFile from 'models/core/mediaFile/Model.MediaFile';
import ProductDocument from 'models/productManagement/productDocument/ProductDocument';

export default class ProductDealerGroupDocument
  extends Model<Model.IProductDealerGroupDocument>({ icon: 'if-login' })
  implements Model.Instance<Model.IProductDealerGroupDocument>
{
  id: number;
  productDocumentId: number;
  productDocument?: ProductDocument;
  dealerGroupId: number;
  mediaFileId: number;
  mediaFile: MediaFile;

  constructor(
    data: Partial<Model.IProductDealerGroupDocument> | Utils.FormData<Model.IProductDealerGroupDocument>,
    language?: Locale.Languages
  ) {
    super(
      {
        mediaFile: MediaFile,
        productDocument: ProductDocument,
      },
      language
    );
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.mediaFile?.name || this.id?.toString(),
    };
  }
}
