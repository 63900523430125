import calculatePricesForContract, { ProductPricingInfo } from 'app/scripts/contract/calculatePricesForContract';
import { addLog } from 'assets/components/feedback/Feedback';
import useForm from 'assets/components/form/hooks/Form';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { first, last, set } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { salesQuoteIndexRoute } from './Sales.Quote.Index';
import PurchaseState from 'models/enums/PurchaseState';
import dayjs from 'dayjs';

export type ContractProduct = Model.IProduct & {
  effectiveDate?: string;
  expiryDate?: string;
  termInMonths?: number;
  deductible?: number;
  maxVehiclePriceCAD?: number;
  minVehiclePriceCAD?: number;
  class?: string;
  tier?: string;
  warrantyOption?: string;
  postSale?: boolean;
  isPackage?: boolean;
  customInfo?: string;
  customInfoGroup?: string;
  pricePointId?: number;
  maxKm?: number;
  salePriceCAD?: number;
  priceCAD?: number;
  minPriceCAD?: number;
  maxPriceCAD?: number;
  originalPriceCAD?: number;
  distributorPriceCAD?: number;
  surchargesPriceCAD?: number;
  discountPriceCAD?: number;
  pricingDistributions?: Utils.PricingDistribution;
  distributorDistributions?: Utils.PricingDistribution;
  subfees?: Array<Utils.SurchargeInfo>;
  documentsFromTemplates?: Array<
    Partial<Model.IProductDocumentTemplate> & {
      notes?: string;
      formFile?: File;
      isDeleted?: boolean;
      uploadedId?: number; //Id of a previous IContractProductDocument
      mediaFileId?: number; //Id for IContractProductDocument.mediaFile from draft
    }
  >;
};
export type ProductForm = Utils.FormData<Model.IContract> & {
  products?: Array<ContractProduct>;
};
export enum SalesQuoteAction {
  VEHICLE = 'vehicle',
  PRODUCTS = 'products',
  QUOTE = 'quote',
}

export default function useSaleQuote() {
  const { lang } = getLocales();
  const { permissions } = useAuthStore();
  const pageRouter = usePageRouter<Module.Sales.Quote.Params, Module.Sales.Quote.Query>({
    route: salesQuoteIndexRoute,
  });
  const id = pageRouter.params.id && !isNaN(parseInt(pageRouter.params.id)) ? parseInt(pageRouter.params.id) : null;

  //#region Data editing
  const form = useForm<ProductForm>({
    default: { products: [], isPackage: false },
    validation: (data, errors) => {
      if (!data.vehicle) set(errors, 'vehicle._objectError', lang.mustNotBeEmpty);
      if (!data.vehicle?.makeCode) set(errors, 'vehicle.makeCode', lang.mustNotBeEmpty);
      if (!data.vehicle?.model) set(errors, 'vehicle.model', lang.mustNotBeEmpty);
      if (!data.vehicle?.posOdometerReading) set(errors, 'vehicle.posOdometerReading', lang.mustNotBeEmpty);
      if (!data.products?.some((p) => p.id)) set(errors, 'products._objectError', lang.mustNotBeEmpty);
      if (data.products?.some((p) => p.priceCAD > p.maxPriceCAD && p.maxPriceCAD > p.minPriceCAD)) {
        errors.priceCAD = lang.invalidForm;
      }
      if (
        data.product?.isPurchaseDetailsVisible ||
        (data.dealer?.products?.some((p) => p.isPurchaseDetailsVisible) && !data.vehicle?.purchasePrice)
      )
        set(errors, 'vehicle.purchasePrice', lang.mustNotBeEmpty);
      if (!data.dealer) set(errors, 'dealer._objectError', lang.mustNotBeEmpty);
      if (
        data.products?.some(
          (p) =>
            !!p.documentsFromTemplates?.length &&
            p.documentsFromTemplates?.some(
              (t) =>
                !!t.id &&
                (t.uploadedId || t.mediaFileId
                  ? t.isDeleted
                    ? !t.formFile && t.required
                    : false
                  : !t.formFile && t.required)
            )
        )
      )
        set(errors, 'products.documentsFromTemplates', lang.notAllRequiredDocumentInfoWasProvided);
    },
  });
  //#endregion

  //#region Tabs
  const tabs = [
    {
      id: SalesQuoteAction.VEHICLE,
      hasErrors: !!form.errors.vehicle,
      label: lang.vehicleInfo,
    },
    {
      id: SalesQuoteAction.PRODUCTS,
      hasErrors: !!form.errors.dealer || !!form.errors.products?._objectError,
      label: lang.products,
    },
    {
      id: SalesQuoteAction.QUOTE,
      label: lang.quote,
    },
  ];
  const activeTab = tabs.find((it) => it.id === pageRouter.params.action) ?? first(tabs);
  const nextTab = useMemo(() => tabs[tabs.findIndex((it) => it.id === activeTab.id) + 1] || last(tabs), [activeTab.id]);
  const prevTab = useMemo(
    () => tabs[tabs.findIndex((it) => it.id === activeTab.id) - 1] || first(tabs),
    [activeTab.id]
  );
  function next() {
    if (activeTab.id === nextTab.id) addLog({ error: lang.invalidForm });
    else {
      pageRouter.updateParams({ action: nextTab.id, id: id?.toString() });
    }
  }
  function prev() {
    if (activeTab.id === nextTab.id) addLog({ error: lang.invalidForm });
    else pageRouter.updateParams({ action: prevTab.id, id: id?.toString() });
  }
  function openTab(tab: SalesQuoteAction) {
    if (id) {
      pageRouter.updateParams({ action: tab, id: id?.toString() });
    }
  }
  function reset() {
    form.reload();
    setProductWithPricesLoading(false);
    setProductWithPrices([]);
    pageRouter.updateParams({ action: SalesQuoteAction.VEHICLE, id: id?.toString() });
  }
  //#endregion

  //#region Vehicle
  function updateVehicle(vehicle: Partial<Model.IVehicle>) {
    form.merge({ vehicle });
  }
  //#endregion

  //#region Product
  function selectProduct(product: Model.IProduct) {
    form.update({
      products: form.data.products?.map((p) => {
        if (p.id === product.id) {
          const updatedProduct = { ...p, ...product };
          updatedProduct.expiryDate =
            updatedProduct.termInMonths && updatedProduct.effectiveDate
              ? dayjs(updatedProduct.effectiveDate, defaultServerDateTimeFormat)
                  .add(updatedProduct.termInMonths, 'months')
                  .format(defaultServerDateTimeFormat)
              : null;
          return updatedProduct;
        } else return p;
      }),
    });
  }

  function updateProduct(product: ContractProduct) {
    form.update({
      products: form.data.products?.map((p) => {
        if (p.id === product.id) {
          const updatedProduct = { ...p, ...product };
          return updatedProduct;
        } else return p;
      }),
    });
  }

  const [productWithPrices, setProductWithPrices] = useState<Array<ProductPricingInfo>>([]);
  const [productWithPricesLoading, setProductWithPricesLoading] = useState(false);

  useEffect(() => {
    if (form.data.dealer && form.data.vehicle) {
      (async function () {
        setProductWithPricesLoading(true);
        const result = await calculatePricesForContract({
          dealer: form.data.dealer as Model.IDealer,
          vehicle: {
            ...form.data.vehicle,
            purchaseState:
              form.data.vehicle?.purchaseState === PurchaseState.PRE_OWNED
                ? PurchaseState.USED
                : form.data.vehicle?.purchaseState,
          } as Model.IVehicle,
        });

        unstable_batchedUpdates(() => {
          setProductWithPrices(result);
          setProductWithPricesLoading(false);
        });
      })();
    }
  }, [
    form.data.dealer,
    form.data.vehicle?.id,
    form.data.vehicle?.purchasePrice,
    form.data.vehicle?.posOdometerReading,
    form.data.vehicle?.purchaseState,
  ]);
  //#endregion

  useEffect(() => {
    reset();
  }, []);

  return {
    pageRouter,
    permissions,
    form,
    isAllowed: permissions.CREATE_CONTRACT || permissions.EDIT_CONTRACT,
    activeTab,
    nextTab,
    prevTab,
    next,
    openTab,
    reset,
    prev,
    updateVehicle,
    selectProduct,
    updateProduct,
    productWithPrices,
    productWithPricesLoading,
    tabs,
  };
}

export function useSalesQuoteProvider() {
  return useDataProvider<ReturnType<typeof useSaleQuote>>();
}
