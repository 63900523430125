import Model from 'models/Model';
import Dealer from '../dealer/Model.Dealer';
import dealerGroupDeleteApi from './delete/Api.DealerGroup.Delete';
import dealerGroupFindApi from './find/Api.DealerGroup.Find';
import dealerGroupListApi from './list/Api.DealerGroup.List';
import dealerGroupSaveApi from './save/Api.DealerGroup.Save';
import MediaFile from 'models/core/mediaFile/Model.MediaFile';
import Distributor from 'models/sales/distributor/Model.Distributor';
import { isEmpty } from 'lodash';
import ProductDealerGroupDocument from '../productDealerGroupDocument/Model.ProductDealerGroupDocument';

export default class DealerGroup
  extends Model<Model.IDealerGroup>({ icon: 'if-login' })
  implements Model.Instance<Model.IDealerGroup>
{
  id: number;
  name?: string;
  dealers?: Dealer[];
  distributorId?: number;
  distributor?: Distributor;
  uiSettingsFile?: MediaFile;
  uiSettings?: Utils.UiSettings;
  productDealerGroupDocuments?: ProductDealerGroupDocument[];
  contractConsentAggregateDescription?: string;

  constructor(data: Partial<Model.IDealerGroup> | Utils.FormData<Model.IDealerGroup>, language?: Locale.Languages) {
    super(
      {
        dealers: [Dealer],
        distributor: Distributor,
        uiSettingsFile: MediaFile,
        productDealerGroupDocuments: [ProductDealerGroupDocument],
      },
      language
    );
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id.toString(),
      subtitle: this.distributor?.name,
      info: `DEALERS: ${!isEmpty(this.dealers) ? this.dealers?.map((d) => d.name)?.join(', ') : 'N/A'}`,
    };
  }

  static async list(body?: Api.Core.DealerGroup.List.IRequest) {
    const { payload, ...rest } = await dealerGroupListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new DealerGroup(it)),
        count: payload.count,
      },
    };
  }

  static async find(params: { id: number }) {
    const { payload, ...rest } = await dealerGroupFindApi(params);
    return {
      ...rest,
      payload: new DealerGroup(payload),
    };
  }
  async save() {
    return await dealerGroupSaveApi(this);
  }
  async delete() {
    return await dealerGroupDeleteApi({ id: this.id });
  }
}
