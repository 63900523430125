import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import tierDeleteApi from 'models/productManagement/tier/delete/Api.Tier.Delete';
import Tier from 'models/productManagement/tier/Model.Tier';
import tierSaveApi from 'models/productManagement/tier/save/Api.Tier.Save';
import { useMemo } from 'react';
import { tierIndexRoute } from './Tier.Index';

export enum TierAction {
  EDIT = 'edit',
  DELETE = 'delete',
}
export type VerifyTierAction = Utils.VerifyExtends<
  Module.ProductManagement.Tier.Actions,
  Utils.ValueOf<typeof TierAction>
>;
export default function useTier() {
  const { permissions } = useAuthStore();
  const pageRouter = usePageRouter<Module.ProductManagement.Tier.Params, Module.ProductManagement.Tier.Query>({
    route: tierIndexRoute,
  });

  const canView = permissions.CREATE_PRODUCT || permissions.EDIT_PRODUCT || permissions.DELETE_PRODUCT;
  const listApi = useApi({
    action: Tier.list,
    default: { limit: defaultLimit, offset: defaultOffset, orderBy: 'orderNumber', orderType: 'asc' },
    body: pageRouter.query.list,
    callback: (req) => pageRouter.redirect({}, { ...pageRouter.query, list: req }),
    wait: !canView,
  });

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  const deleteApi = useApi({
    action: tierDeleteApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const saveApi = useApi({
    action: tierSaveApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  return {
    canView,
    pageRouter,
    listApi,
    deleteApi,
    saveApi,
    selected,
    permissions,
    views: {
      [TierAction.DELETE]: pageRouter.params.action === TierAction.DELETE && selected && permissions.DELETE_TIER,
      [TierAction.EDIT]:
        pageRouter.params.action === TierAction.EDIT &&
        ((permissions.CREATE_TIER && !selected) || (permissions.EDIT_TIER && selected)),
    },
  };
}

export function useTierProvider() {
  return useDataProvider<ReturnType<typeof useTier>>();
}
