enum ClaimTypeDataPointInputType {
  Number = 'Number',
  Text = 'Text',
  Alphanumeric = 'Alphanumeric',
  Any = 'Any',
  Date = 'Date',
}
export default ClaimTypeDataPointInputType;
export type VerifyClaimTypeDataPointInputType = Utils.VerifyExtends<
  Model.Enum.ClaimTypeDataPointInputType,
  Utils.ValueOf<typeof ClaimTypeDataPointInputType>
>;
