import Confirm from 'assets/components/page/templates/Confirm';
import ThemeConfirmDefault from 'assets/components/page/templates/themes/Theme.Confirm.Default';
import { getLocales } from 'assets/locales/Locale';
import { useStatementEmailTemplateProvider } from '../StatementEmailTemplate.Hooks';

export default function StatementEmailTemplateDelete() {
  const { lang } = getLocales();
  const { deleteApi, selected, pageRouter } = useStatementEmailTemplateProvider();

  return (
    <Confirm
      data={selected}
      onConfirm={({ id }) => deleteApi.execute(id)}
      onClose={pageRouter.updateParams}
      actionName={lang.delete}
      itemName={selected.name}
      confirmationType="nextButton"
      subcomponents={ThemeConfirmDefault}
    />
  );
}
