import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonLink from 'assets/components/button/themes/Theme.Button.Link';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import DataMap from 'assets/components/dataMap/DataMap';
import FormatValue from 'assets/components/formatValue/FormatValue';
import Icon from 'assets/components/icon/Icon';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { addProgressToWindow } from 'assets/components/progressWindow/ProgressWindow';
import ThemeTableSimple2 from 'assets/components/table/themes/Theme.Table.Simple.2';
import { getLocales } from 'assets/locales/Locale';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import ThemeOtherVerticalDivider from 'assets/themes/other/Theme.Other.VerticalDivider';
import Theme from 'assets/themes/Theme.Common';
import { combineStrings } from 'assets/utils/data/String';
import { isValidValue } from 'assets/utils/parsersAndValidation/Validators';
import { compact, reduce } from 'lodash';
import PricingLimitTemplate from 'models/productManagement/pricingLimitTemplate/Model.PricingLimitTemplate';
import VehicleClass from 'models/productManagement/vehicleClass/Model.VehicleClass';
import DealerDistributionDiscountTable from 'module/productManagement/components/pricingDistributionTable/DealerDistributionDiscountTable';
import DealerPricingLimitationTable from 'module/productManagement/components/pricingDistributionTable/DealerPricingLimitationTable';
import DistributionPriceSwitch from 'module/productManagement/components/pricingDistributionTable/DistributionPriceSwitch';
import DistributorDistributionTable from 'module/productManagement/components/pricingDistributionTable/DistributorDistributionTable';
import PricingDistributionTable from 'module/productManagement/components/pricingDistributionTable/PricingDistributionTable';
import SubfeeDistributionTable from 'module/productManagement/components/pricingDistributionTable/SubfeeDistributionTable';
import TableFilter from 'module/productManagement/components/tableFilter/TableFilter';
import { Fragment, useEffect, useState } from 'react';
import { useWarrantyClassPricingProvider } from '../WarrantyClassPricing.Hooks';

type EditCustomInfo = { index: number; customInfo: string };
export default function WarrantyClassPricingTable() {
  const { lang } = getLocales();
  const {
    getPrice,
    vehicleClasses,
    limits,
    deductibles,
    productApi,
    updatePrice,
    updateSubfee,
    updateDistributorPrice,
    updateOriginalPrice,
    pricingPlans,
    unselectedVehicleClasses,
    unselectedLimits,
    isAllowedToCopy,
    customInfos,
    removeCustomInfo,
    addEmptyPrices,
    removeLimits,
    removeDeductible,
    setEditableId,
    removeVehicleClass,
    copy,
    data,
    distributorPricingDistributions,
    dealerPricingSettings,
    selection,
    setSelection,
    updateCustomInfo,
  } = useWarrantyClassPricingProvider();

  const [tempLimit, setTempLimit] = useState<PricingLimitTemplate>();
  const [tempVehicleClass, setTempVehicleClass] = useState<VehicleClass>();
  const [tempDeductible, setTempDeductible] = useState<number | null>();
  const [tempCustomInfo, setTempCustomInfo] = useState<string>();

  const disableInputs =
    !pricingPlans.length ||
    (!data.length
      ? !tempLimit || !tempVehicleClass || !isValidValue(tempDeductible) || !isValidValue(tempCustomInfo)
      : !tempLimit && !tempVehicleClass && !isValidValue(tempDeductible) && !isValidValue(tempCustomInfo));

  const _addEmptyPrices = () => {
    addEmptyPrices(
      tempVehicleClass ? [tempVehicleClass] : vehicleClasses,
      tempLimit ? [tempLimit] : limits,
      tempDeductible ? [tempDeductible] : deductibles,
      tempCustomInfo ? [tempCustomInfo] : customInfos
    );
    setTempLimit(null);
    setTempVehicleClass(null);
    setTempDeductible(null);
    setTempCustomInfo(null);
  };

  //#region CUSTOM_INFO_EDIT
  const [currentCustomInfo, setCurrentCustomInfo] = useState<EditCustomInfo>({
    index: -1,
    customInfo: ' ',
  });
  const [prevCustomInfo, setPrevCustomInfo] = useState<EditCustomInfo>({
    index: -1,
    customInfo: ' ',
  });
  useEffect(() => {
    if (prevCustomInfo.index !== -1) {
      setCurrentCustomInfo({ index: prevCustomInfo.index, customInfo: prevCustomInfo.customInfo });
    }
  }, [prevCustomInfo]);
  //#endregion

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
          marginBottom: Theme.Size.M,
          gap: Theme.Size.M,
          justifyContent: 'flex-end',
          position: 'sticky',
          left: 0,
          zIndex: 4,
        }}
      >
        {distributorPricingDistributions.canView && (
          <InputWrapper>
            {distributorPricingDistributions.distributors?.length &&
              !distributorPricingDistributions.isDealerOrDistributor && (
                <SelectInput
                  name="distributorId"
                  value={distributorPricingDistributions.distributorId}
                  label={lang.distributors}
                  data={distributorPricingDistributions.distributors?.map((item) => ({
                    ...item.displayInfo,
                    disabled:
                      !!dealerPricingSettings.dealerId && item.id !== dealerPricingSettings.dealer?.representativeId,
                  }))}
                  onChange={({ distributorId }) => distributorPricingDistributions.setDistributorId(distributorId)}
                  style={{ width: '200px' }}
                />
              )}
            <SelectInput
              name="templateId"
              value={distributorPricingDistributions.distributorData?.templateId}
              label={lang.pricingDistributionTemplate}
              data={distributorPricingDistributions.templates?.map((item) => item.displayInfo)}
              onChange={({ templateId }) =>
                distributorPricingDistributions.setDistributorData({
                  templateId,
                  isUpdated: true,
                  distributions: [],
                  template: distributorPricingDistributions.templates?.find((it) => it.id === templateId),
                })
              }
              disabled={!!distributorPricingDistributions.productRelation?.pricingDistributionTemplateId}
              style={{ width: '200px' }}
            />
          </InputWrapper>
        )}
        {dealerPricingSettings.canView && (
          <InputWrapper>
            {!!dealerPricingSettings.dealers?.length && (
              <SelectInput
                name="dealerId"
                value={dealerPricingSettings.dealerId}
                label={lang.dealers}
                data={dealerPricingSettings.dealers?.map((item) => ({
                  ...item.displayInfo,
                  actionIcon: <Icon class="fas-copy" />,
                  actionTitle: 'Copy adjustments to this dealer',
                  disableEdit:
                    !dealerPricingSettings.dealerId ||
                    dealerPricingSettings.dealerId === item.id ||
                    !distributorPricingDistributions.distributorId ||
                    item.representativeId !== distributorPricingDistributions.distributorId,
                  disabled:
                    !!distributorPricingDistributions.distributorId &&
                    item.representativeId !== distributorPricingDistributions.distributorId,
                }))}
                onChange={({ dealerId }) => {
                  dealerPricingSettings.setDealerId(dealerId);
                  const distributorId = dealerPricingSettings.dealers?.find(
                    (it) => it.id === dealerId
                  )?.representativeId;
                  if (distributorId) distributorPricingDistributions.setDistributorId(distributorId);
                }}
                style={{ width: '200px' }}
                onEdit={(id) => dealerPricingSettings.copyTo(id, addProgressToWindow)}
              />
            )}
          </InputWrapper>
        )}
      </div>
      {((distributorPricingDistributions.canView && !distributorPricingDistributions.isDealerOrDistributor) ||
        dealerPricingSettings.canView) && <br />}
      {distributorPricingDistributions.canEdit && !distributorPricingDistributions.isDealerOrDistributor && (
        <div
          style={{
            display: 'flex',
            alignItems: 'baseline',
            marginBottom: Theme.Size.M,
            gap: Theme.Size.M,
            justifyContent: 'flex-end',
            position: 'sticky',
            left: 0,
            zIndex: 3,
          }}
        >
          {isAllowedToCopy && (
            <Button class={ThemeButtonCircle} media="fas-copy" onClick={copy} render={lang.copyDefaultPricesHere} />
          )}
          <Button
            class={ThemeButtonCircle}
            media="fas-plus"
            render={lang.pricingPlan}
            data={0}
            onClick={setEditableId}
            style={{ marginRight: 'auto' }}
            htmlElementProps={{ title: `${lang.add} ${lang.pricingPlan}` }}
          />
          <InputWrapper>
            <SelectInput
              name="vehicleClass"
              value={tempVehicleClass?.id}
              label={lang.vehicleClass}
              data={unselectedVehicleClasses?.map((item) => item.displayInfo)}
              onChange={({ vehicleClass: id }) =>
                setTempVehicleClass(unselectedVehicleClasses.find((t) => t.id === id))
              }
              disabled={!pricingPlans.length || !unselectedVehicleClasses.length}
              style={{ width: '200px' }}
            />
            <SelectInput
              name="limit"
              value={tempLimit?.id}
              label={lang.limit}
              data={unselectedLimits?.map((item) => item.displayInfo)}
              onChange={({ limit: id }) => setTempLimit(unselectedLimits.find((t) => t.id === id))}
              disabled={!pricingPlans.length || !unselectedLimits.length}
              style={{ width: '200px' }}
            />
            <NumberInput
              name="deductible"
              value={tempDeductible}
              label={lang.deductible}
              min={0}
              onChange={({ deductible }) => setTempDeductible(deductible)}
              style={{ width: '200px' }}
            />
            <TextInput
              name="customInfo"
              value={tempCustomInfo}
              label={lang.customInfo}
              onChange={({ customInfo }) => setTempCustomInfo(customInfo)}
              style={{ width: '200px' }}
            />
            <Button
              class={ThemeButtonCircle}
              media="fas-plus"
              disabled={disableInputs}
              onClick={_addEmptyPrices}
              style={{ border: `${Theme.Size.XXS} solid ${Theme.Color.shadowDark}` }}
              htmlElementProps={{ title: `${lang.add} ${lang.empty} ${lang.prices}` }}
            />
          </InputWrapper>
        </div>
      )}
      <DataMap
        data={selection.pricingPlans}
        render={({ data: pricingPlan }) => (
          <Fragment key={pricingPlan.id}>
            <table className={ThemeTableSimple2}>
              <thead>
                <tr data-active>
                  <th
                    style={{ textAlign: 'left', whiteSpace: 'nowrap' }}
                    colSpan={selection.vehicleClasses.length * selection.deductibles.length}
                  >
                    <div style={{ fontSize: Theme.FontSize.L }}>
                      <TableFilter
                        selection={selection.pricingPlans}
                        data={pricingPlans}
                        onChange={(newData) => {
                          setSelection((old) => ({
                            ...old,
                            pricingPlans: newData,
                          }));
                        }}
                        identifier={(d) => d.id}
                        label={(d) => (
                          <>
                            <span style={{ fontSize: Theme.FontSize.M }}>{d.name}</span>
                            {d.warrantyOption && <span>{`Warranty ${d.warrantyOption}`}</span>}
                            <span>
                              {d.isForNewVehicle ? lang.new : lang.preOwned}: {d.minOdometerKm}km - {d.maxOdometerKm}km
                            </span>
                          </>
                        )}
                        buttonBody={
                          <>
                            {pricingPlan.name}
                            {' | '}
                            {pricingPlan.isForNewVehicle ? lang.new : lang.preOwned}
                            {' | '}
                            {pricingPlan.warrantyOption && (
                              <>
                                {pricingPlan.warrantyOption}
                                {' | '}
                              </>
                            )}
                            <FormatValue value={`${pricingPlan.minOdometerKm}km`} />
                            {' - '}
                            <FormatValue value={`${pricingPlan.maxOdometerKm}km`} />
                          </>
                        }
                      />
                    </div>
                  </th>
                  <th style={{ textAlign: 'right' }}>
                    {!distributorPricingDistributions.isDealerOrDistributor && (
                      <Button
                        media="fas-edit"
                        class={ThemeButtonLink}
                        style={{
                          button: { display: 'inline-block', marginLeft: 'auto', fontSize: Theme.FontSize.M },
                        }}
                        render={lang.edit}
                        data={pricingPlan.id}
                        onClick={setEditableId}
                        htmlElementProps={{
                          title: lang.edit,
                        }}
                      />
                    )}
                  </th>
                </tr>
                <tr>
                  <th>
                    <TableFilter
                      selection={selection.vehicleClasses}
                      data={vehicleClasses}
                      onChange={(newData) => {
                        setSelection((old) => ({
                          ...old,
                          vehicleClasses: newData,
                        }));
                      }}
                      identifier={(d) => d.id}
                      label={(d) => combineStrings(' - ', d.primaryCode, d.secondaryCode)}
                      buttonBody={lang.vehicleClasses}
                      isMultiselect
                    />
                  </th>
                  <DataMap
                    data={selection.vehicleClasses}
                    render={({ data: vehicleClass }) => (
                      <th
                        key={vehicleClass.id}
                        colSpan={deductibles.length}
                        style={{ textAlign: 'center', borderLeft: `1px solid ${Theme.Color.shadow}` }}
                      >
                        {!distributorPricingDistributions.isDealerOrDistributor && (
                          <Button
                            class={ThemeButtonCircle}
                            style={{ button: { display: 'inline-block' } }}
                            media="fas-trash"
                            onClick={removeVehicleClass}
                            data={vehicleClass}
                            disabled={data.some((d) => d.vehicleClassId === vehicleClass.id && d.id)}
                            htmlElementProps={{
                              title: `${lang.remove} ${lang.vehicleClass}`,
                            }}
                          />
                        )}
                        {combineStrings(' - ', vehicleClass.primaryCode, vehicleClass.secondaryCode)}&nbsp;
                      </th>
                    )}
                  />
                </tr>
                <tr>
                  <th>
                    <TableFilter
                      selection={selection.deductibles}
                      data={deductibles}
                      onChange={(newData) => {
                        setSelection((old) => ({
                          ...old,
                          deductibles: newData,
                        }));
                      }}
                      identifier={(d) => d}
                      label={(d) => d.toString()}
                      buttonBody={lang.deductible}
                      isMultiselect
                    />
                    {'/'}
                    <TableFilter
                      selection={selection.limits}
                      data={limits}
                      onChange={(newData) => {
                        setSelection((old) => ({
                          ...old,
                          limits: newData,
                        }));
                      }}
                      identifier={(d) => `${d.term}/${d.maxKm}`}
                      label={(d) => `${d.term}m/${d.maxKm}km`}
                      isMultiselect
                      buttonBody={lang.limits}
                    />
                  </th>
                  <DataMap
                    data={selection.vehicleClasses}
                    render={({ data: vehicleClass }) => (
                      <Fragment key={vehicleClass.id}>
                        <DataMap
                          data={selection.deductibles}
                          render={({ data: deductible }) => (
                            <th
                              key={deductible}
                              style={{ textAlign: 'center', borderLeft: `1px solid ${Theme.Color.shadow}` }}
                            >
                              {!distributorPricingDistributions.isDealerOrDistributor && (
                                <Button
                                  class={ThemeButtonCircle}
                                  style={{ button: { display: 'inline-block' } }}
                                  media="fas-trash"
                                  onClick={removeDeductible}
                                  data={deductible}
                                  disabled={data.some((d) => d.deductible === deductible && d.id)}
                                  htmlElementProps={{
                                    title: `${lang.remove} ${lang.deductible}`,
                                  }}
                                />
                              )}
                              {deductible}
                            </th>
                          )}
                        />
                      </Fragment>
                    )}
                  />
                </tr>
              </thead>
              <tbody>
                <DataMap
                  data={selection.limits}
                  render={({ data: limit }) => (
                    <Fragment key={`${limit.term}/${limit.maxKm}`}>
                      <tr style={{ backgroundColor: Theme.Color.secondary }}>
                        <td style={{ textAlign: 'left' }}>
                          {!distributorPricingDistributions.isDealerOrDistributor && (
                            <Button
                              class={ThemeButtonCircle}
                              style={{ button: { display: 'inline-block' } }}
                              media="fas-trash"
                              onClick={removeLimits}
                              data={limit}
                              disabled={data.some((d) => d.maxKm === limit.maxKm && d.term === limit.term && d.id)}
                              htmlElementProps={{
                                title: `${lang.remove} ${lang.limits}`,
                              }}
                            />
                          )}
                          <FormatValue value={`${limit.term}m`} />
                          /
                          <FormatValue value={`${limit.maxKm}km`} />
                        </td>
                        <DataMap
                          data={selection.vehicleClasses}
                          render={({ data: vehicleClass }) => (
                            <Fragment key={vehicleClass.id}>
                              <DataMap
                                data={selection.deductibles}
                                render={({ data: deductible }) => (
                                  <td key={deductible} style={{ textAlign: 'left', position: 'relative' }}>
                                    {dealerPricingSettings.canView && !!dealerPricingSettings.dealer && (
                                      <>
                                        <DealerPricingLimitationTable
                                          isEditDisabled={dealerPricingSettings.canEdit}
                                          columnKey={`DealerLimitations-${pricingPlan.id}-${deductible}-${vehicleClass.id}-${limit.term}-${limit.maxKm}`}
                                          rows={customInfos}
                                          getDiscountPrices={(customInfo) => {
                                            const pricePoint = getPrice(
                                              limit,
                                              pricingPlan.id,
                                              vehicleClass.id,
                                              deductible,
                                              customInfo
                                            );
                                            return {
                                              base: dealerPricingSettings.getDiscountPrice(
                                                pricePoint?.id,
                                                'ProductPricingPlanClass',
                                                pricePoint?.pricingDistributions
                                              ),
                                              distributor: dealerPricingSettings.getDistributorDiscountPrice(
                                                pricePoint?.id,
                                                'ProductPricingPlanClass',
                                                distributorPricingDistributions.getDistributorPrice(
                                                  pricePoint?.id,
                                                  'ProductPricingPlanClass'
                                                )?.distributions
                                              ),
                                            };
                                          }}
                                          getLimitation={(customInfo) =>
                                            dealerPricingSettings.getDistributionLimitation(
                                              getPrice(limit, pricingPlan.id, vehicleClass.id, deductible, customInfo)
                                                ?.id,
                                              'ProductPricingPlanClass'
                                            )?.limitations
                                          }
                                          updateLimitation={(customInfo, limitations) =>
                                            dealerPricingSettings.updateDistributionLimitation({
                                              limitations,
                                              pricePointId: getPrice(
                                                limit,
                                                pricingPlan.id,
                                                vehicleClass.id,
                                                deductible,
                                                customInfo
                                              )?.id,
                                              pricePointType: 'ProductPricingPlanClass',
                                            })
                                          }
                                          priceOption={({ delta, property, unit }) => (
                                            <DropdownButton
                                              buttonMedia="fas-copy"
                                              horizontalPosition="auto_fixed"
                                              verticalPosition="auto_fixed"
                                              tabIndex={null}
                                              subcomponents={ThemeDropdownButtonPopup}
                                              htmlElementProps={{ title: lang.copy }}
                                              title="Increase all:"
                                              disabled={unit !== 'replacement' || delta < 0}
                                              dropdownOptions={({ animateOut }) => (
                                                <Button
                                                  htmlElementProps={{ title: '' }}
                                                  class={ThemeButtonMenuItem}
                                                  onClick={() => {
                                                    animateOut();
                                                    dealerPricingSettings.updateDistributionLimitation(
                                                      compact(
                                                        data.map((pricePoint) => {
                                                          const currentLimitations =
                                                            dealerPricingSettings.getDistributionLimitation(
                                                              pricePoint?.id,
                                                              'ProductPricingPlanClass'
                                                            );
                                                          if (
                                                            !currentLimitations?.limitations?.unit ||
                                                            currentLimitations?.limitations.unit === unit
                                                          ) {
                                                            const currentBasePrice =
                                                              dealerPricingSettings.getDiscountPrice(
                                                                pricePoint?.id,
                                                                'ProductPricingPlanClass',
                                                                pricePoint.pricingDistributions
                                                              ) +
                                                              reduce(
                                                                distributorPricingDistributions.getDistributorPrice(
                                                                  pricePoint?.id,
                                                                  'ProductPricingPlanClass'
                                                                )?.distributions || {},
                                                                (prev, curr) => prev + curr.amount,
                                                                0
                                                              );
                                                            return {
                                                              ...currentLimitations,
                                                              limitations: {
                                                                ...currentLimitations?.limitations,
                                                                unit,
                                                                [property]:
                                                                  delta === 0 ? null : currentBasePrice + delta,
                                                              },
                                                              pricePointId: pricePoint?.id,
                                                              pricePointType: 'ProductPricingPlanClass',
                                                            };
                                                          }
                                                          return null;
                                                        })
                                                      )
                                                    );
                                                  }}
                                                >
                                                  <span>
                                                    {property === 'minAmount' ? 'Sug. retail' : 'Max'} prices by{' '}
                                                    <FormatValue value={`${delta.toFixed(2)}$`} />
                                                  </span>
                                                </Button>
                                              )}
                                            />
                                          )}
                                        />
                                        <DealerDistributionDiscountTable
                                          isEditDisabled={dealerPricingSettings.canEdit}
                                          columnKey={`DealerDistributionDiscount-${pricingPlan.id}-${deductible}-${vehicleClass.id}-${limit.term}-${limit.maxKm}`}
                                          rows={customInfos}
                                          columns={productApi?.payload?.templateKeys}
                                          getDiscount={(customInfo) =>
                                            dealerPricingSettings.getDistributionDiscount(
                                              getPrice(limit, pricingPlan.id, vehicleClass.id, deductible, customInfo)
                                                ?.id,
                                              'ProductPricingPlanClass'
                                            )?.distributionDiscount
                                          }
                                          updateDiscount={(customInfo, distribution) => {
                                            if (productApi.payload.id && dealerPricingSettings.dealerId) {
                                              const pricePoint = getPrice(
                                                limit,
                                                pricingPlan.id,
                                                vehicleClass.id,
                                                deductible,
                                                customInfo
                                              );
                                              const dealerSettings = dealerPricingSettings.getDistributionDiscount(
                                                pricePoint?.id,
                                                'ProductPricingPlanClass'
                                              );
                                              dealerPricingSettings.updateDistributionDiscount({
                                                ...dealerSettings,
                                                productId: productApi.payload.id,
                                                dealerId: dealerPricingSettings.dealerId,
                                                pricePointId: pricePoint?.id,
                                                pricePointType: 'ProductPricingPlanClass',
                                                distributionDiscount: {
                                                  ...dealerSettings?.distributionDiscount,
                                                  ...distribution,
                                                },
                                              });
                                            }
                                          }}
                                        />
                                        <div className={ThemeOtherVerticalDivider} />
                                      </>
                                    )}
                                    {distributorPricingDistributions.canView &&
                                      !!distributorPricingDistributions.distributor && (
                                        <>
                                          <DistributorDistributionTable
                                            isEditDisabled={distributorPricingDistributions.canEdit}
                                            columnKey={`Distributor-${pricingPlan.id}-${deductible}-${vehicleClass.id}-${limit.term}-${limit.maxKm}`}
                                            rows={customInfos}
                                            columns={distributorPricingDistributions.distributorData?.template?.keys}
                                            getPrice={(customInfo) =>
                                              distributorPricingDistributions.getDistributorPrice(
                                                getPrice(limit, pricingPlan.id, vehicleClass.id, deductible, customInfo)
                                                  ?.id,
                                                'ProductPricingPlanClass'
                                              )?.distributions
                                            }
                                            updatePrice={(customInfo, distributions) =>
                                              distributorPricingDistributions.updateDistributorPrice({
                                                distributions,
                                                pricePointId: getPrice(
                                                  limit,
                                                  pricingPlan.id,
                                                  vehicleClass.id,
                                                  deductible,
                                                  customInfo
                                                )?.id,
                                                pricePointType: 'ProductPricingPlanClass',
                                              })
                                            }
                                            priceOption={({ distributedValue, distributionKey }) => (
                                              <DropdownButton
                                                buttonMedia="fas-copy"
                                                horizontalPosition="auto_fixed"
                                                verticalPosition="auto_fixed"
                                                tabIndex={null}
                                                subcomponents={ThemeDropdownButtonPopup}
                                                htmlElementProps={{ title: lang.copy }}
                                                title="Copy to:"
                                                dropdownOptions={({ animateOut }) => (
                                                  <>
                                                    <Button
                                                      htmlElementProps={{ title: '' }}
                                                      class={ThemeButtonMenuItem}
                                                      onClick={() => {
                                                        animateOut();
                                                        for (const customInfo of customInfos) {
                                                          updateDistributorPrice({
                                                            limit,
                                                            pricingPlan,
                                                            vehicleClass,
                                                            deductible,
                                                            customInfo,
                                                            distributionKey,
                                                            distributedValue,
                                                          });
                                                        }
                                                      }}
                                                    >
                                                      This column
                                                    </Button>
                                                    <Button
                                                      class={ThemeButtonMenuItem}
                                                      htmlElementProps={{ title: '' }}
                                                      onClick={() => {
                                                        animateOut();
                                                        for (const customInfo of customInfos) {
                                                          for (const limit of limits) {
                                                            updateDistributorPrice({
                                                              limit,
                                                              pricingPlan,
                                                              vehicleClass,
                                                              deductible,
                                                              customInfo,
                                                              distributionKey,
                                                              distributedValue,
                                                            });
                                                          }
                                                        }
                                                      }}
                                                    >
                                                      This pricing plan
                                                    </Button>
                                                    <Button
                                                      htmlElementProps={{ title: '' }}
                                                      class={ThemeButtonMenuItem}
                                                      onClick={() => {
                                                        animateOut();
                                                        for (const customInfo of customInfos) {
                                                          for (const limit of limits) {
                                                            for (const pricingPlan of pricingPlans) {
                                                              updateDistributorPrice({
                                                                limit,
                                                                pricingPlan,
                                                                vehicleClass,
                                                                deductible,
                                                                customInfo,
                                                                distributionKey,
                                                                distributedValue,
                                                              });
                                                            }
                                                          }
                                                        }
                                                      }}
                                                    >
                                                      All pricing plans
                                                    </Button>
                                                    <Button
                                                      htmlElementProps={{ title: '' }}
                                                      class={ThemeButtonMenuItem}
                                                      onClick={() => {
                                                        animateOut();
                                                        for (const customInfo of customInfos) {
                                                          for (const deductible of deductibles) {
                                                            for (const vehicleClass of vehicleClasses) {
                                                              updateDistributorPrice({
                                                                limit,
                                                                pricingPlan,
                                                                vehicleClass,
                                                                deductible,
                                                                customInfo,
                                                                distributionKey,
                                                                distributedValue,
                                                              });
                                                            }
                                                          }
                                                        }
                                                      }}
                                                    >
                                                      <span>
                                                        This column
                                                        <br /> & related deductible/classes
                                                      </span>
                                                    </Button>
                                                    <Button
                                                      class={ThemeButtonMenuItem}
                                                      htmlElementProps={{ title: '' }}
                                                      onClick={() => {
                                                        animateOut();
                                                        for (const customInfo of customInfos) {
                                                          for (const limit of limits) {
                                                            for (const deductible of deductibles) {
                                                              for (const vehicleClass of vehicleClasses) {
                                                                updateDistributorPrice({
                                                                  limit,
                                                                  pricingPlan,
                                                                  vehicleClass,
                                                                  deductible,
                                                                  customInfo,
                                                                  distributionKey,
                                                                  distributedValue,
                                                                });
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }}
                                                    >
                                                      <span>
                                                        This pricing plan
                                                        <br /> & related deductible/classes
                                                      </span>
                                                    </Button>
                                                    <Button
                                                      htmlElementProps={{ title: '' }}
                                                      class={ThemeButtonMenuItem}
                                                      onClick={() => {
                                                        animateOut();
                                                        for (const customInfo of customInfos) {
                                                          for (const limit of limits) {
                                                            for (const pricingPlan of pricingPlans) {
                                                              for (const deductible of deductibles) {
                                                                for (const vehicleClass of vehicleClasses) {
                                                                  updateDistributorPrice({
                                                                    limit,
                                                                    pricingPlan,
                                                                    vehicleClass,
                                                                    deductible,
                                                                    customInfo,
                                                                    distributionKey,
                                                                    distributedValue,
                                                                  });
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }}
                                                    >
                                                      <span>
                                                        All pricing plans
                                                        <br /> & related deductible/classes
                                                      </span>
                                                    </Button>
                                                  </>
                                                )}
                                              />
                                            )}
                                          />
                                          {dealerPricingSettings.canView && !!dealerPricingSettings.dealer && (
                                            <DealerDistributionDiscountTable
                                              isEditDisabled={dealerPricingSettings.canEdit}
                                              isDistributorDiscount
                                              columnKey={`DealerDistributorDistributionDiscount-${pricingPlan.id}-${deductible}-${vehicleClass.id}-${limit.term}-${limit.maxKm}`}
                                              rows={customInfos}
                                              columns={distributorPricingDistributions.distributorData?.template?.keys}
                                              getDiscount={(customInfo) =>
                                                dealerPricingSettings.getDistributionDiscount(
                                                  getPrice(
                                                    limit,
                                                    pricingPlan.id,
                                                    vehicleClass.id,
                                                    deductible,
                                                    customInfo
                                                  )?.id,
                                                  'ProductPricingPlanClass'
                                                )?.distributorDistributionDiscount
                                              }
                                              updateDiscount={(customInfo, distribution) => {
                                                if (productApi.payload.id && dealerPricingSettings.dealerId) {
                                                  const pricePoint = getPrice(
                                                    limit,
                                                    pricingPlan.id,
                                                    vehicleClass.id,
                                                    deductible,
                                                    customInfo
                                                  );
                                                  const dealerSettings = dealerPricingSettings.getDistributionDiscount(
                                                    pricePoint?.id,
                                                    'ProductPricingPlanClass'
                                                  );
                                                  dealerPricingSettings.updateDistributionDiscount({
                                                    ...dealerSettings,
                                                    productId: productApi.payload.id,
                                                    dealerId: dealerPricingSettings.dealerId,
                                                    pricePointId: pricePoint?.id,
                                                    pricePointType: 'ProductPricingPlanClass',
                                                    distributorDistributionDiscount: {
                                                      ...dealerSettings?.distributorDistributionDiscount,
                                                      ...distribution,
                                                    },
                                                  });
                                                }
                                              }}
                                            />
                                          )}
                                          <div className={ThemeOtherVerticalDivider} />
                                        </>
                                      )}

                                    {!distributorPricingDistributions.isDealerOrDistributor && (
                                      <>
                                        <PricingDistributionTable
                                          isEditDisabled={distributorPricingDistributions.canEdit}
                                          columnKey={`PricingDistribution-${pricingPlan.id}-${deductible}-${vehicleClass.id}-${limit.term}-${limit.maxKm}`}
                                          rows={customInfos}
                                          columns={productApi?.payload?.templateKeys}
                                          getPrice={(customInfo) =>
                                            getPrice(limit, pricingPlan.id, vehicleClass.id, deductible, customInfo)
                                              ?.pricingDistributions
                                          }
                                          updatePrice={(customInfo, distribution) =>
                                            updatePrice(
                                              limit,
                                              pricingPlan.id,
                                              vehicleClass.id,
                                              deductible,
                                              customInfo,
                                              distribution
                                            )
                                          }
                                          priceOption={({ distributedValue, distributionKey }) => (
                                            <DropdownButton
                                              buttonMedia="fas-copy"
                                              horizontalPosition="auto_fixed"
                                              verticalPosition="auto_fixed"
                                              tabIndex={null}
                                              subcomponents={ThemeDropdownButtonPopup}
                                              htmlElementProps={{ title: lang.copy }}
                                              title="Copy to:"
                                              dropdownOptions={({ animateOut }) => (
                                                <>
                                                  <Button
                                                    class={ThemeButtonMenuItem}
                                                    onClick={() => {
                                                      animateOut();
                                                      for (const customInfo of customInfos) {
                                                        updateOriginalPrice({
                                                          limit,
                                                          pricingPlan,
                                                          vehicleClass,
                                                          deductible,
                                                          customInfo,
                                                          distributionKey,
                                                          distributedValue,
                                                        });
                                                      }
                                                    }}
                                                  >
                                                    This column
                                                  </Button>
                                                  <Button
                                                    class={ThemeButtonMenuItem}
                                                    onClick={() => {
                                                      animateOut();
                                                      for (const customInfo of customInfos) {
                                                        for (const limit of limits) {
                                                          updateOriginalPrice({
                                                            limit,
                                                            pricingPlan,
                                                            vehicleClass,
                                                            deductible,
                                                            customInfo,
                                                            distributionKey,
                                                            distributedValue,
                                                          });
                                                        }
                                                      }
                                                    }}
                                                  >
                                                    This pricing plan
                                                  </Button>
                                                  <Button
                                                    class={ThemeButtonMenuItem}
                                                    onClick={() => {
                                                      animateOut();
                                                      for (const customInfo of customInfos) {
                                                        for (const limit of limits) {
                                                          for (const pricingPlan of pricingPlans) {
                                                            updateOriginalPrice({
                                                              limit,
                                                              pricingPlan,
                                                              vehicleClass,
                                                              deductible,
                                                              customInfo,
                                                              distributionKey,
                                                              distributedValue,
                                                            });
                                                          }
                                                        }
                                                      }
                                                    }}
                                                  >
                                                    All pricing plans
                                                  </Button>
                                                  <Button
                                                    class={ThemeButtonMenuItem}
                                                    onClick={() => {
                                                      animateOut();
                                                      for (const customInfo of customInfos) {
                                                        for (const deductible of deductibles) {
                                                          for (const vehicleClass of vehicleClasses) {
                                                            updateOriginalPrice({
                                                              limit,
                                                              pricingPlan,
                                                              vehicleClass,
                                                              deductible,
                                                              customInfo,
                                                              distributionKey,
                                                              distributedValue,
                                                            });
                                                          }
                                                        }
                                                      }
                                                    }}
                                                  >
                                                    <span>
                                                      This column
                                                      <br /> & related deductible/classes
                                                    </span>
                                                  </Button>
                                                  <Button
                                                    class={ThemeButtonMenuItem}
                                                    onClick={() => {
                                                      animateOut();
                                                      for (const customInfo of customInfos) {
                                                        for (const limit of limits) {
                                                          for (const deductible of deductibles) {
                                                            for (const vehicleClass of vehicleClasses) {
                                                              updateOriginalPrice({
                                                                limit,
                                                                pricingPlan,
                                                                vehicleClass,
                                                                deductible,
                                                                customInfo,
                                                                distributionKey,
                                                                distributedValue,
                                                              });
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }}
                                                  >
                                                    <span>
                                                      This pricing plan
                                                      <br /> & related deductible/classes
                                                    </span>
                                                  </Button>
                                                  <Button
                                                    class={ThemeButtonMenuItem}
                                                    onClick={() => {
                                                      animateOut();
                                                      for (const customInfo of customInfos) {
                                                        for (const limit of limits) {
                                                          for (const pricingPlan of pricingPlans) {
                                                            for (const deductible of deductibles) {
                                                              for (const vehicleClass of vehicleClasses) {
                                                                updateOriginalPrice({
                                                                  limit,
                                                                  pricingPlan,
                                                                  vehicleClass,
                                                                  deductible,
                                                                  customInfo,
                                                                  distributionKey,
                                                                  distributedValue,
                                                                });
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }}
                                                  >
                                                    <span>
                                                      All pricing plans
                                                      <br /> & related deductible/classes
                                                    </span>
                                                  </Button>
                                                </>
                                              )}
                                            />
                                          )}
                                        />
                                        <SubfeeDistributionTable
                                          isEditDisabled={distributorPricingDistributions.canEdit}
                                          columnKey={`Subfees-${pricingPlan.id}-${deductible}-${vehicleClass.id}-${limit.term}-${limit.maxKm}`}
                                          rows={customInfos}
                                          columns={productApi?.payload?.subfee}
                                          getPrice={(customInfo) =>
                                            getPrice(limit, pricingPlan.id, vehicleClass.id, deductible, customInfo)
                                              ?.subfees
                                          }
                                          updatePrice={(customInfo, subfees) =>
                                            updateSubfee(
                                              limit,
                                              pricingPlan.id,
                                              vehicleClass.id,
                                              deductible,
                                              customInfo,
                                              subfees
                                            )
                                          }
                                        />
                                      </>
                                    )}
                                  </td>
                                )}
                              />
                            </Fragment>
                          )}
                        />
                      </tr>
                      <DataMap
                        data={customInfos}
                        render={({ data: customInfo, index }) => (
                          <Fragment key={customInfo}>
                            <tr>
                              <td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  {!distributorPricingDistributions.isDealerOrDistributor && (
                                    <Button
                                      class={ThemeButtonCircle}
                                      style={{ button: { display: 'inline-block' } }}
                                      media="fas-trash"
                                      onClick={removeCustomInfo}
                                      data={customInfo}
                                      disabled={data.some((d) => d.customInfo === customInfo && d.id)}
                                      htmlElementProps={{ title: lang.clearDistributorPrice }}
                                    />
                                  )}
                                  {!distributorPricingDistributions.isDealerOrDistributor ? (
                                    <>
                                      {prevCustomInfo.index === index ? (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          <TextInput
                                            name="customInfo"
                                            value={
                                              currentCustomInfo.index === index
                                                ? currentCustomInfo.customInfo
                                                : customInfo
                                            }
                                            onChange={({ customInfo: newCustomInfo }) => {
                                              if (currentCustomInfo.index === index)
                                                setCurrentCustomInfo({ index, customInfo: newCustomInfo });
                                            }}
                                          />
                                          <Button
                                            class={ThemeButtonCircle}
                                            style={{ button: { display: 'inline-block' } }}
                                            media="fas-save"
                                            onClick={() => {
                                              updateCustomInfo(prevCustomInfo.customInfo, currentCustomInfo.customInfo);
                                              setCurrentCustomInfo({ index: -1, customInfo: '' });
                                              setPrevCustomInfo({ index: -1, customInfo: '' });
                                            }}
                                            htmlElementProps={{ title: lang.save }}
                                          />
                                        </div>
                                      ) : (
                                        <>
                                          {customInfo}
                                          <Button
                                            class={ThemeButtonCircle}
                                            style={{ button: { display: 'inline-block' } }}
                                            media="fas-edit"
                                            onClick={setPrevCustomInfo}
                                            data={{ index, customInfo }}
                                            htmlElementProps={{ title: lang.edit }}
                                          />
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    customInfo
                                  )}
                                </div>
                              </td>
                              <DataMap
                                data={selection.vehicleClasses}
                                render={({ data: vehicleClass }) => (
                                  <Fragment key={vehicleClass.id}>
                                    <DataMap
                                      data={selection.deductibles}
                                      render={({ data: deductible }) => {
                                        const pricePoint = getPrice(
                                          limit,
                                          pricingPlan.id,
                                          vehicleClass.id,
                                          deductible,
                                          customInfo
                                        );
                                        const isUnset = pricePoint?.priceCAD == null;

                                        const distributorDistribution =
                                          distributorPricingDistributions.getDistributorPrice(
                                            pricePoint?.id,
                                            'ProductPricingPlanClass'
                                          );
                                        const distributorPriceSegment = reduce(
                                          distributorDistribution?.distributions || {},
                                          (prev, curr) => prev + curr.amount,
                                          0
                                        );
                                        const basePrice = pricePoint?.priceCAD ?? 0;
                                        const distributorPrice = basePrice + distributorPriceSegment;
                                        const basePriceWithDiscount = dealerPricingSettings.getDiscountPrice(
                                          pricePoint?.id,
                                          'ProductPricingPlanClass',
                                          pricePoint?.pricingDistributions
                                        );
                                        const distributorPriceWithDiscount =
                                          dealerPricingSettings.getDistributorDiscountPrice(
                                            pricePoint?.id,
                                            'ProductPricingPlanClass',
                                            distributorDistribution?.distributions
                                          );
                                        const finalPrice = basePriceWithDiscount + distributorPriceWithDiscount;

                                        const showDealerPrice =
                                          distributorPrice > 0 && !!dealerPricingSettings.dealerId;
                                        const showDistributorPrice =
                                          distributorPrice > 0 && !!distributorPricingDistributions.distributorId;
                                        return (
                                          <td style={{ textAlign: 'right' }} key={deductible}>
                                            <div
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <div style={{ display: 'flex' }}>
                                                {distributorPricingDistributions.canView &&
                                                  !!distributorPricingDistributions.distributorData?.template?.keys && (
                                                    <Button
                                                      disabled={!distributorDistribution}
                                                      onClick={() =>
                                                        distributorPricingDistributions.updateDistributorPrice({
                                                          pricePointId: pricePoint?.id,
                                                          pricePointType: 'ProductPricingPlanClass',
                                                          distributions: {},
                                                        })
                                                      }
                                                      class={{ button: ThemeButtonCircle }}
                                                      media={<Icon class="fas-user-slash" />}
                                                      htmlElementProps={{ title: lang.clearDistributorPrice }}
                                                    />
                                                  )}
                                                {!distributorPricingDistributions.isDealerOrDistributor && (
                                                  <Button
                                                    disabled={isUnset}
                                                    onClick={() =>
                                                      updatePrice(
                                                        limit,
                                                        pricingPlan.id,
                                                        vehicleClass.id,
                                                        deductible,
                                                        customInfo,
                                                        null
                                                      )
                                                    }
                                                    class={{ button: ThemeButtonCircle }}
                                                    media={<Icon class="fas-trash-alt" />}
                                                    htmlElementProps={{ title: `${lang.clear} ${lang.price}` }}
                                                  />
                                                )}
                                              </div>
                                              {showDealerPrice || showDistributorPrice ? (
                                                <span
                                                  style={{
                                                    fontWeight: isUnset ? undefined : 'bold',
                                                    color: isUnset ? Theme.Color.warning : undefined,
                                                  }}
                                                >
                                                  <span style={{ fontSize: Theme.FontSize.S }}>
                                                    {combineStrings(
                                                      '/',
                                                      showDealerPrice ? lang.dealer : undefined,
                                                      showDistributorPrice ? lang.distributor : undefined,
                                                      lang.base
                                                    )}
                                                  </span>
                                                  <br />
                                                  <span style={{ fontSize: Theme.FontSize.S }}>
                                                    {showDealerPrice && (
                                                      <>
                                                        <FormatValue
                                                          value={isUnset ? 'N/A' : `${finalPrice.toFixed(2)}$`}
                                                        />
                                                        /
                                                      </>
                                                    )}
                                                    {showDistributorPrice && (
                                                      <>
                                                        <FormatValue
                                                          value={isUnset ? 'N/A' : `${distributorPrice.toFixed(2)}$`}
                                                        />
                                                        /
                                                      </>
                                                    )}
                                                    <FormatValue value={isUnset ? 'N/A' : `${basePrice.toFixed(2)}$`} />
                                                  </span>
                                                </span>
                                              ) : (
                                                <span
                                                  style={{
                                                    fontWeight: isUnset ? undefined : 'bold',
                                                    color: isUnset ? Theme.Color.warning : undefined,
                                                  }}
                                                >
                                                  <FormatValue value={isUnset ? 'N/A' : `${basePrice.toFixed(2)}$`} />
                                                </span>
                                              )}
                                            </div>
                                          </td>
                                        );
                                      }}
                                    />
                                  </Fragment>
                                )}
                              />
                            </tr>
                          </Fragment>
                        )}
                      />
                    </Fragment>
                  )}
                />
              </tbody>
            </table>
            <br />
          </Fragment>
        )}
      />
      <DistributionPriceSwitch
        templateKeys={productApi?.payload?.templateKeys ?? []}
        onUpdate={(firstKey, secondKey) => {
          for (const limit of limits) {
            for (const pricingPlan of pricingPlans) {
              for (const vehicleClass of vehicleClasses) {
                for (const deductible of deductibles) {
                  for (const customInfo of customInfos) {
                    const originalDistributions = getPrice(
                      limit,
                      pricingPlan.id,
                      vehicleClass.id,
                      deductible,
                      customInfo
                    )?.pricingDistributions;
                    if (originalDistributions) {
                      updatePrice(limit, pricingPlan.id, vehicleClass.id, deductible, customInfo, {
                        ...originalDistributions,
                        [firstKey]: originalDistributions[secondKey],
                        [secondKey]: originalDistributions[firstKey],
                      });
                    }
                  }
                }
              }
            }
          }
        }}
      />
    </>
  );
}
