import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.DealerGroup.UploadProductDocument.IRequest;
type Response = Api.Core.DealerGroup.UploadProductDocument.IResponse;
type Headers = Api.Core.DealerGroup.UploadProductDocument.IHeaders;
const apiDealerGroupUploadProductDocumentPath =
  '/api/DealerManagement/DealerGroup/:id/UploadProductDealerGroupDocument/:productId/:productDocumentId';
export default async function dealerGroupUploadProductDocumentApi(
  params: { id: number; productId: number; productDocumentId: number },
  request: Request
): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    body: request,
    url: createPathFromRoute(apiDealerGroupUploadProductDocumentPath, params),
    method: HttpMethod.post,
    headers: getDefaultHeaders(),
  });
}
