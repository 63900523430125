import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonFloating from 'assets/components/button/themes/Theme.Button.Floating';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { DataProvider } from 'assets/providers/data/DataProvider';
import { HttpStatus, salesHomeRoute } from 'config/Api.Config';
import { isMobile } from 'react-device-detect';
import useStatement, { StatementAction } from './Statement.Hooks';
import StatementDelete from './sections/Statement.Delete';
import StatementCreate from './sections/Statement.Create';
import StatementFilters from './sections/Statement.Filters';
import StatementList from './sections/Statement.List';
import StatementChangeStatus from './sections/Statement.ChangeStatus';
import ErrorPage from 'assets/templates/errorPage/ErrorPage';
import StatementPrint from './sections/Statement.Print';
import StatementUploadDocuments from './sections/Statement.UploadDocuments';
import StatementSendEmail from './sections/Statement.SendEmail';
import { isEmpty } from 'lodash';

export const statementIndexRoute = salesHomeRoute + '/statement/:action?/:id?';
export default function StatementIndex() {
  const { lang } = getLocales();
  const statementHook = useStatement();
  const { permissions } = useAuthStore();

  if (!statementHook.canView) return <ErrorPage status={HttpStatus.unauthorized} />;
  return (
    <>
      <DataProvider
        data={statementHook}
        render={({
          listApi,
          views,
          pageRouter,
          selectedStatements,
          setSelectedStatements,
          sendEmailsForSelectedStatements,
          getAllSelectedStatements,
        }) => (
          <Page
            class={ThemePageList}
            isLoading={listApi.isExecuting}
            header={
              <>
                <b style={{ marginRight: 'auto' }} children={lang.statements} />
                <StatementFilters />
                &nbsp;
                <Button
                  class={isMobile ? ThemeButtonFloating : ThemeButtonCircle}
                  active
                  media={<Icon class="fas-plus" />}
                  render={!isMobile && lang.addNew}
                  onClick={pageRouter.updateParams}
                  disabled={!permissions?.CREATE_STATEMENT}
                  data={{ action: StatementAction.CREATE }}
                />
              </>
            }
            subHeader={
              <>
                <Button
                  class={isMobile ? ThemeButtonFloating : ThemeButtonCircle}
                  active
                  media={<Icon class="fas-envelope" />}
                  render={!isMobile && lang.sendEmails}
                  onClick={sendEmailsForSelectedStatements}
                  disabled={!permissions?.CREATE_STATEMENT || isEmpty(selectedStatements)}
                />
                &nbsp;
                <Button
                  class={{ button: ThemeButtonCircle }}
                  media={
                    selectedStatements?.length === listApi?.payload?.data?.length
                      ? 'fas-check-square'
                      : selectedStatements?.length !== listApi?.payload?.data?.length
                      ? 'fas-minus-square'
                      : 'far-square'
                  }
                  disabled={!listApi?.payload?.count}
                  onClick={async () => setSelectedStatements(await getAllSelectedStatements())}
                  active={!isEmpty(selectedStatements) ? true : false}
                  htmlElementProps={{ title: lang.selectAll }}
                  render={lang.selectAll}
                />
              </>
            }
            render={
              <>
                {views.create && <StatementCreate />}
                {views.uploadDocument && <StatementUploadDocuments />}
                {views.print && <StatementPrint />}
                {views.delete && <StatementDelete />}
                {views.changeStatus && <StatementChangeStatus />}
                {views.sendEmail && <StatementSendEmail />}
                <StatementList />
              </>
            }
            footer={
              <Pagination
                class={ThemePaginationRounded}
                count={listApi.payload?.count}
                offset={listApi.body?.offset}
                limit={listApi.body?.limit}
                onLimitChange={(limit) => listApi.execute((body) => ({ ...body, limit, offset: 0 }))}
                onOffsetChange={(offset) => listApi.execute((body) => ({ ...body, offset }))}
              />
            }
          />
        )}
      />
    </>
  );
}
