import useCurrentApp from 'app/hooks/useCurrentApp';
import Application, { ApplicationStructure } from 'assets/components/application/Application';
import ThemeApplication1 from 'assets/components/application/themes/Theme.Application.1';
import Feedback from 'assets/components/feedback/Feedback';
import ThemeFeedbackDefault from 'assets/components/feedback/themes/Theme.Feedback.Default';
import { useScroll } from 'assets/hooks/events/scroll/Scroll';
import { AppStoreProvider } from 'assets/providers/authStore/Provider.AuthStore';
import AppNavigation from 'assets/templates/appNavigation/AppNavigation';
import {
  ApiHeaderApplication,
  claimsHomeRoute,
  dealerManagementAlternateHomeRoute,
  HttpStatus,
  managementHomeRoute,
  productManagementAlternateHomeRoute,
  salesHomeRoute,
} from 'config/Api.Config';
import { unpackToken } from 'config/Auth.Config';
import accountLoginApi from 'models/account/login/Api.Account.Login';
import accountTokenAuth from 'models/account/tokenAuth/Api.Account.TokenAuth';
import Login from 'module/account/views/login/Login';
import AccountResetPasswordIndex, {
  accountResetPasswordRoute,
} from 'module/account/views/resetPassword/Account.ResetPassword.Index';
import { LiveClaimViewsProvider } from 'module/claims/hooks/useClaimLiveViews';
import ClaimEditIndex, { claimEditIndexRoute } from 'module/claims/views/claimEdit/ClaimEdit.Index';
import ProcessContractConsentIndex, {
  processContractConsentRoute,
} from 'module/public-web/views/processContractConsent/Public.ProcessContractConsent';
import SalesDraftIndex, { salesDraftIndexRoute } from 'module/sales/views/draft/Sales.Draft.Index';
import SalesPayAndSignContractIndex, {
  salesPayAndSignContractIndexRoute,
} from 'module/sales/views/payAndSignContract/Sales.PayAndSignContract.Index';
import StatementSummaryIndex, {
  statementSummaryIndexRoute,
} from 'module/sales/views/statementSummary/StatementSummary.Index';
import { Route, Switch } from 'react-router-dom';
import AccountChangePasswordIndex, {
  accountChangePasswordRoute,
} from '../module/account/views/changePassword/Account.ChangePassword.Index';
import AccountEditUserSettingsIndex, {
  accountEditUserSettingsRoute,
} from '../module/account/views/editUserSettings/Account.EditUserSettings.Index';
import AccountUserWelcome, {
  accountUserWelcomeRoute,
} from '../module/account/views/userWelcome/Account.UserWelcome.Index';
import ClaimIndex, { claimIndexRoute } from '../module/claims/views/claim/Claim.Index';
import ClaimTypeIndex, { claimTypeIndexRoute } from '../module/claims/views/claimType/ClaimType.Index';
import ContractIndex, { contractIndexRoute } from '../module/claims/views/contract/Contract.Index';
import LossCodeIndex, { lossCodeIndexRoute } from '../module/claims/views/lossCode/LossCode.Index';
import PayeeIndex, { payeeIndexRoute } from '../module/claims/views/payee/Payee.Index';
import PayeeTypeIndex, { payeeTypeIndexRoute } from '../module/claims/views/payeeType/PayeeType.Index';
import DealerIndex, { dealerIndexRoute } from '../module/dealerManagement/views/dealer/Dealer.Index';
import DealerGroupIndex, {
  dealerGroupIndexRoute,
} from '../module/dealerManagement/views/dealerGroup/DealerGroup.Index';
import AccessLogIndex, { accessLogIndexRoute } from '../module/management/views/accessLog/AccessLog.Index';
import AuditLogIndex, { auditLogIndexRoute } from '../module/management/views/auditLog/AuditLog.Index';
import CategoryIndex, { categoryIndexRoute } from '../module/management/views/category/Category.Index';
import PermissionIndex, { permissionIndexRoute } from '../module/management/views/permission/Permission.Index';
import RoleIndex, { roleIndexRoute } from '../module/management/views/role/Role.Index';
import TranslationInfo, {
  translationInfoIndexRoute,
} from '../module/management/views/translationInfo/TranslationInfo.Index';
import UserIndex, { userIndexRoute } from '../module/management/views/user/User.Index';
import CustomPricingIndex, {
  customPricingRoute,
} from '../module/productManagement/views/customPricing/CustomPricing.Index';
import InsurerIndex, { insurerIndexRoute } from '../module/productManagement/views/insurer/Insurer.Index';
import NonWarrantyPricingIndex, {
  nonWarrantyPricingRoute,
} from '../module/productManagement/views/nonWarrantyPricing/NonWarrantyPricing.Index';
import PricingDistributionTemplateIndex, {
  pricingDistributionTemplateIndexRoute,
} from '../module/productManagement/views/pricingDistributionTemplate/PricingDistributionTemplate.Index';
import PricingLimitTemplateIndex, {
  pricingLimitTemplateIndexRoute,
} from '../module/productManagement/views/pricingLimitTemplate/PricingLimitTemplate.Index';
import ProductIndex, { productIndexRoute } from '../module/productManagement/views/product/Product.Index';
import ProductCategoryIndex, {
  productCategoryIndexRoute,
} from '../module/productManagement/views/productCategory/ProductCategory.Index';
import SubfeeIndex, { subfeeIndexRoute } from '../module/productManagement/views/subfee/Subfee.Index';
import TierIndex, { tierIndexRoute } from '../module/productManagement/views/tier/Tier.Index';
import VehicleClassIndex, {
  vehicleClassIndexRoute,
} from '../module/productManagement/views/vehicleClass/VehicleClass.Index';
import VehicleMakeIndex, {
  vehicleMakeIndexRoute,
} from '../module/productManagement/views/vehicleMake/VehicleMake.Index';
import VehicleModelIndex, {
  vehicleModelIndexRoute,
} from '../module/productManagement/views/vehicleModel/VehicleModel.Index';
import WarrantyClassPricingIndex, {
  warrantyClassPricingRoute,
} from '../module/productManagement/views/warrantyClassPricing/WarrantyClassPricing.Index';
import WarrantyTierPricingIndex, {
  warrantyTierPricingRoute,
} from '../module/productManagement/views/warrantyTierPricing/WarrantyTierPricing.Index';

import publicClaimSubmitIndex, {
  publicClaimSubmitIndexRoute,
} from 'module/public-web/views/claimSubmit/Claim.Submit.Index';
import PublicClaimSubmitResponsePageIndex, {
  publicClaimResponsePageIndexRoute,
} from 'module/public-web/views/claimSubmit/components/Claim.Submit.ResponsePage';
import SalesContractIndex, { salesContractIndexRoute } from '../module/sales/views/contract/Sales.Contract.Index';
import DistributorIndex, { distributorIndexRoute } from '../module/sales/views/distributor/Distributor.Index';
import LenderIndex, { lenderIndexRoute } from '../module/sales/views/lender/Lender.Index';
import SalesNewContractIndex, {
  salesNewContractIndexRoute,
} from '../module/sales/views/newContract/Sales.NewContract.Index';
import SalesQuoteIndex, { salesQuoteIndexRoute } from '../module/sales/views/quote/Sales.Quote.Index';
import StatementIndex, { statementIndexRoute } from '../module/sales/views/statements/Statement.Index';
import ClaimEmailTemplateIndex, {
  claimEmailTemplateIndexRoute,
} from 'module/management/views/claimEmailTemplate/ClaimEmailTemplate.Index';
import StatementEmailTemplateIndex, {
  statementEmailTemplateIndexRoute,
} from 'module/management/views/statementEmailTemplate/StatementEmailTemplate.Index';

export default function App() {
  const currentApp = useCurrentApp();
  const isPublic = currentApp.id === ApiHeaderApplication.public;
  useScroll({
    topTreshold: 100,
    key: 'appscroll',
    watchQuerySelector: `.${ApplicationStructure.application}`,
    onMiddle: ({ target }) => {
      target.dataset.active = 'true';
    },
    onTop: ({ target }) => {
      delete target.dataset.active;
    },
    onBottom: ({ target }) => {
      target.dataset.active = 'true';
    },
  });
  return (
    <AppStoreProvider
      login={
        isPublic
          ? null
          : async ({ username: email, password }) => {
              const { payload, status } = await accountLoginApi({ email, password });
              return payload && status === HttpStatus.ok ? unpackToken(payload.data) : {};
            }
      }
      refreshToken={
        !isPublic
          ? null
          : async () => {
              const { payload, status } = await accountTokenAuth({
                applicationId: ApiHeaderApplication.public,
                clientId: 'PublicApp',
                clientSecret: '12312312ffadsfadr324##$131ada',
              });
              return payload && status === HttpStatus.ok ? unpackToken(payload.data) : {};
            }
      }
      render={(authInfo) =>
        authInfo.user ? (
          <Application
            class={ThemeApplication1}
            style={authInfo.uiSettings?.cssVariables}
            header={<AppNavigation />}
            children={
              <LiveClaimViewsProvider>
                <Switch>
                  <Route path={dealerIndexRoute} component={DealerIndex} />
                  <Route path={dealerGroupIndexRoute} component={DealerGroupIndex} />
                  <Route exact path={dealerManagementAlternateHomeRoute} component={DealerIndex} />

                  <Route path={claimIndexRoute} component={ClaimIndex} />
                  <Route path={claimEditIndexRoute} component={ClaimEditIndex} />
                  <Route path={claimTypeIndexRoute} component={ClaimTypeIndex} />
                  <Route path={lossCodeIndexRoute} component={LossCodeIndex} />
                  <Route path={payeeIndexRoute} component={PayeeIndex} />
                  <Route path={payeeTypeIndexRoute} component={PayeeTypeIndex} />
                  <Route path={contractIndexRoute} component={ContractIndex} />
                  <Route exact path={claimsHomeRoute} component={ClaimIndex} />

                  <Route path={userIndexRoute} component={UserIndex} />
                  <Route path={roleIndexRoute} component={RoleIndex} />
                  <Route path={categoryIndexRoute} component={CategoryIndex} />
                  <Route path={accessLogIndexRoute} component={AccessLogIndex} />
                  <Route path={auditLogIndexRoute} component={AuditLogIndex} />
                  <Route path={permissionIndexRoute} component={PermissionIndex} />
                  <Route path={claimEmailTemplateIndexRoute} component={ClaimEmailTemplateIndex} />
                  <Route path={statementEmailTemplateIndexRoute} component={StatementEmailTemplateIndex} />

                  <Route path={accountChangePasswordRoute} component={AccountChangePasswordIndex} />
                  <Route path={accountEditUserSettingsRoute} component={AccountEditUserSettingsIndex} />
                  <Route path={accountUserWelcomeRoute} component={AccountUserWelcome} />
                  <Route path={translationInfoIndexRoute} component={TranslationInfo} />
                  <Route exact path={managementHomeRoute} component={UserIndex} />

                  <Route path={productIndexRoute} component={ProductIndex} />
                  <Route path={productCategoryIndexRoute} component={ProductCategoryIndex} />
                  <Route path={vehicleMakeIndexRoute} component={VehicleMakeIndex} />
                  <Route path={vehicleModelIndexRoute} component={VehicleModelIndex} />
                  <Route path={vehicleClassIndexRoute} component={VehicleClassIndex} />
                  <Route path={insurerIndexRoute} component={InsurerIndex} />
                  <Route path={pricingLimitTemplateIndexRoute} component={PricingLimitTemplateIndex} />
                  <Route path={pricingDistributionTemplateIndexRoute} component={PricingDistributionTemplateIndex} />
                  <Route path={customPricingRoute} component={CustomPricingIndex} />
                  <Route path={nonWarrantyPricingRoute} component={NonWarrantyPricingIndex} />
                  <Route path={warrantyTierPricingRoute} component={WarrantyTierPricingIndex} />
                  <Route path={warrantyClassPricingRoute} component={WarrantyClassPricingIndex} />
                  <Route path={tierIndexRoute} component={TierIndex} />
                  <Route path={subfeeIndexRoute} component={SubfeeIndex} />
                  <Route exact path={productManagementAlternateHomeRoute} component={ProductIndex} />

                  <Route path={salesContractIndexRoute} component={SalesContractIndex} />
                  <Route path={salesQuoteIndexRoute} component={SalesQuoteIndex} />
                  <Route path={salesDraftIndexRoute} component={SalesDraftIndex} />
                  <Route path={salesNewContractIndexRoute} component={SalesNewContractIndex} />
                  <Route path={salesPayAndSignContractIndexRoute} component={SalesPayAndSignContractIndex} />
                  <Route path={lenderIndexRoute} component={LenderIndex} />
                  <Route path={distributorIndexRoute} component={DistributorIndex} />
                  <Route path={statementIndexRoute} component={StatementIndex} />
                  <Route path={statementSummaryIndexRoute} component={StatementSummaryIndex} />
                  <Route exact path={salesHomeRoute} component={SalesContractIndex} />

                  <Route path={publicClaimSubmitIndexRoute} component={publicClaimSubmitIndex} />
                  <Route path={publicClaimResponsePageIndexRoute} component={PublicClaimSubmitResponsePageIndex} />
                </Switch>
                <Feedback class={ThemeFeedbackDefault} />
              </LiveClaimViewsProvider>
            }
          />
        ) : authInfo.userId ? null : (
          <Application
            class={ThemeApplication1}
            children={
              isPublic ? (
                <LiveClaimViewsProvider>
                  <Switch>
                    <Route path={publicClaimSubmitIndexRoute} component={publicClaimSubmitIndex} />
                    <Route path={publicClaimResponsePageIndexRoute} component={PublicClaimSubmitResponsePageIndex} />

                    <Route path={accountResetPasswordRoute} component={AccountResetPasswordIndex} />

                    <Route path={processContractConsentRoute} component={ProcessContractConsentIndex} />
                  </Switch>
                  <Feedback class={ThemeFeedbackDefault} />
                </LiveClaimViewsProvider>
              ) : (
                <>
                  <Login />
                  <Feedback class={ThemeFeedbackDefault} />
                </>
              )
            }
          />
        )
      }
    />
  );
}
