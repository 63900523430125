import { addLog } from 'assets/components/feedback/Feedback';
import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { getLocales } from 'assets/locales/Locale';
import { LocationSearch } from 'assets/templates/locationSearch/LocationSearch';
import { formatFormPhone } from 'assets/utils/forms/formatFormPhone';
import { isEmail, isValidPhoneNumber } from 'assets/utils/parsersAndValidation/Validators';
import { HttpStatus } from 'config/Api.Config';
import { set } from 'lodash';
import claimUpdateCustomerInfoApi from 'models/claims/claim/updateCustomerInfo/Api.Claim.UpdateCustomerInfo';

type Props = {
  data?: Model.IClaim;
  onCancel(): void;
  onSave(): void;
};

export default function ClaimEditClientInfoForm(props: Props) {
  const { lang } = getLocales();

  return (
    <ModalForm<Api.Core.Claim.UpdateCustomerInfo.IRequest>
      subcomponents={ThemeModalFormDefault}
      default={props.data || {}}
      dependencyArray={[props.data]}
      title={lang.edit}
      validation={(data, errors) => {
        if (data?.contactEmail && !isEmail(data?.contactEmail))
          set(errors, 'contactEmail', lang.invalidFormatRequires.replace('{format}', 'example@example.com'));
        if (data?.contactPhone && !isValidPhoneNumber(data?.contactPhone))
          set(errors, 'contactPhone', lang.invalidFormatRequires.replace('{format}', '###-###-####'));
      }}
      onCancel={props.onCancel}
      onSubmit={async (data) => {
        const res = await claimUpdateCustomerInfoApi(data);
        if (res.status === HttpStatus.ok) {
          addLog({ success: 'Successfully updated customer info.' });
          props.onSave();
        } else {
          addLog({ error: res.message });
        }
        return props.onCancel();
      }}
      render={({ form }) => (
        <FormWrapper>
          <TextInput
            name="contactEmail"
            label={`${lang.contact} ${lang.email}`}
            value={form.data?.contactEmail ?? props.data?.contract?.client?.email}
            onChange={form.update}
            error={form.errors?.contactEmail}
          />
          <TextInput
            name="contactPhone"
            label={`${lang.contact} ${lang.phoneNumber}`}
            value={form.data?.contactPhone ?? props.data?.contract?.client?.phone}
            onChange={({ contactPhone }) =>
              form.update({
                contactPhone: formatFormPhone(
                  form.data?.contactPhone ?? props.data?.contract?.client?.phone,
                  contactPhone
                ),
              })
            }
            error={form.errors?.contactPhone}
          />
          <LocationSearch
            onSelect={(location) => {
              form.update({
                streetAddress: location?.Line1 || null,
                provinceCode: location?.ProvinceCode || null,
                city: location?.City || null,
                countryCode: location?.CountryIso3 || null,
                postalCode: location?.PostalCode || null,
              });
            }}
          />
          <InputWrapper>
            <TextInput
              name="streetAddress"
              label={lang.streetAddress}
              value={form.data?.streetAddress}
              error={form.errors.streetAddress}
              onChange={form.update}
              icon={<Icon class="if-map-pin" />}
              readOnly
            />
            <TextInput
              name="postalCode"
              label={lang.postalCode}
              value={form.data?.postalCode}
              error={form.errors.postalCode}
              onChange={form.update}
              icon={<Icon class="if-map-pin" />}
            />
            <TextInput
              name="city"
              label={lang.city}
              value={form.data?.city}
              error={form.errors.city}
              onChange={form.update}
              icon={<Icon class="if-map-pin" />}
            />
            <TextInput
              name="provinceCode"
              label={lang.provinceCode}
              value={form.data?.provinceCode}
              error={form.errors.provinceCode}
              onChange={form.update}
              icon={<Icon class="if-map-pin" />}
            />
            <TextInput
              name="countryCode"
              label={lang.countryCode}
              value={form.data?.countryCode}
              error={form.errors.countryCode}
              onChange={form.update}
              icon={<Icon class="if-map-pin" />}
            />
          </InputWrapper>
        </FormWrapper>
      )}
    />
  );
}
