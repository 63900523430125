import Model from 'models/Model';

export default class ClaimTypeDataPoint
  extends Model<Model.IClaimTypeDataPoint>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IClaimTypeDataPoint>
{
  id: number;
  claimTypeId: number;
  version: number;
  name: string;
  isRequired: boolean;
  type: Model.Enum.ClaimTypeDataPointInputType;

  constructor(
    data: Partial<Model.IClaimTypeDataPoint> | Utils.FormData<Model.IClaimTypeDataPoint>,
    language?: Locale.Languages
  ) {
    super({}, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id.toString(),
    };
  }
}
