import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import { useMemo } from 'react';
import { statementSummaryIndexRoute } from './StatementSummary.Index';
import StatementSummary from 'models/sales/statementSummary/Model.StatementSummary';
import statementSummaryCreateForDealerGroupApi from 'models/sales/statementSummary/createForDealerGroup/Api.StatementSummary.CreateForDealerGroup';
import statementSummaryDeleteApi from 'models/sales/statementSummary/delete/Api.StatementSummary.Delete';

export enum StatementSummaryAction {
  CREATE = 'create',
  PRINT = 'print',
  DELETE = 'delete',
}
export type VerifyStatementSummaryAction = Utils.VerifyExtends<
  Module.Claims.StatementSummary.Actions,
  Utils.ValueOf<typeof StatementSummaryAction>
>;
export default function useStatementSummary() {
  const { user, permissions } = useAuthStore();
  const pageRouter = usePageRouter<Module.Claims.StatementSummary.Params, Module.Claims.StatementSummary.Query>({
    route: statementSummaryIndexRoute,
  });

  const canView = permissions.CREATE_STATEMENTSUMMARY || permissions.DELETE_STATEMENTSUMMARY;
  const listApi = useApi({
    action: StatementSummary.list,
    default: { limit: defaultLimit, offset: defaultOffset },
    body: { ...pageRouter.query.list, distributorId: user?.distributorId ? [user.distributorId] : undefined },
    callback: (req) => pageRouter.redirect({}, { ...pageRouter.query, list: req }),
    wait: !canView,
  });

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  const deleteApi = useApi({
    action: statementSummaryDeleteApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  const createApi = useApi({
    action: statementSummaryCreateForDealerGroupApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  return {
    canView,
    pageRouter,
    listApi,
    deleteApi,
    createApi,
    selected,
    permissions,
    views: {
      [StatementSummaryAction.CREATE]:
        pageRouter.params.action === StatementSummaryAction.CREATE && permissions.CREATE_STATEMENTSUMMARY && !selected,
      [StatementSummaryAction.DELETE]:
        pageRouter.params.action === StatementSummaryAction.DELETE && permissions.DELETE_STATEMENTSUMMARY && selected,
      [StatementSummaryAction.PRINT]:
        pageRouter.params.action === StatementSummaryAction.PRINT && permissions.CREATE_STATEMENTSUMMARY && selected,
    },
  };
}

export function useStatementSummaryProvider() {
  return useDataProvider<ReturnType<typeof useStatementSummary>>();
}
