import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import DataMap from 'assets/components/dataMap/DataMap';
import useForm from 'assets/components/form/hooks/Form';
import Icon from 'assets/components/icon/Icon';
import TextInput from 'assets/components/inputs/text/TextInput';
import ErrorWrapper from 'assets/components/inputs/wrappers/ErrorWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import ThemeTableSimple from 'assets/components/table/themes/Theme.Table.Simple';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { defaultDateFormat, defaultServerDateFormat } from 'assets/utils/data/Date';
import dayjs from 'dayjs';
import Contract from 'models/sales/contract/Model.Contract';

type ContractSearchForm = {
  contractNumber: string;
  lastName: string;
  vin: string;
};
export default function ClaimContractSearchForm(props: { onSubmit: (contract: Model.IContract) => void }) {
  const { lang } = getLocales();
  const form = useForm<ContractSearchForm>({
    default: {},
    validation: (data, errors) => {
      if (!data.vin) errors.vin = lang.required;
      else if (data.vin.length < 8) errors.vin = lang.vinMustBeAtLeast8CharsLong;
      else if (!data.contractNumber && !data.lastName) {
        errors.lastName = lang.required;
        errors.contractNumber = lang.required;
      }
    },
    onSubmit: (document) => {
      listPublicApi.execute(document);
    },
  });

  const listPublicApi = useApi({
    action: Contract.listPublic,
    wait: true,
    callback: () => {
      form.reload();
    },
  });

  return (
    <>
      <h2>{lang.contract}</h2>
      <InputWrapper>
        <TextInput
          name="vin"
          label={lang.vin}
          value={form.data.vin}
          error={form.errors.vin}
          onChange={form.update}
          icon={<Icon class="fas-car" />}
        />
        <TextInput
          name="contractNumber"
          label={lang.contractNumber}
          value={form.data.contractNumber}
          error={form.errors.contractNumber}
          onChange={form.update}
          icon={<Icon class="fas-file-contract" />}
        />
        <TextInput
          name="lastName"
          label={lang.lastName}
          value={form.data.lastName}
          error={form.errors.lastName}
          onChange={form.update}
          icon={<Icon class="if-quill-pen" />}
        />
      </InputWrapper>
      {!listPublicApi.payload?.data.length ? (
        <>
          <ErrorWrapper>{listPublicApi.message || lang.noInfo}</ErrorWrapper>
          <br />
        </>
      ) : (
        <>
          <table className={ThemeTableSimple}>
            <thead>
              <tr>
                <th children={lang.contractNumber} />
                <th children={`${lang.product} ${lang.name.toLowerCase()}`} />
                <th children={lang.clientName} />
                <th children={lang.vin} />
                <th children={lang.expiryDate} />
                <th children={lang.dealer} />
                <th children={lang.actions} style={{ textAlign: 'center' }} />
              </tr>
            </thead>
            <tbody>
              <DataMap
                data={listPublicApi.payload?.data}
                render={({ data }) => (
                  <tr key={data.id}>
                    <td children={data.appNumber} />
                    <td children={data?.product?.name} />
                    <td children={data?.client?.displayInfo?.title} />
                    <td children={data?.vehicle?.vin} />
                    <td children={dayjs(data?.expiryDate, defaultServerDateFormat)?.format(defaultDateFormat)} />
                    <td children={data?.dealer?.name} />
                    <td style={{ textAlign: 'center' }}>
                      <Button
                        render={lang.select}
                        class={ThemeButtonCircle}
                        htmlElementProps={{ title: lang.select }}
                        style={{ display: 'inline-block' }}
                        onClick={props.onSubmit}
                        data={data}
                      />
                    </td>
                  </tr>
                )}
              />
            </tbody>
          </table>
          <br />
        </>
      )}
      <Button
        onClick={form.submit}
        media={<Icon class="fas-search" />}
        render={lang.search}
        active={!form.hasErrors && form.isUpdated}
        disabled={form.hasErrors || !form.isUpdated}
        isLoading={form.isSubmitted}
        class={ThemeButtonCircle}
        htmlElementProps={{ title: lang.search }}
      />
    </>
  );
}
