import DataMap from 'assets/components/dataMap/DataMap';
import { amexCard, ascInsuranceLogoTransparent, masterCard, visaCard } from 'assets/themes/assets/Images';
import { dateDef, defaultDateFormat, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { combineStrings } from 'assets/utils/data/String';
import dayjs from 'dayjs';
import ProductType from 'models/enums/ProductType';
import { Fragment, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { useStatementProvider } from '../Statement.Hooks';
import '../style/print.scss';

export default function StatementPrint() {
  const { selected, statementContractsPerMonths, getContractsPaymentAmounts } = useStatementProvider();
  const history = useHistory();
  const componentRef = useRef<HTMLDivElement | null>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
    documentTitle: `Statement #${selected?.statementNumber}`,
  });

  useEffect(() => {
    handlePrint();
    history.goBack();
  }),
    [];

  return !selected ? null : (
    <div id="printStatement" ref={componentRef}>
      <style>{`@page {size: a4 portret; margin: 1cm;}`}</style>
      <h1 style={{ fontSize: '4rem', textAlign: 'end' }}>STATEMENT #{selected.statementNumber}</h1>
      <div className="print_details">
        <div className="print_details_company">
          <img src={ascInsuranceLogoTransparent} />
          <p>
            <b>ASC Insurance Services,</b>
            <br />
            1275 North Service Rd. W, Suite 605
            <br />
            Oakville, ON L6M2W2
          </p>
        </div>
        <div className="print_details_dealer">
          <p>
            <b>Date:</b> {dateDef(selected.createdAtLocal, defaultServerDateTimeFormat).format(defaultDateFormat)}
            <br />
            <b>Dealer:</b> {selected.dealer.name}
            <br />
            <b>Address:</b>
            <br /> {selected.dealer?.streetAddress}, {selected.dealer?.postalCode}
            <br /> {selected.dealer?.city}, {selected.dealer?.provinceCode}, {selected.dealer?.countryCode}
          </p>
          <p>
            If issuing a cheque, please
            <br />
            ensure that it is made payable to:
            <br />
            <b>ASC Insurance Services</b>
          </p>
          <div className="print_details_dealer_cards">
            <img src={visaCard} />
            <img src={masterCard} />
            <img src={amexCard} />
          </div>
        </div>
      </div>
      {/*Statement Balance due section*/}
      <div className="print_table_header">
        <table className="print_table_header_title">
          <tr>
            <th>Balance Due is Payable Upon Receipt</th>
          </tr>
        </table>
        <table className="print_table_header_payments">
          <tr>
            <th>Balance Due:</th>
            {statementContractsPerMonths?.map((it) => (
              <th key={it.label}>{it.label}:</th>
            ))}
          </tr>
          <tr>
            <td>${getContractsPaymentAmounts(selected?.contracts).paymentAmountWithTaxCAD.toFixed(2)}</td>
            {statementContractsPerMonths?.map((it) => (
              <td key={it.label}>${it.paymentAmountWithTaxCAD}</td>
            ))}
          </tr>
        </table>
      </div>
      {/*Contract Summary section*/}
      <div className="print_table_body">
        <table>
          <DataMap
            data={statementContractsPerMonths}
            render={({ data: statementContractGroup }) => (
              <Fragment key={statementContractGroup.label}>
                {statementContractGroup.label && (
                  <>
                    <tr className="print_title_row">
                      <td colSpan={10}>{statementContractGroup.label.toUpperCase()}</td>
                    </tr>

                    {Object.entries(statementContractGroup.productContractsDict).map(([productKey, contracts]) => {
                      return (
                        <Fragment key={productKey}>
                          <tr className="print_background_color">
                            <th style={{ width: '8%' }}>App#</th>
                            <th>Product</th>
                            <th style={{ width: '8%' }}>Stock#</th>
                            <th style={{ width: '20%' }}>Coverage</th>
                            <th>Client</th>
                            <th style={{ width: '10%' }}>Created</th>
                            <th style={{ width: '10%' }}>Effective on</th>
                            <th className="th_payment">Amount</th>
                            <th className="th_payment">Tax</th>
                            <th className="th_payment">Total</th>
                          </tr>
                          <DataMap
                            data={contracts}
                            render={({ data: contract }) => (
                              <Fragment key={contract.id}>
                                <tr>
                                  <td>
                                    {contract.appNumber +
                                      (contract.revisionNumber ? `-R${contract.revisionNumber}` : '')}
                                  </td>
                                  <td>{combineStrings(' ', productKey)}</td>
                                  <td>{contract.vehicle?.stockNumber || 'n/a'}</td>
                                  <td>
                                    {contract.product.productType === ProductType.warrantyTierBased && (
                                      <div>
                                        <b>Term:</b> {contract.termInMonths || 'n/a'}m
                                        <br />
                                        <b>Tier:</b> {contract.tier || 'n/a'}
                                      </div>
                                    )}
                                    {contract.product.productType === ProductType.nonWarranty && (
                                      <div>
                                        <b>Term:</b> {contract.termInMonths || 'n/a'}m
                                        <br />
                                        <b>Price:</b>
                                        {`$${contract.minVehiclePriceCAD.toFixed(2) || 'n/a'} - $${
                                          contract.maxVehiclePriceCAD.toFixed(0) || 'n/a'
                                        }`}
                                      </div>
                                    )}
                                    {contract.product.productType === ProductType.warrantiClassBased && (
                                      <div>
                                        <b>Term:</b> {contract.termInMonths || 'n/a'}m / {contract.maxKm || 'n/a'}km
                                        <br />
                                        <b>Coverage:</b> {contract.customInfo || 'n/a'}
                                      </div>
                                    )}
                                    {contract.product.productType === ProductType.custom && (
                                      <div>
                                        <b>Term:</b> {contract.termInMonths || 'n/a'}m
                                        <br />
                                        <b>Coverage:</b> {contract.customInfo || 'n/a'}
                                        <br />
                                        <b>Coverage Type:</b> {contract.customInfoGroup || 'n/a'}
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    {combineStrings(' ', contract.client?.firstName, contract.client?.lastName)}
                                    <br />
                                    {`(...${contract.vehicle?.vin.toString().slice(-8)})`}
                                  </td>
                                  <td>
                                    {dayjs(contract?.createdAtLocal, defaultServerDateTimeFormat).format(
                                      defaultDateFormat
                                    )}
                                  </td>
                                  <td>
                                    {dayjs(contract?.effectiveDate, defaultServerDateTimeFormat).format(
                                      defaultDateFormat
                                    )}
                                  </td>
                                  <td className="td_payment">{`$${contract.paymentAmountCAD.toFixed(2)}`}</td>
                                  <td className="td_payment">{`$${contract.paymentTaxCAD.toFixed(2)}`}</td>
                                  <td className="td_payment">{`$${contract.paymentAmountWithTaxCAD.toFixed(2)}`}</td>
                                </tr>
                              </Fragment>
                            )}
                          />
                          {contracts?.length > 1 && (
                            <>
                              <tr className="print_background_color print_never_break">
                                <td colSpan={10}></td>
                              </tr>
                              <tr className="print_never_break">
                                <td colSpan={6}></td>
                                <td>
                                  <b>SUBTOTAL</b>
                                </td>
                                <td className="td_payment">
                                  <b>{`$${getContractsPaymentAmounts(contracts).paymentAmountCAD.toFixed(2)}`}</b>
                                </td>
                                <td colSpan={2}></td>
                              </tr>
                              <tr className="print_never_break">
                                <td>
                                  <b># OF APPS</b>
                                </td>
                                <td className="td_payment">
                                  <b>{contracts?.length}</b>
                                </td>
                                <td colSpan={5}></td>
                                <td>
                                  <b>SALES TAX</b>
                                </td>
                                <td className="td_payment">
                                  <b>{`$${getContractsPaymentAmounts(contracts).paymentTaxCAD.toFixed(2)}`}</b>
                                </td>
                                <td></td>
                              </tr>
                              <tr className="print_never_break">
                                <td colSpan={8}></td>
                                <td>
                                  <b>TOTAL</b>
                                </td>
                                <td className="td_payment">
                                  <b>{`$${getContractsPaymentAmounts(contracts).paymentAmountWithTaxCAD.toFixed(
                                    2
                                  )}`}</b>
                                </td>
                              </tr>
                            </>
                          )}
                        </Fragment>
                      );
                    })}
                    {/*Total sum for each range*/}
                    <tr className="print_background_color print_never_break">
                      <td colSpan={10}></td>
                    </tr>
                    <tr className="print_never_break">
                      <td colSpan={6}></td>
                      <td>
                        <b>SUBTOTAL</b>
                      </td>
                      <td className="td_payment">
                        <b>${statementContractGroup.paymentAmountCAD}</b>
                      </td>
                      <td colSpan={2}></td>
                    </tr>
                    <tr className="print_never_break">
                      <td>
                        <b># OF APPS {statementContractGroup.label}</b>
                      </td>
                      <td className="td_payment">
                        <b>{statementContractGroup.length}</b>
                      </td>
                      <td colSpan={5}></td>
                      <td>
                        <b>SALES TAX</b>
                      </td>
                      <td className="td_payment">
                        <b>${statementContractGroup.paymentTaxCAD}</b>
                      </td>
                      <td></td>
                    </tr>
                    <tr className="print_never_break">
                      <td colSpan={8}></td>
                      <td>
                        <b>TOTAL</b>
                      </td>
                      <td className="td_payment">
                        <b>${statementContractGroup.paymentAmountWithTaxCAD}</b>
                      </td>
                    </tr>
                  </>
                )}
              </Fragment>
            )}
          />

          {/*Total sum section*/}
          <tr className="print_title_row">
            <td colSpan={10}>TOTAL SUM</td>
          </tr>
          <tr>
            <td colSpan={6}></td>
            <td>
              <b>SUBTOTAL</b>
            </td>
            <td className="td_payment">
              <b>{`$${getContractsPaymentAmounts(selected?.contracts).paymentAmountCAD.toFixed(2)}`}</b>
            </td>
            <td colSpan={2}></td>
          </tr>
          <tr>
            <td>
              <b># OF APPS</b>
            </td>
            <td className="td_payment">
              <b>{selected?.contracts?.length}</b>
            </td>
            <td colSpan={5}></td>
            <td>
              <b>SALES TAX</b>
            </td>
            <td className="td_payment">
              <b>{`$${getContractsPaymentAmounts(selected?.contracts).paymentTaxCAD.toFixed(2)}`}</b>
            </td>
            <td></td>
          </tr>
          <tr>
            <td colSpan={8}></td>
            <td>
              <b>TOTAL</b>
            </td>
            <td className="td_payment">
              <b>{`$${getContractsPaymentAmounts(selected?.contracts).paymentAmountWithTaxCAD.toFixed(2)}`}</b>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}
