import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { isStringNumber } from 'assets/utils/parsersAndValidation/Validators';
import { compact, sortBy } from 'lodash';
import VehicleClass from 'models/productManagement/vehicleClass/Model.VehicleClass';
import VehicleMake from 'models/productManagement/vehicleMake/Model.VehicleMake';
import React from 'react';
import { useVehicleModelProvider } from '../VehicleModel.Hooks';
import Tier from 'models/productManagement/tier/Model.Tier';

export default function VehicleModelEdit() {
  const { lang } = getLocales();
  const { selected, pageRouter, saveApi, vehicleMake } = useVehicleModelProvider();
  const { payload: vehicleMakesPayload } = useApi({ action: VehicleMake.list });
  const { payload: vehicleClassesPayload } = useApi({ action: VehicleClass.list });
  const { payload: tiersPayload } = useApi({ action: Tier.list });

  return (
    <ModalForm<Api.Core.VehicleModel.Save.IRequest>
      subcomponents={ThemeModalFormDefault}
      dependencyArray={[selected]}
      title={lang.vehicleModel}
      default={{
        ...(selected?.toJSON() || {}),
        vehicleMakeId: vehicleMake?.id,
      }}
      onCancel={pageRouter.updateParams}
      onSubmit={(data) => saveApi.execute(data)}
      validation={(data, errors) => {
        if (!data.code) errors.code = lang.mustNotBeEmpty;
        if (!data.name) errors.name = lang.mustNotBeEmpty;
      }}
      render={({ form }) => (
        <FormWrapper>
          <InputWrapper>
            <TextInput
              name="code"
              label={lang.code}
              value={form.data.code}
              error={form.errors.code}
              onChange={form.update}
              icon={<Icon class="if-quill-pen" />}
            />
            <TextInput
              name="name"
              label={lang.name}
              value={form.data.name}
              error={form.errors.name}
              onChange={form.update}
              icon={<Icon class="if-quill-pen" />}
            />
          </InputWrapper>
          <SelectInput
            name="vehicleMakeId"
            label={lang.makeCode}
            data={sortBy(
              vehicleMakesPayload?.data?.map((it) => it.displayInfo),
              'title'
            )}
            value={form.data.vehicleMake?.id || form.data.vehicleMakeId}
            disabled={!!vehicleMake}
            onChange={({ vehicleMakeId }) => form.update({ vehicleMakeId, vehicleMake: { id: vehicleMakeId } })}
            icon={<Icon class="if-cloud" />}
          />
          <SelectInput
            name="vehicleClassId"
            label={lang.vehicleClasses}
            data={sortBy(
              vehicleClassesPayload?.data?.map((it) => it.displayInfo),
              'title'
            )}
            value={form.data.vehicleClasses?.map((it) => it.id)}
            error={form.errors.vehicleClasses?._objectError}
            onChange={(selectData) =>
              form.update({
                vehicleClasses: selectData.vehicleClassId
                  .map((it) => vehicleClassesPayload?.data?.find((d) => d.id === it))
                  ?.filter((it) => (it ? true : false)),
              })
            }
            isMultiselect
            icon={<Icon class="fas-globe" />}
          />
          <SelectInput
            name="tierId"
            label={lang.tiers}
            data={tiersPayload?.data?.map((it) => it.displayInfo)}
            value={form.data.tiers?.map((it) => it.id)}
            error={form.errors.tiers?._objectError}
            onChange={(selectData) =>
              form.update({
                tiers: compact([tiersPayload?.data?.find((d) => d.id === selectData.tierId)]),
              })
            }
            icon={<Icon class="fas-globe" />}
          />
          <InputWrapper>
            <TextInput
              name="mfgPowertrainWarrantyMonths"
              label={lang.mfgPowertrainWarrantyMonths}
              value={form.data.mfgPowertrainWarrantyMonths}
              onChange={(input) =>
                form.update({
                  mfgPowertrainWarrantyMonths: isStringNumber(input.mfgPowertrainWarrantyMonths)
                    ? input.mfgPowertrainWarrantyMonths
                    : form.data.mfgPowertrainWarrantyMonths,
                })
              }
              icon={<Icon class="if-quill-pen" />}
            />
            <TextInput
              name="mfgPowertrainWarrantyKm"
              label={lang.mfgPowertrainWarrantyKm}
              value={form.data.mfgPowertrainWarrantyKm}
              onChange={(input) =>
                form.update({
                  mfgPowertrainWarrantyKm: isStringNumber(input.mfgPowertrainWarrantyKm)
                    ? input.mfgPowertrainWarrantyKm
                    : form.data.mfgPowertrainWarrantyKm,
                })
              }
              icon={<Icon class="if-quill-pen" />}
            />
          </InputWrapper>
          <InputWrapper>
            <TextInput
              name="mfgWholeVehicleWarrantyMonths"
              label={lang.mfgWholeVehicleWarrantyMonths}
              value={form.data.mfgWholeVehicleWarrantyMonths}
              onChange={(input) =>
                form.update({
                  mfgWholeVehicleWarrantyMonths: isStringNumber(input.mfgWholeVehicleWarrantyMonths)
                    ? input.mfgWholeVehicleWarrantyMonths
                    : form.data.mfgWholeVehicleWarrantyMonths,
                })
              }
              icon={<Icon class="if-quill-pen" />}
            />
            <TextInput
              name="mfgWholeVehicleWarrantyKm"
              label={lang.mfgWholeVehicleWarrantyKm}
              value={form.data.mfgWholeVehicleWarrantyKm}
              onChange={(input) =>
                form.update({
                  mfgWholeVehicleWarrantyKm: isStringNumber(input.mfgWholeVehicleWarrantyKm)
                    ? input.mfgWholeVehicleWarrantyKm
                    : form.data.mfgWholeVehicleWarrantyKm,
                })
              }
              icon={<Icon class="if-quill-pen" />}
            />
          </InputWrapper>
        </FormWrapper>
      )}
    />
  );
}
