import Button from 'assets/components/button/Button';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import Icon from 'assets/components/icon/Icon';
import { getLocales } from 'assets/locales/Locale';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import { autoTriggerDownload } from 'assets/utils/dom/Download';
import { HttpStatus } from 'config/Api.Config';
import { isEmpty } from 'lodash';
import claimJobFindApi from 'models/claims/claimJob/find/Api.ClaimJob.Find';
import { useEffect, useState } from 'react';

export default function ClaimDownloadDocuments({ claim }: { claim: Model.IClaim }) {
  const { lang } = getLocales();
  const [claimJobsDocuments, setClaimJobsDocuments] = useState<Model.IClaimJobDocument[]>([]);

  const triggerAutoDownloadFiles = (files: Model.IMediaFile[]) => {
    files?.forEach((document, index) => {
      setTimeout(() => {
        autoTriggerDownload({ url: document.url, name: document.name });
      }, index * 500);
    });
  };

  useEffect(() => {
    (async () => {
      if (claim && !isEmpty(claim.claimJobs)) {
        for (const claimJob of claim.claimJobs) {
          const res = await claimJobFindApi({ id: claimJob?.id });
          const claimJobDocuments = res.payload?.claimJobDocuments;
          if (res.status === HttpStatus.ok && !isEmpty(claimJobDocuments)) {
            setClaimJobsDocuments((prev) => [...prev, ...claimJobDocuments]);
          }
        }
      }
    })();
  }, [claim]);

  const claimMediaFiles = claim?.claimDocuments?.map((it) => it.mediaFile) || [];
  const claimJobsMediaFiles = claimJobsDocuments?.map((it) => it.mediaFile) || [];

  return (
    <DropdownButton
      buttonBody={lang.download}
      buttonMedia={<Icon class="fas-download" />}
      horizontalPosition="auto_fixed"
      verticalPosition="auto_fixed"
      subcomponents={ThemeDropdownButtonPopup}
      tabIndex={null}
      htmlElementProps={{ title: `${lang.download} ${lang.claim} ${lang.documents}` }}
      dropdownOptions={
        <>
          <Button
            onClick={triggerAutoDownloadFiles}
            data={claimMediaFiles}
            htmlElementProps={{ title: lang.downloadAllClaimDocuments }}
            render={`${lang.claim} ${lang.documents}`}
            class={ThemeButtonMenuItem}
            disabled={isEmpty(claimMediaFiles)}
          />
          <Button
            onClick={triggerAutoDownloadFiles}
            data={claimJobsMediaFiles}
            htmlElementProps={{ title: lang.downloadAllClaimJobsDocuments }}
            render={`${lang.claimJob}s ${lang.documents}`}
            class={ThemeButtonMenuItem}
            disabled={isEmpty(claimJobsMediaFiles)}
          />
          <Button
            onClick={triggerAutoDownloadFiles}
            data={[...claimMediaFiles, ...claimJobsMediaFiles]}
            htmlElementProps={{ title: lang.downloadAll }}
            render={lang.downloadAll}
            class={ThemeButtonMenuItem}
            disabled={isEmpty(claimMediaFiles) && isEmpty(claimJobsMediaFiles)}
          />
        </>
      }
    />
  );
}
