import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import { getLocales } from 'assets/locales/Locale';
import ObjectTable from 'assets/templates/objectTable/ObjectTable';
import { useState } from 'react';
import { get } from 'lodash';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import './PricingDistributionTable.scss';
import { inputStyles } from 'assets/components/inputs/Input';
import { dispatchPricingDistribution, usePricingDistributionEvent } from './PricingDistributionTable';

type Props<T> = {
  columnKey: string;
  columns?: string[];
  rows?: T[];
  isDistributorDiscount?: boolean;
  getDiscount: (row: T) => Utils.DiscountDistribution;
  updateDiscount: (row: T, discount: Utils.DiscountDistribution) => void;
  isEditDisabled: boolean;
};
export default function DealerDistributionDiscountTable<T>({
  columnKey,
  columns,
  rows,
  isDistributorDiscount,
  isEditDisabled,
  getDiscount,
  updateDiscount,
}: Props<T>) {
  const { lang } = getLocales();
  const [isOpen, setIsOpen] = useState(false);

  usePricingDistributionEvent(
    {
      callback: (event) => {
        setIsOpen((old) => (columnKey === event.id ? !old : false));
      },
    },
    [columnKey]
  );
  return (
    <>
      <Button
        class={ThemeButtonCircle}
        style={{ button: { display: 'inline-block' } }}
        media={isDistributorDiscount ? 'fas-percent' : 'fas-percentage'}
        active={isOpen}
        onClick={dispatchPricingDistribution}
        data={{ data: { id: columnKey } }}
        disabled={!columns?.length || !rows?.length}
        htmlElementProps={{
          title: isDistributorDiscount
            ? lang.openDealerDistributorDistributionDiscountTable
            : lang.openDealerDistributionDiscountTable,
        }}
      />
      {isOpen && (
        <ObjectTable
          className={'PricingDistributionTable'}
          paths={columns}
          data={rows}
          focusOnLoad
          translateHeader
          render={({ path: col, data: row }) => {
            const distributionDiscount = get(getDiscount(row), col);

            return (
              <td key={col}>
                <div className={inputStyles.wrapper.inputWrapper}>
                  <NumberInput
                    name="amount"
                    placeholder={lang.amount}
                    style={{ width: '140px' }}
                    value={distributionDiscount?.amount}
                    error={
                      distributionDiscount
                        ? !distributionDiscount.amount
                          ? lang.mustNotBeEmpty
                          : undefined
                        : undefined
                    }
                    onChange={({ amount }) =>
                      updateDiscount(row, {
                        [col]: {
                          ...distributionDiscount,
                          amount,
                        },
                      })
                    }
                    disabled={!isEditDisabled}
                  />
                  <SelectInput
                    name="unit"
                    data={[
                      { id: 'percent', title: 'Percent (%)' },
                      { id: 'addition', title: 'Addition (+- $)' },
                    ]}
                    placeholder={lang.adjustment}
                    style={{ width: '140px' }}
                    value={distributionDiscount?.unit}
                    error={distributionDiscount && !distributionDiscount.unit ? lang.mustNotBeEmpty : undefined}
                    onChange={({ unit }) =>
                      updateDiscount(row, {
                        [col]: {
                          ...distributionDiscount,
                          unit,
                        },
                      })
                    }
                    disabled={!isEditDisabled}
                  />
                </div>
              </td>
            );
          }}
        />
      )}
    </>
  );
}
