import executeServerApi from 'assets/utils/server/ServerApi';
import { getDefaultHeaders, HttpMethod } from 'config/Api.Config';

type Request = Api.Core.Statement.SendEmail.IRequest;
type Response = Api.Core.Statement.SendEmail.IResponse;
type Headers = Api.Core.Statement.SendEmail.IHeaders;
const url = '/api/DealerManagement/Statement/SendEmail';
export default async function statementSendEmailApi(body: Request): Promise<Api.Res<Response>> {
  return await executeServerApi<Request, Response, Headers>({
    method: HttpMethod.post,
    url,
    body,
    headers: getDefaultHeaders(),
  });
}
