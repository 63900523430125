import MediaFile from 'models/core/mediaFile/Model.MediaFile';
import Model from 'models/Model';
import claimJobDocumentDeleteApi from './delete/Api.ClaimJobDocument.Delete';
import claimJobDocumentSaveApi from './save/Api.ClaimJobDocument.Save';

export default class ClaimJobDocument
  extends Model<Model.IClaimJobDocument>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IClaimJobDocument>
{
  id: number;
  name: string;
  notes?: string;
  approved: boolean;
  claimJobId: number;
  claimTypeDocumentId: number;

  mediaFile: MediaFile;
  formFiles?: File[];
  formFile?: File;

  constructor(
    data: Partial<Model.IClaimJobDocument> | Utils.FormData<Model.IClaimJobDocument>,
    language?: Locale.Languages
  ) {
    super({ mediaFile: MediaFile }, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.name || this.id.toString(),
    };
  }

  async save() {
    return await claimJobDocumentSaveApi(this);
  }
  async delete() {
    return await claimJobDocumentDeleteApi({ claimJobId: this.claimJobId, documentId: this.id });
  }
}
