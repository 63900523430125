import DataMap from 'assets/components/dataMap/DataMap';
import { useModalForm } from 'assets/components/form/modalForm/ModalForm';
import Icon from 'assets/components/icon/Icon';
import { inputStyles } from 'assets/components/inputs/Input';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import FileInput from 'assets/components/inputs/file/FileInput';
import ThemeFileInputDropzone from 'assets/components/inputs/file/themes/Theme.FileInput.Dropzone';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { LocationSearch } from 'assets/templates/locationSearch/LocationSearch';
import Theme from 'assets/themes/Theme.Common';
import { formatFormPhone } from 'assets/utils/forms/formatFormPhone';
import { compact, difference, intersectionBy } from 'lodash';
import User from 'models/core/user/Model.User';
import DealerGroup from 'models/dealerManagement/dealerGroup/Model.DealerGroup';
import PaymentType from 'models/enums/PaymentType';
import Distributor from 'models/sales/distributor/Model.Distributor';
import React, { useEffect } from 'react';
import { useDealerProvider } from '../../Dealer.Hooks';
import { DealerForm } from '../Dealer.Edit';
import CheckWrapper from 'assets/components/inputs/wrappers/CheckWrapper';
import MultilineTextInput from 'assets/components/inputs/multilineText/MultilineTextInput';

const DealerGeneralForm: React.FC<{
  additionalProductIds: number[];
  setAdditionalProductIds: React.Dispatch<React.SetStateAction<number[]>>;
}> = ({ additionalProductIds, setAdditionalProductIds }) => {
  const { lang } = getLocales();
  const { user, isAdministrator } = useAuthStore();
  const { form } = useModalForm<DealerForm>();
  const { selected } = useDealerProvider();
  const { payload: distributorsPayload } = useApi({ action: Distributor.list });
  const dealerGroupApi = useApi({
    action: DealerGroup.list,
    body: { distributorId: user?.distributorId ? [user?.distributorId] : undefined },
    wait: !form.data?.representativeId,
  });
  const userApi = useApi({
    action: User.list,
    body: { distributorId: form.data?.representativeId ? [form.data?.representativeId] : undefined },
    wait: !form.data?.representativeId,
  });

  useEffect(() => {
    if (!form.data.representative?.distributorProducts?.length) {
      form.update({
        representative: distributorsPayload?.data?.find(
          (d) => d.id === (form.data.representative?.id ?? form.data.representativeId)
        ) as Utils.FormData<Distributor>,
      });
    }
  }, [distributorsPayload, form.data.representative]);

  useEffect(() => {
    if (form.data.representativeId) {
      userApi.execute({
        distributorId: [form.data.representativeId],
      });
      dealerGroupApi.execute({
        distributorId: [form.data.representativeId],
      });
    }
  }, [form.data?.representativeId]);

  return (
    <FormWrapper>
      <InputWrapper>
        <TextInput
          name="name"
          label={lang.name}
          value={form.data.name}
          error={form.errors.name}
          onChange={form.update}
          icon={<Icon class="if-quill-pen" />}
        />
        <SelectInput
          name="dealerGroupId"
          label={lang.group}
          data={dealerGroupApi?.payload?.data?.map((it) => it.displayInfo)}
          value={form.data.dealerGroupId}
          error={form.errors.dealerGroupId}
          onChange={form.update}
          icon={<Icon class="fas-globe" />}
          disabled={!form.data.representativeId}
        />
      </InputWrapper>
      <SelectInput
        name="representativeId"
        label={lang.distributor}
        data={distributorsPayload?.data?.map((it) => it.displayInfo)}
        value={form.data.representativeId}
        error={form.errors.representativeId}
        onChange={({ representativeId }) => {
          const representative = distributorsPayload?.data?.find((d) => d.id === representativeId);
          form.update({
            representativeId: representative?.id,
            products: intersectionBy(
              form.data.products || [],
              representative.distributorProducts?.map((it) => it.product),
              (p) => p.id
            ),
          });
        }}
        disabled={!isAdministrator}
        icon={<Icon class="fas-globe" />}
      />
      <SelectInput
        name="mainDistributorId"
        label={lang.mainDistributorRepresentative}
        data={userApi.payload?.data?.map((item) => item.displayInfo)}
        value={form.data.mainDistributorId}
        onChange={({ mainDistributorId }) => form.update({ mainDistributorId })}
        icon={<Icon class="fas-user" />}
      />
      <CheckWrapper>
        <CheckInput
          name="isInactive"
          label={lang.isInactive}
          value={form.data.isInactive}
          error={form.errors.isInactive}
          onChange={form.update}
          class={inputStyles.check.switch}
          htmlElementProps={{ title: form.data.isInactive ? lang.disable : lang.enable }}
        />
        <CheckInput
          name="isPaymentRequired"
          label={lang.isPaymentRequired}
          value={form.data.isPaymentRequired}
          error={form.errors.isPaymentRequired}
          onChange={form.update}
          class={inputStyles.check.switch}
          htmlElementProps={{ title: form.data.isPaymentRequired ? lang.disable : lang.enable }}
        />
        <CheckInput
          name="nonUserStatementEmailEnabled"
          label={lang.isStatementAutoEmailEnabled}
          value={form.data.nonUserStatementEmailEnabled}
          error={form.errors.nonUserStatementEmailEnabled}
          onChange={form.update}
          class={inputStyles.check.switch}
          htmlElementProps={{ title: form.data.nonUserStatementEmailEnabled ? lang.disable : lang.enable }}
        />
      </CheckWrapper>
      &nbsp;
      <SelectInput
        name="products"
        label={lang.products}
        data={distributorsPayload?.data
          ?.find((d) => d.id === form.data.representativeId)
          ?.distributorProducts?.map((item) => item.displayInfo)}
        isMultiselect
        value={form.data.products?.map((it) => it.id)}
        onChange={({ products: idList }) => {
          form.update({ products: idList.map((id) => ({ id })) });
          const currentIds = compact(selected?.products?.map((p) => p.id));
          const missingIds = difference(idList, currentIds, additionalProductIds);
          if (missingIds.length) setAdditionalProductIds([...additionalProductIds, ...missingIds]);
        }}
        icon={<Icon class="fas-clipboard-list" />}
      />
      <InputWrapper>
        <TextInput
          name="primaryPhoneNumber"
          label={lang.primaryPhoneNumber}
          value={form.data.primaryPhoneNumber}
          error={form.errors.primaryPhoneNumber}
          onChange={({ primaryPhoneNumber }) =>
            form.update({ primaryPhoneNumber: formatFormPhone(form.data.primaryPhoneNumber, primaryPhoneNumber) })
          }
          icon={<Icon class="if-quill-pen" />}
        />
        <TextInput
          name="primaryEmailAddress"
          label={lang.primaryEmailAddress}
          value={form.data.primaryEmailAddress}
          error={form.errors.primaryEmailAddress}
          onChange={form.update}
          icon={<Icon class="if-envelope" />}
        />
      </InputWrapper>
      <DataMap
        data={[...(form.data?.accountingEmails || []), '']}
        render={({ data: email, index }) => (
          <TextInput
            key={index}
            name="email"
            label={lang.accountingEmails}
            value={email}
            error={form.errors?.accountingEmails}
            onChange={({ email }) => {
              let accountingEmails = form.data?.accountingEmails || [];
              accountingEmails.splice(index, 1, email);
              accountingEmails = accountingEmails.filter((it) => it);
              form.update({ accountingEmails });
            }}
            icon={<Icon class="if-envelope" />}
          />
        )}
      />
      <TextInput
        name="claimPaymentEmail"
        label={lang.claimPaymentEmail}
        value={form.data.claimPaymentEmail}
        error={form.errors.claimPaymentEmail}
        onChange={form.update}
        icon={<Icon class="if-envelope" />}
      />
      <LocationSearch
        onSelect={(location) => {
          form.update({
            streetAddress: location?.Line1 || null,
            provinceCode: location?.ProvinceCode || null,
            city: location?.City || null,
            countryCode: location?.CountryIso3 || null,
            postalCode: location?.PostalCode || null,
          });
        }}
      />
      <InputWrapper>
        <TextInput
          name="streetAddress"
          label={lang.streetAddress}
          value={form.data.streetAddress}
          error={form.errors.streetAddress}
          onChange={form.update}
          icon={<Icon class="if-map-pin" />}
          readOnly
        />
        <TextInput
          name="postalCode"
          label={lang.postalCode}
          value={form.data.postalCode}
          error={form.errors.postalCode}
          onChange={form.update}
          icon={<Icon class="if-map-pin" />}
          readOnly
        />
        <TextInput
          name="city"
          label={lang.city}
          value={form.data.city}
          error={form.errors.city}
          onChange={form.update}
          icon={<Icon class="if-map-pin" />}
          readOnly
        />
        <TextInput
          name="provinceCode"
          label={lang.provinceCode}
          value={form.data.provinceCode}
          error={form.errors.provinceCode}
          onChange={form.update}
          icon={<Icon class="if-map-pin" />}
          readOnly
          style={{ width: Theme.Size.XXL }}
        />
        <TextInput
          name="countryCode"
          label={lang.countryCode}
          value={form.data.countryCode}
          error={form.errors.countryCode}
          onChange={form.update}
          icon={<Icon class="if-map-pin" />}
          readOnly
          style={{ width: Theme.Size.XXL }}
        />
      </InputWrapper>
      <NumberInput
        name="labourRate"
        label={lang.labourRate}
        value={form.data.labourRate}
        error={form.errors.labourRate}
        onChange={form.update}
        step={0.01}
        style={{ input: { textAlign: 'right' } }}
        icon={<Icon class="icon fas-dollar-sign" />}
      />
      <SelectInput
        name="paymentMethodCode"
        label={lang.paymetMethod}
        error={form.errors.paymentMethodCode}
        data={Object.values(PaymentType).map((it) => ({
          id: it,
          title: it,
        }))}
        value={form.data?.paymentMethodCode}
        onChange={form.update}
      />
      <MultilineTextInput
        name="contractConsentAggregateDescription"
        label={lang.contractConsentAggregateDescription}
        value={form.data?.contractConsentAggregateDescription}
        onChange={form.update}
      />
      <FileInput
        name="files"
        label={lang.documents}
        value={form.data.files || []}
        onChange={form.update}
        class={ThemeFileInputDropzone}
      />
    </FormWrapper>
  );
};
export default DealerGeneralForm;
