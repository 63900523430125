import InputType from 'models/enums/InputType';
import ProductDocumentPropertyPath from 'models/enums/ProductDocumentPropertyPath';
import {
  date,
  defaultDateFormat,
  defaultDateTimeFormat,
  defaultServerDateFormat,
  defaultServerDateTimeFormat,
} from '../data/Date';
import { formatNumberWithSeparator, formatNumberWithSeparatorWithoutDecimales } from '../data/Number';
import { entries, flatten } from 'lodash';

export const productPropertyPathTypeMapping: Record<ProductDocumentPropertyPath, InputType> = {
  [ProductDocumentPropertyPath['contract.appNumber']]: InputType.text,
  [ProductDocumentPropertyPath['contract.creator']]: InputType.text,
  [ProductDocumentPropertyPath['contract.effectiveDate']]: InputType.date,
  [ProductDocumentPropertyPath['contract.expiryDate']]: InputType.date,
  [ProductDocumentPropertyPath['contract.createdAtUtc']]: InputType.date,
  [ProductDocumentPropertyPath['contract.createdAtLocal']]: InputType.date,
  [ProductDocumentPropertyPath['contract.modifiedAtUtc']]: InputType.date,
  [ProductDocumentPropertyPath['contract.termInMonths']]: InputType.number,
  [ProductDocumentPropertyPath['contract.maxKm']]: InputType.number,
  [ProductDocumentPropertyPath['contract.salePriceCAD']]: InputType.decimal,
  [ProductDocumentPropertyPath['contract.priceCAD']]: InputType.decimal,
  [ProductDocumentPropertyPath['contract.salePriceWithTaxCAD']]: InputType.decimal,
  [ProductDocumentPropertyPath['contract.totalTaxCAD']]: InputType.decimal,
  [ProductDocumentPropertyPath['contract.dealerCost']]: InputType.decimal,
  [ProductDocumentPropertyPath['contract.dealerCostTaxAmt']]: InputType.decimal,
  [ProductDocumentPropertyPath['contract.dealerCostTotal']]: InputType.decimal,
  [ProductDocumentPropertyPath['contract.dealerCost75Percent']]: InputType.decimal,
  [ProductDocumentPropertyPath['contract.dealerProfit']]: InputType.decimal,
  [ProductDocumentPropertyPath['contract.dealerProfitTaxAmt']]: InputType.decimal,
  [ProductDocumentPropertyPath['contract.coverageKm']]: InputType.number,
  [ProductDocumentPropertyPath['contract.customInfo']]: InputType.text,
  [ProductDocumentPropertyPath['contract.customInfoGroup']]: InputType.text,
  [ProductDocumentPropertyPath['contract.coApplicantFirstName']]: InputType.text,
  [ProductDocumentPropertyPath['contract.coApplicantLastName']]: InputType.text,
  [ProductDocumentPropertyPath['contract.warrantyOption']]: InputType.text,
  [ProductDocumentPropertyPath['contract.postSale']]: InputType.boolean,
  [ProductDocumentPropertyPath['contract.deductible']]: InputType.number,
  [ProductDocumentPropertyPath['contract.subfees']]: InputType.json,
  [ProductDocumentPropertyPath['contract.vehicle.vin']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.year']]: InputType.number,
  [ProductDocumentPropertyPath['contract.vehicle.makeCode']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.model']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.bodyType']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.bodyColor']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.posOdometerReading']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.stockNumber']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.engineSize']]: InputType.number,
  [ProductDocumentPropertyPath['contract.vehicle.purchaseDate']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.deliveryDate']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.classDate']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.purchasePrice']]: InputType.decimal,
  [ProductDocumentPropertyPath['contract.vehicle.inServiceDate']]: InputType.date,
  [ProductDocumentPropertyPath['contract.vehicle.mfgPowertrainWarrantyMonths']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.mfgPowertrainWarrantyKm']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.mfgWholeVehicleWarrantyMonths']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.mfgWholeVehicleWarrantyKm']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.trimLevel']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.engineType']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.engineInfo']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.driveLine']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.purchaseState']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.purchaseType']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.financedAmount']]: InputType.decimal,
  [ProductDocumentPropertyPath['contract.vehicle.interestRate']]: InputType.decimal,
  [ProductDocumentPropertyPath['contract.vehicle.lender.id']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.lender.phoneNumber']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.lender.email']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.lender.name']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.lender.streetAddress']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.lender.postalCode']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.lender.muncipality']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.lender.provinceCode']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.lender.city']]: InputType.text,
  [ProductDocumentPropertyPath['contract.vehicle.lender.countryCode']]: InputType.text,
  [ProductDocumentPropertyPath['contract.dealer.id']]: InputType.text,
  [ProductDocumentPropertyPath['contract.dealer.name']]: InputType.text,
  [ProductDocumentPropertyPath['contract.dealer.primaryPhoneNumber']]: InputType.text,
  [ProductDocumentPropertyPath['contract.dealer.primaryEmailAddress']]: InputType.text,
  [ProductDocumentPropertyPath['contract.dealer.streetAddress']]: InputType.text,
  [ProductDocumentPropertyPath['contract.dealer.postalCode']]: InputType.text,
  [ProductDocumentPropertyPath['contract.dealer.muncipality']]: InputType.text,
  [ProductDocumentPropertyPath['contract.dealer.city']]: InputType.text,
  [ProductDocumentPropertyPath['contract.dealer.provinceCode']]: InputType.text,
  [ProductDocumentPropertyPath['contract.dealer.countryCode']]: InputType.text,
  [ProductDocumentPropertyPath['contract.client.email']]: InputType.text,
  [ProductDocumentPropertyPath['contract.client.firstName']]: InputType.text,
  [ProductDocumentPropertyPath['contract.client.lastName']]: InputType.text,
  [ProductDocumentPropertyPath['contract.client.phone']]: InputType.text,
  [ProductDocumentPropertyPath['contract.client.streetAddress']]: InputType.text,
  [ProductDocumentPropertyPath['contract.client.postalCode']]: InputType.text,
  [ProductDocumentPropertyPath['contract.client.muncipality']]: InputType.text,
  [ProductDocumentPropertyPath['contract.client.provinceCode']]: InputType.text,
  [ProductDocumentPropertyPath['contract.client.countryCode']]: InputType.text,
  [ProductDocumentPropertyPath['contract.client.city']]: InputType.text,
  [ProductDocumentPropertyPath['contract.client.taxExempt']]: InputType.boolean,
  [ProductDocumentPropertyPath['contract.client.taxExemptionBand']]: InputType.text,
  [ProductDocumentPropertyPath['contract.product.code']]: InputType.text,
  [ProductDocumentPropertyPath['contract.product.name']]: InputType.text,
  [ProductDocumentPropertyPath['contract.product.productType']]: InputType.text,
  [ProductDocumentPropertyPath['contract.product.canSellPostSale']]: InputType.boolean,
  [ProductDocumentPropertyPath['contract.product.category.code']]: InputType.text,
  [ProductDocumentPropertyPath['contract.product.category.name']]: InputType.text,
};
const packagePropertyPaths = [
  ProductDocumentPropertyPath['contract.salePriceCAD'],
  ProductDocumentPropertyPath['contract.totalTaxCAD'],
  ProductDocumentPropertyPath['contract.salePriceWithTaxCAD'],
];
const taxPropertyPaths = [ProductDocumentPropertyPath['contract.totalTaxCAD']];

type ConvertProductPropertyByInputTypeProps = {
  propertyPath?: ProductDocumentPropertyPath;
  inputType: InputType;
  inputLabel: string;
  value: any;
  options: {
    isPackage?: boolean;
    isTaxExempt?: boolean;
  };
};
function convertObjectToKeyValuePairList(obj: object) {
  return entries(obj).map(([k, v]) => `${k}:${v}`);
}
function checkIfValueExists(value: any, inputLabel: string) {
  return Array.isArray(value) ? value.some((it) => it?.toString() === inputLabel) : inputLabel === value?.toString();
}

export const convertProductPropertyByInputType = ({
  propertyPath,
  value,
  inputLabel,
  inputType,
  options,
}: ConvertProductPropertyByInputTypeProps) => {
  const propertyInputType = propertyPath && productPropertyPathTypeMapping[propertyPath];
  if (value == null) return '';
  if (options.isPackage && packagePropertyPaths.some((it) => it === propertyPath)) {
    value = 'PKG';
  } else if (options.isTaxExempt && taxPropertyPaths.some((it) => it === propertyPath)) {
    value = 'EXEMPT';
  } else if (propertyInputType) {
    const isPropertyTypeDate = propertyInputType === InputType.date || propertyInputType === InputType.dateTime;
    const isPropertyTypeDifferent = inputType && inputType !== propertyInputType;
    if (typeof value === 'object') {
      value = Array.isArray(value)
        ? flatten(value.map(convertObjectToKeyValuePairList))
        : convertObjectToKeyValuePairList(value);
    }
    if (!isPropertyTypeDate && isPropertyTypeDifferent) {
      switch (inputType) {
        case InputType.boolean:
          value = checkIfValueExists(value, inputLabel);
          break;
        case InputType.number:
          value = checkIfValueExists(value, inputLabel) ? 1 : 0;
          break;
        case InputType.decimal:
          value = checkIfValueExists(value, inputLabel) ? 1 : 0;
          break;
      }
    } else if (isPropertyTypeDate) {
      const d = date(value, InputType.date ? defaultServerDateFormat : defaultServerDateTimeFormat);
      switch (inputType) {
        case InputType.year:
          value = d.format('YYYY');
          break;
        case InputType.month:
          value = d.format('MM');
          break;
        case InputType.day:
          value = d.format('DD');
          break;
        case InputType.number:
          value = d.valueOf();
          break;
        case InputType.decimal:
          value = d.valueOf();
          break;
        case InputType.date:
          value = d.format(defaultDateFormat);
          break;
        case InputType.dateTime:
          value = d.format(defaultDateTimeFormat);
          break;
      }
    }
  } else if ((inputType === InputType.month || inputType === InputType.day) && value.toString().length === 1) {
    value = `0${value}`;
  }
  if ((typeof value == 'number' || !isNaN(Number(value))) && InputType.price === inputType) {
    return formatNumberWithSeparator(value);
  } else if ((typeof value == 'number' || !isNaN(Number(value))) && InputType.formattedNumber === inputType) {
    return formatNumberWithSeparatorWithoutDecimales(Number(value));
  } else if ((typeof value == 'number' || !isNaN(Number(value))) && InputType.formattedDecimal === inputType) {
    return formatNumberWithSeparator(Number(value));
  }
  return typeof value === 'boolean' ? (value ? 'X' : '') : value?.toString() || '';
};
