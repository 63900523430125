import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import DataMap from 'assets/components/dataMap/DataMap';
import ThemeTableSimple from 'assets/components/table/themes/Theme.Table.Simple';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import ClaimJobItem from 'models/claims/claimJobItem/Model.ClaimJobItem';
import { CountryCode, CountryCodeFallback } from 'models/enums/CountryCode';
import LineItem from 'models/enums/LineItem';
import PaymentStatus from 'models/enums/PaymentStatus';
import ClaimEditJobItem, { addRowButtonStyles } from '../ClaimEdit.Job.Item';
import { ClaimJobTableProps } from '../../ClaimEdit.Job.Hooks';

const ClaimEditJobOtherTable = ({
  items,
  lossCodes,
  isWarrantyProduct,
  isAddDisabled,
  currency,
  updateJobItem,
  removeJobItem,
}: ClaimJobTableProps) => {
  const { lang } = getLocales();
  const countryCode =
    currency === 'USD' ? CountryCode.USA : currency === 'CAD' ? CountryCode.CAN : CountryCodeFallback.CA;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ marginLeft: Theme.Size.XL, marginRight: Theme.Size.XL }}>
        <h4>{lang.other}</h4>
      </div>
      <table className={ThemeTableSimple} style={{ fontSize: Theme.FontSize.S }}>
        <thead>
          <tr>
            <th>#</th>
            <th style={{ textAlign: 'center' }}>{lang.payee}</th>
            <th style={{ textAlign: 'center' }}>{lang.lossCode}</th>
            <th style={{ textAlign: 'center' }}>{lang.status}</th>
            <th style={{ textAlign: 'center' }}>Requested amt</th>
            <th style={{ textAlign: 'center' }}>Adjusted amt</th>
            <th style={{ textAlign: 'center' }}>
              {(countryCode === CountryCode.CAN || countryCode === CountryCodeFallback.CA) ? 'GST/HST' : 'Tax'}
            </th>
            {(countryCode === CountryCode.CAN || countryCode === CountryCodeFallback.CA) && (
              <th style={{ textAlign: 'center' }}>PST</th>
            )}
            <th style={{ textAlign: 'center' }}>{lang.total}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <DataMap
            data={items}
            render={({ data: { item, index }, index: ordinalNumber }) => (
              <ClaimEditJobItem
                key={index}
                index={index}
                ordinalNumber={ordinalNumber}
                data={{ ...item, isWarrantyProduct }}
                lossCodes={lossCodes}
                onDelete={(data) => removeJobItem(data, index)}
                onUpdate={(data) => updateJobItem({ ...item, ...data }, index)}
              />
            )}
          />
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={12}>
              <Button
                class={ThemeButtonCircle}
                style={addRowButtonStyles}
                media="fas-plus"
                onClick={() =>
                  updateJobItem(
                    new ClaimJobItem({
                      jobItemTypeCode: LineItem.other,
                      statusCode: PaymentStatus.unpaid,
                      currency,
                      adjudication: 0.0,
                      correction: 0.0,
                      claimJobItemPayee: {
                        payee: { countryCode },
                      } as Model.IClaimJobItemPayee,
                    }).toJSON()
                  )
                }
                disabled={isAddDisabled}
                render={lang.other}
                htmlElementProps={{
                  title: 'Add new other item',
                }}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default ClaimEditJobOtherTable;
