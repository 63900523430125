import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import { inputStyles } from 'assets/components/inputs/Input';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import { getLocales } from 'assets/locales/Locale';
import ClaimTypeDataPointInputType from 'models/enums/ClaimTypeDataPointInputType';

type Props = {
  data: Partial<Model.IClaimTypeDataPoint>;
  onSave(data: Model.IClaimTypeDataPoint): void;
  onCancel(): void;
};
export default function ClaimTypeDataPointEditForm(props: Props) {
  const { lang } = getLocales();

  return (
    <>
      <ModalForm<Model.IClaimTypeDataPoint>
        subcomponents={ThemeModalFormDefault}
        dependencyArray={[props.data]}
        title={lang.documents}
        default={props.data || {}}
        onCancel={props.onCancel}
        onSubmit={props.onSave}
        validation={(data, errors) => {
          if (!data.name) errors.name = lang.mustNotBeEmpty;
          if (!data.type) errors.type = lang.mustNotBeEmpty;
        }}
        render={({ form }) => (
          <FormWrapper>
            <TextInput
              name="name"
              label={lang.name}
              value={form.data.name}
              onChange={form.update}
              icon={<Icon class="if-quill-pen" />}
            />
            <SelectInput
              name="type"
              label={lang.type}
              data={Object.values(ClaimTypeDataPointInputType).map((it) => ({ id: it, title: it }))}
              value={form.data.type}
              onChange={form.update}
            />
            <CheckInput
              name="isRequired"
              label={lang.isRequired}
              class={inputStyles.check.switch}
              value={form.data.isRequired}
              onChange={form.update}
              htmlElementProps={{ title: form.data.isRequired ? lang.disable : lang.enable }}
            />
          </FormWrapper>
        )}
      />
    </>
  );
}
