import { getLocales } from 'assets/locales/Locale';
import { Dictionary, first, groupBy } from 'lodash';
import User from 'models/core/user/Model.User';
import LineItem from 'models/enums/LineItem';
import PaymentStatus from 'models/enums/PaymentStatus';
import Model from 'models/Model';
import Claim from '../claim/Model.Claim';
import ClaimJobDocument from '../claimJobDocument/ClaimJobDocument';
import ClaimJobItem from '../claimJobItem/Model.ClaimJobItem';
import ClaimJobReport from '../claimJobReport/ClaimJobReport';
import ClaimType from '../claimType/Model.ClaimType';
import claimJobChangeOwnershipApi from './changeOwnership/Api.ClaimJob.ChangeOwnership';
import claimJobDeleteApi from './delete/Api.ClaimJob.Delete';
import claimJobFindApi from './find/Api.ClaimJob.Find';
import claimJobListApi from './list/Api.ClaimJob.List';
import claimJobSaveApi from './save/Api.ClaimJob.Save';
import claimJobSetStatusApi from './setStatus/Api.ClaimJob.SetStatus';
import claimJobTakeOwnershipApi from './takeOwnership/Api.ClaimJob.TakeOwnership';
import { CountryCode, CountryCodeFallback } from 'models/enums/CountryCode';
import PaymentType from 'models/enums/PaymentType';

export default class ClaimJob
  extends Model<Model.IClaimJob>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IClaimJob>
{
  id: number;
  claimId: number;
  claim: Claim;
  jobNumber: number;
  statusCode?: Model.Enum.ClaimStatusCode;
  customerComplaint?: string;
  cause?: string;
  correction?: string;
  currency?: string;
  claimType: ClaimType;
  claimTypeId: number;
  ownerId?: number;
  owner?: User;
  claimJobItems: ClaimJobItem[];
  claimJobDocuments?: ClaimJobDocument[];
  claimJobReports?: ClaimJobReport[];
  dataPoints?: Model.IClaimJobDataPoint[];
  createdAtUtc?: string;
  modifiedAtUtc?: string;

  static aggregates(job: ClaimJob) {
    const groupedItems: Dictionary<ClaimJobItem[]> = groupBy(job?.claimJobItems || [], (it) =>
      it.claimJobItemPayee?.payeeId
        ? `${it.claimJobItemPayee?.payeeId}_payee`
        : it.claimJobItemPayee?.clientId
        ? `${it.claimJobItemPayee?.clientId}_client`
        : it.claimJobItemPayee?.dealerId
        ? `${it.claimJobItemPayee?.dealerId}_dealer`
        : null
    );
    return Object.entries(groupedItems).map(([key, items]) => {
      const payee = first(items).claimJobItemPayee;
      const defaultPaymentMethodCode = payee?.payee?.paymentMethodCode || payee?.dealer?.paymentMethodCode;
      const payeeCountryCode =
        first(items)?.claimJobItemPayee?.payee?.countryCode ??
        first(items)?.claimJobItemPayee?.client?.countryCode ??
        first(items)?.claimJobItemPayee?.dealer?.countryCode ??
        CountryCode.CAN;

      return {
        key,
        jobItemId: first(items)?.id,
        jobId: job.id,
        payee,
        contactEmail: payee?.client?.email || payee?.dealer?.primaryEmailAddress || payee?.payee?.email,
        jobItemTypeCode: first(items).jobItemTypeCode,
        currency: payeeCountryCode === CountryCode.CAN || payeeCountryCode === CountryCodeFallback.CA ? 'CAD' : 'USD',
        paymentMethodCode: first(items)?.paymentMethodCode ?? defaultPaymentMethodCode,
        parts: items
          .filter((it) => it.jobItemTypeCode === LineItem.part)
          ?.reduce((prev, curr) => prev + (curr.adjudication || 0), 0.0),
        labour: items
          .filter((it) => it.jobItemTypeCode === LineItem.labour)
          ?.reduce((prev, curr) => prev + (curr.adjudication || 0), 0.0),
        other: items
          .filter((it) => it.jobItemTypeCode === LineItem.other)
          ?.reduce((prev, curr) => prev + (curr.adjudication || 0), 0.0),
        deduct: items
          .filter((it) => it.jobItemTypeCode === LineItem.deductible)
          ?.reduce((prev, curr) => prev + (curr.adjudication || 0), 0.0),
        get ttl() {
          return this.parts + this.labour + this.other - this.deduct;
        },
        gstHst: items?.reduce((prev, curr) => prev + curr.adjudication * curr.gstHst || 0, 0.0),
        provincialSalesTax: items?.reduce((prev, curr) => prev + curr.adjudication * curr.provincialSalesTax || 0, 0.0),
        get totalTaxAmount() {
          return this.gstHst + this.provincialSalesTax;
        },
        get total() {
          return this.ttl + this.totalTaxAmount;
        },
        pendingPaymentAmount: items?.reduce((prev, curr) => {
          const usBankPendingAmount =
            prev +
            (curr.statusCode !== PaymentStatus.paid && curr.usBankVirtualCardId
              ? curr.total * (curr.jobItemTypeCode !== LineItem.deductible ? 1 : -1)
              : 0);
          const eftOrChequePendingAmount =
            prev +
            (curr.statusCode !== PaymentStatus.paid
              ? curr.total * (curr.jobItemTypeCode !== LineItem.deductible ? 1 : -1)
              : 0);

          return curr.paymentMethodCode === PaymentType.cheque || curr.paymentMethodCode === PaymentType.eft
            ? eftOrChequePendingAmount
            : curr.paymentMethodCode === PaymentType.usBank
            ? usBankPendingAmount
            : 0;
        }, 0.0),
        paidAmount: items?.reduce((prev, curr) => {
          const usBankPendingAmount =
            prev +
            (curr.statusCode === PaymentStatus.paid && curr.usBankVirtualCardId
              ? curr.total * (curr.jobItemTypeCode !== LineItem.deductible ? 1 : -1)
              : 0);
          const eftOrChequePendingAmount =
            prev +
            (curr.statusCode === PaymentStatus.paid
              ? curr.total * (curr.jobItemTypeCode !== LineItem.deductible ? 1 : -1)
              : 0);

          return curr.paymentMethodCode === PaymentType.cheque || curr.paymentMethodCode === PaymentType.eft
            ? eftOrChequePendingAmount
            : curr.paymentMethodCode === PaymentType.usBank
            ? usBankPendingAmount
            : 0;
        }, 0.0),
        usBankVirtualCardId: first(items)?.usBankVirtualCardId,
      };
    });
  }

  constructor(data: Partial<Model.IClaimJob> | Utils.FormData<Model.IClaimJob>, language?: Locale.Languages) {
    super(
      {
        claimType: ClaimType,
        owner: User,
        claimJobItems: [ClaimJobItem],
        claimJobDocuments: [ClaimJobDocument],
        claimJobReports: [ClaimJobReport],
        claim: Claim,
      },
      language
    );
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    const { lang } = getLocales();
    return {
      id: this.id,
      info: this.statusCode?.replace(/_/g, ' - ') || lang.noStatusDefined,
      title: this.claimType?.displayInfo?.title,
      subtitle: this.owner?.displayInfo?.title || lang.unknownUser,
    };
  }
  static async list(body?: Api.Core.ClaimJob.List.IRequest) {
    const { payload, ...rest } = await claimJobListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new ClaimJob(it)),
        count: payload.count,
      },
    };
  }

  static async find(params: { id: number }) {
    const { payload, ...rest } = await claimJobFindApi(params);
    return {
      ...rest,
      payload: new ClaimJob(payload),
    };
  }
  static async save(data: Api.Core.ClaimJob.Save.IRequest) {
    return await claimJobSaveApi(data);
  }
  async delete() {
    return await claimJobDeleteApi({ id: this.id });
  }

  static async setStatus(body: Api.Core.ClaimJob.SetStatus.IRequest) {
    return await claimJobSetStatusApi(body);
  }
  static async changeOwnership(body: Api.Core.ClaimJob.ChangeOwnership.IRequest) {
    return await claimJobChangeOwnershipApi(body);
  }
  static async takeOwnership(body: Api.Core.ClaimJob.TakeOwnership.IRequest) {
    return await claimJobTakeOwnershipApi(body);
  }
}
