import { ApiHeaderApplication } from 'config/Api.Config';
import { ClaimCreationType } from 'models/enums/ClaimCreation';

export function getClaimCreatedByApp(claim: Model.IClaim) {
  return claim?.createdFromApplicationId === ApiHeaderApplication.public
    ? ClaimCreationType.PUBLIC
    : claim?.createdFromApplicationId === ApiHeaderApplication.claims
    ? ClaimCreationType.ASC
    : 'N/A';
}
