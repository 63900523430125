import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import Icon from 'assets/components/icon/Icon';
import MultilineTextInput from 'assets/components/inputs/multilineText/MultilineTextInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { cloneDeep } from 'lodash';
import Product from 'models/productManagement/product/Model.Product';
import { useClaimTypeProvider } from '../ClaimType.Hooks';
import ClaimTypeEditReportSettings from './edit/ClaimType.Edit.ReportSettings';
import ClaimTypeEditAggregates from './edit/ClaimType.Edit.Aggregates';
import ClaimTypeEditDocuments from './edit/documents/ClaimType.Edit.Documents';
import ClaimTypeEditLossCodes from './edit/ClaimType.Edit.LossCodes';
import ClaimTypeEditDataPoints from './edit/dataPoints/ClaimType.Edit.DataPoints';

export default function ClaimTypeEdit() {
  const { lang } = getLocales();
  const { selected, pageRouter, views, saveApi } = useClaimTypeProvider();
  const { payload: productsPayload } = useApi({ action: Product.list });

  return (
    <>
      <ModalForm<Api.Core.ClaimType.Save.IRequest>
        subcomponents={ThemeModalFormDefault}
        dependencyArray={[selected, views.copy]}
        title={lang.claimType}
        default={{
          ...(views.copy ? { ...selected?.toJSON(), id: undefined, code: undefined } : selected),
          lossCodes: selected?.lossCodes?.map((it) => it.toJSON()) || [],
          claimTypeReportSettings: selected?.claimTypeReportSettings?.map((it) => it.toJSON()) || [],
          claimTypeAggregates: selected?.claimTypeAggregates?.map((it) => it.toJSON()) || [],
          claimTypeDataPoints: selected?.claimTypeDataPoints?.map((it) => it.toJSON()) || [],
          claimTypeDocuments: selected?.claimTypeDocuments?.map((it) => it.toJSON()) || [],
        }}
        onCancel={pageRouter.updateParams}
        onSubmit={(data) => {
          const finalData = cloneDeep(data);
          finalData.claimTypeAggregates?.forEach((it) => {
            delete it.id;
            delete it.version;
          });
          finalData.claimTypeDocuments?.forEach((it) => {
            delete it.id;
            delete it.version;
          });
          finalData.claimTypeReportSettings?.forEach((it) => {
            delete it.id;
            delete it.version;
            it.infoInputs = JSON.stringify(it.infoInputs) as any;
            it.reportRows = JSON.stringify(it.reportRows) as any;
          });
          finalData.claimTypeDataPoints?.forEach((it) => {
            delete it.id;
            delete it.version;
          });
          saveApi.execute(finalData);
        }}
        validation={(data, errors) => {
          if (!data.name) errors.name = lang.mustNotBeEmpty;
          if (!data.code) errors.code = lang.mustNotBeEmpty;
          if (data.claimTypeReportSettings?.some((it) => !it.name)) {
            if (!errors.claimTypeReportSettings) errors.claimTypeReportSettings = {};
            errors.claimTypeReportSettings.name = lang.mustNotBeEmpty;
          }
          if (!data.product) errors.product = { _objectError: lang.mustNotBeEmpty };
        }}
        tabs={[
          { id: 'info', icon: 'fas-info-circle', text: lang.info },
          { id: 'reportSettings', icon: 'fas-file-contract', text: lang.report },
          { id: 'aggregates', icon: 'fas-layer-group', text: lang.aggregate },
          { id: 'documents', icon: 'fas-file-alt', text: lang.documents },
          { id: 'lossCodes', icon: 'fas-list', text: lang.lossCode },
          { id: 'dataPoints', icon: 'fas-table', text: lang.dataPoints },
        ]}
        render={({ form, activeTab }) =>
          activeTab.id == 'info' ? (
            <FormWrapper>
              <TextInput
                name="name"
                label={lang.name}
                value={form.data.name}
                error={form.errors.name}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <TextInput
                name="code"
                label={lang.code}
                value={form.data.code}
                error={form.errors.code}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <MultilineTextInput
                name="description"
                label={lang.description}
                value={form.data.description}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <SelectInput
                name="product"
                label={lang.product}
                data={productsPayload?.data?.map((item) => ({
                  ...item.displayInfo,
                  icon: <Icon class="fas-box" />,
                }))}
                error={form.errors?.product?._objectError}
                value={form.data.product?.id}
                onChange={({ product: id }) =>
                  form.update({
                    product: { id },
                    claimTypeAggregates:
                      productsPayload.data
                        ?.find((p) => p.id === id)
                        ?.insurers?.map<Model.IClaimTypeAggregate>((insurer, index) => ({
                          id: -1 * index,
                          version: 1,
                          totalAggregate: 0,
                          totalAggregatePriority: 0,
                          maxClaimAmount: 0,
                          maxClaimAmountPriority: 0,
                          maxNumberOfClaims: 0,
                          maxNumberOfClaimsPriority: 0,
                          houseAmount: 0,
                          insurerAmount: 0,
                          houseOpeningNotice: false,
                          insurerOpeningNotice: false,
                          allowOverride: false,
                          insurer: insurer.toJSON(),
                          insurerId: insurer.id,
                        })) || [],
                  })
                }
                icon={<Icon class="if-box" />}
              />
            </FormWrapper>
          ) : activeTab.id == 'reportSettings' ? (
            <ClaimTypeEditReportSettings />
          ) : activeTab.id == 'aggregates' ? (
            <ClaimTypeEditAggregates />
          ) : activeTab.id == 'documents' ? (
            <ClaimTypeEditDocuments />
          ) : activeTab.id == 'lossCodes' ? (
            <ClaimTypeEditLossCodes />
          ) : activeTab.id == 'dataPoints' ? (
            <ClaimTypeEditDataPoints />
          ) : null
        }
      />
    </>
  );
}
