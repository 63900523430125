import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import DataMap from 'assets/components/dataMap/DataMap';
import useForm from 'assets/components/form/hooks/Form';
import Icon from 'assets/components/icon/Icon';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import FileInput from 'assets/components/inputs/file/FileInput';
import ThemeFileInputDropzone from 'assets/components/inputs/file/themes/Theme.FileInput.Dropzone';
import { inputStyles } from 'assets/components/inputs/Input';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import ThemeWrapperPaper from 'assets/themes/wrappers/Theme.Wrapper.Paper';
import InputType from 'models/enums/InputType';
import ProductDocumentPropertyPath from 'models/enums/ProductDocumentPropertyPath';
import { Fragment, useEffect, useState } from 'react';
import { useProductProvider } from '../../Product.Hooks';
import ProductDocumentPreview from './Product.DocumentPreview';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import DateInput from 'assets/components/inputs/dateInput/DateInput';
import { defaultDateFormat, defaultDateTimeFormat } from 'assets/utils/data/Date';
import MultilineTextInput from 'assets/components/inputs/multilineText/MultilineTextInput';
import ProductDocumentSignerSource from 'models/enums/ProductDocumentSignerSource';
import SignatureType from 'models/enums/SignatureType';
import { combineStrings } from 'assets/utils/data/String';

type AdditionalFieldProps = {
  additionalField: Utils.FormData<Model.IProductAdditionalField>;
  onValueChange(data: Partial<Model.IProductAdditionalField>): void;
};
function DefaultValueInput({ onValueChange, additionalField }: AdditionalFieldProps) {
  const { lang } = getLocales();
  return (
    <>
      {additionalField.type === InputType.text && (
        <TextInput
          name="defaultValue"
          label={lang.default}
          value={typeof additionalField?.defaultValue === 'string' ? additionalField?.defaultValue : null}
          onChange={onValueChange}
        />
      )}
      {additionalField.type === InputType.longText && (
        <MultilineTextInput
          name="defaultValue"
          label={lang.default}
          value={typeof additionalField?.defaultValue === 'string' ? additionalField?.defaultValue : null}
          onChange={onValueChange}
        />
      )}
      {additionalField.type === InputType.boolean && (
        <CheckInput
          name="defaultValue"
          label={lang.default}
          value={typeof additionalField?.defaultValue === 'boolean' ? additionalField?.defaultValue : null}
          onChange={onValueChange}
          class={inputStyles.check.switch}
        />
      )}
      {(additionalField.type === InputType.date || additionalField.type === InputType.dateTime) && (
        <DateInput
          name="defaultValue"
          label={lang.default}
          value={typeof additionalField?.defaultValue === 'string' ? additionalField?.defaultValue : null}
          onChange={onValueChange}
          dateTimeFormat={additionalField.type === InputType.dateTime ? defaultDateTimeFormat : defaultDateFormat}
        />
      )}
      {(additionalField.type === InputType.number || additionalField.type === InputType.decimal) && (
        <NumberInput
          name="defaultValue"
          label={lang.default}
          value={typeof additionalField?.defaultValue === 'number' ? additionalField?.defaultValue : null}
          step={additionalField.type === InputType.number ? 1 : 0.01}
          onChange={onValueChange}
        />
      )}
      {additionalField.type === InputType.year && (
        <NumberInput
          name="defaultValue"
          label={lang.default}
          value={typeof additionalField?.defaultValue === 'number' ? additionalField?.defaultValue : null}
          step={1}
          min={1000}
          max={9999}
          onChange={onValueChange}
        />
      )}
      {additionalField.type === InputType.month && (
        <NumberInput
          name="defaultValue"
          label={lang.default}
          value={typeof additionalField?.defaultValue === 'number' ? additionalField?.defaultValue : null}
          step={1}
          min={1}
          max={12}
          onChange={onValueChange}
        />
      )}
      {additionalField.type === InputType.day && (
        <NumberInput
          name="defaultValue"
          label={lang.default}
          value={typeof additionalField?.defaultValue === 'number' ? additionalField?.defaultValue : null}
          step={1}
          min={1}
          max={31}
          onChange={onValueChange}
        />
      )}
    </>
  );
}
export default function ProductEditDocuments() {
  const { lang, translate } = getLocales();
  const { form, uploadedDocuments, newDocuments, product, uploadFile, deleteFile } = useProductProvider();

  const [productDocumentType, setProductDocumentType] = useState<Model.Enum.EngineType>('Internal Combustion');
  const docForm = useForm<Utils.FormData<Model.IProductDocument>>({
    default: {},
    onSubmit: (document) => {
      const index = form.data.productDocuments?.findIndex((p) => p.id === document.id);
      form.data.productDocuments.splice(index, 1, document);
      form.update({
        productDocuments: [...form.data.productDocuments],
      });
      docForm.reload();
    },
  });

  useEffect(() => {
    if (docForm.data.productAdditionalFields) return;
    const doc = product?.productDocuments?.find((d) => d.id === docForm.data.id);
    if (doc) docForm.set(doc);
  }, [product, docForm.data.id]);

  function updateAdditionalField(additionalField: Utils.FormData<Model.IProductAdditionalField>) {
    const index = docForm.data.productAdditionalFields?.findIndex((p) => p.id === additionalField.id);
    docForm.data.productAdditionalFields.splice(index, 1, additionalField);
    docForm.update({
      productAdditionalFields: [...docForm.data.productAdditionalFields],
    });
  }

  function updateSignatureField(signatureField: Utils.FormData<Model.IProductSignatureField>, remove?: boolean) {
    const index = docForm.data.productSignatureFields?.findIndex((p) => p.key === signatureField.key);
    if (remove) {
      if (index >= 0) docForm.data.productSignatureFields.splice(index, 1);
    } else if (index >= 0) {
      docForm.data.productSignatureFields.splice(index, 1, signatureField);
    } else {
      docForm.data.productSignatureFields.push(signatureField);
    }

    docForm.update({
      productSignatureFields: [...docForm.data.productSignatureFields],
    });
  }
  const additionalFields = docForm.data.productAdditionalFields?.filter((f) => f.key);
  const signatureFields = docForm.data.productSignatureFields?.filter((f) => f.key);
  return (
    <>
      <ProductDocumentPreview
        children={
          <>
            <div
              style={{
                fontSize: Theme.FontSize.L,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {lang.documents}
            </div>
            <div
              style={{
                padding: Theme.Size.M,
              }}
            >
              <CheckInput
                onChange={({ isForElectricVehicle }) => {
                  setProductDocumentType(isForElectricVehicle ? 'Electric' : 'Internal Combustion');
                }}
                name="isForElectricVehicle"
                label="Is for electric vehicle"
                class={inputStyles.check.switch}
                value={productDocumentType === 'Electric'}
              />
              <br />
              <FileInput
                name="file"
                fileLimit={1}
                onChange={async ({ file }) => {
                  const formFile = file && file[0]?.original;
                  uploadFile({ formFile, productDocumentType });
                  docForm.update({ formFile });
                }}
                class={ThemeFileInputDropzone}
                error={docForm.errors.formFile?._objectError}
                disabled={!form.data?.id}
              />
            </div>
          </>
        }
        documents={[
          ...uploadedDocuments.map((it) => ({
            id: it.id,
            url: it.mediaFile.url,
            name: it.mediaFile.name,
            info: lang.uploaded,
            description: it.productDocumentType,
            isEditing: docForm.data.id === it.id,
            onClick: () => (docForm.data.id === it.id ? docForm.reload() : docForm.set(it)),
            onDelete: () => deleteFile(it.id),
          })),
          ...newDocuments.map((it) => ({
            id: it.id,
            url: it.mediaFile?.url,
            name: it.mediaFile?.name || lang.unknownDocument,
            info: lang.new,
          })),
        ]}
      />
      <br />
      {additionalFields && (
        <FormWrapper
          class={ThemeWrapperPaper}
          children={
            <>
              <h2>{docForm.data.mediaFile?.name}</h2>
              <DataMap
                data={additionalFields}
                render={({ data: additionalField }) => {
                  const signatureField = signatureFields?.find((s) => s.key === additionalField.key);
                  const fieldType = signatureField ? 'signature' : additionalField.isDisabled ? 'disabled' : 'enabled';
                  return (
                    <Fragment key={additionalField.id}>
                      <SelectInput
                        name="type"
                        data={[
                          { id: 'enabled', title: combineStrings(' | ', additionalField.key, lang.enabled) },
                          { id: 'disabled', title: combineStrings(' | ', additionalField.key, lang.disabled) },
                          { id: 'signature', title: combineStrings(' | ', additionalField.key, lang.signature) },
                        ]}
                        value={fieldType}
                        style={{
                          input:
                            fieldType === 'enabled'
                              ? {
                                  backgroundColor: Theme.Color.success,
                                  color: 'white',
                                }
                              : fieldType === 'disabled'
                              ? {
                                  backgroundColor: Theme.Color.error,
                                  color: 'white',
                                }
                              : {
                                  backgroundColor: Theme.Color.primary,
                                  color: 'white',
                                },
                        }}
                        onChange={({ type }) => {
                          if (type === 'enabled' || !type) {
                            updateAdditionalField({ ...additionalField, isDisabled: false });
                            updateSignatureField(signatureField, true);
                          } else if (type === 'disabled') {
                            updateAdditionalField({ ...additionalField, isDisabled: true });
                            updateSignatureField(signatureField, true);
                          } else {
                            updateAdditionalField({ ...additionalField, isDisabled: true });
                            updateSignatureField({
                              key: additionalField.key,
                              signerSource: ProductDocumentSignerSource['contract.client.email'],
                              routingOrder: 1,
                              productDocumentId: docForm.data.id,
                            });
                          }
                        }}
                      />
                      {signatureField && (
                        <InputWrapper>
                          <SelectInput
                            name="signerSource"
                            label={lang.signerSource}
                            data={Object.values(ProductDocumentSignerSource).map((val) => ({ id: val, title: val }))}
                            value={signatureField.signerSource}
                            onChange={(updated) => updateSignatureField({ ...signatureField, ...updated })}
                          />
                          <SelectInput
                            name="type"
                            label={lang.type}
                            data={Object.values(SignatureType).map((val) => ({ id: val, title: translate(val) }))}
                            value={signatureField.type}
                            onChange={(updated) => updateSignatureField({ ...signatureField, ...updated })}
                          />
                          <NumberInput
                            name="routingOrder"
                            label={lang.routingOrder}
                            value={signatureField.routingOrder}
                            onChange={(updated) => updateSignatureField({ ...signatureField, ...updated })}
                          />
                        </InputWrapper>
                      )}
                      {!additionalField.isDisabled && (
                        <>
                          <InputWrapper>
                            <SelectInput
                              name="propertyPath"
                              label={lang.property}
                              data={Object.values(ProductDocumentPropertyPath).map((val) => ({ id: val, title: val }))}
                              value={additionalField.propertyPath}
                              onChange={(updated) =>
                                updateAdditionalField({ ...additionalField, ...updated, defaultValue: null })
                              }
                            />
                            <SelectInput
                              name="type"
                              label={lang.type}
                              data={Object.values(InputType).map((val) => ({ id: val, title: translate(val) }))}
                              value={additionalField.type}
                              onChange={(updated) =>
                                updateAdditionalField({ ...additionalField, ...updated, defaultValue: null })
                              }
                            />
                          </InputWrapper>
                          <InputWrapper>
                            <TextInput
                              name="group"
                              label={lang.group}
                              value={additionalField.group}
                              onChange={(updated) => updateAdditionalField({ ...additionalField, ...updated })}
                            />
                            <TextInput
                              name="label"
                              label={lang.label}
                              value={additionalField.label}
                              onChange={(updated) => updateAdditionalField({ ...additionalField, ...updated })}
                            />
                          </InputWrapper>
                          <DefaultValueInput
                            additionalField={additionalField}
                            onValueChange={(updated) => updateAdditionalField({ ...additionalField, ...updated })}
                          />
                          <CheckInput
                            name="isRequired"
                            label={lang.isRequired}
                            value={additionalField.isRequired}
                            onChange={(updated) => updateAdditionalField({ ...additionalField, ...updated })}
                            class={inputStyles.check.switch}
                          />
                        </>
                      )}
                    </Fragment>
                  );
                }}
              />
              <Button
                render={lang.save}
                media={<Icon class="fas-save" />}
                onClick={docForm.submit}
                disabled={docForm.hasErrors}
                active={!docForm.hasErrors}
                class={ThemeButtonCircle}
                htmlElementProps={{ title: lang.save }}
              />
            </>
          }
        />
      )}
    </>
  );
}
