import LinkButton from 'assets/components/button/LinkButton';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import DataMap from 'assets/components/dataMap/DataMap';
import { useModalForm } from 'assets/components/form/modalForm/ModalForm';
import Icon from 'assets/components/icon/Icon';
import FileInput from 'assets/components/inputs/file/FileInput';
import ThemeFileInputDropzone from 'assets/components/inputs/file/themes/Theme.FileInput.Dropzone';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { compact, first, flatMap, uniq } from 'lodash';
import Dealer from 'models/dealerManagement/dealer/Model.Dealer';
import Product from 'models/productManagement/product/Model.Product';
import { Fragment, useMemo } from 'react';
import { useDealerGroupProvider } from '../../DealerGroup.Hooks';
import { DealerGroupForm } from '../DealerGroup.Edit';

const DealerGroupProductDocumentForm = () => {
  const { form } = useModalForm<DealerGroupForm>();
  const { selected } = useDealerGroupProvider();
  const { lang } = getLocales();

  const dealerIds = useMemo(() => {
    const ids = uniq([...compact(selected?.dealers?.map((d) => d.id))]);
    return ids.length ? ids : undefined;
  }, [selected?.dealers]);
  const { payload: dealersPayload } = useApi(
    {
      action: Dealer.list,
      wait: !dealerIds,
      body: {
        id: dealerIds,
      },
    },
    [dealerIds]
  );

  const uniqueProductIds = useMemo(() => {
    const productIds = uniq(flatMap(dealersPayload?.data, (d) => d.products.map((p) => p.id)));
    return productIds.length ? productIds : undefined;
  }, [dealersPayload]);

  const { payload: productsPayload } = useApi(
    {
      action: Product.list,
      wait: !uniqueProductIds,
      body: {
        productId: uniqueProductIds,
      },
    },
    [uniqueProductIds]
  );

  return (
    <FormWrapper>
      <DataMap
        data={productsPayload?.data}
        render={({ data: product }) =>
          !product.productDocuments?.length ? null : (
            <Fragment key={product.id}>
              <h3>{product?.name}</h3>
              <DataMap
                data={product.productDocuments}
                render={({ data: productDocument }) => {
                  let currentDocs = form.data.productDealerGroupDocuments ?? [];
                  const foundDocs = currentDocs.filter(
                    (d) => d.productDocumentId === productDocument.id && !d.isDeleted
                  );
                  const dealerGroupProductDocumentMediaFile = currentDocs?.find(
                    (d) => d.id && d.productDocumentId === productDocument.id
                  )?.mediaFile;

                  return (
                    <Fragment key={productDocument.id}>
                      <FileInput
                        name="files"
                        fileLimit={1}
                        value={foundDocs?.map((it) => it.formFile ?? { id: it.id, name: it.mediaFile?.name })}
                        onChange={({ files }) => {
                          currentDocs = currentDocs.map((d) =>
                            d.productDocumentId === productDocument.id ? { ...d, isDeleted: true } : d
                          );
                          if (files.length) {
                            currentDocs.push({
                              productDocumentId: productDocument.id,
                              productDocument,
                              formFile: first(files),
                            });
                          }
                          form.update({ productDealerGroupDocuments: currentDocs });
                        }}
                        class={ThemeFileInputDropzone}
                        label={<b>{productDocument.displayInfo.title}</b>}
                      />
                      {dealerGroupProductDocumentMediaFile && (
                        <LinkButton
                          media={<Icon class="fas-download" />}
                          class={ThemeButtonCircle}
                          style={{ alignSelf: 'flex-end' }}
                          render={`${lang.download} - ${dealerGroupProductDocumentMediaFile?.name}`}
                          url={dealerGroupProductDocumentMediaFile?.url}
                        />
                      )}
                    </Fragment>
                  );
                }}
              />
            </Fragment>
          )
        }
      />
    </FormWrapper>
  );
};

export default DealerGroupProductDocumentForm;
