import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Collection from 'assets/components/data/Collection';
import HeaderData from 'assets/components/data/rows/HeaderData';
import HeaderRow from 'assets/components/data/rows/HeaderRow';
import Row from 'assets/components/data/rows/Row';
import RowActions from 'assets/components/data/rows/RowActions';
import RowData from 'assets/components/data/rows/RowData';
import RowImage from 'assets/components/data/rows/RowImage';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import Confirm from 'assets/components/page/templates/Confirm';
import ThemeConfirmDefault from 'assets/components/page/templates/themes/Theme.Confirm.Default';
import { getLocales } from 'assets/locales/Locale';
import { defaultDateFormat, defaultDateTimeFormat, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { combineStrings } from 'assets/utils/data/String';
import Contract from 'models/sales/contract/Model.Contract';
import useClaimCreator from 'module/claims/hooks/useClaimCreator';
import React, { useState } from 'react';
import { useContractProvider } from '../Contract.Hooks';
import FontNestedSizeVariable from 'assets/themes/variables/FontNestedSizeVariable';
import LinkButton from 'assets/components/button/LinkButton';
import dayjs from 'dayjs';

export default function ContractList() {
  const { lang } = getLocales();
  const { listApi, pageRouter } = useContractProvider();
  const { canCreateClaim, createClaim } = useClaimCreator();
  const [selected, select] = useState<Contract>();
  return (
    <>
      {selected && (
        <Confirm
          data={selected}
          onConfirm={() => createClaim(selected)}
          onClose={() => select(null)}
          actionName={lang.createClaimForContract}
          itemName={selected.displayInfo.title}
          confirmationType="nextButton"
          subcomponents={ThemeConfirmDefault}
        />
      )}
      <Collection class={ThemeCollectionList}>
        <HeaderRow>
          <HeaderData>{lang.appNumber}</HeaderData>
          <HeaderData>
            {lang.vehicle}/{lang.vin}
          </HeaderData>
          <HeaderData children={lang.client} />
          <HeaderData children={lang.dealer} />
          <HeaderData children={lang.createdAt} />
          <HeaderData children={lang.expiryDate} />
          <HeaderData numOfActions={1} />
        </HeaderRow>
        <DataMap
          data={listApi.payload?.data}
          render={({ data: contract }) => (
            <Row
              key={contract.id}
              children={
                <>
                  <RowImage render={<Icon class="fas-file-contract" />} />
                  <RowData
                    info={contract.statusCode?.replace('_', ' - ')}
                    title={contract.appNumber + (contract.revisionNumber ? `-R${contract.revisionNumber}` : '')}
                  />
                  <RowData
                    title={
                      <div style={{ fontSize: `${FontNestedSizeVariable.M}` }}>
                        <div>{contract.vehicle.year?.toString()}</div>
                        <div>
                          {contract.vehicle.makeCode} {contract.vehicle.model}
                        </div>
                      </div>
                    }
                    info={contract.vehicle?.vin}
                  />
                  <RowData children={combineStrings(' ', contract.client?.firstName, contract.client?.lastName)} />
                  <RowData children={contract.dealer?.name} />
                  <RowData>
                    <span>
                      {dayjs(contract?.createdAtLocal, defaultServerDateTimeFormat).format(defaultDateTimeFormat)}
                    </span>
                    <span>
                      by{' '}
                      {combineStrings(' ', contract.createdBy?.firstName, contract.createdBy?.lastName) ||
                        lang.unknownUser}
                    </span>
                  </RowData>
                  <RowData
                    children={dayjs(contract?.expiryDate, defaultServerDateTimeFormat).format(defaultDateFormat)}
                  />
                  <RowActions isVertical>
                    <Button
                      class={ThemeButtonCircle}
                      media={<Icon class="fas-info-circle" />}
                      onClick={pageRouter.updateParams}
                      data={{ id: contract.id.toString() }}
                      htmlElementProps={{
                        title: lang.info,
                      }}
                    />
                    <Button
                      class={ThemeButtonCircle}
                      media={<Icon class="fas-car-crash" />}
                      data={contract}
                      onClick={select}
                      disabled={!canCreateClaim}
                      htmlElementProps={{
                        title: lang.createClaimForContract,
                      }}
                    />
                    {contract.documentationAggregate && (
                      <LinkButton
                        class={ThemeButtonCircle}
                        url={contract.documentationAggregate?.url}
                        media={<Icon class="fas-download" />}
                        target="_blank"
                        disabled={!contract?.documentationAggregate?.url}
                        htmlElementProps={{ title: lang.download }}
                      />
                    )}
                  </RowActions>
                </>
              }
            />
          )}
        />
      </Collection>
    </>
  );
}
