import DataMap from 'assets/components/dataMap/DataMap';
import ModalForm from 'assets/components/form/modalForm/ModalForm';
import ThemeModalFormDefault from 'assets/components/form/modalForm/themes/Theme.ModalForm.Default';
import EmailHtml from 'assets/components/html-templates/email/EmailTemplate';
import Icon from 'assets/components/icon/Icon';
import RichTextInput from 'assets/components/inputs/richTextInput/RichTextInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { applicationOrigin } from 'config/Api.Config';
import Role from 'models/core/role/Model.Role';
import StatementEmailTemplateTags from 'module/management/components/statementEmailTemplate/StatementEmailTemplateTags';
import { renderToString } from 'react-dom/server';
import { StatementEmailTriggers, useStatementEmailTemplateProvider } from '../StatementEmailTemplate.Hooks';
import CheckWrapper from 'assets/components/inputs/wrappers/CheckWrapper';
import CheckInput from 'assets/components/inputs/check/CheckInput';
import { inputStyles } from 'assets/components/inputs/Input';

export default function StatementEmailTemplateEdit() {
  const { lang } = getLocales();
  const { selected, pageRouter, saveApi } = useStatementEmailTemplateProvider();
  const { payload: rolesPayload } = useApi({ action: Role.list });

  return (
    <>
      <ModalForm<Api.Core.StatementEmailTemplate.Save.IRequest>
        subcomponents={ThemeModalFormDefault}
        dependencyArray={[selected]}
        title={lang.emailTemplate}
        default={
          selected?.toJSON() || {
            htmlBody: renderToString(EmailHtml({ body: 'Message', origin: applicationOrigin })),
            additionalRecipientEmails: [],
          }
        }
        onCancel={pageRouter.updateParams}
        onSubmit={saveApi.execute}
        validation={(data, errors) => {
          if (!data.name) errors.name = lang.mustNotBeEmpty;
          if (!data.senderEmail) errors.senderEmail = lang.mustNotBeEmpty;
          if (!data.senderName) errors.senderName = lang.mustNotBeEmpty;
          if (!data.htmlBody) errors.htmlBody = lang.mustNotBeEmpty;
          if (!data.statementStatusTrigger) errors.statementStatusTrigger = lang.mustNotBeEmpty;
        }}
        render={({ form }) => (
          <>
            <FormWrapper>
              <TextInput
                name="name"
                label={lang.name}
                value={form.data.name}
                error={form.errors.name}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <InputWrapper>
                <TextInput
                  name="senderName"
                  label={lang.senderName}
                  value={form.data.senderName}
                  error={form.errors.senderName}
                  onChange={form.update}
                  icon={<Icon class="if-quill-pen" />}
                />
                <TextInput
                  name="senderEmail"
                  label={lang.senderEmail}
                  value={form.data.senderEmail}
                  error={form.errors.senderEmail}
                  onChange={form.update}
                  icon={<Icon class="if-quill-pen" />}
                />
              </InputWrapper>
              <SelectInput
                name="statementStatusTrigger"
                label={lang.statementStatusTrigger}
                data={Object.values({ ...StatementEmailTriggers })?.map((status) => ({
                  id: status,
                  title: status.replace(/_/g, ' - '),
                  icon: <Icon class="fas-box" />,
                }))}
                value={form.data.statementStatusTrigger}
                error={form.errors.statementStatusTrigger}
                onChange={form.update}
                icon={<Icon class="if-cloud" />}
              />
              <h2>{lang.sendCCTo}</h2>
              <CheckWrapper>
                <CheckInput
                  name="sendCCToRepresentative"
                  label={lang.representative}
                  class={inputStyles.check.switch}
                  value={form.data?.sendCCToRepresentative}
                  onChange={form.update}
                  htmlElementProps={{ title: form.data?.sendCCToRepresentative ? lang.disable : lang.enable }}
                />
              </CheckWrapper>
              <h2>{lang.sendTo}</h2>
              <SelectInput
                name="recipientsWithRole"
                label={lang.recipientsWithRole}
                data={rolesPayload?.data?.map((item) => ({
                  ...item.displayInfo,
                }))}
                value={form.data.recipientsWithRole?.map((it) => it.id)}
                isMultiselect
                onChange={({ recipientsWithRole: id }) =>
                  form.update({ recipientsWithRole: id?.map((id) => ({ id })) })
                }
                icon={<Icon class="if-cloud" />}
              />
              <h2>{lang.additionalRecipientEmails}</h2>
              <DataMap
                data={[...(form.data.additionalRecipientEmails || []), '']}
                render={({ data: email, index }) => (
                  <TextInput
                    key={index}
                    name="email"
                    label={lang.email}
                    value={email}
                    error={form.errors.additionalRecipientEmails}
                    onChange={({ email }) => {
                      let additionalRecipientEmails = form.data.additionalRecipientEmails || [];
                      additionalRecipientEmails.splice(index, 1, email);
                      additionalRecipientEmails = additionalRecipientEmails.filter((it) => it);
                      form.update({ additionalRecipientEmails });
                    }}
                    icon={<Icon class="if-quill-pen" />}
                  />
                )}
              />
              <h2>{lang.email}</h2>
              <TextInput
                name="subject"
                label={lang.subject}
                value={form.data.subject}
                error={form.errors.subject}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
              />
              <RichTextInput
                name="htmlBody"
                label={lang.htmlBody}
                value={form.data.htmlBody}
                error={form.errors.htmlBody}
                onChange={form.update}
                icon={<Icon class="if-quill-pen" />}
                customActions={<StatementEmailTemplateTags />}
              />
            </FormWrapper>
          </>
        )}
      />
    </>
  );
}
