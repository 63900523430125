import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import executeServerApi from 'assets/utils/server/ServerApi';
import { HttpMethod, getDefaultHeaders } from 'config/Api.Config';
import Model from 'models/Model';

export async function dealerDistributionDiscountSaveApi(body?: Api.Core.DealerDistributionDiscount.Save.IRequest) {
  const serverResponse = await executeServerApi<
    Api.Core.DealerDistributionDiscount.Save.IRequest,
    Api.Core.DealerDistributionDiscount.Save.IResponse,
    Api.Core.DealerDistributionDiscount.Save.IHeaders
  >({
    method: body?.id ? HttpMethod.put : HttpMethod.post,
    url: '/api/DealerManagement/DealerDistributionDiscount',
    body,
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
export async function dealerDistributionDiscountListApi(body?: Api.Core.DealerDistributionDiscount.List.IRequest) {
  return await executeServerApi<
    Api.Core.DealerDistributionDiscount.List.IRequest,
    Api.Core.DealerDistributionDiscount.List.IResponse,
    Api.Core.DealerDistributionDiscount.List.IHeaders
  >({
    method: HttpMethod.get,
    url: createPathFromRoute('/api/DealerManagement/DealerDistributionDiscount'),
    body,
    headers: getDefaultHeaders(),
  });
}
export async function dealerDistributionDiscountDeleteApi(id: number) {
  const serverResponse = await executeServerApi<
    Api.Core.DealerDistributionDiscount.Delete.IRequest,
    Api.Core.DealerDistributionDiscount.Delete.IResponse,
    Api.Core.DealerDistributionDiscount.Delete.IHeaders
  >({
    method: HttpMethod.delete,
    url: createPathFromRoute('/api/DealerManagement/DealerDistributionDiscount/:id', { id }),
    headers: getDefaultHeaders(),
  });
  return serverResponse;
}
export default class DealerDistributionDiscount
  extends Model<Model.IDealerDistributionDiscount>({ icon: 'fas-cloud' })
  implements Model.Instance<Model.IDealerDistributionDiscount>
{
  id: number;
  productId: number;
  dealerId: number;
  distributionDiscount: Utils.DiscountDistribution;
  distributorDistributionDiscount: Utils.DiscountDistribution;

  pricePointId: number;
  pricePointType: Utils.PricePointType;

  constructor(
    data: Partial<Model.IDealerDistributionDiscount> | Utils.FormData<Model.IDealerDistributionDiscount>,
    language?: Locale.Languages
  ) {
    super({}, language);
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    return {
      id: this.id,
      title: this.id.toString(),
    };
  }

  static async list(body?: Api.Core.DealerDistributionDiscount.List.IRequest) {
    const { payload, ...rest } = await dealerDistributionDiscountListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new DealerDistributionDiscount(it)),
        count: payload.count,
      },
    };
  }
  async save() {
    return await dealerDistributionDiscountSaveApi(this);
  }
  async delete() {
    return await dealerDistributionDiscountDeleteApi(this.id);
  }
}
