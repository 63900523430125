import React from 'react';
import { defaultServerDateTimeFormat, defaultDateTimeFormat, defaultDateFormat, date } from 'assets/utils/data/Date';
import { isImageUrl, isIcon, isNumberWithMeasurementUnit } from 'assets/utils/parsersAndValidation/Validators';
import Icon, { iconStyles } from '../icon/Icon';
import TextBox from '../textBox/TextBox';
import { getLocales } from 'assets/locales/Locale';
import { formatNumberWithSeparator, formatNumberWithSeparatorWithoutDecimales } from 'assets/utils/data/Number';
import dayjs from 'dayjs';

export default function FormatValue(props: Component.FormatValue.Import) {
  const { lang } = getLocales();
  const possibleDate = props.value
    ?.toString()
    ?.replace(/\.\d+$/g, '')
    .toString();
  const d =
    possibleDate &&
    (possibleDate as string).match(/\d{4}-\d{2}-\d{2}(T|\s)\d{2}:\d{2}:\d{2}/g) &&
    possibleDate?.length === defaultServerDateTimeFormat.length
      ? props.objKey?.toLowerCase().indexOf('utc') >= 0
        ? date(possibleDate, defaultServerDateTimeFormat, true)
        : dayjs(possibleDate, defaultServerDateTimeFormat, true)
      : null;
  if (d?.isValid())
    return <>{d.format(d.isSame(d.clone().startOf('day')) ? defaultDateFormat : defaultDateTimeFormat)}</>;
  else {
    if (isImageUrl(props.value)) return <Icon class={iconStyles.common.image} />;
    else if (isIcon(props.value)) return <Icon class={props.value} />;
    else if (isNumberWithMeasurementUnit(props.value)) {
      const [num, measurement] = props.value?.toString()?.match(/\d+(\.\d+)?|\w+|\$/g) || [];
      return (
        <>
          {measurement === '$' && <TextBox class="measurement" text={measurement} />}
          {formatNumberWithSeparator(num || 0)}
          {measurement !== '$' && <TextBox class="measurement" text={measurement} />}
        </>
      );
    } else if (typeof props.value == 'boolean')
      return <Icon class={props.value ? iconStyles.common.checkBoxChecked : iconStyles.common.checkBox} />;
    else if (typeof props.value == 'number' || !isNaN(Number(props.value)))
      return (
        <>
          {props.value.toString().indexOf('.') >= 0
            ? formatNumberWithSeparator(Number(props.value))
            : formatNumberWithSeparatorWithoutDecimales(Number(props.value))}
        </>
      );
    else if (props.value) return <>{props.value}</>;
    else return <>{lang.empty}</>;
  }
}
