import Button from 'assets/components/button/Button';
import LinkButton from 'assets/components/button/LinkButton';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardGridItem from 'assets/components/card/themes/Theme.Card.GridItem';
import { addLog } from 'assets/components/feedback/Feedback';
import useForm from 'assets/components/form/hooks/Form';
import Icon from 'assets/components/icon/Icon';
import FileInput from 'assets/components/inputs/file/FileInput';
import ThemeFileInputDropzone from 'assets/components/inputs/file/themes/Theme.FileInput.Dropzone';
import MultilineTextInput from 'assets/components/inputs/multilineText/MultilineTextInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { combineStrings } from 'assets/utils/data/String';
import { HttpStatus } from 'config/Api.Config';
import claimJobDocumentDeleteApi from 'models/claims/claimJobDocument/delete/Api.ClaimJobDocument.Delete';
import claimJobDocumentSaveApi from 'models/claims/claimJobDocument/save/Api.ClaimJobDocument.Save';
import ClaimDocumentPreview from 'module/claims/views/claim/other/Claim.DocumentPreview';
import { useEffect, useMemo, useState } from 'react';
import { useClaimJobEdit } from './ClaimEdit.Job.Hooks';
import { isEmpty } from 'lodash';

type DeleteJobDocumentProps = { documentId: number; claimJobId: number };
export default function ClaimEditJobDocuments() {
  const { lang } = getLocales();
  const { reload, uploadedDocuments, uploadableDocuments, claimJob, isClaimJobEditable, isClaimJobEditableByStatus } =
    useClaimJobEdit();
  const { execute: deleteClaimJobDocument } = useApi({
    action: claimJobDocumentDeleteApi,
    wait: true,
    callback: reload,
  });

  const onJobDocumentDelete = ({ documentId, claimJobId }: DeleteJobDocumentProps) =>
    deleteClaimJobDocument({ claimJobId, documentId });

  const [document, selectDocument] = useState<Model.IClaimTypeDocument>();
  useEffect(() => {
    selectDocument(uploadableDocuments[0]);
  }, [uploadableDocuments]);
  const docForm = useForm<Model.IClaimJobDocument>(
    {
      default: !document
        ? {}
        : {
            name: document.name,
            claimTypeDocumentId: document.id,
            approved: document.approvalRequired ? false : true,
          },
      validation: (data, errors) => {
        if (!data.claimTypeDocumentId) errors.claimTypeDocumentId = lang.required;
        if (isEmpty(data?.formFiles) && document?.required) errors.formFiles = lang.required;
      },
      onSubmit: async (data) => {
        let totalFiles = 0;
        let totalUploadedFiles = 0;
        let totalFailedFiles = 0;
        for (const file of data.formFiles) {
          totalFiles += 1;
          if (file) {
            const res = await claimJobDocumentSaveApi({ ...data, formFile: file, claimJobId: claimJob.id });
            if (res.status === HttpStatus.ok) totalUploadedFiles += 1;
            else totalFailedFiles += 1;
          }
        }
        addLog({
          success: `${totalUploadedFiles} out of ${totalFiles} files were uploaded successfully. ${totalFailedFiles} file(s) failed to upload. Please retry for any failed uploads if necessary.`,
        });
        docForm.reload();
        reload();
      },
    },
    [document]
  );

  const formFiles = useMemo(
    () =>
      docForm.data.formFiles &&
      docForm.data.formFiles.map((it) => ({
        original: it,
        type: it?.type as Utils.File.MimeType,
        dataUrl: window.URL.createObjectURL(it),
      })),
    [docForm.data.formFiles]
  );

  return !uploadableDocuments.length ? null : (
    <div style={{ marginLeft: Theme.Size.XL, marginRight: Theme.Size.XL }}>
      <h2 style={{ display: 'flex', justifyContent: 'space-between' }}>
        {lang.documents}
        <span>
          <SelectInput
            name="docId"
            style={{
              formInput: {
                maxWidth: Theme.Size.pixel(240),
              },
            }}
            placeholder={lang.documents + '...'}
            data={uploadableDocuments?.map((document) => ({
              id: document.id,
              title: combineStrings(' - ', document.name, document.source),
            }))}
            value={document?.id}
            onChange={({ docId }) => selectDocument(uploadableDocuments.find((it) => it.id === docId))}
            icon={<Icon class="fas-file" />}
          />
        </span>
      </h2>
      <ClaimDocumentPreview
        documents={uploadedDocuments.map((it) => ({
          id: it.id,
          url: it.mediaFile?.url,
          name: it.name,
          extension: it.mediaFile?.extension,
          description: it.notes,
          approved: it.approved,
          onDelete: () => onJobDocumentDelete({ documentId: it.id, claimJobId: it.claimJobId }),
        }))}
      />
      <br />
      <FileInput
        name="files"
        fileLimit={document?.allowMultiple ? 5 : 1}
        value={formFiles}
        onChange={({ files }) => {
          const updatedFormFiles = files?.map((formFile) => formFile && formFile.original);
          docForm.update({ formFiles: updatedFormFiles });
        }}
        class={ThemeFileInputDropzone}
        disabled={!document || isClaimJobEditableByStatus || !isClaimJobEditable}
        error={docForm.errors?.formFiles?._objectError}
        renderBefore={
          !document ? (
            lang.youNeedToSelectOneDocument
          ) : (
            <Card
              class={ThemeCardGridItem}
              style={{
                cardMedia: { width: 0, height: 0 },
                card: { width: '400px', borderRadius: '10px' },
                cardActions: { marginBottom: '5px' },
              }}
              actions={
                document.example && (
                  <LinkButton
                    class={ThemeButtonCircle}
                    style={{ fontSize: Theme.FontSize.M, margin: 0 }}
                    url={document.example}
                    media={<Icon class="fas-external-link-alt" />}
                    render={lang.example}
                    target="_blank"
                  />
                )
              }
              info={document.source}
              title={combineStrings(' - ', document.name)}
              description={document.description}
            />
          )
        }
        renderAfter={
          <>
            <TextInput
              name="name"
              label={lang.name}
              value={docForm.data.name}
              onChange={docForm.update}
              error={docForm.errors.name}
              disabled={isClaimJobEditableByStatus || !isClaimJobEditable}
            />
            &nbsp;
            {document?.allowNotes && (
              <MultilineTextInput
                name="notes"
                label={lang.notes}
                value={docForm.data.notes}
                onChange={docForm.update}
                error={docForm.errors.notes}
                disabled={isClaimJobEditableByStatus || !isClaimJobEditable}
              />
            )}
          </>
        }
      />
      <br />
      <Button
        render={lang.add}
        media={<Icon class="fas-upload" />}
        onClick={docForm.submit}
        disabled={docForm.hasErrors || !document || isClaimJobEditableByStatus || !isClaimJobEditable}
        active={!docForm.hasErrors}
        class={ThemeButtonCircle}
        style={{ marginLeft: 'auto' }}
      />
      <br />
    </div>
  );
}
