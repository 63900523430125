import Icon from 'assets/components/icon/Icon';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import { getLocales } from 'assets/locales/Locale';
import { LocationSearch } from 'assets/templates/locationSearch/LocationSearch';
import Theme from 'assets/themes/Theme.Common';
import { formatFormPhone } from 'assets/utils/forms/formatFormPhone';
import PayeeType from 'models/claims/payeeType/Model.PayeeType';

type Props = {
  form: Hooks.Form.Export<Module.Claims.Claim.Submit.Form>;
};

export const PublicClaimSubmitRepairFacilityForm = ({ form }: Props) => {
  const { lang } = getLocales();
  const payeeData = form.data?.newPayee;
  const payeeErrors = form.errors?.newPayee;

  function updatePayee(payee: Utils.FormData<Model.IPayee>) {
    form.merge({ newPayee: { ...payee, payeeTypeId: PayeeType.REPAIR_FACILITY_ID } });
  }

  return (
    <FormWrapper style={{ padding: 0 }}>
      <LocationSearch
        onSelect={(location) => {
          updatePayee({
            streetAddress: location?.Line1 || null,
            provinceCode: location?.ProvinceCode || null,
            city: location?.City || null,
            countryCode: location?.CountryIso3 || null,
            postalCode: location?.PostalCode || null,
          });
        }}
      />
      <InputWrapper>
        <TextInput
          name="streetAddress"
          label={lang.streetAddress}
          value={payeeData?.streetAddress}
          error={payeeErrors?.streetAddress}
          onChange={updatePayee}
          icon={<Icon class="if-map-pin" />}
        />
        <TextInput
          name="postalCode"
          label={lang.postalCode}
          value={payeeData?.postalCode}
          error={payeeErrors?.postalCode}
          onChange={updatePayee}
          icon={<Icon class="if-map-pin" />}
        />
        <TextInput
          name="city"
          label={lang.city}
          value={payeeData?.city}
          error={payeeErrors?.city}
          onChange={updatePayee}
          icon={<Icon class="if-map-pin" />}
        />
        <TextInput
          name="provinceCode"
          label={payeeData?.countryCode}
          value={payeeData?.provinceCode}
          error={payeeErrors?.provinceCode}
          onChange={updatePayee}
          icon={<Icon class="if-map-pin" />}
          style={{ width: Theme.Size.XXL }}
        />
        <TextInput
          name="countryCode"
          label={lang.countryCode}
          value={payeeData?.countryCode}
          error={payeeErrors?.countryCode}
          onChange={updatePayee}
          icon={<Icon class="if-map-pin" />}
          style={{ width: Theme.Size.XXL }}
          readOnly
        />
      </InputWrapper>
      <TextInput
        name="name"
        label={lang.name}
        value={payeeData?.name}
        error={payeeErrors?.name}
        onChange={updatePayee}
        icon={<Icon class="if-quill-pen" />}
      />
      <TextInput
        name="phoneNumber"
        label={lang.phoneNumber}
        value={payeeData?.phoneNumber}
        error={payeeErrors?.phoneNumber}
        onChange={({ phoneNumber }) =>
          updatePayee({ phoneNumber: formatFormPhone(payeeData?.phoneNumber, phoneNumber) })
        }
        icon={<Icon class="if-quill-pen" />}
      />
      <TextInput
        name="email"
        label={lang.email}
        value={payeeData?.email}
        error={payeeErrors?.email}
        onChange={updatePayee}
        icon={<Icon class="if-quill-pen" />}
      />
    </FormWrapper>
  );
};
