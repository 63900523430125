import { ProgressInfo } from 'assets/utils/data/Iterations';
import './ProgressWindow.scss';
import { getLocales } from 'assets/locales/Locale';

export type ProgressKey =
  | 'priceDistribution'
  | 'distributorPriceDistribution'
  | 'dealerLimit'
  | 'dealerDiscount'
  | 'syncAllWithVertafore';
const PROGRESS_WINDOW_DOM_ID = 'progressWindow';

function getProgressTitleByKey(progressKey: ProgressKey) {
  const { lang } = getLocales();

  return progressKey === 'syncAllWithVertafore'
    ? lang.syncing
    : progressKey === 'priceDistribution'
    ? lang.prices
    : progressKey === 'distributorPriceDistribution'
    ? lang.distributorDistributions
    : progressKey === 'dealerLimit'
    ? lang.limits
    : lang.adjustments;
}

export default function ProgressWindow() {
  return <div id={PROGRESS_WINDOW_DOM_ID} className="ProgressWindow" />;
}

export function addProgressToWindow(progressKey: ProgressKey, progressInfo: ProgressInfo) {
  const progressWindow = document.getElementById(PROGRESS_WINDOW_DOM_ID);
  if (progressWindow) {
    let progressSection = progressWindow.querySelector(`#${progressKey}`);
    if (!progressSection) {
      progressSection = document.createElement('div');
      progressSection.id = progressKey;
      progressWindow.appendChild(progressSection);
    }
    progressSection.setAttribute('progress', progressInfo.percentCompleted.toFixed(2));
    progressSection.innerHTML = `${getProgressTitleByKey(progressKey)}: ${progressInfo.currentItem}/${
      progressInfo.totalItems
    } | ${progressInfo.percentCompleted.toFixed(2)}% | Estimated ${(progressInfo.timeEstimateMs / 1000).toFixed(0)}s`;

    if (
      Array.from(progressWindow.getElementsByTagName('*')).every(
        (it) => it.getAttribute('progress')?.toString() === '100.00'
      )
    ) {
      progressWindow.innerHTML = '';
    }
  }
}
